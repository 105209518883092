import React, { useState, useEffect } from 'react';
import { Button, Select, Input, Form, InputNumber, Row, Col, Tooltip } from 'antd';
import { BillItems } from 'sigt';
import { getBothOrdinances, getOrdinancesByType } from '../../services/ordinances';
import _ from 'lodash';
import BillTable from './BillTable';

const OrdinanceBill: React.FC<OrdinanceBillProps> = ({ procedureType, codCatId, handleSetBill}) => {
  const [ordinances, setOrdinances] = useState<Ordinance[]>([]);
  const [metros, setMetros] = useState(1.00);
  const [selected, setSelected] = useState<any>();
  const [data, setData] = useState<BillItems[]>([]);
  const [totalPetro, setTotalPetro] = useState(0);
  const [totalBs, setTotalBs] = useState(0);

  const [form] = Form.useForm();

  const getOrdinances = async () => {
    const { ordenanzas, metrosConstruccion } = codCatId 
      ? await getBothOrdinances(codCatId,procedureType) 
      : await getOrdinancesByType(procedureType);
    setOrdinances(ordenanzas);
    setSelected(null);
    setData([]);
    form.setFieldsValue({ ordenanza: null });
    setTotalPetro(0);
    setTotalBs(0);
    if (metrosConstruccion) setMetros(parseFloat(metrosConstruccion));
  };

  useEffect(() => {
    getOrdinances();
    // eslint-disable-next-line
  }, [procedureType]);

  useEffect(()=>{
    handleSetBill({ totalBs, totalPetro, items: data });
    // eslint-disable-next-line
  },[totalPetro,totalBs,data])

  useEffect(()=>{  
    let prcd = ordinances.find(p=> p.id === selected);
    const petro = typeof prcd?.tarifa === 'string' ? parseFloat(prcd?.tarifa) : prcd?.tarifa;
    if (prcd?.utilizaCodCat || prcd?.utilizaVariable){
      form.setFieldsValue({
        factor: prcd?.utilizaCodCat ? 'Area (m²)' : prcd?.variable, 
        factorValue: prcd?.utilizaCodCat ? metros : undefined, 
        valorCalc: prcd?.utilizaCodCat ? prcd?.valorCalc : undefined,
      });
    }else{
      form.setFieldsValue({ valorCalc: prcd?.costoOrdenanza });
    }
    form.setFieldsValue({ petro, costoOrdenanza: prcd?.costoOrdenanza });
    // eslint-disable-next-line
  },[selected]);

  const onFinish = async (values) => {
    values.id = values.ordenanza;  
    values.ordenanza = ordinances.find(({id}) => id === values.ordenanza)?.descripcionOrdenanza;
    setData([...data,values]);
    form.resetFields();
  };

  const handleDelete = (id: number) => {
    setSelected(null);
    setData(data.filter((value : any )=> value.id !== id));
  }

  const setTotal = ({totalPetro, totalBs}) => {
    setTotalBs(totalBs);
    setTotalPetro(totalPetro);
  }

  const onChangeVariable = (value) => {
    const costoOrdenanza = form.getFieldValue('costoOrdenanza');
    if (costoOrdenanza){
      const valorCalc = Math.round(((costoOrdenanza * value) + Number.EPSILON) * 100) / 100
      form.setFieldsValue({ valorCalc });
    }
  }

  return (
    <React.Fragment>
      <Form form={form} name="ordinance_items" onFinish={onFinish} layout='vertical' >
        <Row gutter={16} align='bottom' >
          <Col xs={24} sm={18} md={16}>
            <Form.Item label='Ordenanza' rules={[{required: true, message: 'Por favor seleccione un item'}]} name='ordenanza'>
              <Select onChange={(value:number) => setSelected(value)} placeholder='Seleccione Item' style={{ width: '100%' }} >
                {ordinances.map(({id,descripcionOrdenanza}) => (
                  typeof _.find(data,{id}) !== 'object' ?
                  <Select.Option key={id} value={id}>
                    <Tooltip title={descripcionOrdenanza}>
                      {descripcionOrdenanza}
                    </Tooltip>
                  </Select.Option> : null 
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button type='primary' htmlType='submit' >
                Agregar
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          { ordinances.find(({id}) => id===selected)?.utilizaCodCat === true || ordinances.find(({id}) => id===selected)?.utilizaVariable === true ? 
            <React.Fragment>
              <Col flex={1}>
                <Form.Item label='Descripción del factor' name='factor'>
                  <Input disabled placeholder='Descripción del factor'/>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item label='Valor del factor' rules={[{required: true, message: 'Ingrese el valor de la variable'}]} name='factorValue'>
                  <InputNumber onChange={onChangeVariable} style={{width:'100%'}} min={1} 
                    disabled={ordinances.find(({id}) => id===selected)?.utilizaCodCat === true} placeholder='Valor del factor' 
                  />
                </Form.Item>
              </Col>
            </React.Fragment> 
            : null
          }
          <Col flex={1}>
            <Form.Item label='Tarifa en PETRO' name='petro'>
              <InputNumber style={{width:'100%'}} disabled />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item label='Costo Ordenanza' name='costoOrdenanza'>
              <InputNumber style={{width:'100%'}} disabled
                formatter={value => `Bs ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value ? value.replace(/Bs\s?|(,*)/g, '') : ''} 
              />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item label='Total Calculado' name='valorCalc'>
              <InputNumber
                formatter={value => `Bs ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value ? value.replace(/Bs\s?|(,*)/g, '') : ''} 
                style={{width:'100%'}} disabled
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <BillTable data={data} handleDelete={handleDelete} setTotal={setTotal}/>
    </React.Fragment>
  );
};

export default OrdinanceBill;

interface OrdinanceBillProps {
  handleSetBill: Function;
  procedureType: number;
  codCatId?: string;
}

interface Ordinance {
  costoOrdenanza: string;
  descripcionOrdenanza: string;
  tarifa:string;
  utilizaCodCat: Boolean;
  utilizaVariable: Boolean;
  valorCalc?: number;
  variable: string;
  id: number
}