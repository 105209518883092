import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Select, Form, Input, Button, Divider, Typography, Checkbox, Modal, message } from 'antd';
import { State, States } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import axios, { AxiosResponse } from 'axios';
import { useLocation, useHistory, useParams } from 'react-router';
import { Utils } from '../../utils/validators';
import { UploadOutlined } from '@ant-design/icons';
import { setSupport, changeSupportData } from '../../redux/actions/procedures';
import pdfImage from '../../assets/images/pdf.png';
import excelImage from '../../assets/images/excel.png';
const server = process.env.REACT_APP_SERVER_URL;

const SupportForm: React.FC<SupportFormProps> = ({ thm, auth, setSupport, prcd, changeSupportData }) => {
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [action, setAction] = useState<boolean>();
  const [uploadFiles, setUploadFiles] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const fileRef = useRef<any>(null);
  const history = useHistory();
  const [previewImage, setPreviewImage] = useState<string>('');
  const location = useLocation();
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [state, setState] = useState<string | undefined>();
  const [type, setType] = useState<string | undefined>();
  const [previewTitle, setPreviewTitle] = useState<string>('');
  const create = location.pathname.includes('/dashboard/soporte/crear');
  const view = location.pathname.includes('/dashboard/soporte/ver/ticket');
  const process = location.pathname.includes('/dashboard/soporte/ticket');
  const title = create ? 'Crear Ticket' : process ? 'Procesar Ticket' : 'Ver Ticket';
  const params: any = useParams();
  const data = prcd.support?.find((e) => e.id === +params.id);
  const canAswer = ['finalizado', 'enproceso', 'enrevision'];
  const isExcel = ['xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xls', 'xlt', 'xls', 'xml', 'xml', 'xlam', 'xla', 'xlw', 'XLR'];

  const handleSubmit = async () => {
    const values = await form.validateFields();
    setLoading(true);
    const datos = { ...values, nombre: auth.user?.nombreCompleto };
    try {
      if (create) {
        const fd = new FormData();
        if (fileList) Object.keys(fileList).forEach((e) => fd.append('recaudos', fileList[e].file));
        const tramite = {
          datos,
          tipoTramite: 37,
        };
        const resp = await axios.post(
          `${server}/procedures/init`,
          { tramite },
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        if (resp.status === 201) {
          const response: AxiosResponse<TakingUploadResponse> = await axios.post(
            `${server}/uploads/takings/${resp.data.tramite.codigoTramite}`,
            fd,
            { headers: { Authorization: `Bearer ${auth.token}` } }
          );
          if (response.status === 200) {
            message.success('Tramite creado exitosamente');
            setSupport(resp.data.tramite);
            form.resetFields();
            setFileList([]);
          }
        }
      } else if (process) {
        const tramite = {
          datos,
          idTramite: data?.id,
          aprobado: action,
          estado: type,
          revision: { aprobado: true },
        };
        const resp = await axios.put(
          `${server}/procedures/update`,
          { tramite, idUsuario: auth.user?.id },
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        if (resp.status === 200) {
          message.success('Tramite actualizado exitosamente');
          changeSupportData(resp.data.tramite.id, resp.data.tramite);
          form.resetFields();
          setFileList([]);
        }
      }
      history.push('/dashboard/soporte/inbox');
    } catch (e) {
      message.error('Error en el servidor');
    } finally {
      setLoading(false);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (file) {
        const type = file.type.split('/')[1] ? file.type.split('/')[1] : isExcel.includes(file.name.split('.')[1]) ? 'excel' : '';
        switch (type) {
          case 'pdf':
            reader.readAsDataURL(file);
            reader.onload = () => resolve(pdfImage);
            reader.onerror = (error) => reject(error);
            break;
          case 'excel':
            reader.readAsDataURL(file);
            reader.onload = () => resolve(excelImage);
            reader.onerror = (error) => reject(error);
            break;
          default:
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
            break;
        }
      }
    });
  };

  const handleUpload = async (target) => {
    const url = await getBase64(target.files[0]);
    const data = { file: target.files[0], url };
    setFileList([...fileList, data]);
  };

  const handlePreview = async (file, index) => {
    if (file.url) {
      setPreviewImage(file.url);
    } else {
      setPreviewImage(file);
    }
    setPreviewVisible(true);
    setPreviewTitle(file?.file?.name ? file?.file?.name : `Recaudo ${index + 1}`);
  };

  useEffect(() => {
    if (create) form.setFieldsValue({ tipoDocumento: 'V' });
    if (view || process) {
      const aux = canAswer.filter((e) => e !== 'enproceso').includes(data?.estado || '')
        ? data?.datos.funcionario
        : data?.datos.usuario;
      form.setFieldsValue({
        ...aux,
        estado: data?.estado === 'enrevision' ? 'finalizado' : data?.estado !== 'enproceso' ? data?.estado : 'enrevision',
      });
      setType(data?.estado === 'enrevision' ? 'finalizado' : 'enrevision');
      setState(data?.estado);
      if (data?.recaudos?.length) {
        setFileList(data.recaudos);
        setUploadFiles(true);
      }
    }
    //eslint-disable-next-line
  }, []);

  const imagePreview = (previewImage) => {
    if (previewImage.includes('pdf')) {
      return <img alt='pdf' style={{ width: '30%' }} src={pdfImage} />;
    } else if (previewImage.includes('excel')) {
      return <img alt='excel' style={{ width: '30%' }} src={excelImage} />;
    } else {
      return <img alt='imagen' style={{ width: '90%' }} src={previewImage} />;
    }
  };

  const deleteFile = (name) => {
    setFileList(fileList.filter((e) => e.file.name !== name));
    setPreviewVisible(false);
  };
  const preview = (
    <Modal
      width={700}
      visible={previewVisible}
      title={previewTitle}
      footer={
        create
          ? [
              <Button key='delete' type='danger' onClick={() => deleteFile(previewTitle)}>
                Eliminar
              </Button>,
            ]
          : null
      }
      onCancel={() => setPreviewVisible(false)}
      bodyStyle={{ padding: '20px', textAlign: 'center' }}
    >
      <div>{imagePreview(previewImage)}</div>
      {!create && (
        <Button style={{ marginTop: '15px' }} target='_blank' type='primary' href={previewImage}>
          Descargar
        </Button>
      )}
    </Modal>
  );
  const docTypeSelect = (
    <Form.Item noStyle name='tipoDocumento'>
      <Select disabled={!create}>
        <Select.Option value='J'>J</Select.Option>
        <Select.Option value='G'>G</Select.Option>
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='P'>P</Select.Option>
      </Select>
    </Form.Item>
  );
  const coverImg = (file) => {
    if (file.url) {
      return <img src={file.url} style={{ width: '100%', height: 120, objectFit: 'contain' }} alt='' />;
    } else {
      if (file?.includes('pdf')) {
        return <img src={pdfImage} style={{ width: '100%', height: 120, objectFit: 'contain' }} alt='' />;
      } else if (file?.includes('excel')) {
        return <img src={excelImage} style={{ width: '100%', height: 120, objectFit: 'contain' }} alt='' />;
      } else {
        return <img src={file} style={{ width: '100%', height: 120, objectFit: 'contain' }} alt='' />;
      }
    }
  };
  const content = (
    <Form form={form} onFinish={handleSubmit} layout='vertical'>
      {data?.usuarioSoporte && (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '16px', fontWeight: 'bold' }}>
          <Typography.Text strong>
            USUARIO SOPORTE: {data?.usuarioSoporte ? data?.usuarioSoporte.toUpperCase() : ''}
          </Typography.Text>
        </div>
      )}
      <Divider orientation='left'>
        <Typography.Title level={4}>Datos del Ticket</Typography.Title>
      </Divider>
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        <Col xl={12} md={12} xs={24}>
          <Form.Item label='Documento' normalize={Utils.normalize.isDoc} name='documento'>
            <Input readOnly={!create} addonBefore={docTypeSelect} placeholder='Documento' />
          </Form.Item>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Form.Item label='R.I.M' normalize={Utils.normalize.toUpper} name='rim'>
            <Input readOnly={!create} placeholder='RIM' />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        <Col xl={24} md={24} xs={24}>
          <Form.Item
            label='Tipo de Incidente'
            name='tipo'
            rules={[{ required: true, message: 'Debe ingresar el tipo de incidente' }]}
          >
            <Select disabled={!create} placeholder='Tipo'>
              <Select.Option value='1'>Error al importar usuario de GTIC</Select.Option>
              <Select.Option value='2'>Error de credenciales en SUT</Select.Option>
              <Select.Option value='3'>Problemas con datos del contribuyente</Select.Option>
              <Select.Option value='4'>Caso de deudas erroneas</Select.Option>
              <Select.Option value='5'>Corregir liquidaciones y fechas</Select.Option>
              <Select.Option value='6'>Pagos sin validar</Select.Option>
              <Select.Option value='7'>Pagos devueltos por sistema</Select.Option>
              <Select.Option value='8'>Error de Cajero</Select.Option>
              <Select.Option value='9'>Error de Analista</Select.Option>
              <Select.Option value='10'>Falla en funcion del sistema</Select.Option>
              <Select.Option value='11'>Otro caso</Select.Option>
              <Select.Option value='12'>Cambio o inclusion de Activididad Economica (Aforo)</Select.Option>
              <Select.Option value='13'>Declaracion de años pasados</Select.Option>
              <Select.Option value='14'>Solicitud de Exoneración Especial</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        <Col flex={1}>
          <Form.Item
            label='Observación'
            rules={[{ required: true, message: 'Por favor ingresar observación!' }]}
            name='observacion'
          >
            <Input.TextArea readOnly={!create} maxLength={250} style={{ resize: 'none' }} placeholder='Observación' />
          </Form.Item>
        </Col>
      </Row>
      {create && (
        <Row style={{ marginLeft: 16 }} gutter={16}>
          <Col span={24}>
            <Checkbox value={uploadFiles} onChange={() => setUploadFiles(!uploadFiles)}>
              Archivos Adjuntos
            </Checkbox>
          </Col>
        </Row>
      )}
      {uploadFiles && (
        <>
          <Divider orientation='left'>
            <Typography.Title level={4}>Archivos Adjuntos</Typography.Title>
          </Divider>
          {create && (
            <Row style={{ marginLeft: 16 }}>
              <input
                accept='image/*'
                onChange={({ target }) => handleUpload(target)}
                ref={fileRef}
                type='file'
                style={{ display: 'none' }}
              />
              <Button icon={<UploadOutlined />} onClick={() => fileRef.current.click()}>
                Subir Archivo
              </Button>
            </Row>
          )}
          <Row style={{ marginLeft: 8, marginTop: 10 }} gutter={[16, 16]}>
            {fileList.map((e, index) => (
              <Col xl={6} xs={12} key={index}>
                <Card
                  bodyStyle={{ display: 'none' }}
                  hoverable
                  onClick={() => handlePreview(e, index)}
                  style={{ width: '100%' }}
                  cover={coverImg(e)}
                />
              </Col>
            ))}
          </Row>
          {preview}
        </>
      )}
      {canAswer.includes(data?.estado || '') && (
        <>
          <Row style={{ paddingLeft: 16 }} gutter={16}>
            <Col xl={24} md={24} xs={24}>
              <Form.Item
                label='Respuesta'
                rules={[{ required: type?.includes('finalizado'), message: 'Por favor ingresar respuesta!' }]}
                name='respuesta'
              >
                <Input.TextArea style={{ resize: 'none' }} readOnly={data?.estado === 'finalizado'} maxLength={250} />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ paddingLeft: 16 }} gutter={16}>
            <Col xl={12} md={12} xs={24}>
              <Form.Item
                label={data?.estado === 'finalizado' ? 'Estado' : 'Proximo Estado'}
                rules={[{ required: true, message: 'Por favor ingresar un estado!' }]}
                name='estado'
              >
                <Select
                  onChange={(e: string) => setType(e)}
                  disabled={canAswer.filter((e) => e !== 'enproceso').includes(data?.estado || '')}
                  placeholder='Estado'
                >
                  <Select.Option value='enrevision'>En proceso</Select.Option>
                  <Select.Option value='finalizado'>Atendido</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      {!view ? (
        state !== 'enproceso' ? (
          <Row align='middle' justify='end' style={{ marginLeft: 16, marginTop: 16 }} gutter={16}>
            <Col>
              <Button onClick={() => history.goBack()}>Atras</Button>
            </Col>
            <Col>
              <Button loading={loading} type='primary' htmlType='submit'>
                {create ? 'Crear' : 'Procesar'}
              </Button>
            </Col>
          </Row>
        ) : (
          <Row align='middle' justify='end' style={{ marginLeft: 16, marginTop: 16 }} gutter={16}>
            <Col>
              <Button onClick={() => history.goBack()}>Atras</Button>
            </Col>
            <Col>
              <Button loading={loading} onClick={() => setAction(true)} type='primary' htmlType='submit'>
                Atender
              </Button>
            </Col>
          </Row>
        )
      ) : (
        <Row align='middle' style={{ marginLeft: 16, marginTop: 16 }} gutter={16}>
          <Button onClick={() => history.goBack()}>Atras</Button>
        </Row>
      )}
    </Form>
  );

  return (
    <Card
      style={{ height: '100%' }}
      title={title}
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      {content}
    </Card>
  );
};

interface SupportFormProps {
  auth: States.Auth;
  thm: States.ThemeColors;
  prcd: States.Procedures;
  setSupport: Function;
  changeSupportData: Function;
}

interface TakingUploadResponse {
  recaudos: string[];
}

const mapStateToProps = ({ thm, auth, prcd }: State) => ({ thm, auth, prcd });

export default connect(mapStateToProps, { setSupport, changeSupportData })(SupportForm);
