import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Form, Input, Tooltip, Button, Descriptions, Divider, Typography, message } from 'antd';
import { State, States } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import moment from 'moment';
import handlingMessage from '../../utils/handlingMessage';
import { getDataIU, getProperty } from '../../services/estates';
import { useLocation } from 'react-router';
import { Utils } from '../../utils/validators';
import Petro from '../Icons/Petro';
import { DownloadOutlined } from '@ant-design/icons';
import Axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

const formatCurrency = (number: number | string) => { 
  number = (typeof number !== 'number') ? parseFloat(number) : number
  return new Intl.NumberFormat('de-DE').format(Math.round(((number) + Number.EPSILON) * 100) / 100)
};

export const clasificaionIU = (key, property, width) => {
  switch (key) {
    case 'EJIDO':
      return (
        <>
          <Descriptions style={{marginTop: 15}} title={'Datos de inmueble ' + key?.toLowerCase() ?? key} column={width > 992 ? 2 : 1} layout={width > 768 ? 'horizontal' : 'vertical'} bordered>
            <Descriptions.Item
              label='Clase de Ejido'
            >
              {property?.clase ?? 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item
              label='Tenencia'
            >
              {property?.tenencia ?? 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item
              label='Uso de ejido'
            >
              {property?.uso ?? 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item
              label='Nro de contrato'
            >
              {property?.contrato ?? 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item
              label='Fecha de vencimiento'
            >
              {property.fechaVencimiento ? moment(property.fechaVencimiento).utcOffset(4).format('YYYY/MM/DD') : 'N/A'}
            </Descriptions.Item>
          </Descriptions>
        </>
      )
    case 'MERCADO':
      return (
        <>
          <Descriptions style={{marginTop: 15}} title={'Datos de inmueble ' + key?.toLowerCase() ?? key} column={width > 992 ? 2 : 1} layout={width > 768 ? 'horizontal' : 'vertical'} bordered>
            <Descriptions.Item
              label='Tipo de local'
            >
              {property?.tipoLocal ?? 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item
              label='Actividad Comercial'
            >
              {property?.tipoAE ?? 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item
              label='Canon de Arrendamiento'
            >
              {property?.canonArrendamientoMercado ?? 'N/A'}
            </Descriptions.Item>
          </Descriptions>
        </>
      )
    case 'QUIOSCO':
      return (
        <>
          <Descriptions style={{marginTop: 15}} title={'Datos de inmueble ' + key?.toLowerCase() ?? key} column={width > 992 ? 2 : 1} layout={width > 768 ? 'horizontal' : 'vertical'} bordered>
            <Descriptions.Item
              label='Zona'
            >
              {property?.zonaQuiosco ?? 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item
              label='Tipo'
            >
              {property?.tipoQuiosco ?? 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item
              label='Objeto'
            >
              {property?.objetoQuiosco ?? 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item
              label='Canon de Arrendamiento'
            >
              {property?.canonArrendamientoQuiosco ?? 'N/A'}
            </Descriptions.Item>
          </Descriptions>
        </>
      )
    case 'CEMENTERIO':
      return (
        <>
          <Descriptions style={{marginTop: 15}} title={'Datos de inmueble ' + key?.toLowerCase() ?? key} column={width > 992 ? 2 : 1} layout={width > 768 ? 'horizontal' : 'vertical'} bordered>
            <Descriptions.Item
              label='Area de servicio'
            >
              {property?.areaServicios ?? 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item
              label='Sector'
            >
              {property?.sector ?? 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item
              label='Tenencia cementerio'
            >
              {property?.tenencia ?? 'N/A'}
            </Descriptions.Item>
          </Descriptions>
        </>
      )
    default:
      break;
  }
}

const RegisterProperty : React.FC<RegisterPropertyProps> = ({thm, auth, prsh, coin}) => {
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const [property, setProperty] = useState<any>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [dataIU, setDataIU] = useState<any>();

  useEffect(() => {
    getDataIU(auth.token)
      .then(res => setDataIU(res))
      .catch(error => message.error('Error al momento de obtener los datos'));
  }, [])

  const searchProperty = async() => {
    setDisabled(true);
    const values = await form.validateFields();
    handlingMessage({
      action: () => getProperty(values.codCatSearch, auth.token),
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: (data) => {
        if(data.status === 200){
          const inmueble = { ...data.inmueble };
          inmueble.avaluos = inmueble.avaluos.map((a) => ({...a, avaluo: +Number(a.avaluo * coin.petro).toFixed(2) }))
          setProperty(inmueble);
        }
        setDisabled(false);
      }
   });
  }

  const urlCertificate = () =>{
    let codigoCatastral = property?.codigoCatastral ?? 'N/A'
    let metrosTerreno = property?.metrosTerreno ?? 'N/A'
    let metrosConstruccion = property?.metrosConstruccion ?? 'N/A'
    let clasificacion = property?.clasificacion ?? 'N/A'
    let avaluoTerreno = property?.avaluos[0]?.avaluoTerreno ? Number(property?.avaluos[0]?.avaluoTerreno ?? 0).toFixed(8) : 'N/A'
    let avaluoConstruccion = property?.avaluos[0].avaluoConstruccion ? Number(property?.avaluos[0].avaluoConstruccion ?? 0).toFixed(8) : 'N/A'
    let direccion = property?.direccion ?? 'N/A'
    let parroquia = dataIU?.parishes && property?.codigoCatastral ? dataIU?.parishes.find(p => p.id_parroquia === +property?.codigoCatastral.slice(4,6)).nombre : 'N/A'
    let tipoInmueble = property?.tipoInmueble ?? 'N/A'
    let relativo = property?.relativo ?? 'N/A'
    
    return `${server}/reportSolvRRI?codigoCatastral=${codigoCatastral}&metrosTerreno=${metrosTerreno}&metrosConstruccion=${metrosConstruccion}&clasificacion=${clasificacion}&avaluoTerreno=${avaluoTerreno}&avaluoconstruccion=${avaluoConstruccion}&direccion=${direccion}&parroquia=${parroquia}&tipoInmueble=${tipoInmueble}&relativo=${relativo}`
  }

  const handleCertificado = async () => {
    try {
      const response = await Axios.get(urlCertificate())
      if(response.status === 200){
        const win = window.open(response.data, '_blank')
        win?.focus();
      }
    } catch (error) {
      message.error('Error al obtener el certificado')
    }
  }

  return (
    <Card style={{ height: '100%' }} title='Consultar Inmueble' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
     headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}}>
      <Form form={form}  onFinish={searchProperty} layout='vertical'>
        <Row style={{paddingLeft: 16}} gutter={16}> 
          <Col span={12}>
            <Form.Item label='Código de identificación del Inmueble' name='codCatSearch'  normalize={Utils.normalize.toUpper} rules={[{ required: true, message: 'Debe ingresar el Código de identificación del Inmueble' }]}>
              <Input placeholder='Código de identificación del Inmueble' />
            </Form.Item>
          </Col>
          <Col span={12}  style={{alignSelf:'center', paddingTop:'15px'}}>
            <Button type='primary' disabled={disabled} htmlType='submit'>Buscar</Button>
          </Col>   
        </Row>
      </Form>
      {property && <>
        {(property.propietarios.length > 0 || property.propietarioRim) && <Descriptions title='Contribuyentes enlazados' column={3} layout='vertical' bordered>
          {property?.propietarios?.map((p) => (<>
            <Descriptions.Item label='Documento de Identidad'>{p?.rif || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label='Referencia de inmueble'>{p?.rim || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label='Razón Social'>{p?.razonSocial || 'N/A'}</Descriptions.Item>
          </>))}
          {property?.propietarioRim && <>
            <Descriptions.Item label='Documento de Identidad'>{property?.propietarioRim?.rif || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label='Referencia de inmueble'>{property?.propietarioRim?.rim || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label='Razón Social'>{property?.propietarioRim?.razonSocial || 'N/A'}</Descriptions.Item>
          </>}
        </Descriptions>}
        <Descriptions style={{marginTop: 15}} title='Datos del inmueble' column={width > 992 ? 2 : 1} layout={width > 768 ? 'horizontal' : 'vertical'} bordered>
          <Descriptions.Item label='Codigo Catastral'>{property.codigoCatastral || 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Tipo de Inmueble'>{property.tipoInmueble || 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Clasificación del Inmueble'>{property.clasificacion || 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Condición'>{property.relacion || 'NO DEFINIDO'}</Descriptions.Item>
          <Descriptions.Item label='Metros de Construccion'>{property.metrosConstruccion || 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Metros de Terreno'>{property.metrosTerreno || 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Dirección' span={width > 992 ? 2 : 1}>{property.direccion || 'N/A'}</Descriptions.Item>
          {/* <Descriptions.Item label='Parroquia'>{property?.idParroquia ? prsh.parish.find(p => p.id === property.idParroquia)?.nombre : 'N/A'}</Descriptions.Item> */}
          <Descriptions.Item label='Entidad'>{dataIU?.entities ? `${dataIU.entities[0].entidad_federal} ${dataIU.entities[0].municipio}` : 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Parroquia'>{dataIU?.parishes && property?.codigoCatastral ? dataIU?.parishes.find(p => p.id_parroquia === +property?.codigoCatastral.slice(4,6)).nombre : 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Ambito'>{property?.ambito ? `${property?.ambito?.nombre} ${property?.ambito?.cod_ambito}` : 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Sector'>{dataIU?.sectors && property?.ambito ? dataIU.sectors.find(p => p.id_parroquia === property?.ambito?.id_parroquia)?.nombre : 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Manzana'>{property?.manzana ? property?.manzana?.nombre : 'N/A'}</Descriptions.Item>
          {/* <Descriptions.Item label='Parcela'>{property?.codigoCatastral ? property?.codigoCatastral.slice(-12, -9) : 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Subparcela'>{property?.codigoCatastral ? property?.codigoCatastral.slice(-9, -6) : 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Nivel'>{property?.codigoCatastral ? property?.codigoCatastral.slice(-6, -3) : 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Unidad'>{property?.codigoCatastral ? property?.codigoCatastral.slice(-3) : 'N/A'}</Descriptions.Item> */}
          <Descriptions.Item label='Fecha inicio' span={width > 992 ? 2 : 1}>
            {property.fechaInicio ? moment(property.fechaInicio).utcOffset(4).format('DD/MM/YYYY') : 'N/A'}
          </Descriptions.Item>
        </Descriptions>
        {/* <Descriptions layout='vertical' bordered>
          <Descriptions.Item label='Avaluos' span={3}>
            {<Descriptions bordered layout={width > 480 ? 'horizontal' : 'vertical'} >
              {property.avaluos?.map(e => <Descriptions.Item key={e.anio} label={e.anio} span={2}><Tooltip title={`${formatCurrency(+(Number(+e.avaluo * coin.petro).toFixed(2)))} Bs.` } ><span style={{cursor: 'pointer'}} >{e.avaluo}<Petro style={{ marginLeft: 8 }}/></span></Tooltip></Descriptions.Item>)}
            </Descriptions>}
          </Descriptions.Item>
        </Descriptions> */}
        <Descriptions style={{marginTop: 15}} title='Avalúos' column={width > 992 ? 2 : 1} layout={width > 768 ? 'horizontal' : 'vertical'} bordered>
         { property.avaluos && property.avaluos.map(avaluo => {
          return (<>
            <Descriptions.Item
              label='Avalúo de Terreno en Petros'
            >
              {(+avaluo.avaluoTerreno ?? 0).toFixed(8) ?? 0} <Petro />
            </Descriptions.Item>
            <Descriptions.Item
              label='Avalúo de Construcción en Petros'
            >
              {(+avaluo.avaluoConstruccion ?? 0).toFixed(8) ?? 0} <Petro />
            </Descriptions.Item>
          </>)
          })
         }
        </Descriptions>
        {clasificaionIU(property?.clasificacion, property, width)}
        <Button onClick={() => handleCertificado()}
          icon={<DownloadOutlined />} type='primary' style={{ marginTop: 10 }}>
          Descarga Certificado
        </Button>
      </>}
    </Card>
  )
}

interface RegisterPropertyProps {
  auth: States.Auth
  thm: States.ThemeColors
  prsh: States.Parish
  coin: States.Coins
}

const mapStateToProps = ({ thm, auth, prsh, coin }: State) => ({ thm, auth, prsh, coin })

export default connect(mapStateToProps)(RegisterProperty);