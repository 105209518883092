import React from 'react';
import { Card, Typography } from 'antd';
import { Chart, LineAdvance, Tooltip } from 'bizcharts';

const LineChart: React.FC<LineChartProps> = ({ data = fakeData, loading }) => {
  return (
    <Card bodyStyle={{ paddingTop: 10 }} loading={loading}>
      <Typography.Text strong style={{ display: 'inline-block', marginBottom: 15 }}>Tasas de AE (liquidadas/pagadas) por mes</Typography.Text> <br />
      <Chart height={400} padding={[10, 20, 70, 40]} autoFit data={data} scale={{ valor: { min: 0 } }}>
        <LineAdvance shape='smooth' point area position='fecha*valor' color='name' />
        <Tooltip />
      </Chart>
    </Card>
  );
};

export default LineChart;

interface LineChartProps {
  data?: {
    name: string
    fecha: string
    valor: number
  }[]
  loading: boolean
};

const fakeData = [
  { name: 'Liquidado', fecha: '01/07', valor: 120 },
  { name: 'Liquidado', fecha: '02/07.', valor: 123 },
  { name: 'Liquidado', fecha: '03/07', valor: 113 },
  { name: 'Liquidado', fecha: '04/07', valor: 106 },
  { name: 'Liquidado', fecha: '05/07', valor: 105 },
  { name: 'Liquidado', fecha: '06/07.', valor: 95 },
  { name: 'Liquidado', fecha: '07/07', valor: 69 },
  { name: 'Liquidado', fecha: '08/07', valor: 63 },
  { name: 'Liquidado', fecha: '09/07', valor: 106 },
  { name: 'Liquidado', fecha: '10/07', valor: 105 },
  { name: 'Liquidado', fecha: '11/07.', valor: 95 },
  { name: 'Liquidado', fecha: '12/07', valor: 69 },
  { name: 'Liquidado', fecha: '13/07', valor: 63 },
  { name: 'Liquidado', fecha: '14/07', valor: 50 },
  { name: 'Liquidado', fecha: '15/07', valor: 37 },
  { name: 'Pagado', fecha: '01/07', valor: 90 },
  { name: 'Pagado', fecha: '02/07.', valor: 87 },
  { name: 'Pagado', fecha: '03/07', valor: 67 },
  { name: 'Pagado', fecha: '04/07', valor: 81 },
  { name: 'Pagado', fecha: '05/07', valor: 98 },
  { name: 'Pagado', fecha: '06/07.', valor: 78 },
  { name: 'Pagado', fecha: '07/07', valor: 40 },
  { name: 'Pagado', fecha: '08/07', valor: 60 },
  { name: 'Pagado', fecha: '09/07', valor: 87 },
  { name: 'Pagado', fecha: '10/07', valor: 58 },
  { name: 'Pagado', fecha: '11/07.', valor: 78 },
  { name: 'Pagado', fecha: '12/07', valor: 56 },
  { name: 'Pagado', fecha: '13/07', valor: 63 },
  { name: 'Pagado', fecha: '14/07', valor: 20 },
  { name: 'Pagado', fecha: '15/07', valor: 5 }
];