import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, Table, Tooltip, Form, Select, Spin, Divider, Row, Col, message, InputNumber } from 'antd';
import { connect } from 'react-redux';
import { State, States, Wallets } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import { fetchOfficials } from '../../redux/actions/official';
import { fetchWallets, updateWalletUser } from '../../redux/actions/charges';
import { ColumnsType } from 'antd/lib/table';
import { CheckOutlined, CloseOutlined, EyeOutlined, LoadingOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import axios from 'axios';
import Item from 'antd/lib/list/Item';
import handlingMessage from '../../utils/handlingMessage';
const server = process.env.REACT_APP_SERVER_URL;

const EditableCell:React.FC<EditableCellProps | any> = ({editing, dataIndex, title, record, index, inputNode, children, ...restProps }) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};


const WalletsC: React.FC<WalletsProps> = ({ thm, auth, chrg, ofc, fetchOfficials, fetchWallets, updateWalletUser }) => {
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState<boolean>(false);
  const history = useHistory();
  const isEditing = record => record.idCartera === editingKey;
  const wallets = chrg.wallets.map((w, i) => ({ ...w, index: i }));

  const getOfficials = async () => await fetchOfficials(auth.token, auth.user?.tipoUsuario);
  const getWallets = async () => await fetchWallets(auth.token);

  useEffect(() => {
    if (auth) {
      getOfficials();
      getWallets();
    }
    //eslint-disable-next-line
  }, [auth]);

  const assignUser = async ({idCartera, esAr, nombreCompleto }) => {
    const { idUsuario } = await form.getFieldsValue();
    if (idUsuario === nombreCompleto){
      setLoading(false);
      setEditingKey('');
      form.setFieldsValue({ idUsuario: null });
    }else{
      setLoading(true);
      handlingMessage({
        action: () => updateWalletUser(auth.token, { 
          idCartera, 
          idUsuario, 
          nombreCompleto: ofc.officials.find((o) => o.id === idUsuario )?.nombreCompleto || '', 
          esAr 
        }),
        key: `assingUser_${idCartera}`,
        loadingMessage: 'Asignando responsable...',
        cb: (data) => {
          if(data.status === 200){
            // console.log(data);          
          }
          setLoading(false);
          setEditingKey('');
          form.setFieldsValue({ idUsuario: null });
        }
      })
    }
  };

  const generateWallets = async () => {
    const values = await form.validateFields();
    setCreating(true);
    try {
      const response = await axios.post(`${server}/wallet/charging`, values ,{ headers: { Authorization: `Bearer ${auth.token}` }});
      message.success(response.data.message || 'Carteras generadas exitosamente!');
    } catch (error) {
      message.error(error.response.data.message || 'Error al generar las carteras de cobranzas');
    } finally {
      setCreating(false);
    }
  }

  const columns: ColumnsType<Wallets> | any = [
    {
      title: 'N#',
      dataIndex: 'index',
      align: 'center',
      width: 50,
      render: (index) => <Avatar style={{ backgroundColor: thm.primaryColor }}>{index + 1}</Avatar>,
    },
    {
      title: 'Tipo',
      dataIndex: 'esAr',
      width: 160,
      filters: [{value: true, text: 'AR'}, { value: false, text: 'TOP'}],
      onFilter: (value,record) => value === record.esAr,
      render: (ar) => ar ? 'AR' : 'TOP' 
    },
    {
      title: 'Responsable',
      dataIndex: 'idUsuario',
      editable: true,
      inputNode: (<Select defaultOpen dropdownRender={(menu) => 
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <Row style={{ flexWrap: 'nowrap', padding: 8 }} justify='center' >
            <Button type='link' style={{ border: 'none' }} onClick={() => form.setFieldsValue({ idUsuario: null }) } >Eliminar</Button>
          </Row>
        </div>
      }>
        {ofc.officials.filter(o => o.cargo === 33 && !wallets.some((w) => w.idUsuario === o.id)).map((ac) => <Select.Option key={ac.id} value={ac.id}>{ac.nombreCompleto}</Select.Option>)}
      </Select>),
      render: (id, record) => record.nombreCompleto || 'N/A' 
    },
    {
      title: 'Contactados',
      dataIndex: 'porcentajeContactado',
      width: 100,
      render: (contactado) => `${(+Number(contactado).toFixed(2) * 100).toFixed()} %`
    },
    {
      title: 'Pago AE',
      dataIndex: 'porcentajePagado',
      width: 100,
      render: (pagado) => `${(+Number(pagado).toFixed(2) * 100).toFixed()} %`
    },
    {
      title: 'Acciones',
      dataIndex: 'idCartera',
      render: (id, record) => !isEditing(record) ? (
          <span>
            <Tooltip title='Asignar Cartera'>
              <Button type='link' icon={<UserSwitchOutlined />} onClick={() => { form.setFieldsValue({ idUsuario: record.nombreCompleto }); setEditingKey(id); }} style={{ marginRight: 8, border: 'none' }}/>
            </Tooltip>
            <Tooltip title='Ver Cartera'>
              <Button type='link' icon={<EyeOutlined />} onClick={() => history.push(`./carteras/${id}`)} style={{ border: 'none' }} />
            </Tooltip>
          </span>)
        : !loading ? (
          <span>
            <Tooltip title='Confirmar'>
              <Button type='link' disabled={loading} icon={<CheckOutlined />} onClick={() => assignUser(record)} style={{ marginRight: 8, border: 'none' }}/>
            </Tooltip>
            <Tooltip placement="bottom" title="Cancelar">
              <Button style={{ border: 'none' }} type="link" disabled={loading} icon={<CloseOutlined />} onClick={() => { setEditingKey(''); form.setFieldsValue({ idUsuario: null }); }} />
            </Tooltip>  
          </span>
        ) : <Spin indicator={<LoadingOutlined />} style={{ marginLeft: 20 }} />
    },
  ];

  const mergedColumns = columns.map(c => 
    !c.editable ? c : ({
      ...c,
      onCell: record => ({
        inputNode: c.inputNode,
        dataIndex: c.dataIndex,
        title: c.title,
        editing: isEditing(record)
      })
    })
  );

  return (
    <Card style={{ height: '100%' }} title='Carteras' headStyle={{ backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: '#fff' }} bodyStyle={{ overflowY: 'auto', height: '90%' }}>
      <Form form={form} layout='vertical' >
        <Row gutter={[16,8]} align='middle'>
          <Col xs={24} lg={6}>
            <Form.Item label='Cantidad de contribuyentes por cartera' name='cant_per_wallet' rules={[{required: true, message: 'Ingresa una cantidad'}]}>
              <InputNumber style={{ width: '100%' }} min={1} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item label='Cantidad de carteras de top' name='cant_top' rules={[{required: true, message: 'Ingresa la cantidad de carteras del top'}]}>
              <InputNumber style={{ width: '100%' }} min={1} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item label='Cantidad de carteras de AR' name='cant_ar' rules={[{required: true, message: 'Ingresa la cantidad de carteras AR'}]}>
              <InputNumber style={{ width: '100%' }} min={1} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={3}><Button style={{ marginTop: 15 }} type='primary' loading={creating} onClick={() => generateWallets()}>Generar Carteras</Button></Col>
        </Row>
        <Table components={{ body: { cell: EditableCell } }} bordered pagination={{ pageSize: 7 }} columns={mergedColumns} dataSource={wallets} rowKey={({ idCartera }) => idCartera} />
      </Form>
    </Card>
  );
};

const mapStateToProps = ({ thm, auth, chrg, ofc }: State) => ({ thm, auth, chrg, ofc });

export default connect(mapStateToProps, { fetchOfficials, fetchWallets, updateWalletUser })(WalletsC);

interface WalletsProps {
  thm: States.ThemeColors;
  auth: States.Auth;
  chrg: States.Charges;
  ofc: States.Officials;
  fetchOfficials: (token, id) => Promise<any>;
  fetchWallets: (token) => Promise<Response>;
  updateWalletUser: (token, user: Wallets) => Promise<Response>;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: Item;
  inputNode: React.ReactNode;
}
