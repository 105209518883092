import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;


export const fetchSettlementsForBenefit = async ({rim,document,userType,docType}:taxPayerProps) => {
  try {
    const response = await axios.get(`${server}/settlements/debts/${userType}?documento=${document}&tipoDocumento=${docType}&referencia=${rim}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

interface taxPayerProps {
  document: number;
  userType: string;
  rim: string;
  docType: string;
}