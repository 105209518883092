import React, { useEffect, useState } from 'react';
import { Card, Form, Divider, Typography, Row, Input, Col, Select, Table, Button, DatePicker, message } from 'antd';
import { useWindowDimensions } from '../../../utils/hooks';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Utils } from '../../../utils/validators';
import axios from 'axios';
import moment from 'moment';
import { setProcedure } from '../../../redux/actions/procedures';
const server = process.env.REACT_APP_SERVER_URL;

const UserPFForm: React.FC<UserPFFormProps> = ({ thm, institutions, destinations, banks, auth, setProcedure }) => {
  const [nacionalidad, setNacionalidad] = useState<string>('V');
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  const { width } = useWindowDimensions();
  const history = useHistory();
  const selectedInstitution = institutions?.find(i => i.nombreCorto === 'SEDETEMA');
  const disabledDate = current => current > moment().endOf('day');
  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  useEffect(() => {
    if(!institutions) history.push('/dashboard');
    // eslint-disable-next-line
  }, [institutions]);
  
  const handleSubmit = async () => {
    const values = await form.validateFields();
    Object.keys(values).forEach(k => {
      if(moment.isMoment(values[k])) {
        values[k] = values[k].toISOString();
      }
    });
    const pago = {
      fecha: values.fecha,
      banco: values.banco,
      referencia: values.referencia,
      costo: destinations.find(d => d.destino === data.destino)?.montoCalculado
    };
    delete values.fecha;
    delete values.banco;
    delete values.referencia;
    values.metodo = 'Transferencia';
    values.monto = formatCurrency(pago.costo || 0);
    values.fechaTramite = new Date();
    const datos = { ...values };
    const tramite = {
      datos,
      pago,
      tipoTramite: 17,
      recaudos: []
    };
    setLoading(true);
    try {
      const resp = await axios.post(`${server}/procedures/init`, { tramite }, { headers: { Authorization: `Bearer ${auth.token}` }});
      if(resp.status === 201) {
        setLoading(false);
        message.success('Tramite creado exitosamente');
        setProcedure(resp.data.tramite);
        form.resetFields();
        form.setFieldsValue({   
          banco: banks ? banks?.filter((b) => selectedInstitution?.cuentasBancarias?.map((c) => c.banco).includes(b.id))[0].id || banks[0].id : 1
        });
        history.push('/dashboard/bandejas');
      } else {
        setLoading(false);
        message.error('Error al generar tramite');
      }
    } catch(e) {
      message.error((e.response.data.message as string).includes('referencia ya existe') ? 'La referencia bancaria ya ha sido utilizada.' : 'Error en el servidor');
    } finally { setLoading(false) }
  };
  
  const handleBankChange = banco => {
    form.setFieldsValue({   
      banco
    });
  };

  const cedulaAddon = (
    <Select defaultValue='V' value={nacionalidad} onChange={(e) => setNacionalidad(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
    </Select>
  );

  const columns: any = [
    {
      title: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
      fixed: 'left'
    },
    {
      title: 'Numero de Cuenta',
      dataIndex: 'numeroCuenta',
      key: 'numeroCuenta'
    },
    {
      title: 'Titular',
      dataIndex: 'nombreTitular',
      key: 'nombreTitular'
    },
    {
      title: 'Documento de Identidad',
      dataIndex: 'documento',
      key: 'documento',
    }
  ];

  return (
    <Card style={{ height: '100%' }} title='Tasa de Salida de Pasajeros' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}}>
      <Form scrollToFirstError onFinish={() => handleSubmit()} form={form} layout='vertical' style={{ padding: width < 992 ? '0 10px' : '0px 20px' }}
        onValuesChange={(c, v) => { form.setFieldsValue(v); setData(v) }}>
        <Divider orientation='left' style={{ marginLeft: -30 }}>
          <Typography.Title level={4}>Datos del Pasajero</Typography.Title>
        </Divider>
        <Row gutter={24}>
          <Col xs={24} xl={8}>
            <Form.Item label='Cedula' name='cedula' rules={[{ required: true, message: 'Debe ingresar el numero de cedula' }, 
              { validator: Utils.validators.cedula }]} normalize={Utils.normalize.isNumber}>
              <Input placeholder='Cedula' addonBefore={cedulaAddon} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Nombre y Apellido' name='nombreCompleto' rules={[{ required: true, message: 'Debe ingresar el nombre del solicitante' }]}>
              <Input placeholder='Nombre y Apellido' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Destino' name='destino' rules={[{ required: true, message: 'Debe ingresar el destino' }]}>
              <Select placeholder='Destino'>
                {destinations.filter(d => d.habilitado).map(d => (
                  <Select.Option value={d.destino} key={d.id}>{d.destino}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
          <Typography.Title level={4}>Datos de Pago</Typography.Title>
        </Divider>
        <Row gutter={24}>
          {!data.destino && 
          <Col span={24}>
            <Typography.Text strong>Debe ingresar el destino al que se dirige el pasajero para calcular el monto a pagar.</Typography.Text>
          </Col>}
          {data.destino &&
          <React.Fragment>
            <Col span={24}>
              <Typography.Text strong type='danger'>
                La tasa de salida con destino {data.destino} tiene una costo de BsS. {formatCurrency(destinations.find(d => d.destino === data.destino)?.montoCalculado || 0)} y&nbsp;
                el monto de la transferencia debe coincidir exactamente con el costo del tramite y debe ser realizada desde el mismo banco.
              </Typography.Text>
              <Table bordered style={{ margin: '20px 0px' }} pagination={false} columns={columns} dataSource={selectedInstitution?.cuentasBancarias?.map((c) => 
                ({ ...c, documento: `${(c.rif) ? 'RIF: ' : 'Cedula: '}${(c.rif || c.cedula)}`, banco: banks?.find(b => b.id === c.banco)?.nombre, key: c.id })
              )} />
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item label='Banco' name='banco' rules={[{ required: true, message: 'Debe indicar el banco' }]}>
                {banks ? <Select onChange={handleBankChange} placeholder='Banco'>
                  {banks?.filter((b) => selectedInstitution?.cuentasBancarias?.map((c) => c.banco).includes(b.id)).map((b, i) =>
                    <Select.Option key={`b-${b.id}`} value={b.id}>{b.nombre}</Select.Option>
                  )}
                </Select> : <div></div>}
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item label='Numero de Referencia' name='referencia' rules={[{ required: true, message: 'Por favor ingrese el numero de referencia.' }]}>
                <Input placeholder='Referencia' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item label='Fecha de Pago' name='fecha' rules={[{ required: true, message: 'Por favor ingrese la fecha de pago.' }]}>
                <DatePicker style={{ width: '100%' }} format={"DD-MM-YYYY"} disabledDate={disabledDate} />
              </Form.Item>
            </Col>
          </React.Fragment>}
        </Row>
      </Form>
      <Button style={{ marginTop: 15 }} loading={loading} type='primary' onClick={form.submit}>Generar Tramite</Button>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, institutions: state.inst.institutions, destinations: state.cst.destinations, 
  banks: state.bk.banks, auth: state.auth });

export default connect(mapStateToProps, { setProcedure })(UserPFForm);

interface UserPFFormProps {
  thm: States.ThemeColors
  institutions: States.Institutions['institutions']
  destinations: States.Costs['destinations']
  banks: States.Banks['banks']
  auth: States.Auth
  setProcedure: Function
}