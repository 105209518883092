import React from 'react';
import { Collapse } from 'antd';
import ExoTaxPayer from './ExoTaxPayer';
import ExoAE from './ExoAE';
import ExoBranches from './ExoBranches';

const { Panel } = Collapse;

const Exonerations: React.FC = () => {
  return (
    <Collapse defaultActiveKey={[]}>
      <Panel header="Actividades económicas" key="ae">
        <ExoAE/>
      </Panel>
      <Panel header="Contribuyentes" key="taxPayer">
        <ExoTaxPayer/>
      </Panel>
      <Panel header="Ramos" key="branches">
        <ExoBranches/>
      </Panel>
    </Collapse> 
  );
};

export default Exonerations;
