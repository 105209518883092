import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Descriptions, Divider, Form, Input, message, Row, Select, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import { IContributer, ISucursal } from './SolvB';
const server = process.env.REACT_APP_SERVER_URL;

const SolvA = () => {
  const [searchForm] = Form.useForm();
  const [payForm] = Form.useForm();
  const { width } = useWindowDimensions();
  const thm = useSelector((state: State) => state.thm);
  const auth = useSelector((state: State) => state.auth);
  const insitutions = useSelector((state: State) => state.inst.institutions);
  const pesoBS = useSelector((state: State) => state.coin.peso);
  const dolar = useSelector((state: State) => state.coin.dolar);
  const euro = useSelector((state: State) => state.coin.euro);
  const petro = useSelector((state: State) => state.coin.petro);

  const selectedInstitution = insitutions?.find((i) => i.id === 9);

  // const parish = useSelector((state: State) => state.prsh.parish);

  const [tipoDocumento, setTipoDocumento] = useState('V');
  const [solA, setSolA] = useState<SolvAResponse>({
    contribuyente: null,
    sucursales: [],
  });
  const [selectedInmueble, setSelectedInmueble] = useState<{
    inmueble: IInmueble | null;
    sucursal: ISucursalSolvA | null;
  }>({
    inmueble: null,
    sucursal: null,
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});

  const calcTotal = () =>
    data.pagos?.reduce((acc, p) => {
      if (!p) return 0;
      if (p.metodoPago === 'EFECTIVO PESO') {
        const amount = +p.costo * +pesoBS;
        return acc + amount;
      }
      if (p.metodoPago === 'EFECTIVO DOLAR') {
        const amount = +p.costo * +dolar;
        return acc + amount;
      }
      if (p.metodoPago === 'EFECTIVO EURO') {
        const amount = +p.costo * +euro;
        return acc + amount;
      }
      return acc + +p.costo;
    }, 0) || 0;

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  // const getAmountToPay(selected) = 5432

  const getAmountToPay = (inmueble: IInmueble): number => {
    const avaluo = +(inmueble.avaluo || 0);
    const monto = avaluo * petro * 0.01;
    return monto;
  };

  const getAmountToPayPetro = (inmueble: IInmueble): number => {
    const avaluoTerreno = Number(inmueble?.avaluo_terreno ?? 0);
    const avaluoCosntruccion = Number(inmueble?.avaluo_construccion ?? 0);
    const monto = (avaluoTerreno + avaluoCosntruccion) * 0.01;
    console.log('getAmountToPayPetro', avaluoTerreno, avaluoCosntruccion, monto);
    return monto;
  };

  const search = async () => {
    const values = await searchForm.validateFields();
    const { razonSocial, doc } = values;
    if (razonSocial || doc) {
      try {
        setLoading(true);
        const params = `tipoDocumento=${tipoDocumento.trim()}&documento=${doc.trim()}`;
        const response = await Axios.get<{ data: SolvAResponse }>(`${server}/solvencies/a?${params}`, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        const contribuyente = response.data.data.contribuyente;
        const sucursales = response.data.data.sucursales.filter((suc) => suc.inmuebles.length > 0);
        setSolA({ contribuyente, sucursales });
      } catch (e) {
        message.error(e.response?.data?.message || 'Error al buscar contribuyentes');
      } finally {
        setLoading(false);
      }
    } else {
      message.warn('Debe ingresar algún parametro para la busqueda de contribuyente');
    }
  };

  const pay = async (inmueble, sucursal) => {
    console.log(inmueble);
    if (inmueble.avaluo_terreno == null || inmueble.avaluo_construccion == null) {
      message.error('Debe actualizar los datos de su inmueble');
      return;
    }
    if (!solA.contribuyente) return;
    // if (!selectedInmueble.inmueble) return
    // const values = await payForm.validateFields();
    // Object.keys(values).forEach((k) => {
    //   if (moment.isMoment(values[k])) {
    //     values[k] = values[k].toISOString();
    //   }
    // });
    // const pagos = Array.isArray(payForm.getFieldValue('pagos')) ? cloneDeep(payForm.getFieldValue('pagos')) || [] : [];
    // if (pagos.filter((p) => p).find((p) => p.metodoPago === 'TRANSFERENCIA' && !sedematBanks.includes(p.destino))) {
    //   return message.error('Los metodos de pago de transferencia y cheque deben ser realizados solo a los bancos de HACIENDA');
    // }
    // pagos
    //   .filter((p) => p)
    //   .forEach((p) => {
    //     Object.keys(p).forEach((k) => {
    //       if (moment.isMoment(p[k])) {
    //         p[k] = p[k].toISOString();
    //       }
    //     });
    //   });
    // console.log(pagos)
    // pagos.forEach(pago => pago.costo = pago.metodoPago === 'EFECTIVO PESO' ? pago.costo * pesoBS : pago.costo);
    // pagos.forEach(pago => pago.costo = pago.metodoPago === 'EFECTIVO DOLAR' ? pago.costo * dolar : pago.costo);
    // pagos.forEach(pago => pago.costo = pago.metodoPago === 'EFECTIVO EURO' ? pago.costo * euro : pago.costo);
    // if (calcTotal() < getAmountToPay(selectedInmueble.inmueble))
    //   return message.error('La suma de los montos de las transacciones debe ser igual o mayor al total de la liquidación');
    // setLoading(true);
    // const complete = message.loading('Creando pago para la declaración de impuestos');
    try {
      const { inmuebles, ...restContribuyente } = solA.contribuyente;
      // const inmueble = selectedInmueble.inmueble;
      let montoAvaluo = getAmountToPayPetro(inmueble);
      // inmueble.avaluo = montoAvaluo
      const tramite: any = {
        // pagos: pagos.map(p => p.metodoPago === 'EFECTIVO PESO' ? ({ ...p, metodoPago: 'EFECTIVO' }) : p),
        // pago: pagos[0],
        contribuyente: restContribuyente,
        costo: montoAvaluo,
      };
      if (selectedInmueble.sucursal) {
        const { inmuebles, ...restSucursal } = selectedInmueble.sucursal;
        tramite.contribuyente.sucursal = {
          ...restSucursal,
          inmueble: inmueble,
        };
      } else {
        tramite.contribuyente.inmueble = inmueble;
      }

      const response = await Axios.post(
        `${server}/solvencies/a`,
        { tramite },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      // window.open(response?.data?.tramite?.certificado, "_blank")
      message.success('Solvencia registrada de manera exitosa');
      setSelectedInmueble({
        sucursal: null,
        inmueble: null,
      });
      setSolA({
        contribuyente: null,
        sucursales: [],
      });
      searchForm.resetFields();
      // payForm.resetFields()
      // auth.user?.tipoUsuario === 4 ? history.push('/dashboard/impuestos/pagar') : history.push('/dashboard/impuestos/pendientes');
    } catch (e) {
      message.error(e.response?.data?.message ? e.response.data.message : 'Error al enviar los datos de la solvencia');
      // message.error(
      //   e.response?.data?.message
      //     ? (e.response.data.message as string).includes('referencia')
      //       ? 'La referencia bancaria ya ha sido utilizada.'
      //       : e.response.data.message
      //     : 'Error al crear el pago'
      // );
    } finally {
      setLoading(false);
      // complete();
    }
  };

  const columns: ColumnsType<IInmueble> = [
    {
      title: 'Código Catastaral',
      dataIndex: 'cod_catastral',
    },
    {
      title: 'Dirección',
      dataIndex: 'direccion',
    },
    {
      title: 'Tipo de Inmueble',
      dataIndex: 'tipo_inmueble',
    },
    {
      title: 'Condición',
      dataIndex: 'relacion_contribuyente',
    },
    {
      title: 'Acciones',
      render: (_, record: IInmueble) => (
        <Button
          type='primary'
          onClick={() => {
            // toi yuk
            const sucursal =
              solA.sucursales.find((sucursal) => sucursal.inmuebles.some((inm) => inm.id_inmueble === record.id_inmueble)) ||
              null;
            setSelectedInmueble({
              inmueble: record,
              sucursal: sucursal,
            });
            console.log(record, sucursal);
            pay(record, sucursal);
          }}
          icon={<EyeOutlined />}
        >
          Generar Solvencia
        </Button>
      ),
    },
  ];

  return (
    <Card
      style={{ height: '100%' }}
      title='Solvencia A'
      bodyStyle={{
        height: 'calc(100% - 88px',
        overflowY: 'scroll',
        overflowX: 'hidden',
        padding: '10px 40px',
      }}
      headStyle={{
        height: 64,
        backgroundColor: thm.primaryColor,
        padding: width < 992 ? '0 10px' : '0 20px',
        color: 'white',
      }}
    >
      {/* {!selectedInmueble.inmueble ? */}
      <>
        <Form layout='vertical' form={searchForm}>
          <Row gutter={24}>
            <Col xs={24} xl={7}>
              <Form.Item label='Documento de Identidad' name='doc'>
                <Input placeholder='Documento de Identidad' addonBefore={tipoAddon} />
              </Form.Item>
            </Col>

            <Col xl={6} xs={12}>
              <Button
                onClick={() => search()}
                style={{ marginTop: width < 1200 ? 0 : 38, marginBottom: width < 1200 ? 20 : 0 }}
                loading={loading}
                icon={<SearchOutlined />}
                type='primary'
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
        {solA.contribuyente && (
          <>
            <Typography.Title level={3}>Contribuyente</Typography.Title>
            <Descriptions title='' bordered column={{ xl: 24 }}>
              <Descriptions.Item label='Documento de Identidad' span={12}>
                {solA.contribuyente?.tipo_documento
                  ? `${solA.contribuyente?.tipo_documento}-${solA.contribuyente?.documento}`
                  : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label='Razón Social' span={12}>
                {solA.contribuyente?.razon_social || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label='Denominación Comercial' span={12}>
                {solA.contribuyente?.denominacion_comercial || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label='Siglas' span={12}>
                {solA.contribuyente?.siglas || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label='Tipo de Contribuyente' span={12}>
                {solA.contribuyente?.tipo_contribuyente || 'N/A'}
              </Descriptions.Item>
            </Descriptions>

            {solA.contribuyente.hasSolvencyB && solA.contribuyente?.inmuebles.length > 0 && (
              <>
                <Typography.Title style={{ marginTop: '16px' }} level={4}>
                  Inmuebles del Contribuyente
                </Typography.Title>

                <Table columns={columns} dataSource={solA?.contribuyente.inmuebles} bordered loading={loading} />
              </>
            )}
          </>
        )}

        {solA.sucursales.length > 0 && (
          <>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={3}>Sucursales</Typography.Title>
            </Divider>
            {solA.sucursales.map((sucursal) => (
              <>
                <Descriptions key={sucursal.id_registro_municipal} bordered column={{ xl: 24 }}>
                  <Descriptions.Item label='Denominación Comercial' span={12}>
                    {sucursal.denominacion_comercial || 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label='Nombre del Representante' span={12}>
                    {sucursal.nombre_representante || 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label='Fecha de Aprobación' span={12}>
                    {sucursal.fecha_aprobacion ? moment(sucursal.fecha_aprobacion).format('DD/MM/YYYY') : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label='Teléfono' span={12}>
                    {sucursal.telefono_celular ? sucursal.telefono_celular : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label='Correo Electrónico' span={12}>
                    {sucursal.email || 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label='Estado de la Licencia' span={12}>
                    {sucursal.estado_licencia || 'No Definido'}
                  </Descriptions.Item>
                  <Descriptions.Item label='Tipo de Sociedad' span={12}>
                    {sucursal.tipo_sociedad || 'No Definido'}
                  </Descriptions.Item>
                  {/* <Descriptions.Item label='Parroquia' span={12}>{parish.find(e => (e.id === sucursal.id_parroquia)) || 'No Definido'}</Descriptions.Item> */}
                  <Descriptions.Item label='Dirección' span={24}>
                    {sucursal.direccion || 'No Definido'}
                  </Descriptions.Item>
                </Descriptions>
                {sucursal.inmuebles.length > 0 && (
                  <>
                    <Typography.Title level={4} style={{ marginTop: '16px' }}>
                      Inmuebles de Sucursal {sucursal.referencia_municipal}
                    </Typography.Title>

                    <Table columns={columns} dataSource={sucursal.inmuebles} bordered loading={loading} />
                  </>
                )}
              </>
            ))}
          </>
        )}
      </>
      {/* :
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
            <Typography.Text strong>Razón Social: {solA.contribuyente?.razon_social}</Typography.Text>
            <Typography.Text strong>
              Documento: {solA.contribuyente?.tipo_documento}-{solA.contribuyente?.documento}
              {selectedInmueble.sucursal && (
                <React.Fragment>
                  <br />
                  R.I.M: {selectedInmueble.sucursal.referencia_municipal}
                </React.Fragment>
              )}
            </Typography.Text>
          </div>
          <Button type='default' onClick={() => setSelectedInmueble({
            inmueble: null,
            sucursal: null
          })}>
            Atrás
          </Button>

          <Descriptions style={{"marginTop": "24px"}} title='Datos del inmueble' column={width > 992 ? 2 : 1} layout={width > 768 ? 'horizontal' : 'vertical'} bordered>
            <Descriptions.Item label='Codigo Catastral'>{selectedInmueble.inmueble.cod_catastral}</Descriptions.Item>
            <Descriptions.Item label='Tipo de Inmueble'>{selectedInmueble.inmueble.tipo_inmueble || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label='Condición'>{selectedInmueble.inmueble.relacion_contribuyente || 'NO DEFINIDO'}</Descriptions.Item>
            <Descriptions.Item label='Metros de Construccion'>{selectedInmueble.inmueble.metros_construccion || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label='Metros de Terreno'>{selectedInmueble.inmueble.metros_terreno || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label='Dirección' span={width > 992 ? 2 : 1}>{selectedInmueble.inmueble.direccion || 'N/A'}</Descriptions.Item>
          </Descriptions>

          <Divider orientation='left' style={{ marginLeft: -10, marginTop: '24px' }}>
            <Typography.Title level={4}>Datos de Pago</Typography.Title>
          </Divider>
          <TooltipCoin priceBs={getAmountToPay(selectedInmueble.inmueble)} dolarBs={dolar} pesoBs={pesoBS} euroBs={euro}>
            <Typography.Text strong style={{fontSize: "1.2rem"}}>
              El monto de la Solvencia A es de Bs {formatCurrency(getAmountToPay(selectedInmueble.inmueble))}
            </Typography.Text>
          </TooltipCoin>
          <Form layout='vertical' style={{ marginTop: 20, marginBottom: 16 }} form={payForm} initialValues={{ metodoPago: 'TRANSFERENCIA' }} onValuesChange={(c, v) => { searchForm.setFieldsValue(v); setData(v) }}>
            <MultipleTransactions selectedReqType='TRAMITE' selectedInstitution={selectedInstitution} form={payForm} data={data} onlyOne={true}/>
          </Form>
          <Row>
              <Divider orientation='left' style={{ marginLeft: 0 }}>
                <Typography.Title level={4}>Total Ingresado</Typography.Title>
              </Divider>
              <Col xl={24} xs={24}>
                <Descriptions bordered>
                  <Descriptions.Item label='Total Ingresado' style={{fontSize: '1.2rem'}}>
                    <TooltipCoin priceBs={calcTotal()} dolarBs={dolar} pesoBs={pesoBS} euroBs={euro}>
                      Bs.{' '}
                      {console.log('data: ', data) + '' === 'undefined' &&
                        formatCurrency(calcTotal())}
                    </TooltipCoin>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Divider orientation='left' style={{ marginLeft: 0 }}>
                <Typography.Title level={4}>Monto Restante</Typography.Title>
              </Divider>
              <Col xl={24} xs={24}>
                <Descriptions bordered>
                  <Descriptions.Item
                    label={
                      calcTotal()
                        >
                          +getAmountToPay(selectedInmueble.inmueble).toFixed(2)
                        ? 'Total a Favor'
                        : 'Total Restante'
                    }
                    style={{
                      color:
                          calcTotal()
                          >=
                         +getAmountToPay(selectedInmueble.inmueble).toFixed(2)
                          ? '#86E92B'
                          : 'red',
                          fontSize: "1.2rem"
                    }}
                  >
                    <TooltipCoin priceBs={Math.abs(calcTotal() - +getAmountToPay(selectedInmueble.inmueble).toFixed(2))} dolarBs={dolar} pesoBs={pesoBS} euroBs={euro}>
                      Bs.{' '}
                      {formatCurrency(
                        (
                          calcTotal()
                          -
                          +getAmountToPay(selectedInmueble.inmueble).toFixed(2)) *
                        (
                          calcTotal()
                          >
                          +getAmountToPay(selectedInmueble.inmueble).toFixed(2)
                          ? 1
                          : -1)
                      )}
                    </TooltipCoin>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
              
              <Col>
                {data?.pagos?.length > 0 && (
                  <Button
                    type='primary'
                    loading={loading}
                    style={{ marginTop: 15 }}
                    onClick={() => pay()}
                    icon={<CreditCardFilled />}
                  >
                    Pagar
                  </Button>
                )}
              </Col>
            </Row>
        </>
      } */}
    </Card>
  );
};

export default SolvA;

interface SolvAResponse {
  contribuyente: IContributerSolvA | null;
  sucursales: ISucursalSolvA[];
}

interface IContributerSolvA extends IContributer {
  hasSolvencyB: boolean;
  inmuebles: IInmueble[];
}

interface ISucursalSolvA extends ISucursal {
  inmuebles: IInmueble[];
}

interface IInmueble {
  id_inmueble: number;
  cod_catastral: string;
  direccion: string;
  id_parroquia: number;
  metros_construccion: string;
  metros_terreno: string;
  fecha_creacion: string;
  fecha_actualizacion: string;
  fecha_ultimo_avaluo: string | null;
  tipo_inmueble: 'RESIDENCIAL' | 'COMERCIAL' | 'INDUSTRIAL';
  id_registro_municipal: number | null;
  posee_gas: boolean;
  relacion_contribuyente: string | number | null;
  id_liquidacion_fecha_inicio: number | null;
  dir_doc: string | null;
  cod_tramite: string | null;
  avaluo: string;
  avaluo_terreno?: string;
  avaluo_construccion?: string;
}
