import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Typography, message, DatePicker, Button, Anchor, Spin } from 'antd';
import LineChart from './graphs/LineChart';
import NoiceLineChart from './graphs/NoiceLineChart';
import PieChart from './graphs/PieChart';
import BarChart from './graphs/BarChart';
import ARs from './graphs/ARs';
import Top1000 from './graphs/Top1000';
import TDI from './graphs/TDI';
import PDP from './graphs/PDP';
import TNL from './graphs/TNL';
import axios from 'axios';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import moment from 'moment';
import Modal from 'antd/lib/modal/Modal';
import excelImage from '../../assets/images/excel.png';
const { Paragraph } = Typography;
const server = process.env.REACT_APP_SERVER_URL;
const { Link } = Anchor;

const mergeObjectsProps = (oldObj, newObj) => {
  for (const key in newObj) {
    oldObj[key] = { ...oldObj[key], ...newObj[key] };
  }
  return oldObj;
};

type VoidFunction = () => void;
type PromiseVoidFunction = () => Promise<void>;

const SedematStats: React.FC<SedematStatsProps> = ({ auth }) => {
  const [finished, setIsFinished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState<{ [date: string]: Stats['estadisticas'] | undefined }>({});
  const [date, setDate] = useState<string>(moment().utcOffset('-4').startOf('month').format('MM-DD-YYYY'));
  const [mouseOverButton, setMouseOverButton] = useState<boolean>();
  const [modalVisible, setModalVisible] = useState<boolean>();
  const [contributorsCertificate, setContributorsCertificate] = useState<string | null>(null);
  const [contributorsCertificateLoading, setContributorsCertificateLoading] = useState(false);

  const formatNumber = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  const handleRIMButtonMouseEnter: VoidFunction = () => {
    setMouseOverButton(true);
  };

  const handleRIMButtonMouseLeave: VoidFunction = () => {
    setMouseOverButton(false);
  };

  const createCertificate: PromiseVoidFunction = async () => {
    setContributorsCertificateLoading(true);
    const response = await axios.get<string>(`${server}/stats/sedemat/contributors?date=${date}`, {
      headers: { Authorization: `Bearer ${auth.token}` },
    });
    if (response.data) setContributorsCertificateLoading(false);
    setContributorsCertificate(response.data);
  };

  const handleButtonClick: VoidFunction = async () => {
    setModalVisible(true);
  };

  useEffect(() => {
    if (auth.user?.tipoUsuario !== 2) return;
    fetchStats();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (auth.user?.tipoUsuario !== 2) return;
    if (!loading && !stats[date]) {
      fetchStats();
    }
    // eslint-disable-next-line
  }, [date]);

  const fetchStats = async () => {
    setIsFinished(true);
    setLoading(true);
    const isCurrentDate = date === moment().utcOffset('-4').startOf('month').format('MM-DD-YYYY');
    try {
      if (auth.user?.tipoUsuario !== 2) return;
      let _stats;
      if (isCurrentDate) {
        const allStats = await Promise.all([
          axios.get<Stats>(`${server}/stats/sedemat/total`, {
            headers: { Authorization: `Bearer ${auth.token}` },
          }),
          axios.get<Stats>(`${server}/stats/sedemat/graphs`, {
            headers: { Authorization: `Bearer ${auth.token}` },
          }),
          axios.get<Stats>(`${server}/stats/sedemat/top`, {
            headers: { Authorization: `Bearer ${auth.token}` },
          }),
          axios.get<Stats>(`${server}/stats/sedemat/settlements`, {
            headers: { Authorization: `Bearer ${auth.token}` },
          }),
        ]);
        const emptyStats = { contribuyentes: {}, coeficientes: {}, mensual: {}, total: {} };
        const stats1 = mergeObjectsProps(emptyStats, allStats[0].data.estadisticas);
        const stats2 = mergeObjectsProps(stats1, allStats[1].data.estadisticas);
        const stats3 = mergeObjectsProps(stats2, allStats[2].data.estadisticas);
        _stats = mergeObjectsProps(stats3, allStats[3].data.estadisticas);
      }

      if (!isCurrentDate) {
        const response = await axios.get<Stats>(`${server}/stats/sedemat/${date}`, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        _stats = response.data.estadisticas;
      }
      // const _stats = response.data.estadisticas;
      _stats.mensual.totalBsPorRamo = _stats.mensual.totalBsPorRamo.map((r) => ({ ...r, valor: +(r.valor / 1).toFixed(2) }));
      _stats.mensual.recaudado.totalRecaudacion = _stats.mensual.recaudado.totalRecaudacion.map((r) => ({
        ...r,
        valor: +(r.valor / 1).toFixed(2),
      }));
      setStats({ ...stats, [date]: _stats });
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al obtener estadisticas');
    } finally {
      setIsFinished(false);
      setLoading(false);
    }
  };

  const _stats = stats[date];

  return (
    <>
      <Modal
        visible={modalVisible}
        title='Reporte Contribuyentes'
        cancelText='Atrás'
        onCancel={() => {
          setContributorsCertificate(null);
          setModalVisible(false);
        }}
        okText='Generar Reporte'
        onOk={createCertificate}
        width={450}
      >
        <div>
          <Paragraph strong style={{ textAlign: 'center', margin: '0px 5px' }}>
            ¿Deseas generar reporte de contribuyentes - {date} ?
          </Paragraph>
          {contributorsCertificate && (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 10, flexDirection: 'column' }}>
              <img src={excelImage} alt='excelIcon' style={{ width: 100 }} />
              <Anchor>
                <Link href={contributorsCertificate} title='Descargar Reporte' />
              </Anchor>
            </div>
          )}

          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            {contributorsCertificateLoading && <Spin />}
          </div>
        </div>
      </Modal>
      <Row style={{ marginBottom: 20 }}>
        <Col>
          <Typography.Text strong>Fecha:</Typography.Text>
          <DatePicker
            picker='month'
            value={moment(date)}
            onChange={(v) => setDate(v?.startOf('month').format('MM-DD-YYYY') || moment().format('MM-DD-YYYY'))}
            format='MM/YYYY'
            style={{ marginLeft: 10 }}
            disabled={loading}
            disabledDate={(current) => current > moment().endOf('month') || current < moment('08/01/2020')}
          />
        </Col>
      </Row>
      <Card style={{ marginBottom: 30 }} bodyStyle={{ position: 'relative' }} loading={loading}>
        <Row gutter={24}>
          <Col xs={24} xl={6} style={{ marginTop: 0, textAlign: 'center' }}>
            <Typography.Text strong>Total de Usuarios Registrados</Typography.Text> <br />
            <Typography.Text strong style={{ fontSize: 24, textAlign: 'right', width: '100%', color: 'black' }}>
              {formatNumber(_stats?.total?.cantidadUsuarios || 0)}
            </Typography.Text>
          </Col>
          <Col xs={24} xl={6} style={{ marginTop: 0, textAlign: 'center' }}>
            <Typography.Text strong>Total de Contribuyentes</Typography.Text> <br />
            <Typography.Text strong style={{ fontSize: 24, textAlign: 'right', width: '100%', color: 'black' }}>
              {formatNumber(_stats?.total?.cantidadContribuyentes || 0)}
            </Typography.Text>
          </Col>
          <Col xs={24} xl={6} style={{ marginTop: 0, textAlign: 'center' }}>
            <Typography.Text strong>Total de Registros Municipales</Typography.Text> <br />
            <Typography.Text strong style={{ fontSize: 24, textAlign: 'right', width: '100%', color: 'black' }}>
              {formatNumber(_stats?.total?.cantidadRIMs?.registrados || 0)}
            </Typography.Text>
          </Col>
          <Col
            xs={24}
            xl={6}
            style={{
              marginTop: 0,
              textAlign: 'center',
              cursor: 'pointer',
              opacity: `${mouseOverButton ? 0.9 : 1}`,
              backgroundColor: `${mouseOverButton ? '#f5f5f5' : '#fff'}`,
            }}
            onMouseEnter={handleRIMButtonMouseEnter}
            onMouseLeave={handleRIMButtonMouseLeave}
            onClick={handleButtonClick}
          >
            <Typography.Text strong>Registros Municipales (declarado/pagado)</Typography.Text> <br />
            <Typography.Text strong style={{ fontSize: 24, textAlign: 'right', width: '100%', color: 'black' }}>
              {formatNumber(_stats?.total?.cantidadRIMs?.liquidados || 0)} /{' '}
              {formatNumber(_stats?.total?.cantidadRIMs?.pagados || 0)}
            </Typography.Text>
          </Col>
        </Row>
      </Card>
      <Row gutter={24}>
        <Col xs={24} xl={12}>
          <NoiceLineChart
            data={_stats?.mensual?.recaudado?.totalRecaudacion}
            extra={_stats?.mensual?.recaudado?.extra}
            loading={loading}
          />
        </Col>
        <Col xs={24} xl={12}>
          <LineChart data={_stats?.mensual?.totalTasasAE} loading={loading} />
        </Col>
      </Row>
      <Row gutter={24} style={{ marginTop: 30 }}>
        <Col xs={24} xl={12}>
          <PieChart setLoading={setLoading} loading={loading} finished={finished} />
        </Col>
        <Col xs={24} xl={12}>
          <BarChart data={_stats?.mensual?.totalLiquidaciones} loading={loading} />
        </Col>
      </Row>
      <Row gutter={24} style={{ marginTop: 30 }}>
        <Col xs={24} xl={12}>
          <ARs data={_stats?.contribuyentes?.AR} loading={loading} />
        </Col>
        <Col xs={24} xl={12}>
          <Top1000 data={_stats?.contribuyentes?.top} loading={loading} />
        </Col>
      </Row>
      <Row gutter={24} style={{ marginTop: 30 }}>
        <Col xs={24} xl={8}>
          <TDI
            data={
              _stats
                ? _stats.coeficientes.TDI.find(
                    (p) =>
                      p.anio === +moment(date).format('YYYY') &&
                      p.mes?.toLowerCase() === moment(date).subtract(1, 'm').locale('es').format('MMMM')
                  )
                : undefined
            }
            loading={loading}
          />
        </Col>
        <Col xs={24} xl={8}>
          <PDP
            data={
              _stats
                ? _stats.coeficientes.PDP.find(
                    (p) =>
                      p.anio === +moment(date).format('YYYY') && p.mes?.toLowerCase() === moment(date).locale('es').format('MMMM')
                  )
                : undefined
            }
            loading={loading}
          />
        </Col>
        <Col xs={24} xl={8}>
          <TNL
            data={
              _stats
                ? _stats.coeficientes.TNL.find(
                    (p) =>
                      p.anio === +moment(date).format('YYYY') && p.mes?.toLowerCase() === moment(date).locale('es').format('MMMM')
                  )
                : undefined
            }
            loading={loading}
          />
        </Col>
      </Row>
      <Row gutter={24} style={{ marginTop: 30 }}></Row>
    </>
  );
};

const mapStateToProps = (state: State) => ({ auth: state.auth });

export default connect(mapStateToProps)(SedematStats);

interface SedematStatsProps {
  auth: States.Auth;
}

export interface Stats {
  estadisticas: {
    coeficientes: {
      PDP: {
        anio: number;
        mes: string;
        limiteSuperior: number;
        promedio: number;
      }[];
      TNL: {
        anio: number;
        mes: string;
        coeficiente: number;
        valor: number;
      }[];
      TDI: {
        anio: number;
        mes: string;
        coeficiente: number;
        valor: number;
      }[];
    };
    contribuyentes: {
      AR: {
        total: number;
        liquidado: number;
        pagado: number;
      };
      top: {
        total: number;
        liquidado: number;
        pagado: number;
      };
    };
    mensual: {
      recaudado: {
        totalRecaudacion: {
          fecha: string;
          valor: number;
        }[];
        extra: {
          fechaInicio: string;
          fechaFin: string;
          color: string;
          descripcion: string;
        }[];
      };
      totalBsPorRamo: {
        fecha: string;
        ramo: string;
        valor: number;
      }[];
      totalLiquidaciones: {
        [ramo: string]: {
          name: string;
          fecha: string;
          valor: number;
        };
      }[];
      totalTasasAE: {
        name: string;
        fecha: string;
        valor: number;
      }[];
    };
    total: {
      cantidadContribuyentes: number;
      cantidadUsuarios: number;
      cantidadRIMs: {
        liquidados: number;
        pagados: number;
        registrados: number;
      };
    };
  };
}
