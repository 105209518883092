import axios, { AxiosResponse } from "axios";
import { SET_NOTIFICATIONS, NotificationTypes, SET_NOTIFICATIONS_AS_READ, SEND_NOTIFICATION, USER_NOTIFICATION } from './actionsTypes'
import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { Notificacion } from "sigt";

const server = process.env.REACT_APP_SERVER_URL;

export const setNotifications = (notifications: Notificacion[]): NotificationTypes => ({type: SET_NOTIFICATIONS , payload: notifications})

export const setNotificationsAsRead = (read: boolean): NotificationTypes => ({type: SET_NOTIFICATIONS_AS_READ , payload: read})

export const sendNotification = (send: Notificacion): NotificationTypes => ({type: SEND_NOTIFICATION , payload: send})

export const hasNewNotifications = (hasNew: boolean): NotificationTypes => ({type: USER_NOTIFICATION , payload: hasNew})

export const fetchNotifications = (token: string): ThunkAction<Promise<void>, {}, {}, NotificationTypes> => 
async (dispatch: ThunkDispatch<{}, {}, NotificationTypes>): Promise<void> => {
  try {
    const response: AxiosResponse<FetchNotificationsResponse> = await axios.get(`${server}/notification`, { headers: { Authorization: `Bearer ${token}` } });
    dispatch(setNotifications(response.data.notificaciones))
  } catch (e) {
    throw e;
  }
};

export const markNotificationsAsRead = (token: string): ThunkAction<Promise<void>, {}, {}, NotificationTypes> => 
async (dispatch: ThunkDispatch<{}, {}, NotificationTypes>): Promise<void> => {
  try {
    const response: AxiosResponse = await axios.put(`${server}/notification/markAsRead`, {}, { headers: { Authorization: `Bearer ${token}` } });
    dispatch(setNotificationsAsRead(response.data.result));
  } catch (e) {
    throw e;
  }
};
interface FetchNotificationsResponse extends Response {
  notificaciones: Notificacion[];
}