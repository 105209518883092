import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const fecthReceipts = async (token, id) => {
  try {
    const response = await axios.get(`${server}/cashier/${id}/receipts`,{ headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const generateCertificade = async (data, renovacion, type, token) => {
  try {
    const datos = [{ datos: {...data, renovacion} }];
    if (type === 'SOLA') {
      console.log(datos)
      datos[0].datos.contribuyente = {razon_social: datos[0].datos.contribuyente, documento: datos[0].datos.cedulaORif, direccion: datos[0].datos.direccion, inmueble: {cod_catastral: datos[0].datos.codCat, tipo_inmueble: datos[0].datos.tipoInmueble, avaluo: datos[0].datos.avaluo, direccion: datos[0].datos.direccionInmueble}}
    }
    const response = await axios.post(`${server}/receipt/${type}/generate`, {datos}, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}