import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const recoverPassword = async (values) => {
  try {
    const response = await axios.post(`${server}/auth/forgotPassword`, values);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const newPassword = async (values) => {
  try {
    const response = await axios.patch(`${server}/auth/recoverPassword`, values);
    return response.data;
  } catch (error) {
    throw error;
  }
};