import React from 'react';
import { Select } from 'antd';

const ShiftSelect: React.FC<ShiftSelectProps> = ({ onChange, value, placeholder, disabled = false }) => {
  return (
    <Select value={value} onChange={onChange} placeholder={placeholder} disabled={disabled}>
      <Select.Option value='M'>Matutino</Select.Option>
      <Select.Option value='V'>Vespertino</Select.Option>
      <Select.Option value='N'>Nocturno</Select.Option>
    </Select>
  );
};

export default ShiftSelect;

interface ShiftSelectProps {
  onChange: (e: any) => any
  value: any
  placeholder: string
  disabled?: boolean
}