import { States } from "sigt";
import { OfficialsTypes, SET_OFFICIALS, DELETE_OFFICIAL, CREATE_OFFICIAL, EDIT_OFFICIAL, AUTH_USER_LOGOUT } from "../actions/actionsTypes";

const intitialState: States.Officials = {
  officials: [],
  error: {}
};

export default (state = intitialState, action: OfficialsTypes): States.Officials => {
  switch(action.type) {
    case SET_OFFICIALS: return { ...state, officials: action.payload };
    case CREATE_OFFICIAL: return {...state, officials: [...state.officials, action.payload] }
    case EDIT_OFFICIAL: 
      const oIndex = state.officials.findIndex((o) => o.id === action.payload.id);
      state.officials[oIndex] = Object.assign({}, action.payload);
      return {...state, ...state.officials };
    case DELETE_OFFICIAL:
      const offc = state.officials ? state.officials : [];
      const index = state.officials.findIndex((o) => o.id === action.payload);
      const _offcial = offc[index]
      _offcial.bloqueado = !_offcial.bloqueado
      offc[index] = _offcial;
      return {
        ...state,
        officials: offc
      };
    case AUTH_USER_LOGOUT: return intitialState;
    default: return state;
  }
};