import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const fetchExonerationTaxPayer = async ({document,docType,rim}) => {
  try {
    const response = await axios.get(`${server}/exonerations/contributor/${docType}/${document}/${rim}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const createExonerationTaxPayer = async ({doc,typeDoc,from,activities,ref}) => {
  try {
    const response = await axios.post(`${server}/exonerations/contributor`,{doc,typeDoc,ref,from,activities},{ headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateExonerationEndDate = async ({to,id}) => {
  try {
    const response = await axios.patch(`${server}/exonerations/${id}`,{to},{ headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchExonerationsAE = async () => {
  try {
    const response = await axios.get(`${server}/exonerations/activities`,{ headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const createExonerationAE = async ({from,activities}) => {
  try {
    const response = await axios.post(`${server}/exonerations/activity`,{from,activities},{ headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    return response.data.data;
  } catch (error) {
    throw error;
  }
}

export const fetchExonerationsBranches = async () => {
  try {
    const response = await axios.get(`${server}/exonerations/branches`,{ headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const createExonerationBranch = async ({from,branches}) => {
  try {
    const response = await axios.post(`${server}/exonerations/branch`,{from,branches},{ headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    return response.data.data;
  } catch (error) {
    throw error;
  }
}


