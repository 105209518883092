import React, { useEffect, useState } from 'react';
import {
  Card,
  Form,
  Tabs,
  Row,
  Col,
  Input,
  Select,
  Button,
  message,
  Spin,
  Divider,
  Typography,
  Table,
  InputNumber,
  Descriptions,
  Checkbox,
  Modal,
  Collapse,
  Alert,
  DatePicker,
} from 'antd';
import { useWindowDimensions } from '../../utils/hooks';
import {
  States,
  State,
  ActividadEconomica,
  ServicioMunicipal,
  InmuebleUrbano,
  Publicidad,
  Liquidacion,
  Request,
  Fecha,
} from 'sigt';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import axios from 'axios';
import { LoadingOutlined, DeleteOutlined, CheckCircleFilled, DownloadOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import _, { remove, cloneDeep, uniq, maxBy, minBy } from 'lodash';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { addRequest } from '../../redux/actions/requests';
import '../../assets/css/components/DeclareTaxes.css';
import { setTax } from '../../redux/actions/taxes';
import UserSelect from '../Forms/components/UserSelect';
import { Utils } from '../../utils/validators';
import moment from 'moment';
import Petro from '../Icons/Petro';
import { fromMonthToNumber, fromNumberToMonth } from '../../utils/fromMonthToNumber';
import { formatCurrency, roundCalc } from './PaySpecificTax';
import FileImageUpload from '../Forms/components/FileImageUpload';
const server = process.env.REACT_APP_SERVER_URL;

const DeclareAnyTaxes: React.FC<DeclareTaxesProps> = ({ thm, auth, addRequest }) => {
  const [nacionalidadRif, setNacionalidadRif] = useState('J');
  const [nacionalidad, setNacionalidad] = useState('V');
  const [active, setActive] = useState<'JURIDICO' | 'NATURAL'>('JURIDICO');
  const [loading, setLoading] = useState(false);
  const [taxes, setTaxes] = useState<Taxes | null | any>(null);
  const [data, setData] = useState<any>({});
  const [PP, setPP] = useState<{ [f: string]: any[] }>({});
  const [article, setArticle] = useState<{ [f: string]: string } | undefined>(undefined);
  const [subarticle, setSubarticle] = useState<{ [f: string]: string } | undefined>(undefined);
  const [quantity, setQuantity] = useState<{ [f: string]: number } | undefined>(undefined);
  const [includeIU, setIncludeIU] = useState<boolean>(false);
  const [includePP, setIncludePP] = useState<boolean>(false);
  const [includeAE, setIncludeAE] = useState<boolean>(false);
  const [includeSM, setIncludeSM] = useState<boolean>(false);
  const [includeVH, setIncludeVH] = useState<boolean>(false);
  const [creating, setCreating] = useState(false);
  const [esAgenteSENIAT, setEsAgenteSENIAT] = useState(false);
  const [fetchingIU, setFetchingIU] = useState(false);
  const [fechaSM, setFechaSM] = useState<any>({ year: null, month: null, taxesSM: [] });
  const [file, setFile] = useState<any>([]);
  const [periodoIU, setPeriodoIU] = useState<any>();
  const [selectPeriodoIU, setSelectPeriodoIU] = useState<any>();

  const petro = useSelector((state: State) => state.coin.petro);
  const aeRedux = useSelector((state: State) => state.ae);
  const allowedPayment = [25, 22];
  const solvencia = +((taxes?.AE?.[0].costoSolvencia || 0) / petro).toFixed(2);

  const { width } = useWindowDimensions();
  const history = useHistory();
  const [form] = Form.useForm();

  // const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  useEffect(() => {
    if (auth.user?.tipoUsuario === 4) {
      if (auth.user?.contribuyente) {
        setActive(auth.user.contribuyente.tipoContribuyente);
        setNacionalidad(auth.user.contribuyente.tipoDocumento);
        setNacionalidadRif(auth.user.contribuyente.tipoDocumento);
      } else {
        message.error('Este usuario no posee un contribuyente asociado para la declaración de impuestos.');
        history.push('/dashboard');
      }
    }
    // eslint-disable-next-line
  }, [auth.user]);

  useEffect(() => {
    if (auth.user?.tipoUsuario !== 4) {
      form.setFieldsValue({
        documento: null,
        rim: null,
      });
    }
    // eslint-disable-next-line
  }, [active]);

  useEffect(() => {
    form.setFieldsValue({
      PP: {
        subarticulo: undefined,
      },
    });
    // eslint-disable-next-line
  }, [data.PP?.articulo]);

  useEffect(() => {
    if (taxes?.esContribuyenteTop) {
      setIncludeAE(true);
      setIncludeSM(true);
    }
    if (taxes?.esAgenteRetencion) {
      // setIncludeAE(true);
      // setIncludeSM(true);
      // setIncludeIU(true);
    }
  }, [taxes]);

  useEffect(() => {
    // const includingAE = getAllAESubtotal() === -1 && !!taxes?.AE;
    if (includeIU) {
      // if (includingAE) {
      //   setIncludeIU(false);
      // } else {
      fetchIU();
      // }
    }
    // eslint-disable-next-line
  }, [includeIU, data]);

  const fetchIU = async () => {
    setFetchingIU(true);
    try {
      setTaxes((_taxes) => (_taxes ? { ..._taxes, IU: [] } : null));
      const deuda = taxes?.AE?.[0]?.deuda[taxes?.AE?.[0]?.deuda.length - 1];
      const documento = auth.user?.tipoUsuario === 4 ? auth.user?.contribuyente?.documento : form.getFieldValue('documento');
      const monto = +getAESubtotal(deuda?.month, deuda?.year);
      const declaredAE = !isNaN(monto);
      const response = await axios.get(
        `${server}/settlements/iu/declaration?doc=${documento}${data.rim ? `&ref=${data.rim}` : ''}&pref=${
          active === 'JURIDICO' ? nacionalidadRif : nacionalidad
        }${declaredAE ? `&amount=${monto}` : ''}`,
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      setTaxes((_taxes) => (_taxes ? { ..._taxes, IU: response.data.IU } : null));
      let a = response.data.IU.map((iu) => {
        return iu.deuda.map((d) => `${d.period} - ${d.year}`);
      });
      console.log(a, 'setSelectPeriodoIU');
      setSelectPeriodoIU(a);
    } catch (e) {
      message.error('Error al consultar impuestos de inmuebles urbanos.');
    } finally {
      setFetchingIU(false);
    }
  };

  const firstLetterMayus = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  const calculateIU = () => {
    const calc: {
      desglose: { inmueble: number; clasificacion: string; monto: number; period: string }[];
      fechaCancelada: { month: string; year: number; period: string };
      monto: number;
      ramo: 'IU';
      inmueble: number;
      clasificacion: string;
    }[] = [];
    periodoIU.taxes
      .filter((t) => t.deuda)
      .forEach((t) => {
        t.deuda.forEach((d) => {
          // const index = calc.findIndex((c) => c.fechaCancelada.month === d.month && c.fechaCancelada.year === d.year);
          // if (index === -1) {
          calc.push({
            desglose: [
              {
                inmueble: t.id,
                clasificacion: t?.clasificacion ?? 'N/A',
                monto: d.impuestoInmueble.terreno + d.impuestoInmueble.construccion,
                period: d.period,
              },
            ],
            fechaCancelada: { month: d?.month, year: d.year, period: d.period },
            monto: d.impuestoInmueble.terreno + d.impuestoInmueble.construccion,
            ramo: 'IU',
            inmueble: t.id,
            clasificacion: t?.clasificacion ?? 'N/A',
          });
          // } else {
          //   calc[index].desglose = [
          //     ...calc[index].desglose,
          //     { inmueble: t.id, monto: !d.exonerado ? d.impuestoInmueble * (1 - d.descuento) : 0, descuento: d.descuento },
          //   ];
          // calc[index].monto =
          // calc[index].monto + (!d.exonerado ? +((d.impuestoInmueble * (1 - d.descuento)) / (taxes.petro || 1)).toFixed(8) : 0);
          // }
        });
      });
    return calc;
  };

  const calculateVH = () => {
    const calc: {
      desglose: { vehiculo: number; monto: number }[];
      fechaCancelada: { month: string; year: number };
      monto: number;
      ramo: 'VH';
    }[] = [];
    taxes?.VH?.forEach((t) => {
      t.deuda.forEach((d) => {
        const index = calc.findIndex((c) => c.fechaCancelada.year === d.year);
        if (index === -1) {
          calc.push({
            desglose: [{ vehiculo: t.vehiculo.id_vehiculo, monto: +t.tarifa * petro }],
            fechaCancelada: { year: d.year, month: '-' },
            monto: +t.tarifa,
            ramo: 'VH',
          });
        } else {
          calc[index].desglose = [...calc[index].desglose, { vehiculo: t.vehiculo.id_vehiculo, monto: +t.tarifa * petro }];
          calc[index].monto = +calc[index].monto + +t.tarifa;
        }
      });
    });
    return calc;
  };

  const calculateSM = () => {
    const calc: {
      desglose: { inmueble: number; montoAseo: number; descuento: number }[];
      fechaCancelada: { month: string; year: number };
      monto: number;
      ramo: 'SM';
    }[] = [];
    fechaSM?.taxesSM?.forEach((t) => {
      t.deuda.forEach((d) => {
        const index = calc.findIndex((c) => c.fechaCancelada.month === d.month && c.fechaCancelada.year === d.year);
        if (index === -1) {
          calc.push({
            desglose: [
              {
                inmueble: t.id,
                montoAseo: !d.exonerado ? +(+t.tarifaAseo * (1 - d.descuento)) : 0,
                descuento: d.descuento,
              },
            ],
            fechaCancelada: { month: d.month, year: d.year },
            monto: !d.exonerado ? +(+t.tarifaGas * (1 - d.descuento)) + +(+t.tarifaAseo * (1 - d.descuento)).toFixed(8) : 0,
            ramo: 'SM',
          });
        } else {
          calc[index].desglose = [
            ...calc[index].desglose,
            {
              inmueble: t.id,
              montoAseo: !d.exonerado ? t.tarifaAseo * (1 - d.descuento) : 0,
              descuento: d.descuento,
            },
          ];
          calc[index].monto =
            calc[index].monto + (!d.exonerado ? +(+t.tarifaGas * (1 - d.descuento)) + +(+t.tarifaAseo * (1 - d.descuento)) : 0);
        }
      });
    });
    return calc;
  };

  const handleSubmit = async () => {
    const documento = auth.user?.tipoUsuario === 4 ? auth.user?.contribuyente?.documento : form.getFieldValue('documento');
    const isValid = !!documento;
    if (isValid) {
      try {
        setLoading(true);
        const response = await axios.get<{ impuesto: Taxes }>(
          `${server}/settlements?doc=${documento}${data.rim ? `&ref=${data.rim}` : ''}&pref=${
            active === 'JURIDICO' ? nacionalidadRif : nacionalidad
          }&contrib=${active}`,
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        if (response.status === 202)
          history.push('/dashboard/impuestos/enlazar', { datosEnlace: (response.data as any).datosEnlace[0] });
        setTaxes(response.data.impuesto);
      } catch (e) {
        message.error(e?.response?.data?.error || e?.response?.data?.message || 'Error en el servidor');
      } finally {
        setLoading(false);
      }
    } else {
      message.error('Debe ingresar los datos requeridos para la consulta de impuestos');
    }
  };

  // const isMinTributable = (year, month, id) => {
  //   const AE = cloneDeep(taxes?.AE || []);
  //   const isExo = AE.find((ae) => ae.id === id)?.deuda?.find((d) => d.month === month && d.year === year)?.exonerado;
  //   if (isExo) return false;
  //   if (data && data[year] && data[year][month]) {
  //     console.log(AE, 'PABLO', Math.max(...data[year][month].filter(num => num)), data[year][month][id], Math.max(...data[year][month].filter(num => num)) === data[year][month][id]);
  //     const lowerFee = AE.sort((a, b) => {
  //       if (a.alicuota > b.alicuota) return 1;
  //       if (b.alicuota > a.alicuota) return -1;
  //       return 0;
  //     }).find((ae) => data[year][month][ae.id] * ae.alicuota <= ae.minimoTributable);
  //     return lowerFee?.id === id;
  //   }
  //   return false;
  // };

  const isMinTributable = (year, month, id) => {
    const AE = cloneDeep(taxes?.AE || []);
    // console.log(AE, 'vamoave');
    const AEMonth = data[year][month];
    const isExo = AE.find((ae) => ae.id === id)?.deuda?.find((d) => d.month === month && d.year === year)?.exonerado;
    if (isExo) return false;
    if (data && data[year] && data[year][month]) {
      // console.log(AE, 'PABLO', Math.max(...data[year][month].filter(num => num)), data[year][month][id], Math.max(...data[year][month].filter(num => num)) === data[year][month][id]);
      const correctFee = AE.every((ae) => AEMonth[ae.id] * ae.alicuota <= ae.minimoTributable)
        ? AE.sort((a, b) => {
            if (a.minimoTributable > b.minimoTributable) return 1;
            if (a.minimoTributable < b.minimoTributable) return -1;
            return 0;
          }).find((ae) => Math.max(...AEMonth.filter((num) => num >= 0)) === data[year][month][ae.id])
        : undefined;
      // console.log(AE.every(ae => data[year][month][ae.id] * ae.alicuota <= ae.minimoTributable), correctFee,'AE')
      return correctFee?.id === id;
    }
    return false;
  };

  const declareMono = async () => {
    const impuestos: TaxDeclaration['impuestos'] = [];
    if (taxes?.MONO) {
      const { montoAE, montoIU, montoPP, montoSM, montoSAE, aforos } = taxes.MONO;
      taxes?.MONO?.deuda.forEach((m) => {
        impuestos.push({
          ramo: 'AE',
          fechaCancelada: {
            month: moment().locale('es').month(m.month).subtract(1, 'month').format('MMMM'),
            year: +moment().locale('es').month(m.month).subtract(1, 'month').format('YYYY'),
          },
          monto: +Number(montoAE + montoSAE).toFixed(8),
          desglose: aforos.map((a) => ({ aforo: a, montoDeclarado: 0, montoCobrado: 0 })),
        });
        impuestos.push({
          ramo: 'IU',
          fechaCancelada: {
            month: m.month,
            year: m.year,
          },
          monto: +Number(montoIU).toFixed(8),
          desglose: undefined,
        });
        impuestos.push({
          ramo: 'SM',
          fechaCancelada: {
            month: m.month,
            year: m.year,
          },
          monto: +Number(montoSM / 1.16).toFixed(8),
          desglose: [{ inmueble: 0, montoAseo: (montoSM / 1.16 / 2) * petro }],
        });
        impuestos.push({
          ramo: 'PP',
          fechaCancelada: {
            month: m.month,
            year: m.year,
          },
          monto: +Number(montoPP).toFixed(8),
          desglose: undefined,
        });
      });
      const montoTotal = impuestos.map((i) => i.monto).reduce((c, v) => c + v, 0);
      const procedimiento: TaxDeclaration = {
        documento: form.getFieldValue('documento') || auth.user?.contribuyente?.documento,
        rim: form.getFieldValue('rim'),
        tipoDocumento: active === 'JURIDICO' ? nacionalidadRif : nacionalidad,
        impuestos,
        totalPagoImpuestos: montoTotal,
        contribuyente: taxes?.contribuyente,
        usuario: form.getFieldValue('usuario'),
        esMonotributo: true,
        esAgenteSENIAT,
      };
      setCreating(true);
      try {
        const response = await axios.post(
          `${server}/settlements/init`,
          { procedimiento },
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        message.success('Declaración creada exitosamente.');
        addRequest({ ...response.data.solicitud, creditoFiscal: taxes?.creditoFiscal });
        response.data.solicitud.liquidaciones.forEach((item) => {
          const data: Liquidacion = {
            id: item.id,
            fecha: item.fecha,
            ramo: item.ramo,
            monto: item.monto,
            montoPetro: item.montoPetro,
            aprobado: response.data.solicitud.aprobado,
            pagado: response.data.solicitud.pagado,
            estado: response.data.solicitud.estado,
          };
          setTax(data);
        });
        setCreating(false);
        !allowedPayment.find((id) => auth.user?.institucion?.cargo?.id === id)
          ? setTaxes(null)
          : history.push(`/dashboard/impuestos/pagar/${response.data.solicitud.id}`);
      } catch (e) {
        message.error(e?.response?.data?.message || 'Error en el servidor');
      } finally {
        setCreating(false);
      }
    }
  };

  const declare = async () => {
    if (fetchingIU)
      return message.warning(
        'Debe calcularse los impuestos a pagar por Inmuebles Urbanos para hacer la declaración correspondiente.'
      );
    const firstValidation = validateAESequence();
    const validation = firstValidation && validateAEDeclarations();

    if (includeAE && !validation && taxes?.AE)
      return message.error('Debe ingresar la declaración correspondiente a Actividades Economicas');
    const impuestos: TaxDeclaration['impuestos'] = [];
    if (includeAE && taxes?.AE && taxes?.AE.length > 0) {
      if (file.length < 1 && auth.user?.tipoUsuario === 4)
        return message.warning('Debe subir la planilla de declaración de I.V.A. para declarar impuestos');
      // console.log(taxes?.AE[getLongerAE()], 'prueba')
      taxes?.AE[getLongerAE()].deuda.forEach((ae) => {
        if (
          data[ae.year] &&
          data[ae.year][ae.month] &&
          (data[ae.year][ae.month][taxes?.AE[getLongerAE()].id] === 0 || data[ae.year][ae.month][taxes?.AE[getLongerAE()].id])
        ) {
          impuestos.push({
            ramo: 'AE',
            fechaCancelada: {
              month: ae.month,
              year: ae.year,
            },
            monto: taxes.AE
              ? +(
                  parseFloat(getAESubtotal(ae.month, ae.year) || '0') /* + taxes.AE[0].costoSolvencia*/ / (taxes.petro || 1)
                ).toFixed(8)
              : 0,
            // desglose: getMinTributable(ae)
            // desglose por defecto
            desglose: taxes.AE?.filter((d) => d.deuda.some((de) => de.month === ae.month && de.year === ae.year)).map((i) => ({
              aforo: i.id,
              montoDeclarado: data[ae.year][ae.month][i.id].toFixed(2),
              montoCobrado:
                parseFloat((data[ae.year][ae.month][i.id] * i.alicuota).toFixed(2)) <= i.minimoTributable &&
                isMinTributable(ae.year, ae.month, i.id) /* &&
              (fromMonthToNumber(ae.month) <= 1 ? ae.year < 2023 : ae.year < 2022)*/
                  ? i.minimoTributable
                  : parseFloat((data[ae.year][ae.month][i.id] * i.alicuota).toFixed(2)),
              minimoTributable: +(aeRedux?.activities?.find((a) => a.id === i.id)?.minimoTributable ?? 0),
              // minTributable: i.minimoTributable = +(aeRedux?.activities?.find(a => a.id === i.id)?.minimoTributable ?? 0)
            })),
          });
        }
      });
      // console.clear()
      // console.log('impuesto',impuestos)
    }
    if (includeAE && taxes && taxes.AE && taxes.montoAcarreado.AE.monto > 0) {
      impuestos.push({
        ramo: 'AE',
        fechaCancelada: {
          month: taxes.montoAcarreado.AE.fecha.month,
          year: taxes.montoAcarreado.AE.fecha.year,
        },
        monto: +(taxes.montoAcarreado.AE.monto /* + taxes.AE[0].costoSolvencia*/ / taxes.petro).toFixed(8),
        desglose: null,
      });
    }
    if (includeSM && taxes?.SM && taxes?.SM.length > 0) {
      impuestos.push(...calculateSM());
    }
    if (includeSM && taxes && taxes.montoAcarreado.SM.monto > 0) {
      impuestos.push({
        ramo: 'SM',
        fechaCancelada: {
          month: taxes.montoAcarreado.SM.fecha.month,
          year: taxes.montoAcarreado.SM.fecha.year,
        },
        monto: taxes.montoAcarreado.SM.monto,
        desglose: null,
      });
    }
    if (includeIU && taxes?.IU && taxes.IU.length > 0) {
      impuestos.push(...calculateIU());
    }
    if (includeIU && taxes && taxes.montoAcarreado.IU.monto > 0) {
      impuestos.push({
        ramo: 'IU',
        fechaCancelada: {
          month: taxes.montoAcarreado.IU.fecha.month,
          year: taxes.montoAcarreado.IU.fecha.year,
        },
        monto: taxes.montoAcarreado.IU.monto,
        desglose: null,
      });
    }
    if (includePP && taxes && taxes.PP) {
      taxes.PP.deuda.forEach((pp) => {
        impuestos.push({
          ramo: 'PP',
          fechaCancelada: {
            month: pp.month,
            year: pp.year,
          },
          monto: +(getPPSubtotal(pp.month + pp.year) / (taxes.petro || 1)).toFixed(8),
          desglose: PP[pp.month + pp.year]
            ? PP[pp.month + pp.year].map((p) => ({
                subarticulo: taxes.PP
                  ? taxes.PP.articulos
                      .find((art) => {
                        return !!art.subarticulos.find((subart) => subart.nombreSubarticulo === p.subarticulo)?.id;
                      })
                      ?.subarticulos.find((subart) => subart.nombreSubarticulo === p.subarticulo)?.id
                  : 0,
                cantidad: p.cantidad,
                monto: getPPItemCost(p.date, p.articulo, p.subarticulo, p.cantidad).toFixed(2),
              }))
            : [],
        });
      });
    }
    if (includePP && taxes && taxes.montoAcarreado.PP.monto > 0) {
      impuestos.push({
        ramo: 'PP',
        fechaCancelada: {
          month: taxes.montoAcarreado.PP.fecha.month,
          year: taxes.montoAcarreado.PP.fecha.year,
        },
        monto: taxes.montoAcarreado.PP.monto,
        desglose: null,
      });
    }
    if (includeVH && taxes?.VH && taxes?.VH.length > 0) {
      impuestos.push(...calculateVH());
    }
    const montoTotal = roundCalc(
      impuestos.map((i) => i.monto).reduce((c, v) => parseFloat(c.toFixed(2)) + parseFloat(v.toFixed(2)))
    );
    const procedimiento: TaxDeclaration = {
      documento: form.getFieldValue('documento') || auth.user?.contribuyente?.documento,
      rim: form.getFieldValue('rim'),
      tipoDocumento: active === 'JURIDICO' ? nacionalidadRif : nacionalidad,
      impuestos,
      totalPagoImpuestos: montoTotal,
      contribuyente: taxes?.contribuyente,
      usuario: form.getFieldValue('usuario'),
      esAgenteRetencion: taxes?.esAgenteRetencion,
      esAgenteSENIAT,
      esMonotributo: false,
    };
    setCreating(true);
    try {
      const response = await axios.post(
        `${server}/settlements/init`,
        { procedimiento },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      message.success('Declaración creada exitosamente.');

      if ([200, 201].includes(response.status)) {
        if (file.length > 0) {
          const fd = new FormData();
          file.forEach((e) => fd.append('planillas', e.file));

          await axios
            .post(`${server}/uploads/planillas/${response.data.solicitud.id}`, fd, {
              headers: { Authorization: `Bearer ${auth.token}` },
            })
            .then((res) => message.success('Archivo de declaración guardado exitosamente.'))
            .catch((error) => {
              message.error('Error al momento de guardar el archivo de declaración.');
              console.log(error);
            });
        }
      }

      addRequest({ ...response.data.solicitud, creditoFiscal: taxes?.creditoFiscal });
      response.data.solicitud.liquidaciones.forEach((item) => {
        const data: Liquidacion = {
          id: item.id,
          fecha: item.fecha,
          ramo: item.ramo,
          monto: item.monto,
          aprobado: response.data.solicitud.aprobado,
          pagado: response.data.solicitud.pagado,
          estado: response.data.solicitud.estado,
          montoPetro: item.montoPetro,
        };
        setTax(data);
      });
      setCreating(false);

      !allowedPayment.find((id) => auth.user?.institucion?.cargo?.id === id)
        ? setTaxes(null)
        : history.push(`/dashboard/impuestos/pagar/${response.data.solicitud.id}`);
    } catch (e) {
      message.error(e?.response?.data?.message || 'Error en el servidor');
    } finally {
      setCreating(false);
    }
  };

  const getMinTributable = (ae) => {
    let declaracion = taxes.AE?.filter((d) => d.deuda.some((de) => de.month === ae.month && de.year === ae.year)).map((i) => {
      return {
        aforo: i.id,
        minimoTributable: i.minimoTributable,
        montoDeclarado: data[ae.year][ae.month][i.id].toFixed(2),
        montoCobrado:
          parseFloat((data[ae.year][ae.month][i.id] * i.alicuota).toFixed(2)) <= i.minimoTributable &&
          isMinTributable(ae.year, ae.month, i.id) /* &&
          (fromMonthToNumber(ae.month) <= 1 ? ae.year < 2023 : ae.year < 2022)*/
            ? i.minimoTributable
            : parseFloat((data[ae.year][ae.month][i.id] * i.alicuota).toFixed(2)),
      };
    });

    let minTributable: any;
    let MonthMinTributable = declaracion.every((d) => d.montoDeclarado < d.minimoTributable);

    if (MonthMinTributable) {
      minTributable = declaracion.reduce((prev, curr) => {
        return curr.minimoTributable > prev.minimoTributable ? curr : prev;
      }, declaracion[0]);

      declaracion.forEach((d) => {
        if (d.aforo === minTributable.aforo) {
          d.minTributable = true;
          d.minimoTributable = +(aeRedux?.activities?.find((a) => a.id === d.aforo)?.minimoTributable ?? 0);
        } else {
          d.minTributable = false;
          d.minimoTributable = +(aeRedux?.activities?.find((a) => a.id === d.aforo)?.minimoTributable ?? 0);
        }
      });
    } else {
      declaracion.forEach((d) => {
        d.minTributable = false;
        d.minimoTributable = +(aeRedux?.activities?.find((a) => a.id === d.aforo)?.minimoTributable ?? 0);
      });
    }

    return declaracion;
  };

  const rifAddon = (
    <Select value={nacionalidadRif} onChange={(e) => setNacionalidadRif(e)}>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='G'>G</Select.Option>
    </Select>
  );

  const cedulaAddon = (
    <Select value={nacionalidad} onChange={(e) => setNacionalidad(e)}>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='V'>V</Select.Option>
    </Select>
  );

  const AEcolumns: ColumnsType<ActividadEconomica> = [
    {
      title: 'Aforo',
      dataIndex: 'nombreActividad',
      key: 'nombreActividad',
    },
    {
      title: 'Alicuota',
      dataIndex: 'alicuota',
      key: 'alicuota',
      render: (record) => <span>{parseFloat((record * 100).toPrecision(15)).toFixed(2)}%</span>,
    },
    {
      title: 'Mínimo Tributable',
      dataIndex: 'minimoTributable',
      render: (mt, record) =>
        // (
        //   fromMonthToNumber(record.date?.month) <= 1
        //     ? record.date
        //       ? record.date.year < 2023
        //       : false
        //     : record.date
        //     ? record.date.year < 2022
        //     : false
        // ) ? (
        !record.date?.exo ? <span>{`Bs. ${formatCurrency(mt)}`}</span> : <b>EXONERADO</b>,
      // ) : (
      //   <b>NO APLICA</b>
      // ),
    },
    {
      title: 'Monto a Declarar (Bs.)',
      dataIndex: 'date',
      render: (date, record) => {
        return (
          <Form.Item name={[date.year, date.month, record.id]} style={{ margin: 0, width: '100%' }}>
            <InputNumber
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
              min={0}
              step={1}
              style={{ width: '100%' }}
              placeholder='Monto'
            />
          </Form.Item>
        );
      },
    },
    {
      title: 'Impuestos',
      dataIndex: 'date',
      render: (date, record) => {
        const exists = data[date.year] && data[date.year][date.month] && data[date.year][date.month][record.id];
        return (
          <span>
            {exists
              ? `Bs. ${formatCurrency(
                  parseFloat((data[date.year][date.month][record.id] * record.alicuota).toFixed(2)) <= record.minimoTributable &&
                    isMinTributable(date.year, date.month, record.id) /* &&
                    (fromMonthToNumber(date.month) <= 1 ? date.year < 2023 : date.year < 2022)*/
                    ? record.minimoTributable
                    : parseFloat((data[date.year][date.month][record.id] * record.alicuota).toFixed(2))
                )}`
              : exists === 0
              ? `Bs. ${
                  isMinTributable(date.year, date.month, record.id) /* &&
                  (fromMonthToNumber(date.month) <= 1 ? date.year < 2023 : date.year < 2022)*/
                    ? formatCurrency(record.minimoTributable)
                    : 0
                }`
              : 'N/A'}
          </span>
        );
      },
    },
    {
      title: 'Petros',
      dataIndex: 'date',
      render: (date, record) => {
        const exists = data[date.year] && data[date.year][date.month] && data[date.year][date.month][record.id];
        return (
          <span>
            {exists
              ? `${(parseFloat((data[date.year][date.month][record.id] * record.alicuota) as any) <= record.minimoTributable &&
                isMinTributable(date.year, date.month, record.id) /* &&
                (fromMonthToNumber(date.month) <= 1 ? date.year < 2023 : date.year < 2022)*/
                  ? record.minimoTributable / (taxes?.petro || 1)
                  : Number(
                      parseFloat(((data[date.year][date.month][record.id] * record.alicuota) / (taxes?.petro || 1)).toFixed(8))
                    )
                ).toFixed(8)}`
              : exists === 0
              ? `${
                  isMinTributable(date.year, date.month, record.id) /* &&
                  (fromMonthToNumber(date.month) <= 1 ? date.year < 2023 : date.year < 2022)*/
                    ? Number(record.minimoTributable / (taxes?.petro || 1)).toFixed(8)
                    : 0
                }`
              : 'N/A'}
            <Petro />
          </span>
        );
      },
    },
  ];

  const SMcolumns: ColumnsType<any> = [
    {
      title: 'Fecha',
      dataIndex: 'deuda',
      render: (date) => (
        <span>
          {firstLetterMayus(date.month)} {date.year}
        </span>
      ),
    },
    {
      title: 'Tarifa Aseo',
      dataIndex: 'tarifaAseo',
      render: (ta, record) =>
        record.deuda.exo ? (
          <b>EXONERADO</b>
        ) : (
          <span>Bs. {formatCurrency(+(ta * petro * (1 - record.deuda.descuento)).toFixed(2))}</span>
        ),
    },
    {
      title: 'Tarifa Aseo en Petros',
      dataIndex: 'tarifaAseo',
      render: (ta, record) =>
        record.deuda.exo ? (
          <b>EXONERADO</b>
        ) : (
          <span>
            {(+(ta * (1 - record.deuda.descuento))).toFixed(8)} <Petro />
          </span>
        ),
    },
    // {
    //   title: 'Tarifa Gas',
    //   dataIndex: 'tarifaGas',
    //   render: (tg, record) =>
    //     record.deuda.exo ? <b>EXONERADO</b> : <span>Bs. {formatCurrency(+(tg * (1 - record.deuda.descuento)).toFixed(2))}</span>,
    // },
    // {
    //   title: 'Tarifa Gas en Petros',
    //   dataIndex: 'tarifaGas',
    //   render: (tg, record) =>
    //     record.deuda.exo ? (
    //       <b>EXONERADO</b>
    //     ) : (
    //       <span>
    //         {(+(tg * (1 - record.deuda.descuento)) / (taxes?.petro || 1)).toFixed(8)} <Petro />
    //       </span>
    //     ),
    // },
  ];

  const TasaSMColumn: ColumnsType<any> = [
    {
      title: 'Fecha',
      dataIndex: 'deuda',
      render: (date) => (
        <span>
          {firstLetterMayus(date.month)} {date.year}
        </span>
      ),
    },
    {
      title: 'Descripcion',
      dataIndex: 'descripcion',
      render: (ta) => <span>TASA DEL 10 PORCIENTO POR DESHECHOS SÓLIDOS</span>,
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      render: (ta) => <span>Bs. {formatCurrency(+(ta * petro).toFixed(2))}</span>,
    },
  ];

  if (taxes?.SM?.find((sm) => sm.deuda.find((d) => d.descuento > 0))) {
    SMcolumns[5] = Object.assign({}, SMcolumns[4]);
    SMcolumns[4] = Object.assign({}, SMcolumns[3]);
    SMcolumns[3] = Object.assign({}, SMcolumns[2]);
    SMcolumns[2] = Object.assign({}, SMcolumns[1]);
    SMcolumns[1] = {
      title: 'Descuento',
      dataIndex: 'deuda',
      render: (_, record) => <b>{record.deuda.descuento * 100}%</b>,
    };
  }

  const IUcolumns: ColumnsType<any> = [
    {
      title: 'Fecha',
      dataIndex: 'deuda',
      render: (date) => (
        <span>{date?.month ? `${firstLetterMayus(date.month)} ${date.year}` : `${date.period} ${date.year}`}</span>
      ),
    },
    {
      title: 'Impuesto Terreno en Bs.',
      dataIndex: 'impuesto',
      render: (imp, record) => <span>Bs. {formatCurrency(+imp.terreno * (petro || 1))}</span>,
    },
    {
      title: 'Impuesto Construcción en Bs.',
      dataIndex: 'impuesto',
      render: (imp, record) => <span>Bs. {formatCurrency(+imp.construccion * (petro || 1))}</span>,
    },
    {
      title: 'Impuesto Terreno en Petros',
      dataIndex: 'impuesto',
      render: (imp, record) => (
        <span>
          {(+imp.terreno).toFixed(8)} <Petro />
        </span>
      ),
    },
    {
      title: 'Impuesto Construcción en Petros',
      dataIndex: 'impuesto',
      render: (imp, record) => (
        <span>
          {(+imp.construccion).toFixed(8)} <Petro />
        </span>
      ),
    },
  ];

  const VHcolumns: ColumnsType<any> = [
    {
      title: 'Fecha',
      dataIndex: 'deuda',
      render: (date) => <span> Año {date.year} </span>,
    },
    {
      title: 'Tarifa en Bs.',
      dataIndex: 'tarifa',
      render: (tarifa, record) => <span>Bs. {tarifa ? formatCurrency(+tarifa * petro) : 'N/A'}</span>,
    },
    {
      title: 'Tarifa en Petros',
      dataIndex: 'tarifa',
      render: (tarifa, record) => (
        <span>
          {' '}
          {tarifa ? +tarifa.toFixed(8) : 'N/A'} <Petro />{' '}
        </span>
      ),
    },
  ];

  // if (taxes?.IU?.find((sm) => sm.deuda.find((d) => d.descuento > 0))) {
  //   IUcolumns[3] = Object.assign({}, IUcolumns[2]);
  //   IUcolumns[2] = Object.assign({}, IUcolumns[1]);
  //   IUcolumns[1] = {
  //     title: 'Descuento',
  //     dataIndex: 'deuda',
  //     render: (_, record) => <b>{record.deuda.descuento * 100}%</b>,
  //   };
  // }

  const PPcolumns: ColumnsType<any> = [
    {
      title: 'Categoría',
      dataIndex: 'articulo',
      width: '30%',
    },
    {
      title: 'Tipo de Aviso',
      dataIndex: 'subarticulo',
      width: '30%',
    },
    {
      title: 'Cantidad',
      dataIndex: 'cantidad',
      width: '15%',
      render: (qty) => <span>{formatCurrency(qty)}</span>,
    },
    {
      title: 'Costo',
      dataIndex: 'date',
      width: '15%',
      render: (date, record) => (
        <span>Bs. {formatCurrency(getPPItemCost(date, record.articulo, record.subarticulo, record.cantidad))}</span>
      ),
    },
    {
      title: 'Acciones',
      dataIndex: 'date',
      width: '10%',
      render: (date, record) => (
        <span>
          <Button type='link' onClick={() => removePPItem(date, record.articulo, record.subarticulo)}>
            <DeleteOutlined />
          </Button>
        </span>
      ),
    },
  ];

  const MONOcolumns: ColumnsType<any> = [
    {
      dataIndex: 'date',
      title: 'Fecha',
      render: (date) => (
        <span>
          {firstLetterMayus(date.month)} {date.year}
        </span>
      ),
    },
    {
      dataIndex: 'date',
      title: 'Monto (MMV)',
      render: () => (
        <span>
          {getMonoTotal()} <Petro />
        </span>
      ),
    },
    // {
    //   dataIndex: 'date',
    //   title: 'Solvencia de Actividades Económicas (Petros)',
    //   render: () => (
    //     <span>
    //       {taxes?.MONO?.montoSAE || 0} <Petro />
    //     </span>
    //   ),
    // },
  ];

  const getVHTotal = () => {
    if (taxes?.VH) {
      return taxes?.VH.map((vh) => {
        return vh.deuda.reduce((p, v) => p + +(vh.tarifa * petro).toFixed(2), 0);
      }).reduce((p, v) => p + v, 0);
    }
  };
  const validateAESequence = () => {
    if (taxes?.AE) {
      const validated = taxes.AE.map((aforo) => {
        const deudas = [...aforo.deuda];
        const deudasAlteradasPorDeclaracion = deudas.map((mes, index) => {
          // console.log(data, 'MASTER');
          if (
            data[mes.year] &&
            data[mes.year][mes.month] &&
            (data[mes.year][mes.month][aforo.id] === 0 || data[mes.year][mes.month][aforo.id])
          ) {
            return index;
          } else return mes;
        });
        const deudasFiltradas = deudasAlteradasPorDeclaracion.filter((elem) => typeof elem === 'number');
        // console.log(deudas, deudasAlteradasPorDeclaracion, deudasFiltradas);
        return deudasFiltradas.length > 0 && deudasFiltradas.every((elem, index) => elem === index);
      });
      return validated.every((elem) => elem);
    } else {
      return false;
    }
  };

  enum Months {
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  }

  const validateAEDeclarations = () => {
    if (taxes?.AE) {
      const arr: any = [];
      const ae = cloneDeep(taxes?.AE || []);
      ae.forEach((aforo) => {
        const deudas = [...aforo.deuda];
        const deudasAlteradasPorDeclaracion = deudas.map((mes) => {
          // console.log(data, 'MASTER');
          if (
            data[mes.year] &&
            data[mes.year][mes.month] &&
            (data[mes.year][mes.month][aforo.id] === 0 || data[mes.year][mes.month][aforo.id])
          ) {
            mes.month = Months[mes.month];
            return mes;
          } else return undefined;
        });
        const deudasFiltradas = deudasAlteradasPorDeclaracion.filter((elem) => elem);
        const mesMayor = deudasFiltradas.reduce((a, c) => (c.month > a.month ? c : a), { month: 0 });
        console.log(deudas, deudasAlteradasPorDeclaracion, deudasFiltradas, mesMayor);
        deudasFiltradas.length > 0 && arr.push(mesMayor);
      });
      const aforoMayorMes = arr.reduce((a, c) => (c.month > a ? c.month : a), 0);
      console.log(arr, aforoMayorMes);
      return arr.every((elem) => elem.month === aforoMayorMes);
    } else {
      return false;
    }
  };

  const getAllAESubtotal = () => {
    if (taxes?.AE) {
      try {
        return parseFloat(
          taxes.AE.map((ae) =>
            ae.deuda
              .map((d) => {
                if (data[d.year][d.month][ae.id] === 0) return isMinTributable(d.year, d.month, ae.id) ? ae.minimoTributable : 0;
                if (typeof data[d.year][d.month][ae.id] !== 'string' && !!data[d.year][d.month][ae.id])
                  return data[d.year][d.month][ae.id] * ae.alicuota <= ae.minimoTributable &&
                    isMinTributable(d.year, d.month, ae.id)
                    ? ae.minimoTributable
                    : data[d.year][d.month][ae.id] * ae.alicuota;
                else throw Error;
              })
              .reduce((cv, nv) => cv + nv)
          )
            .reduce((c, v) => c + v)
            .toFixed(2)
        ) /* + parseFloat((taxes.AE[0].costoSolvencia * taxes.AE[getLongerAE()].deuda.length).toFixed(2))*/;
      } catch (e) {
        return -1;
      }
    }
    return -1;
  };

  const getAESubtotal = (month, year) => {
    if (taxes?.AE) {
      try {
        // console.log(taxes.AE.filter((ae) => ae.deuda.some((de) => de.month === month && de.year === year)).map(ae => ae.minimoTributable), year, month, 'probando')
        return taxes.AE.filter((ae) => ae.deuda.some((de) => de.month === month && de.year === year))
          .map((ae) => {
            if (data[year][month][ae.id] === 0)
              return isMinTributable(year, month, ae.id) /* && (fromMonthToNumber(month) <= 1 ? year < 2023 : year < 2022)*/
                ? ae.minimoTributable
                : 0;
            if (data[year] && data[year][month] && typeof data[year][month][ae.id] !== 'string' && !!data[year][month][ae.id])
              return data[year][month][ae.id] * ae.alicuota <= ae.minimoTributable && isMinTributable(year, month, ae.id) /* &&
                (fromMonthToNumber(month) <= 1 ? year < 2023 : year < 2022)*/
                ? ae.minimoTributable
                : data[year][month][ae.id] * ae.alicuota;
            else throw Error;
          })
          .reduce((cv, nv) => nv + cv)
          .toFixed(2);
      } catch (e) {
        return 'N/A';
      }
    }
    return 'N/A';
  };

  const getAEGrandTotal = () =>
    taxes && taxes.AE && taxes?.montoAcarreado.AE.monto > 0
      ? roundCalc(/*taxes?.AE[0].costoSolvencia + */ taxes?.montoAcarreado.AE.monto + getAllAESubtotal())
      : roundCalc(getAllAESubtotal());

  const getSMSubtotal = () =>
    fechaSM && fechaSM.taxesSM
      ? fechaSM.taxesSM.reduce(
          (p, v) =>
            p +
            +(
              v.deuda.reduce((prev, current) => {
                const toAdd = +((!current.exonerado ? v.tarifaAseo * (1 - current.descuento) : 0) * petro);
                return prev + toAdd;
              }, 0) * 1.1
            ).toFixed(2),
          0
        )
      : 0;

  const getIUSubtotalConstruccion = (index) =>
    periodoIU
      ? periodoIU.taxes[index].deuda
        ? periodoIU.taxes[index].deuda
            ?.map((d) => +(d.impuestoInmueble?.construccion * (petro || 1)).toFixed(2))
            .reduce((p, v) => p + v, 0)
        : 0
      : 0;

  const getIUSubtotalTerreno = (index) => {
    return periodoIU
      ? periodoIU.taxes[index].deuda
        ? periodoIU.taxes[index].deuda
            ?.map((d) => +(d.impuestoInmueble?.terreno * (petro || 1)).toFixed(2))
            .reduce((p, v) => p + v, 0)
        : 0
      : 0;
  };

  const getIUTotalTerreno = () =>
    periodoIU && periodoIU.taxes.length > 0 ? periodoIU.taxes.reduce((p, c, index) => p + getIUSubtotalTerreno(index), 0) : 0;

  const getIUTotalConstruccion = () => {
    return periodoIU && periodoIU.taxes.length > 0
      ? periodoIU.taxes.reduce((p, c, index) => p + getIUSubtotalConstruccion(index), 0)
      : 0;
  };

  const getMonoTotal = () =>
    taxes && taxes.MONO ? taxes.MONO.montoAE + taxes.MONO.montoIU + taxes.MONO.montoPP + taxes.MONO.montoSM : 0;

  const removePPItem = (date, articulo, subarticulo) => {
    const copy = Object.assign({}, PP);
    remove(copy[date], (pp) => pp.subarticulo === subarticulo && pp.articulo === articulo);
    setPP(copy);
  };

  const includingAE = getAllAESubtotal() === -1 && !!taxes?.AE;

  const addPPItem = (date) => {
    if (!article || !subarticle || !quantity) {
      message.error('Debe ingresar el subarticulo que desea declarar con su respectiva cantidad.');
    } else if (PP[date] && !!PP[date].find((pp) => pp.articulo === article[date] && pp.subarticulo === subarticle[date])) {
      message.error('Ya ha creado una declaracion para este subarticulo.');
    } else {
      PP[date]
        ? setPP({
            ...PP,
            [date]: [
              ...PP[date],
              { articulo: `${article[date]}`, subarticulo: `${subarticle[date]}`, cantidad: `${quantity[date]}` },
            ],
          })
        : setPP({
            ...PP,
            [date]: [{ articulo: `${article[date]}`, subarticulo: `${subarticle[date]}`, cantidad: `${quantity[date]}` }],
          });
      setQuantity(undefined);
      setArticle(undefined);
      setSubarticle(undefined);
    }
  };

  const getPPSubtotal = (date) => {
    if (PP[date] ? PP[date].length === 0 : true) return 0;
    return parseFloat(
      PP[date]
        .map((pp) => {
          const subArt = taxes?.PP?.articulos
            .find((ord) => ord.nombreArticulo === pp.articulo)
            ?.subarticulos.find((sub) => sub.nombreSubarticulo === pp.subarticulo);
          if (subArt?.parametro === 'UNIDADES') return pp.cantidad * (subArt ? subArt.costo : 0);
          else if (pp.cantidad <= 10000) return pp.cantidad * (subArt ? subArt.costo : 0);
          else return pp.cantidad * (subArt && subArt.costoAlto ? subArt.costoAlto : 0);
        })
        .reduce((c, v) => c + v)
        .toFixed(2)
    );
  };

  const getPPItemCost = (date, art, sub: string, qty) => {
    if (!PP[date]) return 0;
    return parseFloat(
      (() => {
        const subArt = taxes?.PP?.articulos
          .find((ord) => ord.nombreArticulo === art)
          ?.subarticulos.find((s) => s.nombreSubarticulo === sub);
        if (subArt?.parametro === 'UNIDADES') return qty * (subArt ? subArt.costo : 0);
        else if (qty <= 10000) return qty * (subArt ? subArt.costo : 0);
        else return qty * (subArt && subArt.costoAlto ? subArt.costoAlto : 0);
      })().toFixed(2)
    );
  };

  const getPPTotal = () => {
    const dates = taxes?.PP?.deuda.map((d) => d.month + d.year);
    return taxes
      ? parseFloat(
          dates
            ?.map((d) => getPPSubtotal(d))
            .reduce((c, v) => c + v)
            .toFixed(2) || '0'
        ) + parseFloat(taxes.montoAcarreado.PP.monto.toFixed(2))
      : 0;
  };

  const getPanelSubtitle = (month, year) => {
    const isDeclared = isNaN(getAESubtotal(month, year) as any);
    return (
      <span>
        {firstLetterMayus(month)} {year}
        <CheckCircleFilled style={{ color: isDeclared ? 'gray' : 'green', marginLeft: 5 }} />
      </span>
    );
  };

  const checkSM = () => {
    if (taxes.SM) {
      let montoSM = taxes.SM?.map((sm) => sm.tarifaAseo).reduce((p, c) => p + c, 0);
      return montoSM;
    } else return 0;
  };

  const getLongerAE = (): number => {
    const id = taxes?.AE?.sort((a, b) => {
      if (a.deuda.length > b.deuda.length) return -1;
      if (a.deuda.length < b.deuda.length) return 1;
      return 0;
    })[0]?.id;
    return taxes?.AE?.findIndex((ae) => ae.id === id) || 0;
  };

  const handleFilterPeriodoIU = (e, iu, index) => {
    let arrFecha = e.split('-');
    let fecha = { year: arrFecha[1].trim(), periodo: arrFecha[0].trim() };
    console.log(e, arrFecha, fecha);
    let filterTaxes = filterTaxesPeriodoIU(iu, index, fecha.year, fecha.periodo);
    console.log('taxes iu', taxes);
    if (periodoIU) {
      let newTaxes = periodoIU.taxes;
      newTaxes[index] = filterTaxes;
      console.log(newTaxes, periodoIU);

      setPeriodoIU({ taxes: newTaxes });
    } else {
      let obj = {
        clasificacion: null,
        codCat: null,
        deuda: null,
        direccionInmueble: null,
        id: null,
        ultimosAvaluos: null,
      };
      let newTaxes = new Array(taxes.IU.length).fill(obj);
      newTaxes[index] = filterTaxes;
      console.log(newTaxes);
      setPeriodoIU({ taxes: newTaxes });
    }
  };

  const filterTaxesPeriodoIU = (iu, index, year, periodo) => {
    let cloneTaxesIU = taxes.IU[index];
    let newTaxeIU = (cloneTaxesIU = {
      ...cloneTaxesIU,
      deuda: cloneTaxesIU.deuda.filter((s) => {
        if (s.tipoPeriodo.includes('mensual')) {
          enum month {
            'enero' = 1,
            'febrero',
            'marzo',
            'abril',
            'mayo',
            'junio',
            'julio',
            'agosto',
            'septiembre',
            'octubre',
            'noviembre',
            'diciembre',
          }

          console.log(month[periodo], month[s.period], index, year, periodo, s);
          if (moment(`${s.year}-${month[s.period]}`).isSameOrBefore(moment(`${year}-${month[periodo]}`))) return s;
        }

        if (s.tipoPeriodo.includes('trimestral')) {
          enum trimestre {
            'Primer Trimestre' = 2,
            'Segundo Trimestre' = 5,
            'Tercer Trimestre' = 8,
            'Cuarto Trimestre' = 11,
          }

          console.log(trimestre[periodo], trimestre[s.period], index, year, periodo, s);
          if (moment(`${s.year}-${trimestre[s.period]}`).isSameOrBefore(moment(`${year}-${trimestre[periodo]}`))) return s;
        }

        if (s.tipoPeriodo.includes('semestral')) {
          enum Semestre {
            'Primer Semestre' = 5,
            'Segundo Trimestre' = 11,
          }

          console.log(index, year, periodo, s);
          if (moment(`${s.year}-${Semestre[s.period]}`).isSameOrBefore(moment(`${year}-${Semestre[periodo]}`))) return s;
        }

        if (s.tipoPeriodo.includes('anual')) {
          console.log(index, year, periodo, s);
          if (s.year <= year) return s;
        }
      }),
    });

    return newTaxeIU;
  };

  const handleFechaSM = (e) => {
    let arrFecha = e.split(' ');
    let fecha = { year: arrFecha[1], month: arrFecha[0] };
    let taxesSM = filterTaxesSMByDate(fecha.year, fecha.month);
    setFechaSM({ year: fecha.year, month: fecha.month, taxesSM });
  };

  const filterTaxesSMByDate = (year, month) => {
    enum listMonth {
      'enero' = 1,
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    }

    return taxes.SM.map((sm) => {
      return {
        ...sm,
        deuda: sm.deuda.filter((s) =>
          moment(`${s.year}-${listMonth[s.month]}`).isSameOrBefore(moment(`${year}-${listMonth[month]}`))
        ),
      };
    });
  };

  const setTaxesSMDate = () => {
    let fecha = taxes.SM.map((sm) => {
      return sm.deuda.map((s) => `${s.month} ${s.year}`);
    }).flat();

    return uniq(fecha);
  };

  const getPeriodoIU = (index) => {
    let periodo = taxes.IU[index].deuda.map((s) => `${s.period} - ${s.year}`);
    console.log(periodo, taxes.IU, index);
    return periodo;
  };

  const addFile = (e) => {
    setFile([...file, e]);
  };

  const removeFile = (e) => {
    let newFile = file.filter((f) => f.file.name !== e);
    setFile(newFile);
  };

  return (
    <ConditionalWrapper
      condition={width > 778}
      wrapper={(children) => (
        <Card
          style={{ height: '100%' }}
          title={auth.user?.tipoUsuario === 3 ? 'Liquidar Impuestos' : 'Declarar Impuestos'}
          bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
          headStyle={{
            height: 64,
            backgroundColor: thm.primaryColor,
            padding: width < 992 ? '0 10px' : '0 20px',
            color: 'white',
          }}
        >
          {children}
        </Card>
      )}
    >
      <React.Fragment>
        {(taxes || (auth.user?.tipoUsuario === 4 && auth.user?.contribuyente)) && (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography.Text strong>
              Razón Social: {taxes?.razonSocial.replace(' null', '') || auth.user?.contribuyente?.razonSocial}
            </Typography.Text>
            <Typography.Text strong>
              Documento: {active === 'JURIDICO' ? nacionalidadRif : nacionalidad}-
              {data.documento || auth.user?.contribuyente?.documento}
              {taxes && data.rim && (
                <React.Fragment>
                  <br />
                  R.I.M: {data.rim}
                </React.Fragment>
              )}
            </Typography.Text>
          </div>
        )}
        <Form
          form={form}
          layout='vertical'
          style={{ padding: width < 992 ? '0 10px' : '0px 20px' }}
          onFinish={() => handleSubmit()}
          onValuesChange={(c, v) => {
            form.setFieldsValue(v);
            setData(v);
          }}
        >
          {!taxes && (
            <React.Fragment>
              {auth.user?.tipoUsuario !== 4 ? (
                <Tabs
                  defaultActiveKey='JURIDICO'
                  activeKey={active}
                  onChange={(e) => (!loading ? setActive(e as any) : () => {})}
                >
                  <Tabs.TabPane key='JURIDICO' tab={<span>Jurídico</span>}>
                    <Row gutter={24}>
                      {loading && (
                        <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Spin
                            tip='Consultando Impuestos...'
                            indicator={<LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '15px 0px' }} />}
                          />
                        </Col>
                      )}
                      {!loading && (
                        <React.Fragment>
                          <Col xs={24} md={12} xl={8}>
                            <Form.Item label='R.I.F.' name='documento' normalize={Utils.normalize.toUpper}>
                              <Input placeholder='R.I.F.' addonBefore={rifAddon} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12} xl={8}>
                            <Form.Item label='R.I.M.' name='rim' normalize={Utils.normalize.toUpper}>
                              <Input placeholder='R.I.M.' />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Button type='primary' onClick={form.submit}>
                              Consultar
                            </Button>
                          </Col>
                        </React.Fragment>
                      )}
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane key='NATURAL' tab={<span>Natural</span>}>
                    <Row gutter={24}>
                      {loading && (
                        <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Spin
                            tip='Consultando Impuestos...'
                            indicator={<LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '15px 0px' }} />}
                          />
                        </Col>
                      )}
                      {!loading && (
                        <React.Fragment>
                          <Col xs={24} md={12} xl={8}>
                            <Form.Item label='Cedula de Identidad' name='documento'>
                              <Input placeholder='Cedula' addonBefore={cedulaAddon} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12} xl={8}>
                            <Form.Item label='R.I.M.' name='rim'>
                              <Input placeholder='R.I.M.' />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Button type='primary' onClick={form.submit}>
                              Consultar
                            </Button>
                          </Col>
                        </React.Fragment>
                      )}
                    </Row>
                  </Tabs.TabPane>
                </Tabs>
              ) : (
                <Row>
                  {loading ? (
                    <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Spin
                        tip='Consultando Impuestos...'
                        indicator={<LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '15px 0px' }} />}
                      />
                    </Col>
                  ) : (
                    <React.Fragment>
                      <Col span={24} style={{ marginBottom: 15, marginTop: 10 }}>
                        <Typography.Text>
                          Para la declaración de impuestos debe ingresar el registro municipal correspondiente al contribuyente
                          enlazado a su cuenta para el cual desea declarar o liquidar impuestos.&nbsp; Tenga en cuenta que este
                          registro municipal debe ser previamente actualizado en el enlace de cuenta HACIENDA.
                        </Typography.Text>
                      </Col>
                      <Col xs={24} md={12} xl={8}>
                        <Form.Item label='R.I.M.' name='rim'>
                          <Input placeholder='R.I.M.' />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Button type='primary' onClick={form.submit}>
                          Consultar
                        </Button>
                      </Col>
                    </React.Fragment>
                  )}
                </Row>
              )}
            </React.Fragment>
          )}
          {taxes && (
            <React.Fragment>
              <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
                <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                  Estado de Cuenta
                </Typography.Title>
              </Divider>
              <Button
                href={`${server}/settlements/accountStatement/${taxes.contribuyente}?tipoContribuyente=${active}${
                  data.rim ? `&referencia=${data.rim}` : ''
                }`}
                target='_blank'
                icon={<DownloadOutlined />}
                type='primary'
                style={{ marginTop: 0 }}
              >
                Descargar
              </Button>
              <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
                <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                  Declaración de IVA
                </Typography.Title>
              </Divider>
              <FileImageUpload accept='.pdf' onUpload={addFile} onRemove={removeFile} />
              {(parseFloat(taxes.creditoFiscal || '0') > 0 || parseFloat(taxes.creditoFiscalRetencion || '0') > 0) && (
                <>
                  <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
                    <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                      Credito Fiscal
                    </Typography.Title>
                  </Divider>
                  <Row style={{ marginBottom: 20 }}>
                    <Col span={24}>
                      <Typography.Text>
                        <b>Disponible por Compensación:</b> Bs. {formatCurrency(parseFloat(taxes.creditoFiscal || '0'))}
                      </Typography.Text>
                      <Col span={24}>
                        <Typography.Text>
                          <b>Disponible por Retención:</b> Bs. {formatCurrency(parseFloat(taxes.creditoFiscalRetencion || '0'))}
                        </Typography.Text>
                      </Col>
                    </Col>
                  </Row>
                </>
              )}
              {taxes.usuarios?.length > 0 && auth.user?.tipoUsuario !== 4 && (
                <>
                  <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
                    <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                      Usuario de S.U.T.
                    </Typography.Title>
                  </Divider>
                  <UserSelect users={taxes.usuarios} form={form} />
                </>
              )}
              {!taxes.esMonotributo && (
                <>
                  {(taxes.AE || taxes.montoAcarreado.AE.monto > 0) && (
                    <React.Fragment>
                      <Divider orientation='left' style={{ marginLeft: -20 }}>
                        <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                          Actividades Económicas
                        </Typography.Title>
                      </Divider>
                      {
                        /*!taxes?.esAgenteRetencion && !taxes?.esContribuyenteTop && */ <Checkbox
                          checked={includeAE}
                          style={{ marginBottom: 15 }}
                          onChange={(e) => setIncludeAE(e.target.checked)}
                        >
                          Incluir Actividades Económicas en la liquidación actual
                        </Checkbox>
                      }
                      {includeAE && (
                        <ConditionalWrapper
                          wrapper={(children) => <Card bodyStyle={{ border: '1px solid #f0f0f0' }}>{children}</Card>}
                          condition={width > 776}
                        >
                          <Collapse accordion style={{ border: '1px solid #f0f0f0' }}>
                            {taxes.AE &&
                              taxes.AE.length > 0 &&
                              taxes.AE[getLongerAE()]?.deuda?.map((d) => {
                                return (
                                  <Collapse.Panel
                                    key={d.month + d.year}
                                    header={getPanelSubtitle(d.month, d.year)}
                                    style={{ border: '1px solid #f0f0f0' }}
                                  >
                                    <Table
                                      columns={AEcolumns}
                                      dataSource={taxes.AE?.filter((ae) =>
                                        ae.deuda.some((de) => de.month === d.month && de.year === d.year)
                                      ).map((ae, key) => {
                                        ae.date = { month: d.month, year: d.year, exo: d.exonerado };
                                        ae.key = key;
                                        return Object.assign({}, ae);
                                      })}
                                      bordered
                                      pagination={false}
                                    />
                                    <Descriptions
                                      layout={width > 778 ? 'horizontal' : 'vertical'}
                                      bordered
                                      style={{ border: '1px solid #f0f0f0' }}
                                    >
                                      <Descriptions.Item label='Sub-Total'>
                                        {isNaN(getAESubtotal(d.month, d.year) as any)
                                          ? 'N/A'
                                          : `Bs. ${formatCurrency(parseFloat(getAESubtotal(d.month, d.year) || '0'))}`}
                                      </Descriptions.Item>
                                      {/* <Descriptions.Item label='Costo por Solvencia'>
                                        {taxes?.AE && taxes?.AE[0]
                                          ? `Bs. ${formatCurrency(taxes.AE[0].costoSolvencia)} (${solvencia} Petros)`
                                          : 'N/A'}
                                      </Descriptions.Item> */}
                                      <Descriptions.Item label='Total'>
                                        {isNaN(getAESubtotal(d.month, d.year) as any) || !taxes?.AE
                                          ? 'N/A'
                                          : `Bs. ${formatCurrency(
                                              parseFloat(getAESubtotal(d.month, d.year) || '0') /* + taxes.AE[0].costoSolvencia*/
                                            )}`}
                                      </Descriptions.Item>
                                    </Descriptions>
                                  </Collapse.Panel>
                                );
                              })}
                          </Collapse>
                          <Row gutter={0} style={{ marginTop: 20 }}>
                            {taxes.montoAcarreado.AE.monto > 0 && (
                              <React.Fragment>
                                <Divider orientation='left' style={{ marginLeft: -20 }}>
                                  <Typography.Text ellipsis strong style={{ marginLeft: 5 }}>
                                    Monto de la Ultima Declaración Vigente
                                  </Typography.Text>
                                </Divider>
                                <Col xs={24} xl={12}>
                                  <Descriptions
                                    layout={width > 778 ? 'horizontal' : 'vertical'}
                                    bordered
                                    style={{ border: '1px solid #f0f0f0' }}
                                  >
                                    <Descriptions.Item
                                      label={`Monto (${taxes.montoAcarreado.AE.fecha.month.toUpperCase()} ${
                                        taxes.montoAcarreado.AE.fecha.year
                                      })`}
                                    >
                                      Bs. {formatCurrency(taxes.montoAcarreado.AE.monto)}
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                                <Col xs={0} xl={12} />
                                <Col xs={24} xl={12}>
                                  <Descriptions
                                    layout={width > 778 ? 'horizontal' : 'vertical'}
                                    bordered
                                    style={{ border: '0px 1px 1px 1px solid #f0f0f0' }}
                                  >
                                    {/* <Descriptions.Item
                                      label={`Costo por Solvencia (${taxes.montoAcarreado.AE.fecha.month.toUpperCase()} ${
                                        taxes.montoAcarreado.AE.fecha.year
                                      })`}
                                    >
                                      Bs. {formatCurrency(taxes.AE ? taxes.AE[0].costoSolvencia : 0)}
                                    </Descriptions.Item> */}
                                  </Descriptions>
                                </Col>
                                <Col xs={0} xl={12} />
                              </React.Fragment>
                            )}
                            <Divider orientation='left' style={{ marginLeft: -20 }}>
                              <Typography.Text ellipsis strong style={{ marginLeft: 5 }}>
                                Total de la Declaración
                              </Typography.Text>
                            </Divider>
                            <Col xs={24} xl={12}>
                              <Descriptions
                                layout={width > 778 ? 'horizontal' : 'vertical'}
                                bordered
                                style={{ border: '1px solid #f0f0f0' }}
                              >
                                <Descriptions.Item label={`Total`} span={6}>
                                  {getAllAESubtotal() === -1 ? 'N/A' : `Bs. ${formatCurrency(getAEGrandTotal())}`}
                                </Descriptions.Item>
                              </Descriptions>
                            </Col>
                            <Col xs={0} xl={12} />
                          </Row>
                        </ConditionalWrapper>
                      )}
                    </React.Fragment>
                  )}
                  {checkSM() !== 0 && (
                    <React.Fragment>
                      <Divider orientation='left' style={{ marginLeft: -20 }}>
                        <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                          Servicios Municipales
                        </Typography.Title>
                      </Divider>
                      {
                        /*!taxes?.esAgenteRetencion && !taxes?.esContribuyenteTop && */ <Checkbox
                          checked={includeSM}
                          style={{ marginBottom: 15 }}
                          onChange={(e) => setIncludeSM(e.target.checked)}
                        >
                          Incluir Servicios Municipales en la liquidación actual
                        </Checkbox>
                      }
                      {includeSM && (
                        <ConditionalWrapper
                          condition={width > 778}
                          wrapper={(children) => <Card bodyStyle={{ border: '1px solid #f0f0f0' }}>{children}</Card>}
                        >
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography.Text ellipsis strong>
                              {'SELECCIONE LA FECHA HASTA DONDE DECLARAR'}
                            </Typography.Text>
                            <Select style={{ width: '150px', marginTop: '10px' }} onChange={(e) => handleFechaSM(e)}>
                              {setTaxesSMDate().map((f: any) => {
                                return (
                                  <Select.Option key={f} value={f}>
                                    {f}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </div>
                          <Divider />

                          {fechaSM.taxesSM &&
                            fechaSM.taxesSM?.map((sm, i) => (
                              <React.Fragment key={sm.id}>
                                <Typography.Text strong>
                                  {sm.codCat ? `Código Catastral: ${sm.codCat}` : 'INMUEBLE SIN INFORMACIÓN CATASTRAL'}
                                </Typography.Text>
                                {sm.ocupado && sm.ocupado !== '' && (
                                  <Typography.Text strong>{`Ocupado por contribuyente: ${sm.ocupado}`}</Typography.Text>
                                )}
                                <Table
                                  columns={SMcolumns}
                                  dataSource={
                                    fechaSM.taxesSM
                                      ? sm?.deuda.map((d, key) => ({
                                          deuda: { month: d.month, year: d.year, exo: d.exonerado, descuento: d.descuento },
                                          tarifaAseo: fechaSM.taxesSM ? sm.tarifaAseo : 0,
                                          tarifaGas: fechaSM.taxesSM ? sm.tarifaGas : 0,
                                          key,
                                        }))
                                      : []
                                  }
                                  bordered
                                  pagination={false}
                                  style={{ marginTop: 15, marginBottom: 15 }}
                                />
                                <Typography.Text strong>{'TASA ADMINISTRATIVA DE DESECHOS SOLIDOS'}</Typography.Text>
                                <Table
                                  columns={TasaSMColumn}
                                  dataSource={
                                    fechaSM.taxesSM
                                      ? sm?.deuda.map((d, key) => ({
                                          deuda: { month: d.month, year: d.year, exo: d.exonerado, descuento: d.descuento },
                                          monto: fechaSM.taxesSM ? sm.tarifaAseo * 0.1 : 0,
                                          key,
                                        }))
                                      : []
                                  }
                                  bordered
                                  pagination={false}
                                  style={{ marginTop: 15, marginBottom: 15 }}
                                />
                                <Typography.Text strong>
                                  Total del Inmueble para Aseo: Bs.{' '}
                                  {formatCurrency(
                                    sm?.deuda.reduce((prev, current) => {
                                      const toAdd = +((!current.exonerado ? sm.tarifaAseo * (1 - current.descuento) : 0) * petro);
                                      return prev + toAdd;
                                    }, 0) * 1.1
                                  )}
                                </Typography.Text>
                                {/* <br />
                              <Typography.Text strong>
                                Total del Inmueble para Gas: Bs.{' '}
                                {formatCurrency(
                                  +sm.deuda
                                    .reduce((p, v) => p + (!v.exonerado ? sm.tarifaGas * (1 - v.descuento) : 0), 0)
                                    .toFixed(2)
                                )}
                              </Typography.Text>
                              <br /> */}
                                {i !== (taxes?.IU?.length || 0) - 1 && <Divider />}
                              </React.Fragment>
                            ))}
                          <Row gutter={0} style={{ marginTop: 20 }}>
                            {taxes.montoAcarreado.SM.monto > 0 && (
                              <React.Fragment>
                                <Divider orientation='left' style={{ marginLeft: -20 }}>
                                  <Typography.Text ellipsis strong style={{ marginLeft: 5 }}>
                                    {width > 778
                                      ? 'Monto por Ultima Facturación Vigente de Servicios Municipales'
                                      : 'MoMonto por Ultima Facturación Vigente'}
                                  </Typography.Text>
                                </Divider>
                                <Col xs={24} xl={12}>
                                  <Descriptions
                                    layout={width > 778 ? 'horizontal' : 'vertical'}
                                    bordered
                                    style={{ border: '1px solid #f0f0f0' }}
                                  >
                                    <Descriptions.Item
                                      label={`Monto por Ultima Facturación (${taxes.montoAcarreado.SM.fecha.month.toUpperCase()} ${
                                        taxes.montoAcarreado.SM.fecha.year
                                      })`}
                                    >
                                      Bs. {formatCurrency(taxes.montoAcarreado.SM.monto)}
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                              </React.Fragment>
                            )}
                            <Divider orientation='left' style={{ marginLeft: -20 }}>
                              <Typography.Text ellipsis strong style={{ marginLeft: 5 }}>
                                Total de la Facturación
                              </Typography.Text>
                            </Divider>
                            {/* {!taxes?.esAgenteRetencion && (
                              <Col span={12} style={{ marginBottom: 15 }}>
                                <Checkbox value={esAgenteSENIAT} onChange={(e) => setEsAgenteSENIAT(e.target.checked)}>
                                  Es agente de retención especial SENIAT
                                </Checkbox>
                              </Col>
                            )} */}
                            <Col span={24}>
                              <Descriptions
                                layout={width > 778 ? 'horizontal' : 'vertical'}
                                bordered
                                style={{ border: '1px solid #f0f0f0' }}
                              >
                                <Descriptions.Item label='Total'>Bs. {formatCurrency(getSMSubtotal())}</Descriptions.Item>
                                {/* <Descriptions.Item
                                  label={!(esAgenteSENIAT || taxes.esAgenteRetencion) ? 'I.V.A.' : 'I.V.A. (75% Retenido)'}
                                >
                                  Bs.{' '}
                                  {formatCurrency(
                                    parseFloat(
                                      (getSMSubtotal() * (!(esAgenteSENIAT || taxes.esAgenteRetencion) ? 0.16 : 0.04)).toFixed(2)
                                    )
                                  )}
                                </Descriptions.Item>
                                <Descriptions.Item label={`Total`}>
                                  Bs.{' '}
                                  {formatCurrency(
                                    parseFloat(
                                      (
                                        getSMSubtotal() * (!(esAgenteSENIAT || taxes.esAgenteRetencion) ? 1.16 : 1.04) +
                                        taxes.montoAcarreado.SM.monto
                                      ).toFixed(2)
                                    )
                                  )}
                                </Descriptions.Item> */}
                              </Descriptions>
                            </Col>
                            <Col xs={0} xl={12} />
                          </Row>
                        </ConditionalWrapper>
                      )}
                    </React.Fragment>
                  )}
                  {(taxes.IU || taxes.montoAcarreado.IU.monto > 0) && (
                    <React.Fragment>
                      <Divider orientation='left' style={{ marginLeft: -20 }}>
                        <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                          Inmuebles Urbanos
                        </Typography.Title>
                      </Divider>
                      <Checkbox checked={includeIU} style={{ marginBottom: 15 }} onChange={(e) => setIncludeIU(e.target.checked)}>
                        Incluir Inmuebles Urbanos en la liquidación actual
                      </Checkbox>
                      {/* {!includeIU && includingAE && (
                        <Alert
                          message='Debe incluir y declarar Actividades Económicas para poder calcular los impuestos de Inmuebles Urbanos'
                          style={{ fontWeight: 'bold' }}
                          type='warning'
                        />
                      )} */}
                      {includeIU && (
                        <ConditionalWrapper
                          condition={width > 778}
                          wrapper={(children) => (
                            <Card
                              bodyStyle={{
                                border: '1px solid #f0f0f0',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                              }}
                            >
                              {children}
                            </Card>
                          )}
                        >
                          {fetchingIU && (
                            <Spin
                              tip='Calculando...'
                              indicator={
                                <LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '15px 0px' }} />
                              }
                            />
                          )}
                          {taxes.IU?.map((iu, i) => (
                            <React.Fragment key={iu.id}>
                              <Row justify='space-between' align='top'>
                                <Col span={12} style={{ display: 'flex', flexDirection: 'column' }}>
                                  <Typography.Text strong>Código Catastral: {iu.codCat}</Typography.Text>
                                  <Typography.Text strong>Clasificación: {iu?.clasificacion ?? ''}</Typography.Text>
                                  <Typography.Text strong>Dirección: {iu?.direccionInmueble ?? ''}</Typography.Text>
                                </Col>
                              </Row>

                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography.Text ellipsis strong>
                                  {'SELECCIONE El PERIODO HASTA DONDE DECLARAR'}
                                </Typography.Text>
                                <Select
                                  key={iu.id}
                                  style={{ width: '250px', marginTop: '10px' }}
                                  onChange={(e) => handleFilterPeriodoIU(e, iu, i)}
                                >
                                  {selectPeriodoIU &&
                                    selectPeriodoIU[i].map((f, ii) => {
                                      return (
                                        <Select.Option key={iu.id + ii} value={f}>
                                          {f}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </div>
                              <Divider />

                              {periodoIU &&
                                periodoIU?.taxes.map((iu, index) => {
                                  return (
                                    iu.deuda &&
                                    i === index && (
                                      <>
                                        <Table
                                          columns={IUcolumns}
                                          dataSource={iu.deuda?.map((d, key) => ({
                                            deuda: {
                                              period: d.period,
                                              month: d.month,
                                              year: d.year,
                                              exo: d.exonerado,
                                              descuento: d.descuento,
                                            },
                                            impuesto: d.impuestoInmueble,
                                            key,
                                          }))}
                                          bordered
                                          pagination={false}
                                          style={{ marginTop: 15, marginBottom: 15 }}
                                        />
                                        <Typography.Text strong>
                                          Total del Impuesto Terreno: Bs. {formatCurrency(getIUSubtotalTerreno(index))}
                                        </Typography.Text>
                                        <Typography.Text strong>
                                          Total del Impuesto Construcción: Bs. {formatCurrency(getIUSubtotalConstruccion(index))}
                                        </Typography.Text>
                                        <Typography.Text strong>
                                          Total del Impuesto: Bs.{' '}
                                          {formatCurrency(getIUSubtotalConstruccion(index) + getIUSubtotalTerreno(index))}
                                        </Typography.Text>
                                        {i !== (taxes?.IU?.length || 0) - 1 && <Divider />}
                                      </>
                                    )
                                  );
                                })}
                            </React.Fragment>
                          ))}
                          <Row gutter={0} style={{ marginTop: 20 }}>
                            {taxes.montoAcarreado.IU.monto > 0 && (
                              <React.Fragment>
                                <Divider orientation='left' style={{ marginLeft: -20 }}>
                                  <Typography.Text ellipsis strong style={{ marginLeft: 5 }}>
                                    {width > 778
                                      ? 'Monto por Ultimo Impuesto Vigente de Inmuebles Urbanos'
                                      : 'Monto por Ultimo Impuesto Vigente'}
                                  </Typography.Text>
                                </Divider>
                                <Col xs={24} xl={12}>
                                  <Descriptions
                                    layout={width > 778 ? 'horizontal' : 'vertical'}
                                    bordered
                                    style={{ border: '1px solid #f0f0f0' }}
                                  >
                                    <Descriptions.Item
                                      label={`Monto por Ultimo Impuesto (${taxes.montoAcarreado.IU.fecha.month.toUpperCase()} ${
                                        taxes.montoAcarreado.IU.fecha.year
                                      })`}
                                    >
                                      Bs. {formatCurrency(taxes.montoAcarreado.IU.monto)}
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                              </React.Fragment>
                            )}
                            <Divider orientation='left' style={{ marginLeft: -20 }}>
                              <Typography.Text ellipsis strong style={{ marginLeft: 5 }}>
                                Total del Impuesto
                              </Typography.Text>
                            </Divider>
                            <Col xl={24} xs={24} sm={24} md={24}>
                              <Descriptions
                                layout={width > 778 ? 'horizontal' : 'vertical'}
                                bordered
                                style={{ border: '1px solid #f0f0f0' }}
                              >
                                <Descriptions.Item label={`Total Impuesto Terreno`}>
                                  Bs. {formatCurrency(getIUTotalTerreno())}
                                </Descriptions.Item>
                                <Descriptions.Item label={`Total Impuesto Construcción`}>
                                  Bs. {formatCurrency(getIUTotalConstruccion())}
                                </Descriptions.Item>
                                <Descriptions.Item label={`Total Impuesto`}>
                                  Bs. {formatCurrency(getIUTotalConstruccion() + getIUTotalTerreno())}
                                </Descriptions.Item>
                              </Descriptions>
                            </Col>
                            <Col xs={0} xl={12} />
                          </Row>
                        </ConditionalWrapper>
                      )}
                    </React.Fragment>
                  )}
                  {/* {(taxes.PP || taxes.montoAcarreado.PP.monto > 0) && (
                    <React.Fragment>
                      <Divider orientation='left' style={{ marginLeft: -20 }}>
                        <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                          Publicidad y Propaganda
                        </Typography.Title>
                      </Divider>
                      <Checkbox checked={includePP} style={{ marginBottom: 15 }} onChange={(e) => setIncludePP(e.target.checked)}>
                        Incluir Publicidad y Propaganda en la liquidación actual
                      </Checkbox>
                      {includePP && (
                        <ConditionalWrapper
                          condition={width > 778}
                          wrapper={(children) => <Card bodyStyle={{ border: '1px solid #f0f0f0' }}>{children}</Card>}
                        >
                          {taxes.PP && taxes.PP.deuda.length > 0 && (
                            <Tabs>
                              {taxes.PP &&
                                taxes.PP.deuda.length > 0 &&
                                taxes.PP.deuda.map((pp, key) => (
                                  <Tabs.TabPane tab={`${firstLetterMayus(pp.month)} ${pp.year}`} key={pp.month + pp.year + key}>
                                    <Row gutter={24}>
                                      <Col xs={24} xl={12}>
                                        <Select
                                          placeholder='Categoría'
                                          value={article ? article[pp.month + pp.year] : undefined}
                                          onChange={(e) => setArticle({ ...article, [pp.month + pp.year]: e })}
                                        >
                                          {taxes.PP?.articulos.map((art) => (
                                            <Select.Option value={art.nombreArticulo}>{art.nombreArticulo}</Select.Option>
                                          ))}
                                        </Select>
                                      </Col>
                                      <Col xs={24} xl={12}>
                                        <Select
                                          placeholder='Tipo de Aviso'
                                          value={subarticle ? subarticle[pp.month + pp.year] : undefined}
                                          onChange={(e) => setSubarticle({ ...subarticle, [pp.month + pp.year]: e.toString() })}
                                        >
                                          {taxes.PP?.articulos
                                            .find((art) => (article ? art.nombreArticulo === article[pp.month + pp.year] : false))
                                            ?.subarticulos?.map((subart) => (
                                              <Select.Option value={subart.nombreSubarticulo}>
                                                {subart.nombreSubarticulo}
                                              </Select.Option>
                                            ))}
                                        </Select>
                                      </Col>
                                      <Col xs={12} xl={6} style={{ marginTop: 10 }}>
                                        <Input
                                          placeholder='Cantidad'
                                          value={quantity ? quantity[pp.month + pp.year] : undefined}
                                          onChange={(q) =>
                                            setQuantity({ ...quantity, [pp.month + pp.year]: parseInt(q.target.value) })
                                          }
                                          type='numeric'
                                        />
                                      </Col>
                                      <Col xs={12} xl={6} style={{ marginTop: 10 }}>
                                        <Button type='primary' onClick={() => addPPItem(pp.month + pp.year)}>
                                          Agregar a Declaración
                                        </Button>
                                      </Col>
                                    </Row>
                                    <Table
                                      columns={PPcolumns}
                                      dataSource={
                                        PP
                                          ? PP[pp.month + pp.year]?.map((p, key) => {
                                              p.date = pp.month + pp.year;
                                              p.key = key;
                                              return p;
                                            })
                                          : []
                                      }
                                      bordered
                                      pagination={false}
                                      style={{ marginTop: 15 }}
                                    />
                                    <Row>
                                      <Col xs={0} xl={12} />
                                      <Col xs={24} xl={12}>
                                        <Descriptions
                                          layout={width > 778 ? 'horizontal' : 'vertical'}
                                          bordered
                                          style={{ border: '1px solid #f0f0f0' }}
                                        >
                                          <DescriptionsItem label='Total'>
                                            Bs. {formatCurrency(getPPSubtotal(pp.month + pp.year))}
                                          </DescriptionsItem>
                                        </Descriptions>
                                      </Col>
                                    </Row>
                                  </Tabs.TabPane>
                                ))}
                            </Tabs>
                          )}
                          <Row>
                            {taxes.montoAcarreado.PP.monto > 0 && (
                              <React.Fragment>
                                <Divider orientation='left' style={{ marginLeft: -20 }}>
                                  <Typography.Text ellipsis strong style={{ marginLeft: 5 }}>
                                    Monto por Ultima Declaración Vigente
                                  </Typography.Text>
                                </Divider>
                                <Col xs={24} xl={12}>
                                  <Descriptions
                                    layout={width > 778 ? 'horizontal' : 'vertical'}
                                    bordered
                                    style={{ border: '1px solid #f0f0f0' }}
                                  >
                                    <Descriptions.Item
                                      label={`Monto por Ultima Declaración (${taxes.montoAcarreado.PP.fecha.month.toUpperCase()} ${
                                        taxes.montoAcarreado.PP.fecha.year
                                      })`}
                                    >
                                      Bs. {formatCurrency(taxes.montoAcarreado.PP.monto)}
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                              </React.Fragment>
                            )}
                            <Divider orientation='left' style={{ marginLeft: -20 }}>
                              <Typography.Text ellipsis strong style={{ marginLeft: 5 }}>
                                Total de la Declaración
                              </Typography.Text>
                            </Divider>
                            <Col xs={24} xl={12}>
                              <Descriptions
                                layout={width > 778 ? 'horizontal' : 'vertical'}
                                bordered
                                style={{ border: '1px solid #f0f0f0' }}
                              >
                                <Descriptions.Item label={`Total`}>
                                  Bs. {formatCurrency(roundCalc(getPPTotal()))}
                                </Descriptions.Item>
                              </Descriptions>
                            </Col>
                          </Row>
                        </ConditionalWrapper>
                      )}
                    </React.Fragment>
                  )} */}
                </>
              )}
              {taxes.VH && (
                <React.Fragment>
                  <Divider orientation='left' style={{ marginLeft: -20 }}>
                    <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                      Vehículos
                    </Typography.Title>
                  </Divider>
                  <Checkbox checked={includeVH} style={{ marginBottom: 15 }} onChange={(e) => setIncludeVH(e.target.checked)}>
                    Incluir Vehículos en la liquidación actual
                  </Checkbox>
                  {includeVH && (
                    <ConditionalWrapper
                      condition={width > 778}
                      wrapper={(children) => (
                        <Card
                          bodyStyle={{
                            border: '1px solid #f0f0f0',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          {children}
                        </Card>
                      )}
                    >
                      {taxes.VH?.map((vh, i) => (
                        <React.Fragment key={vh.id}>
                          <Row justify='space-between' align='top'>
                            <Col span={4} style={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography.Text strong>Placa: {vh.vehiculo.placa_vehiculo}</Typography.Text>
                              <Typography.Text strong>Modelo: {vh.vehiculo.modelo_vehiculo}</Typography.Text>
                            </Col>
                            <Col span={8} style={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography.Text strong>Categoria: {vh.vehiculo.categoria}</Typography.Text>
                              <Typography.Text strong>SubCategoria: {vh.vehiculo.subcategoria}</Typography.Text>
                            </Col>
                            <Col span={4} style={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography.Text strong>Año: {vh.vehiculo.anio_vehiculo}</Typography.Text>
                            </Col>
                          </Row>
                          <Table
                            columns={VHcolumns}
                            dataSource={vh.deuda?.map((d, key) => ({
                              deuda: { year: d.year },
                              key,
                              tarifa: +vh.tarifa,
                            }))}
                            bordered
                            pagination={false}
                            style={{ marginTop: 15, marginBottom: 15 }}
                          />
                          <Typography.Text strong>
                            Total del Vehículo: Bs.{' '}
                            {formatCurrency(vh.deuda.reduce((p, v) => p + +(vh.tarifa * petro).toFixed(2), 0))}
                          </Typography.Text>
                          {i !== (taxes?.VH?.length || 0) - 1 && <Divider />}
                        </React.Fragment>
                      ))}
                      <Row gutter={0} style={{ marginTop: 20 }}>
                        <Divider orientation='left' style={{ marginLeft: -20 }}>
                          <Typography.Text ellipsis strong style={{ marginLeft: 5 }}>
                            Total del Impuesto
                          </Typography.Text>
                        </Divider>
                        <Col xl={12} xs={24}>
                          <Descriptions
                            layout={width > 778 ? 'horizontal' : 'vertical'}
                            bordered
                            style={{ border: '1px solid #f0f0f0' }}
                          >
                            <Descriptions.Item label={`Total`}>Bs. {formatCurrency(getVHTotal())}</Descriptions.Item>
                          </Descriptions>
                        </Col>
                        <Col xs={0} xl={12} />
                      </Row>
                    </ConditionalWrapper>
                  )}
                </React.Fragment>
              )}
              {taxes.esMonotributo && (
                <>
                  <Divider orientation='left' style={{ marginLeft: -20 }}>
                    <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                      Monotributo
                    </Typography.Title>
                  </Divider>
                  <Typography.Text style={{ display: 'inline-block', marginTop: -20 }} strong>
                    La declaración por monotributo corresponde a las declaraciones de Actividades Económicas, Servicios
                    Municipales, Inmuebles Urbanos y Publicidad y Propaganda. <br />
                    El monto a pagar es de {getMonoTotal()} <Petro /> más {taxes.MONO?.montoSAE || 0} <Petro /> por solvencia de
                    Actividades Económicas por mes de deuda.
                  </Typography.Text>
                  <Row>
                    <Col xs={24} xl={12}>
                      <Table
                        pagination={false}
                        bordered
                        columns={MONOcolumns}
                        dataSource={taxes.MONO?.deuda.map((d, key) => ({
                          date: d,
                          key,
                        }))}
                        style={{ marginTop: 10 }}
                      />
                    </Col>
                  </Row>
                  <Divider orientation='left' style={{ marginLeft: -20 }}>
                    <Typography.Text ellipsis strong style={{ marginLeft: 3 }}>
                      Total de la Declaración
                    </Typography.Text>
                  </Divider>
                  <Row>
                    <Col xs={24} xl={12}>
                      <Descriptions bordered>
                        <Descriptions.Item label='Total'>
                          {(getMonoTotal() + (taxes.MONO?.montoSAE || 0)) * (taxes.MONO?.deuda.length || 0)}{' '}
                          <Petro style={{ marginLeft: 5 }} />
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                  </Row>
                </>
              )}
            </React.Fragment>
          )}
        </Form>
        {taxes && (includeAE || includeSM || includeIU || includePP || includeVH || taxes.esMonotributo) && (
          <Button
            type='primary'
            style={{ marginTop: 10 }}
            loading={creating}
            onClick={() => (taxes.esMonotributo ? declareMono() : declare())}
          >
            Liquidar
          </Button>
        )}
        <Modal
          title='Creando declaración de  impuestos'
          visible={creating}
          cancelButtonProps={{ hidden: true }}
          bodyStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          okButtonProps={{ hidden: true }}
          closable={false}
        >
          <Spin
            tip='Creando declaración...'
            indicator={<LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '15px 0px' }} />}
          />
        </Modal>
      </React.Fragment>
    </ConditionalWrapper>
  );
};

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps, { addRequest })(DeclareAnyTaxes);

interface DeclareTaxesProps {
  thm: States.ThemeColors;
  auth: States.Auth;
  addRequest: (request: Request) => any;
}

interface Taxes {
  usuarios: { id: number; correo: string }[];
  contribuyente: string;
  razonSocial: string;
  creditoFiscal: string;
  creditoFiscalRetencion?: string;
  esAgenteRetencion: boolean;
  esContribuyenteTop: boolean;
  esMonotributo: boolean;
  montoAcarreado: {
    AE: {
      monto: number;
      fecha: { month: string; year: number };
    };
    SM: {
      monto: number;
      fecha: { month: string; year: number };
    };
    IU: {
      monto: number;
      fecha: { month: string; year: number };
    };
    PP: {
      monto: number;
      fecha: { month: string; year: number };
    };
  };
  AE?: ActividadEconomica[];
  SM?: ServicioMunicipal[];
  IU?: InmuebleUrbano[];
  PP?: Publicidad;
  MONO?: MonoTax;
  petro: number;
}

interface TaxDeclaration {
  usuario?: number;
  documento: string;
  rim?: string;
  tipoDocumento: string;
  contribuyente?: string;
  esMonotributo: boolean;
  esAgenteRetencion?: boolean;
  esAgenteSENIAT: boolean;
  impuestos: {
    ramo: 'SM' | 'PP' | 'IU' | 'AE' | 'VH';
    minimoTributable?: object | null;
    fechaCancelada: {
      month: string;
      year: number;
    };
    monto: number;
    desglose: any;
  }[];
  totalPagoImpuestos: number;
}

interface MonoTax {
  deuda: Fecha[];
  montoAE: number;
  montoIU: number;
  montoSM: number;
  montoPP: number;
  montoSAE: number;
  aforos: number[];
  date?: Fecha;
  key?: number;
}
