import React from 'react';
import { Card } from 'antd';

const DisplayDoc: React.FC<DisplayDocProps> = ({url, content : Content, expand}) => {
  return (
    <Card
      style={{ width: '100%' }}
      bodyStyle={{padding:0}}
      cover={<iframe style={{ height: expand? '60vh' :'80vh',border: 'none', borderBottom: '1px solid lightgrey'}} title="DOCS" src={url} />}
    >
      {Content ? (typeof Content === 'string' ? Content : <Content/>) : null}
    </Card>
    );
};

interface DisplayDocProps {
  url: string;
  content?: React.FC | string
  expand?: boolean
}

export default DisplayDoc;
