import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Select, Input, Button, message, Table } from 'antd';
import { Fine, State, States, Taxpayer } from 'sigt';
import { connect } from 'react-redux';
import { useWindowDimensions } from '../../../utils/hooks';
import { SearchOutlined, EyeOutlined, CreditCardFilled, EyeFilled } from '@ant-design/icons';
import axios from 'axios';
import { ColumnsType } from 'antd/lib/table';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
const server = process.env.REACT_APP_SERVER_URL;

const GetTaxpayerInfo: React.FC<GetTaxpayerInfoProps> = ({ auth, thm }) => {
  const [tipoDocumento, setTipoDocumento] = useState('V');
  const [searching, setSearching] = useState(false);
  const [fines, setFines] = useState<Fine[]>([]);

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if ((history.location.state as any)?.multas) {
      setFines((history.location.state as any)?.multas);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (auth.user?.tipoUsuario === 4) {
      search();
    }
  }, [auth]);

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );
  const columns: ColumnsType<any> = [
    {
      title: 'Documento de Identidad',
      render: (_, r) => (
        <span>
          {r.nacionalidad}-{r.cedula}
        </span>
      ),
    },
    {
      title: 'Codigo Multa',
      render: (_, r) => <span>{r.codigoMulta}</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (_, r) => (r.fechaCreacion ? <span>{moment(r.fechaCreacion).utc().format('DD/MM/YYYY')}</span> : 'Sin Fecha'),
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      render: (_, multa) => (
        <Button type='primary' onClick={() => action(multa)} icon={<EyeOutlined />}>
          {'Corregir'}
        </Button>
      ),
    },
  ];

  const action = (multa: Fine) => {
    console.log(multa);
    if (location.pathname.includes('dashboard/correcciones/multas')) {
      history.push(`/dashboard/correcciones/multas/${multa.id}`, { multa, multas: fines });
    }
  };

  const search = async () => {
    const values = await form.validateFields();
    try {
      const _tipoDocumento = /^\d+$/.test(values?.documento ?? 'null') ? tipoDocumento : 'V';
      setSearching(true);
      const response = await axios.get(
        `${server}/fines/instances/usuario/usuario?documento=${values.documento}&tipoDocumento=${_tipoDocumento}`,
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      setFines(response.data.fines);
    } catch (e) {
      message.error(e?.response?.data?.message || 'Error al buscar contribuyente');
    } finally {
      setSearching(false);
    }
  };

  return (
    <Form layout='vertical' form={form}>
      <Row gutter={24}>
        <Col xs={24} xl={7}>
          <Form.Item
            label='Documento de Identidad / Placa del Vehículo'
            name='documento'
            rules={[{ required: true, message: 'Debe ingresar el documento de identidad o placa del vehículo' }]}
          >
            <Input placeholder='Documento de Identidad / Placa del Vehículo' addonBefore={tipoAddon} />
          </Form.Item>
        </Col>
        <Col xl={3} xs={12}>
          <Button
            onClick={() => search()}
            style={{ marginTop: width < 1200 ? 0 : 38, width: '100%' }}
            loading={searching}
            icon={<SearchOutlined />}
            type='primary'
          >
            Buscar
          </Button>
        </Col>
        <Col span={24}>
          <Table
            style={{ marginTop: width < 1200 ? 38 : 0 }}
            columns={columns}
            dataSource={fines}
            rowKey='id'
            bordered
            loading={searching}
          />
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = (state: State) => ({ auth: state.auth, thm: state.thm });

export default connect(mapStateToProps)(GetTaxpayerInfo);

interface GetTaxpayerInfoProps {
  auth: States.Auth;
  thm: States.ThemeColors;
}
