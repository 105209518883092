import React, { useEffect, useState, useRef } from 'react';
import { SearchOutlined, FormOutlined, ExceptionOutlined, RocketOutlined } from '@ant-design/icons';
import { Table, Tooltip, Button, Card, Input, Tabs, Tag } from 'antd';
import { connect } from 'react-redux';
import { State, States } from 'sigt';
import { useHistory } from 'react-router-dom';
import { fetchOfficials, deleteOfficial } from '../../redux/actions/official';
import { useWindowDimensions } from '../../utils/hooks';
import Highlighter from 'react-highlight-words';
import '../../assets/css/components/TableOfficial.css';
import _ from 'lodash';
import { ColumnsType } from 'antd/lib/table';

const TableSuport: React.FC<PropsTable> = ({ thm, auth, prcd, onlyTable }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColum] = useState('');
  const searchInput = useRef<any>(null);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const { width } = useWindowDimensions();
  const [tab, setTab] = useState<string>(onlyTable ? 'ticket' : 'tramites');
  const data: any[] = [];
  const filterStatus: any[] = [];
  const filterTypes: any[] = [];

  const types = {
    1: { color: 'error', name: 'Error al importar usuario de GTIC' },
    2: { color: 'error', name: 'Error de credenciales en SUT' },
    3: { color: 'warning', name: 'Problemas con datos del contribuyente' },
    4: { color: 'warning', name: 'Caso de deudas erroneas' },
    5: { color: 'cyan', name: 'Corregir liquidaciones y fechas' },
    6: { color: 'gold', name: 'Pagos sin validar' },
    7: { color: 'gold', name: 'Pagos devueltos por sistema' },
    8: { color: 'error', name: 'Error de Cajero' },
    9: { color: 'error', name: 'Error de Analista' },
    10: { color: 'warning', name: 'Falla en funcion del sistema' },
    11: { color: 'default', name: 'Otro caso' },
    12: { color: 'default', name: 'Cambio o inclusion de Activididad Economica' },
    13: { color: 'default', name: 'Declaracion de años pasados' },
    14: { color: 'gold', name: 'Solicitud de Exoneración Especial' },
  };

  const StatusConfig = {
    iniciado: { color: 'default', name: 'Iniciado' },
    finalizado: { color: 'green', name: 'Atendido' },
    enproceso: { color: 'geekblue', name: 'Por atender' },
    enrevision: { color: 'lime', name: 'En proceso' },
    error: { color: 'error', name: 'Error' },
  };

  const getDoc = (tipo, doc) => (tipo && doc ? `${tipo}-${doc}` : 'N/A');

  prcd?.support?.forEach((ele) => {
    const estado = StatusConfig[ele.estado];
    const usuario = ele?.datos?.usuario;
    const tipo = types[usuario?.tipo];
    data.push({ ...ele, estado, tipo, documento: getDoc(usuario?.tipoDocumento, usuario?.documento), nombre: usuario.nombre });
    if (typeof _.find(filterStatus, (e) => e.value === estado.name) !== 'object')
      filterStatus.push({ value: estado.name, text: estado.name });
    if (typeof _.find(filterTypes, (e) => e.value === tipo.name) !== 'object')
      filterTypes.push({ value: tipo.name, text: tipo.name });
  });
  const myData: any[] = data.filter((e) => e.usuario === auth?.user?.id);

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined onClick={() => {}} style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Codigo',
      dataIndex: 'codigoTramite',
      align: 'center',
      width: '20%',
      key: 'codigoTramite',
      ...getColumnSearchProps('codigoTramite'),
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      align: 'center',
      width: '20%',
      key: 'nombre',
      ...getColumnSearchProps('nombre'),
    },
    {
      title: 'Documento',
      dataIndex: 'documento',
      key: 'documento',
      width: '20%',
      align: 'center',
      ...getColumnSearchProps('documento'),
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      key: 'tipo',
      align: 'center',
      filters: filterTypes,
      onFilter: (value, record) => record.tipo.name.toLowerCase().indexOf((value as string).toLowerCase()) === 0,
      render: (value) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={value.color}>
          {value.name}
        </Tag>
      ),
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      filters: filterStatus,
      onFilter: (value, record) => record.estado.name.toLowerCase().indexOf((value as string).toLowerCase()) === 0,
      render: (value) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={value.color}>
          {value.name}
        </Tag>
      ),
    },
    {
      title: 'Acciones',
      key: 'action',
      render: (_, record) => (
        <span>
          {tab === 'tramites' && record.estado.name !== 'Atendido' ? (
            <Tooltip title='Procesar'>
              <Button
                shape='round'
                type='danger'
                icon={<RocketOutlined />}
                onClick={() => history.push(`/dashboard/soporte/ticket/${record.id}`)}
              >
                {width > 440 ? 'Procesar' : null}
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title='ver'>
              <Button
                shape='round'
                type='primary'
                icon={<RocketOutlined />}
                onClick={() => history.push(`/dashboard/soporte/ver/ticket/${record.id}`)}
              >
                {width > 440 ? 'Ver' : null}
              </Button>
            </Tooltip>
          )}
        </span>
      ),
    },
  ];

  useEffect(() => {
    aux();
    // eslint-disable-next-line
  }, [fetchOfficials, auth.token]);

  const aux = async () => {
    if (auth.token && auth.user?.tipoUsuario) {
      await fetchOfficials(auth.token, auth.user?.tipoUsuario);
    }
    setLoading(false);
  };
  return (
    <>
      {!onlyTable ? (
        <Card
          title={tab === 'tramites' ? 'Tickets por atender' : 'Tickets'}
          headStyle={{ backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: '#fff' }}
          bodyStyle={{ overflowY: 'auto' }}
        >
          <Tabs onChange={setTab} activeKey={tab}>
            <Tabs.TabPane
              key='tramites'
              tab={
                <span>
                  <FormOutlined />
                  Tickets por atender
                </span>
              }
            >
              <Table
                style={{ height: '100%' }}
                size='middle'
                loading={loading}
                pagination={{ pageSize: 6 }}
                columns={columns}
                dataSource={data.filter((e) => e.estado.name !== 'Atendido')}
                rowKey={(e) => e.id}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              key='tickets'
              tab={
                <span>
                  <ExceptionOutlined />
                  Tickets
                </span>
              }
            >
              <Table
                style={{ height: '100%' }}
                size='middle'
                loading={loading}
                pagination={{ pageSize: 6 }}
                columns={columns}
                dataSource={myData}
                rowKey={(e) => e.id}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              key='btn'
              tab={
                <Button type='primary' onClick={() => history.push('../soporte/crear')}>
                  Crear Ticket
                </Button>
              }
            />
          </Tabs>
        </Card>
      ) : (
        <>
          <Button type='primary' onClick={() => history.push('../soporte/crear')}>
            Crear Ticket
          </Button>
          <Table
            style={{ height: '100%' }}
            size='middle'
            loading={loading}
            pagination={{ pageSize: 6 }}
            columns={columns}
            dataSource={myData}
            rowKey={(e) => e.id}
          />
        </>
      )}
    </>
  );
};

const WrapperTableSuport: React.FC<PropsTable> = ({ thm, auth, prcd }) => {
  const { width } = useWindowDimensions();
  const canSupport = [24, 90, 38];
  return (
    <>
      {canSupport.includes(auth.user?.institucion?.cargo?.id || 0) ? (
        <TableSuport thm={thm} auth={auth} prcd={prcd} onlyTable={false} />
      ) : (
        <Card
          style={{ height: '100%' }}
          title='Tickets'
          headStyle={{ backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: '#fff' }}
          bodyStyle={{ overflowY: 'auto' }}
        >
          <TableSuport thm={thm} auth={auth} prcd={prcd} onlyTable={true} />
        </Card>
      )}
    </>
  );
};

const mapStateToProps = ({ auth, thm, prcd }: State) => ({ auth, thm, prcd });

export default connect(mapStateToProps, { fetchOfficials, deleteOfficial })(WrapperTableSuport);

interface PropsTable {
  auth: States.Auth;
  thm: States.ThemeColors;
  prcd: States.Procedures;
  onlyTable?: boolean;
}
