import React, { useState } from 'react';
import { Card, Table, Button, InputNumber, Input, Form, Tooltip, Spin, Row, Col, Collapse, Select, Divider, Switch } from 'antd';
import { useWindowDimensions } from '../../utils/hooks';
import { CheckOutlined, CloseOutlined, LoadingOutlined, EditOutlined, PlusSquareOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { State, States, Destination } from 'sigt';
import { connect } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import _ from 'lodash';
import { updateDestinationCost, createDestinationCost, updateAllDestinationCosts } from '../../redux/actions/cost';
import handlingMessage from '../../utils/handlingMessage';
import '../../assets/css/components/TableDestination.css';

const formatCurrency = (number: number) => {
  const num = Math.round(((number) + Number.EPSILON) * 100) / 100
  return new Intl.NumberFormat('de-DE').format(num);
};

const round2 = (number) => Math.round(((number) + Number.EPSILON) * 100) / 100

const EditableCell:React.FC<EditableCellProps | any> = ({editing, dataIndex, title, inputNode, record, index, children, ...restProps }) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[{required: true, message: `Por favor ingresar un valor`,}]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const CreateForm: React.FC<CreateFormProps> = ({data, createDestinationCost, setVisibleCreate}) => {
  const [addDestination, setAddDestination] = useState(false);
  const [form] = Form.useForm();
  
  const onFinish = async () => {
    const values = await form.validateFields();
    values.tasa = round2(values.tasa / 100);
    handlingMessage({ action: () => createDestinationCost(values), key:'createDestinationCost', loadingMessage: 'Creando...' })
  }

  return (
    <Form form={form} onFinish={onFinish}>
      <Row justify='end' gutter={[12,12]}>
          <Col xs={24} sm={24} md={6}>
            <Form.Item name='destino' rules={[{required: true, message: `Por favor ingrese un destino`}]}>
              { addDestination ? <Input placeholder='Destino' /> :
                <Select style={{width:'100%'}} placeholder='Destino' dropdownRender={menu => (
                  <>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <Row>
                      <Button type='link' icon={<PlusSquareOutlined/>} style={{margin:0}} onClick={() => {setAddDestination(true); form.setFieldsValue({destino: ''})}}>
                        Agregar
                      </Button>
                    </Row>
                  </>
                )}>
                  { Object.keys(data).map((ele,key)=>(<Select.Option key={ele} value={ele}>{ele}</Select.Option>))}
                </Select>
              }
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Form.Item name='tipo' rules={[{required: true, message: `Por favor seleccion una unidad`}]}>
              <Select style={{width:'100%'}} placeholder='Unidad'>
                <Select.Option value='BUSETA' > Buseta </Select.Option>
                <Select.Option value='CARRO POR PUESTO' > Carro por puesto </Select.Option>
                <Select.Option value='BUS CAMA' > Bus cama </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={5}>
            <Form.Item name='monto' rules={[{required: true, message: `Por favor ingresar un monto`}]}>
              <InputNumber placeholder='Monto' decimalSeparator=',' style={{width: '100%'}} min={1} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={3} >
            <Form.Item name='tasa' rules={[{required: true, message: `Por favor ingrese un valor`}]} >
              <InputNumber placeholder='Tasa' step={1} min={1} formatter={value => value ? `${value}%` : ''} parser={value => value ? value.replace('%', ''): ''} decimalSeparator=',' style={{width: '100%'}}/>
            </Form.Item>
          </Col>
          <Col style={{textAlign:'right'}} xs={24} sm={6} md={4} >
            <Button style={{marginRight:8}} type='primary' icon={<CheckOutlined/>} htmlType='submit'/>
            <Button ghost  type='danger' icon={<CloseOutlined/>} onClick={()=>setVisibleCreate()}/>
          </Col>
        </Row>
    </Form>
  );
}


const SubTable: React.FC<SubTableProps> = ({ data, form, updateDestinationCost }) => {
  const [editingKey, setEditingKey] = useState(-1);
  const [loading, setLoading] = useState(false);
  const isEditing = record => record.id === editingKey;

  const saveValue = async (record: any) => {
    const monto = form.getFieldValue('monto');
    const tasa = form.getFieldValue('tasa');
    const values = {...record, monto, tasa: round2(tasa/100)};
    setLoading(true);
    handlingMessage({ action: () => updateDestinationCost(values), key:'updateDestinationCost', loadingMessage: 'Actualizando valor' })
    setEditingKey(-1);
    setLoading(false)
  };

  const updateStatus = async (record: any) => {
    setLoading(true);
    const values = {...record, tasa: round2(record.tasa/100)};
    handlingMessage({ action: () => updateDestinationCost(values), key:'updateStatus', loadingMessage: record?.habiltado ? 'Habilitando...' : 'Deshabilitando' })
    setLoading(false)
  };

  const columns = [
    { title: 'Unidad', dataIndex: 'tipo', key:'tipo' },
    { 
      title: 'Monto del pasaje', 
      dataIndex: 'monto', 
      key: 'monto',
      render: (record) => <span>Bs. {formatCurrency(parseFloat(record))}</span>,
      editable: true,
      inputNode: <InputNumber decimalSeparator=',' style={{width: '100%'}} min={1}/>
    },
    { 
      title: '%', 
      dataIndex: 'tasa', 
      key: 'tasa',
      editable: true,
      inputNode: <InputNumber step={1} min={1} formatter={value => value ? `${value}%` : ''} parser={value => value ? value.replace('%', ''): ''} decimalSeparator=',' style={{width: '100%'}}/>
    },
    { 
      title: 'Tasa', 
      key: 'tasa',
      dataIndex: 'montoCalculado', 
      render: (record) => <span>Bs. {formatCurrency(parseFloat(record))}</span>,
    },
    {
      title: '',
      dataIndex: 'operacion',
      render: (text, record) => !isEditing(record) ? (<div style={{textAlign:'right'}}>
        {record.habilitado ? <Tooltip  title='Editar'><Button style={{marginRight: 10}} icon={<EditOutlined/>} type='link' onClick={() => {form.setFieldsValue({ ...record }); setEditingKey(record.id)}}/></Tooltip> : null }
        <Tooltip title={record.habilitado ? 'Deshabilitar' : 'Habilitar'}>
          <Switch size='small' loading={loading} checked={record?.habilitado} onChange={(value)=> updateStatus({...record, habilitado: value})} checkedChildren={<CheckOutlined />}unCheckedChildren={<CloseOutlined />}/>
        </Tooltip>
      </div>) : !loading ? (
        <span>
          <Tooltip title='Confirmar'>
            <Button type='link' disabled={loading} icon={<CheckOutlined />} onClick={() => saveValue(record)} />
          </Tooltip>
          <Tooltip placement="bottom" title="Cancelar">
            <Button type="link" disabled={loading} icon={<CloseOutlined />} onClick={() => setEditingKey(-1)} />
          </Tooltip>  
        </span>
      ) : (
        <Spin indicator={<LoadingOutlined />} style={{ marginLeft: 20 }} />
      )
    }
  ];

  const transformedColumns = columns.map((c) => 
    !c.editable ? c : ({
      ...c,
      onCell: record => ({
        inputNode: c.inputNode,
        dataIndex: c.dataIndex,
        title: c.title,
        editing: isEditing(record)
      })
    })
  );
  
  return (
    <Table rowClassName={(record) => !record.habilitado ? 'disabledRow' : ''} rowKey={e => e.id} columns={transformedColumns} dataSource={data} pagination={false} components={{ body: { cell: EditableCell } }} />
  );
};

const TableDestination: React.FC<TableDestinationProps> = ({ thm, destinations, updateDestinationCost, createDestinationCost, updateAllDestinationCosts}) => {
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const [visibleCreate,setVisibleCreate] = useState(false); 
  const data = _.groupBy(destinations.map((e)=>({...e, tasa: e.tasa*100 })),(e)=>e.destino.toUpperCase());

  const applyFactorToDestinations = () => {
    handlingMessage({
      action: () => updateAllDestinationCosts({tasa: round2(form.getFieldValue('factorDestination')/100)}),
      key:'applyFactor',
      loadingMessage:'Actualizando Valores...'
    });
  }

  return (
    <Card style={{ height: '100%' }} title='Tasas de Destinos' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
    headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}}>
        <Form form={form} component={false} initialValues={{ factorDestination: 10 }}>
          { !visibleCreate ? 
            <Row gutter={[12,16]}>
              <Col flex={1}>
                <Button type='primary' icon={<PlusCircleOutlined/>} onClick={()=>setVisibleCreate(true)}>Añadir</Button>
              </Col>
              <Col>
                <Form.Item label='Factor de Conversión' name='factorDestination' style={{ margin: 0 }}>
                  <InputNumber step={1} min={1} formatter={value => value ? `${value}%` : ''} parser={value => value ? value.replace('%', ''): ''} decimalSeparator=',' style={{width: '100%'}}/>
                </Form.Item>
              </Col>
              <Col><Button type='primary' onClick={() => applyFactorToDestinations()}>Aplicar</Button></Col>
            </Row> :
            <CreateForm data={data} createDestinationCost={createDestinationCost} setVisibleCreate={() => setVisibleCreate(false)}/>
          }
          <Collapse >
            { Object.keys(data).map((ele)=>(
              <Collapse.Panel header={ele.toLocaleUpperCase()} key={ele}>
                {<SubTable updateDestinationCost={updateDestinationCost} data={data[ele]} form={form} />}
              </Collapse.Panel>))
            }
          </Collapse>
        </Form>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, destinations: state.cst.destinations });

export default connect(mapStateToProps,{updateDestinationCost, createDestinationCost,updateAllDestinationCosts})(TableDestination);

interface TableDestinationProps {
  thm: States.ThemeColors
  destinations: Destination[]
  updateDestinationCost: (formValues: Destination) => Promise<Response>;
  createDestinationCost: (formValues: Destination) => Promise<Response>;
  updateAllDestinationCosts: (values: {tasa: number}) => Promise<Response>;
}

interface SubTableProps { 
  data: any, 
  form: FormInstance 
  updateDestinationCost: (formValues: Destination) => Promise<Response>;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: Item;
}

interface Item {
  key: number;
  name: string;
  cost: number;
}

interface CreateFormProps {
  data: _.Dictionary<Destination[]> 
  createDestinationCost: (formValues: any) => Promise<Response>; 
  setVisibleCreate: Function 
}
