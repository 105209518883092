import React, { CSSProperties } from 'react';

const initialStyle: CSSProperties = {
  width: 15,
  height: 15, 
  objectFit: 'contain'
};

const Dolar: React.FC<DolarProps> = ({ style = {}, ...props }) => {
  const _styles = {
    ...initialStyle,
    ...style
  };

  return (
    <img src={require('../../assets/images/dolar.png')} style={_styles} alt='Dolar' {...props} />
  );
};

export default Dolar;

interface DolarProps {
  style?: CSSProperties
}