import React, { useEffect, useState, useRef } from 'react';
import { updateExonerationEndDate, fetchExonerationsBranches, createExonerationBranch } from '../../../../services/exonerations';
import { Table, Input, Button, Row, Form, DatePicker, Select, Col, Tooltip } from 'antd';
import { SearchOutlined, CloseOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useSelector, connect } from 'react-redux';
import { State, States } from 'sigt';
import Highlighter from 'react-highlight-words';
import handlingMessage from '../../../../utils/handlingMessage';
import Item from 'antd/lib/list/Item';

const EditableCell:React.FC<EditableCellProps | any> = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps
}) => {
  const inputNode = <DatePicker format='DD-MM-YYYY' style={{textAlignLast:'center'}} />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{margin: 0}}
          rules={[{required: true,message: `Por favor ingresar ${title}!`,}]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ExoBranches: React.FC<ExoBranchesProps> = ({brch}) => {
  const [exoneracionBranches, setExoneracionBranches] = useState<exoBranches[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColum] = useState('');
  const searchInput = useRef<any>(null);
  const { thm } = useSelector((state: State) => ({ thm: state.thm }));
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [editingKey, setEditingKey] = useState('');
  const isEditing = record => record.key === editingKey;

  const getExoneracionesBranches = async () => {
    const { data } = await fetchExonerationsBranches();
    setExoneracionBranches(data.exoneracionesGeneralesDeRamos.map((ele,index) => ({...ele, key:index})));
    setLoading(false);
  }

  useEffect(() => {
    getExoneracionesBranches();
  }, []);

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined onClick={() => {}} style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const columnsExoBranches: any | ColumnsType<exoBranches> = [
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      ...getColumnSearchProps('descripcion'),
    },
    {
      title: 'Fecha de inicio',
      dataIndex: 'fechaInicio',
      key: 'fechaInicio',
      ...getColumnSearchProps('fechaInicio'),
      render: (text) => moment(text).utcOffset('+0400').format('DD-MM-YYYY'),
    },
    {
      title: 'Fecha Fin',
      dataIndex: 'fechaFin',
      key: 'fechaFin',
      editable:true,
      ...getColumnSearchProps('fechaFin'),
      render: (text) => text ? moment(text).utcOffset('+0400').format('DD-MM-YYYY') : 'No definida',
    },  
    {
      key: "action",
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Tooltip placement="bottom" title="Confirmar">
              <Button type="link" icon={<CheckOutlined />} onClick={() => save(record.id)} />
            </Tooltip>
            <Tooltip placement="bottom" title="Cancelar">
              <Button type="link" icon={<CloseOutlined />} onClick={() => setEditingKey('')} />
            </Tooltip>                        
          </span>
        ) : (
            <span>
            <Tooltip placement="bottom" title="Editar">
              <Button type="link" icon={<EditOutlined />} onClick={() => edit(record)} />
            </Tooltip>
          </span>
        );
      },
    }
  ];

  const save = async (key) => {
    try {
      const row = await editForm.validateFields();
      const newData = [...exoneracionBranches];
      const index = newData.findIndex(item => key === item.id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        const values = newData[index];
        handlingMessage({
          action: () => updateExonerationEndDate({to:values.fechaFin,id:values.id}),
          key:'endExoneration',
          loadingMessage: 'Actualizando Exoneracion',
          cb: () => {setExoneracionBranches(newData);}
        });
        setEditingKey('');
      }
      
    } catch (errInfo) {
      throw errInfo
    }
  };

  const onSubmitExoneration = async () => {
    let { branches, from } = await form.validateFields();

    const brchs = branches ? branches.map((id)=>brch?.branches.find((l)=>l.id === id)) : undefined;

    handlingMessage({
      action: () => createExonerationBranch({ branches:brchs , from: moment(from).toISOString() }),
      key: 'createExo',
      loadingMessage: 'Creando exoneracion...',
      cb: ({exoneraciones}) => {
        if(exoneraciones){
          setExoneracionBranches([...exoneracionBranches, ...exoneraciones]);
          form.resetFields();
        }
      },
    });
  };
  const mergedColumns = columnsExoBranches.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => ({
        record,
        inputType: 'date',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const components = {
    body: {
      cell: EditableCell,
    }
  };
  
  return (
    <>
        <Form initialValues={{ from: moment() }} form={form} layout='vertical' onFinish={onSubmitExoneration}>
          <Row gutter={[16, 16]} align='bottom'>
            <Col xs={24} sm={24} md={15}>
              <Form.Item label='Ramos' name='branches' rules={[{ required: true, message: 'Debe seleccionar al menos un ramo' }]}>
                <Select placeholder='Seleccione los ramos a exonerar...' allowClear filterOption={(value: string, option) => option?.title?.includes(value.toUpperCase())} style={{ width: '100%' }} mode='multiple'>
                  {brch.branches.map(({id,descripcion}) =>
                    <Select.Option key={id} value={id} title={descripcion.toUpperCase()} >
                      <Tooltip title={descripcion}>{descripcion.toUpperCase()}</Tooltip>
                    </Select.Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={5}>
              <Form.Item label='Fecha inicio' name='from'>
                <DatePicker format={'DD-MM-YYYY'} style={{textAlignLast:'center'}} />
              </Form.Item>
            </Col>
            <Col xs={24} md={3} xl={4} style={{alignSelf:'flex-end', marginBottom:'2px'}}>
              <Form.Item>
                <Button type='primary' htmlType='submit'>
                  Exonerar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      <Form form={editForm}><Table rowKey={({ id }) => id} size='middle' loading={loading} components={components} columns={mergedColumns} dataSource={exoneracionBranches} /></Form>
    </>
  );
};

const mapStateToProps = ({brch}: State) => ({brch});

export default connect(mapStateToProps)(ExoBranches);

interface ExoBranchesProps {
  brch: States.Branches
}

interface exoBranches {
  id: number;
  descripcion: string;
  fechaInicio?: string;
  fechaFin?: string;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: Item;
}