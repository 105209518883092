import React from 'react';
import { Collapse } from 'antd';
import DiscountAE from './DiscountAE';
import DiscountTaxPayer from './DiscountTaxPayer';
const { Panel } = Collapse;

const Discount: React.FC = () => {
  return (
    <Collapse defaultActiveKey={[]}>
      <Panel header="Actividades económicas" key="ae">
        <DiscountAE/>
      </Panel>
      <Panel header="Contribuyentes" key="taxPayer">
        <DiscountTaxPayer/>
      </Panel>
    </Collapse> 
  );
};

export default Discount;
