import React from 'react';
import { Form, Input, Button, Col, Row, Divider, Select } from 'antd';
import { Utils } from '../../../utils/validators';
import ParishSelect from './ParishSelect';

const OwnersFormList: React.FC<OwnersFormListProps> = ({ name, tipoTramite, cargo, disabled}) => {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <div>
          {fields.map((field, index) => (
            <React.Fragment key={field.key}>
              <Form.Item required={true} key={field.key} style={{ width: '100%' }}>
                <Row gutter={24} style={{ padding: '0 20px' }}>
                  <Col xs={24} xl={8}>
                    <Form.Item name={[field.name, 'razonSocial']} fieldKey={field.fieldKey} label='Razon Social' validateTrigger={['onChange', 'onBlur']} rules={[{ required: true, message: 'Por favor ingrese la razon social del propietario' }]}>
                      <Input disabled={(tipoTramite === 43 && cargo === 93) || disabled} placeholder='Razon Social' />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item name={[field.name, 'cedulaORif']} fieldKey={field.fieldKey} label='Cedula o RIF' rules={[{ required: true, message: 'Por favor ingrese la cedula o RIF del propietario' }]}>
                      <Input disabled={(tipoTramite === 43 && cargo === 93) || disabled} placeholder='Cedula' addonBefore={
                        <Form.Item name={[field.name, 'nacionalidad']} style={{ margin: '-1px' }}>
                          <Select disabled={(tipoTramite === 43 && cargo === 93) || disabled} placeholder='N'>
                            <Select.Option value='V'>V</Select.Option>
                            <Select.Option value='E'>E</Select.Option>
                            <Select.Option value='J'>J</Select.Option>
                            <Select.Option value='G'>G</Select.Option>
                          </Select>
                        </Form.Item>
                      } />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item name={[field.name, 'telefono']} fieldKey={field.fieldKey} label='Telefono' rules={[{ required: true, message: 'Por favor ingrese el telefono del propietario' }, 
                      { validator: Utils.validators.phoneNumber }]} normalize={Utils.normalize.isNumber}>
                      <Input disabled={(tipoTramite === 43 && cargo === 93) || disabled} placeholder='Telefono' />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item name={[field.name, 'direccion']} label='Direccion' fieldKey={field.fieldKey} rules={[{ required: true, message: 'Por favor ingrese la direccion del propietario' }]}>
                      <Input disabled={(tipoTramite === 43 && cargo === 93) || disabled} placeholder='Direccion' />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item name={[field.name, 'correo']} fieldKey={field.fieldKey} label='Correo Electrónico' rules={[{ required: true, 
                      message: 'Por favor ingrese el correo electrónico del propietario' }, { validator: Utils.validators.email }]}>
                      <Input disabled={(tipoTramite === 43 && cargo === 93) || disabled} placeholder='Correo Electrónico' />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item name={[field.name, 'parroquia']} fieldKey={field.fieldKey} label='Parroquia' rules={[{ required: true, message: 'Por favor ingrese la Parroquia asociada a la dirección' }]}>
                      <ParishSelect tipoTramite={tipoTramite} cargo={cargo} disabled={disabled} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              {index < (fields.length - 1) && <Divider />}
            </React.Fragment>
          ))}
          <Row gutter={[16,16]}>
            {(fields.length > 0 && (!(tipoTramite === 43 && cargo === 93) || !disabled) ) && <Col><Button type='danger' onClick={() => remove(fields.length - 1)}>Eliminar Propietario</Button></Col> }
            { (!(tipoTramite === 43 && cargo === 93) || !disabled) && <Col><Button type='dashed' onClick={() => add()}>Agregar Propietario</Button></Col>}
          </Row>
        </div>
      )}
    </Form.List>
  );
};

export default OwnersFormList;

export interface OwnersFormListProps {
  name: string,
  tipoTramite?: number,
  cargo?: number,
  disabled?: boolean,
}