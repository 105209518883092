import React, { useState, useEffect } from 'react';
import {
  Card,
  Form,
  Row,
  Typography,
  Divider,
  Col,
  Input,
  Select,
  DatePicker,
  Button,
  message,
  Descriptions,
  Modal,
  Upload,
  Checkbox,
  InputNumber,
  notification,
} from 'antd';
import { useWindowDimensions } from '../../../utils/hooks';
import { States, State, Fine, FineArticule, FineNumeral, FineGaceta, FineLiteral } from 'sigt';
import { connect } from 'react-redux';
import { Utils } from '../../../utils/validators';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { setFine } from '../../../redux/actions/procedures';
import axios from 'axios';
import { useHistory } from 'react-router';
import { RcFile, UploadProps } from 'antd/es/upload';
import { UploadFile } from 'antd/es/upload/interface';
import { getFineArticule } from '../../../services/fines';
import { useSelector } from 'react-redux';
const server = process.env.REACT_APP_SERVER_URL;

interface FineObject {
  gaceta: FineGaceta | null;
  articulo: FineArticule | null;
  numeral: FineNumeral | null;
  literal: FineLiteral | null;
}

const PoliceTicketForm: React.FC<PoliceTicketFormProps> = ({ thm, parish, setFine, auth }) => {
  const [nacionalidad, setNacionalidad] = useState<string>('V');
  const [loading, setLoading] = useState<boolean>(false);
  const [dataPago, setDataPago] = useState<{
    numeral: FineNumeral[];
    montoAPagar: number;
  }>({
    numeral: [],
    montoAPagar: 0,
  });
  const [fineData, setFineData] = useState<{
    articules: FineArticule[] | null;
    numeral: FineNumeral[] | null;
    gaceta: FineGaceta[] | null;
    literal: FineLiteral[] | null;
  }>({
    articules: null,
    numeral: null,
    literal: null,
    gaceta: null,
  });

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const history = useHistory();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [includeDataVH, setIncludeDataVH] = useState(false);
  const [includeDataInfractor, setIncludeDataInfractor] = useState(false);
  const [montoApagar, setMontoAPagar] = useState(0);
  const PETRO = useSelector((state: State) => state.coin).petro;

  const AMOUNT_MORE_ONE_INFRACTION = 30;

  const cedulaAddon = (
    <Select defaultValue='V' value={nacionalidad} onChange={(e) => setNacionalidad(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  useEffect(() => {
    getFineArticule(auth.token)
      .then((res) => {
        setFineData(res);
      })
      .catch((error) => {
        console.log(error);
        message.error('Error al momento de obtener los datos');
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      cedulaFuncionario: `${auth.user?.nacionalidad}-${auth.user?.cedula}`,
      nombreCompletoFuncionario: auth.user?.nombreCompleto,
    });
  }, [auth.user, form]);

  const handleUnidad = () => {
    const unidad = form.getFieldValue('unidad');

    if (dataPago?.numeral) {
      if (dataPago?.numeral?.length > 1) {
        setDataPago((state) => {
          return { ...state, montoAPagar: AMOUNT_MORE_ONE_INFRACTION };
        });
      } else {
        if (dataPago?.numeral[0]?.ut) {
          const montoAPagar = UT * Number(unidad || 1);
          setDataPago((state) => {
            return { ...state, montoAPagar };
          });
        }
      }
    }
  };

  const handleSubmit = async () => {
    if (!includeDataVH && !includeDataInfractor) return message.error('Debe incluir datos del vehiculo o del infractor');

    const values = await form.validateFields();

    if (values?.infraccion?.length === 0) return message.error('Debe incluir datos de la infracción');

    if (includeDataVH && !includeDataInfractor) {
      values.nacionalidad = 'V';
      values.cedula = values.placa;
      values.fechaNacimiento = null;
    } else {
      values.nacionalidad = nacionalidad;
    }

    const infraccion = values.infraccion.map((f) => {
      const numeral = fineData?.numeral?.find((g) => g.id_numeral === f.numeral) ?? null;
      return {
        gaceta: fineData?.gaceta?.find((g) => g.id_gaceta === f.gaceta) ?? null,
        articulo: fineData?.articules?.find((g) => g.id_articulo === f.articulo) ?? null,
        numeral,
        literal: fineData?.literal?.find((g) => g.id_literal === f.literal) ?? null,
        unidad: numeral?.ut ? values.unidad : null,
      };
    });

    const tramite = {
      tipoTramite: 20,
      cedula: values.cedula,
      nacionalidad: values.nacionalidad,
      monto: Number(dataPago?.montoAPagar ?? 0), // PETROS
      datos: { ...values, infraccion },
    };
    try {
      setLoading(true);
      const resp = await axios.post<{
        multa: Fine;
        userCreated: Boolean;
        repeatInfringer: {
          numberOfFines: Number;
          isRepeatInfringer: Boolean;
        };
      }>(`${server}/fines/init`, { tramite }, { headers: { Authorization: `Bearer ${auth.token}` } });

      if (resp.status === 200) {
        console.log('despues del if sts 200', resp?.data, resp?.data?.repeatInfringer?.isRepeatInfringer);

        if (fileList.length > 0) {
          const fd = new FormData();
          fileList.forEach((e) => {
            fd.append('multas', e.originFileObj as RcFile);
          });
          const responseFile = await axios.post(`${server}/uploads/finings/${resp.data.multa.id}`, fd, {
            headers: { Authorization: `Bearer ${auth.token}` },
          });
          if (responseFile.status !== 200) message.error('Error al subir las imágenes de soporte');
        }
        setFine(resp.data.multa);
        setFileList([]);
        setDataPago({ numeral: [], montoAPagar: 0 });
        form.resetFields();
        message.success('Multa asignada exitosamente');
        if (auth.user?.tipoUsuario === 4) history.push('/dashboard/bandejas?tipo=multas');
        else form.resetFields();

        console.log(resp?.data, resp?.data?.repeatInfringer?.isRepeatInfringer);
        if (resp?.data?.repeatInfringer?.isRepeatInfringer) {
          const { montoPetro, costo } = resp?.data?.multa;
          notification.info({
            message: `Notificación`,
            duration: 0,
            description: (
              <>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Typography.Text>
                      Se aplico el doble del costo a la multa debido a que el infractor es reincidente. Posee mas de una multa
                    </Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Typography.Text>Monto Bs: {costo}</Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Typography.Text>Monto MMV: {montoPetro}</Typography.Text>
                  </Col>
                </Row>
              </>
            ),
            placement: 'topLeft',
          });
        }

        if (resp.data.userCreated) {
          const { cedula, nacionalidad, correo, nombreCompleto, telefonoMovil, direccion } =
            resp?.data?.multa?.datos?.funcionario;
          notification.info({
            message: `Notificación`,
            duration: 0,
            description: (
              <>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Typography.Text>Usuario registrado exitosamente con los siguientes datos:</Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Typography.Text>Nombre: {nombreCompleto ?? ''}</Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Typography.Text>Documento: {nacionalidad && cedula ? `${nacionalidad}-${cedula}` : ''}</Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Typography.Text>Correo: {correo ?? ''}</Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Typography.Text>Teléfono: {telefonoMovil ?? ''}</Typography.Text>
                  </Col>
                  <Col span={24}>
                    <div style={{ overflow: 'hidden', wordBreak: 'break-all' }}>Dirección: {direccion ?? ''}</div>
                  </Col>
                </Row>
              </>
            ),
            placement: 'topLeft',
          });
        }
      }
    } catch (e) {
      message.error(e?.response?.data?.message ?? 'Error al crear la multa');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleCunstomRequest = ({ file, onSuccess }) => {
    if (!handleBeforeUpload(file)) return;

    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const uploadButton = <Button icon={<UploadOutlined />}>Subir Archivo</Button>;

  const handleBeforeUpload = (file) => {
    const isImage = file.type.includes('image');
    const isPdf = file.type.includes('pdf');

    if (isImage) return true;
    if (isPdf) return true;

    message.error(`Tipo de archivo invalido`);
    return Promise.reject();
  };

  const handlerFormInfraccion = (index: number, type: string) => {
    const fieldsValue = form.getFieldsValue();
    const itemsValue = fieldsValue.infraccion || [];

    if (type === 'gaceta') {
      itemsValue[index] = {
        ...itemsValue[index],
        articulo: null,
        numeral: null,
        literal: null,
      };
    }
    if (type === 'articulo') {
      itemsValue[index] = {
        ...itemsValue[index],
        numeral: null,
        literal: null,
      };
    }
    if (type === 'numeral') {
      itemsValue[index] = {
        ...itemsValue[index],
        literal: null,
      };

      const numeral: FineNumeral[] = itemsValue.map((v) => fineData?.numeral?.find((n) => n.id_numeral === v.numeral));
      setDataPago({ numeral, montoAPagar: numeral.length === 1 ? Number(numeral[0].valor_petro) : AMOUNT_MORE_ONE_INFRACTION });
      form.setFieldsValue({ unidad: 0 });
    }
    if (type === 'literal') {
      itemsValue[index] = {
        ...itemsValue[index],
      };
    }
    form.setFieldsValue({ infraccion: itemsValue });
  };

  const handlerRemoveFormInfraccion = (index) => {
    const numeral = dataPago?.numeral;
    const _numeral = numeral.filter((n) => n.id_numeral !== form.getFieldsValue(['infraccion'])?.infraccion[index]?.numeral);
    const montoAPagar =
      _numeral.length === 1 ? Number(_numeral[0].valor_petro) : _numeral.length === 0 ? 0 : AMOUNT_MORE_ONE_INFRACTION;
    setDataPago({ numeral: _numeral, montoAPagar });
    form.setFieldsValue({ unidad: 0 });
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Crear Multa'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Form
        scrollToFirstError
        form={form}
        layout='vertical'
        style={{ padding: width < 992 ? '0 10px' : '0px 20px' }}
        onFinish={() => handleSubmit()}
      >
        <Row style={{ marginBottom: 24 }} gutter={24}>
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Datos de Control
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={8}>
            <Form.Item
              label='Número de boleta'
              name='boleta'
              rules={[
                { required: true, message: 'Debe ingresar el número de boleta' },
                { validator: Utils.validators.alphaNumeric },
              ]}
              normalize={Utils.normalize.toUpper}
            >
              <Input placeholder='Número de boleta' />
            </Form.Item>
          </Col>
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Datos del Funcionario
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={8}>
            <Form.Item
              label='Cedula'
              name='cedulaFuncionario'
              rules={[
                { required: true, message: 'Debe ingresar el numero de cedula del infractor' },
                { validator: Utils.validators.cedula },
              ]}
              normalize={Utils.normalize.isNumber}
            >
              <Input readOnly={true} placeholder='Cedula' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item
              label='Nombre y Apellido'
              name='nombreCompletoFuncionario'
              rules={[{ required: true, message: 'Debe ingresar el nombre del infractor' }]}
            >
              <Input readOnly={true} placeholder='Nombre y Apellido' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={18}>
            <Checkbox onChange={() => setIncludeDataInfractor(!includeDataInfractor)}>Incluir Datos del Infractor</Checkbox>
          </Col>
          {includeDataInfractor && (
            <>
              <Divider orientation='left' style={{ marginLeft: -20 }}>
                <Typography.Title level={4} style={{ marginLeft: 5 }}>
                  Datos del Infractor
                </Typography.Title>
              </Divider>
              <Col xs={24} xl={8}>
                <Form.Item
                  label='Cédula o Documento de Identificación'
                  name='cedula'
                  rules={[
                    { required: true, message: 'Debe ingresar el numero de cedula del infractor' },
                    { validator: Utils.validators.cedula },
                  ]}
                  normalize={Utils.normalize.isNumber}
                >
                  <Input placeholder='Cedula' addonBefore={cedulaAddon} />
                </Form.Item>
              </Col>
              <Col xs={24} xl={8}>
                <Form.Item
                  label='Nombre y Apellido'
                  name='nombreCompleto'
                  rules={[{ required: true, message: 'Debe ingresar el nombre del infractor' }]}
                >
                  <Input placeholder='Nombre y Apellido' />
                </Form.Item>
              </Col>
              <Col xs={24} xl={6}>
                <Form.Item
                  label='Fecha de Nacimiento'
                  name='fechaNacimiento'
                  rules={[{ required: true, message: 'Debe ingresar la fecha de nacimiento del infractor' }]}
                >
                  <DatePicker style={{ width: '100%' }} format='DD/MM/YYYY' />
                </Form.Item>
              </Col>
              <Col xs={24} xl={8}>
                <Form.Item label='Teléfono Fijo' name='telefono' normalize={Utils.normalize.isNumber}>
                  <Input placeholder='Teléfono Fijo' />
                </Form.Item>
              </Col>
              <Col xs={24} xl={8}>
                <Form.Item
                  label='Teléfono Movil'
                  name='telefonoMovil'
                  rules={[
                    { required: true, message: 'Debe ingresar el teléfono del infractor' },
                    { validator: Utils.validators.phoneNumber },
                  ]}
                  normalize={Utils.normalize.isNumber}
                >
                  <Input placeholder='Teléfono Movil' />
                </Form.Item>
              </Col>
              <Col xs={24} xl={6}>
                <Form.Item
                  label='Correo Electrónico'
                  name='correo'
                  rules={[
                    { required: true, message: 'Debe ingresar el correo electrónico del infractor' },
                    { validator: Utils.validators.email },
                  ]}
                  normalize={Utils.normalize.toLower}
                >
                  <Input placeholder='Correo Electrónico' />
                </Form.Item>
              </Col>
              <Col xs={24} xl={6}>
                <Form.Item label='Tipo de Licencia' name='tipoLicencia'>
                  <Input placeholder='Tipo de Licencia' />
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <Form.Item
                  label='Dirección'
                  name='direccion'
                  rules={[{ required: true, message: 'Debe ingresar la dirección del infractor' }]}
                >
                  <Input placeholder='Dirección' />
                </Form.Item>
              </Col>
            </>
          )}
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Datos de la Infracción
            </Typography.Title>
          </Divider>
          <Form.List name={'infraccion'}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Row gutter={16} style={{ width: '100%', marginLeft: 0 }} key={field.key}>
                    <Col xs={24} xl={6}>
                      <Form.Item
                        label='Gaceta'
                        name={[field.name, 'gaceta']}
                        rules={[{ required: true, message: 'Debe seleccionar la gaceta de la infracción' }]}
                      >
                        <Select
                          placeholder='Gaceta'
                          onChange={(e) => {
                            handlerFormInfraccion(index, 'gaceta');
                          }}
                        >
                          {fineData.gaceta &&
                            fineData.gaceta?.map((gaceta) => (
                              <Select.Option key={gaceta.descripcion + gaceta.id_gaceta} value={gaceta.id_gaceta}>
                                {gaceta.descripcion}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={6}>
                      <Form.Item
                        label='Articulo'
                        name={[field.name, 'articulo']}
                        rules={[{ required: true, message: 'Debe seleccionar el articulo de la infracción' }]}
                      >
                        <Select
                          placeholder='Articulo'
                          onChange={(e) => {
                            handlerFormInfraccion(index, 'articulo');
                          }}
                        >
                          {fineData.articules &&
                            fineData.articules
                              ?.filter((f) => f.id_gaceta === form.getFieldValue(['infraccion', field.name, 'gaceta']))
                              ?.sort((a, b) => {
                                if (/^[^0-9]*$/.test(a.articulo) || /^[^0-9]*$/.test(b.articulo)) return -1;
                                const aNum = parseInt(a?.articulo.match(/\d+/)![0]);
                                const bNum = parseInt(b?.articulo.match(/\d+/)![0]);
                                return aNum - bNum;
                              })
                              ?.map((articule) => (
                                <Select.Option key={articule.articulo + articule.id_articulo} value={articule.id_articulo}>
                                  {articule.articulo}
                                </Select.Option>
                              ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={6}>
                      <Form.Item
                        label='Numeral'
                        name={[field.name, 'numeral']}
                        rules={[{ required: true, message: 'Debe seleccionar el numeral de la infracción' }]}
                      >
                        <Select
                          placeholder='Numeral'
                          onChange={(e) => {
                            handlerFormInfraccion(index, 'numeral');
                          }}
                        >
                          {fineData.numeral &&
                            fineData.numeral
                              ?.filter((f) => f.id_articulo === form.getFieldValue(['infraccion', field.name, 'articulo']))
                              ?.sort((a, b) => {
                                if (/^[^0-9]*$/.test(a.numeral) || /^[^0-9]*$/.test(b.numeral)) return -1;
                                const aNum = parseInt(a?.numeral.match(/\d+/)![0]);
                                const bNum = parseInt(b?.numeral.match(/\d+/)![0]);
                                return aNum - bNum;
                              })
                              ?.map((n) => (
                                <Select.Option key={n.id_numeral + n.numeral} value={n.id_numeral}>
                                  {n.numeral}
                                </Select.Option>
                              ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={6}>
                      <Form.Item label='Literal' name={[field.name, 'literal']}>
                        <Select
                          placeholder='Literal'
                          onChange={(e) => {
                            handlerFormInfraccion(index, 'literal');
                          }}
                        >
                          {fineData.literal &&
                            fineData.literal
                              ?.filter((f) => f.id_numeral === form.getFieldValue(['infraccion', field.name, 'numeral']))
                              .map((n) => (
                                <Select.Option key={n.id_literal + n.literal} value={n.id_literal}>
                                  {n.literal}
                                </Select.Option>
                              ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} style={{ marginBottom: '25px' }}>
                      <Typography style={{ margin: 10, wordBreak: 'break-word' }}>
                        {form.getFieldsValue(['infraccion'])?.infraccion[index]?.numeral &&
                          fineData?.numeral &&
                          `Descripción Numeral: ${
                            fineData?.numeral?.find(
                              (f) => f?.id_numeral === form.getFieldsValue(['infraccion'])?.infraccion[index]?.numeral
                            )?.descripcion
                          }`}
                      </Typography>
                      <Typography style={{ margin: 10, wordBreak: 'break-word' }}>
                        {form.getFieldsValue(['infraccion'])?.infraccion[index]?.literal &&
                          fineData?.literal &&
                          `Descripción Literal: ${
                            fineData?.literal?.find(
                              (f) => f?.id_literal === form.getFieldsValue(['infraccion'])?.infraccion[index]?.literal
                            )?.descripcion
                          }`}
                      </Typography>
                    </Col>
                    <Col xs={24} xl={24}>
                      <Button
                        type='danger'
                        onClick={() => {
                          handlerRemoveFormInfraccion(index);
                          remove(index);
                        }}
                        icon={<MinusCircleOutlined />}
                      >
                        Eliminar Infracción
                      </Button>
                    </Col>
                    <Divider />
                  </Row>
                ))}
                <Col xs={24} xl={24}>
                  <Form.Item>
                    <Button
                      type='dashed'
                      onClick={() => {
                        add();
                      }}
                      icon={<PlusOutlined />}
                    >
                      Agregar Infracción
                    </Button>
                  </Form.Item>
                </Col>
              </>
            )}
          </Form.List>

          <Col xs={24} xl={6}>
            <Form.Item
              label='Fecha y Hora'
              name='fechaHora'
              rules={[{ required: true, message: 'Debe ingresar la fecha y hora de la infracción' }]}
            >
              <DatePicker style={{ width: '100%' }} showTime showHour showMinute showSecond={false} format='DD/MM/YYYY hh:mm a' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
            <Form.Item
              label='Lugar'
              name='lugar'
              rules={[{ required: true, message: 'Debe ingresar el lugar de la infracción' }]}
            >
              <Input placeholder='Lugar' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item
              label='Parroquia'
              name='parroquia'
              rules={[{ required: true, message: 'Debe ingresar la Parroquia en donde ocurrió la infracción' }]}
            >
              <Select placeholder='Parroquia'>
                {parish.map((p) => (
                  <Select.Option value={p.nombre} key={p.id}>
                    {p.nombre}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={18}>
            <Form.Item label='Detalles del Evento' name='detalle'>
              <Input placeholder='Detalles de Evento' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={18}>
            <Checkbox onChange={() => setIncludeDataVH(!includeDataVH)}>Incluir Datos del Vehiculo</Checkbox>
          </Col>
          {includeDataVH && (
            <>
              <Divider orientation='left' style={{ marginLeft: -20 }}>
                <Typography.Title level={4} style={{ marginLeft: 5 }}>
                  Datos del Vehículo
                </Typography.Title>
              </Divider>
              <Col xs={24} xl={6}>
                <Form.Item
                  label='Placa'
                  name='placa'
                  rules={[{ required: true, message: 'Debe ingresar la placa del vehículo' }]}
                >
                  <Input placeholder='Placa' maxLength={7} />
                </Form.Item>
              </Col>
              <Col xs={24} xl={6}>
                <Form.Item
                  label='Marca'
                  name='marca'
                  rules={[{ required: true, message: 'Debe ingresar la marca del vehículo' }]}
                >
                  <Input placeholder='Marca' />
                </Form.Item>
              </Col>
              <Col xs={24} xl={6}>
                <Form.Item
                  label='Modelo'
                  name='modelo'
                  rules={[{ required: true, message: 'Debe ingresar del modelo del vehículo' }]}
                >
                  <Input placeholder='Modelo' />
                </Form.Item>
              </Col>
              <Col xs={24} xl={6}>
                <Form.Item
                  label='Color'
                  name='color'
                  rules={[{ required: true, message: 'Debe ingresar el color del vehículo' }]}
                >
                  <Input placeholder='Color' />
                </Form.Item>
              </Col>
              <Col xs={24} xl={6}>
                <Form.Item
                  label='Año'
                  name='ano'
                  rules={[{ required: true, message: 'Debe ingresar el año del vehículo' }]}
                  normalize={Utils.normalize.isNumber}
                >
                  <Input placeholder='Año' maxLength={4} />
                </Form.Item>
              </Col>
            </>
          )}
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Datos de Pago
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={24}>
            {dataPago?.numeral && dataPago?.numeral?.length === 1 && dataPago?.numeral[0]?.ut && (
              <>
                <Col xs={24} xl={8}>
                  <Form.Item label='Unidad' name='unidad' rules={[{ required: false }]}>
                    <InputNumber
                      onChange={handleUnidad}
                      placeholder='Unidades'
                      type='number'
                      step='1'
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            <Descriptions column={2} bordered>
              {dataPago?.numeral?.length === 1 && dataPago?.numeral[0]?.ut && (
                <Descriptions.Item label={'Monto Por Unidad Petros:'}>{UT}</Descriptions.Item>
              )}
              <Descriptions.Item label={'Monto A Pagar MMV:'}>{dataPago?.montoAPagar}</Descriptions.Item>
            </Descriptions>
          </Col>
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Imagen de Soporte
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={8}>
            <Upload
              listType='picture'
              customRequest={handleCunstomRequest}
              beforeUpload={handleBeforeUpload}
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {uploadButton}
            </Upload>
            <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
              <img alt='example' style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </Col>
        </Row>
        <Form.Item>
          <Button loading={loading} type='primary' htmlType='submit'>
            Generar Multa
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, parish: state.prsh.parish, auth: state.auth });

export default connect(mapStateToProps, { setFine })(PoliceTicketForm);
export const UT = 0.03;

interface PoliceTicketFormProps {
  thm: States.ThemeColors;
  parish: States.Parish['parish'];
  setFine: (fine: Fine) => void;
  auth: States.Auth;
}
