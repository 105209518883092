import React, { useState } from 'react';
import { Chart, Tooltip, View, Interval, Line } from 'bizcharts';
import { Card, Typography, Select, Row, Col } from 'antd';

const BarChart: React.FC<BarChartProps> = ({ data = fakeData, loading }) => {
  const [selected, setSelected] = useState('AE');

  const _data = [...data[selected]];
  const biggestValue = _data.sort((a, b) => a.valor > b.valor ? -1 : b.valor > a.valor ? 1 : 0)[0];
  const averageData = data[selected].filter(d => d.name === 'Liquidado').map(d => {
    const payed = data[selected].find(e => e.fecha === d.fecha && e.name === 'Pagado');
    return {
      fecha: d.fecha,
      porcentaje: +((payed?.valor || 1) / ((payed?.valor || 1) + d.valor) * 100).toFixed(2)
    };
  });

  return (
    <Card bodyStyle={{ paddingTop: 10 }} loading={loading}>
      <Row style={{ justifyContent: 'space-between', marginBottom: 10 }}>
        <Typography.Text strong style={{ display: 'inline-block', marginBottom: 15 }}>Total de liquidaciones (vigentes/pagadas)</Typography.Text>
        <Col xl={12} xs={24} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Select value={selected} onChange={(v) => setSelected(v)}>
            <Select.Option value='AE'>Actividades Económicas</Select.Option>
            <Select.Option value='SM'>Servicios Municipales</Select.Option>
            <Select.Option value='IU'>Inmuebles Urbanos</Select.Option>
            <Select.Option value='PP'>Publicidad y Propaganda</Select.Option>
          </Select>
        </Col>
      </Row>
      <Chart height={372} padding='auto' autoFit scale={{ porcentaje: { alias: 'Porcentaje Pagado', min: 0, max: biggestValue?.valor || 0 * 1.2 }, valor: { min: 0, max: biggestValue?.valor || 0 * 1.2 } }}>
        <Tooltip shared />
        <View data={data[selected]}>
          <Interval adjust={[{ type: 'dodge', marginRatio: 0 }]} color='name' position='fecha*valor' />
        </View>
        <View data={averageData}>
          <Line position='fecha*porcentaje' color='orange' size={0} />
        </View>
      </Chart>
    </Card>
  );
};

export default BarChart;

interface BarChartProps {
  data?: {
    [ramo: string]: {
      name: string
      fecha: string
      valor: number
    }
  }[]
  loading: boolean
};

const fakeData = {
  AE: [
    { name: 'Liquidado', fecha: '01/08', valor: 251 },
    { name: 'Liquidado', fecha: '02/08', valor: 289 },
    { name: 'Liquidado', fecha: '03/08', valor: 217 },
    { name: 'Liquidado', fecha: '04/08', valor: 157 },
    { name: 'Liquidado', fecha: '05/08', valor: 187 },
    { name: 'Liquidado', fecha: '06/08', valor: 145 },
    { name: 'Liquidado', fecha: '07/08', valor: 210 },
    { name: 'Liquidado', fecha: '08/08', valor: 237 },
    { name: 'Liquidado', fecha: '09/08', valor: 135 },
    { name: 'Liquidado', fecha: '10/08', valor: 198 },
    { name: 'Liquidado', fecha: '11/08', valor: 174 },
    { name: 'Liquidado', fecha: '12/08', valor: 257 },
    { name: 'Liquidado', fecha: '13/08', valor: 256 },
    { name: 'Liquidado', fecha: '14/08', valor: 102 },
    { name: 'Pagado', fecha: '01/08', valor: 242 },
    { name: 'Pagado', fecha: '02/08', valor: 194 },
    { name: 'Pagado', fecha: '03/08', valor: 117 },
    { name: 'Pagado', fecha: '04/08', valor: 247 },
    { name: 'Pagado', fecha: '05/08', valor: 117 },
    { name: 'Pagado', fecha: '06/08', valor: 145 },
    { name: 'Pagado', fecha: '07/08', valor: 161 },
    { name: 'Pagado', fecha: '08/08', valor: 177 },
    { name: 'Pagado', fecha: '09/08', valor: 115 },
    { name: 'Pagado', fecha: '10/08', valor: 198 },
    { name: 'Pagado', fecha: '11/08', valor: 174 },
    { name: 'Pagado', fecha: '12/08', valor: 208 },
    { name: 'Pagado', fecha: '13/08', valor: 220 },
    { name: 'Pagado', fecha: '14/08', valor: 189 }
  ]
};