import React, { useState, useRef} from 'react';
import { Row, Col, Button, Card, Modal, Form } from 'antd';
import { DeleteOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';
import pdfImage from '../../../assets/images/pdf.png';
import fileImage from '../../../assets/images/file.png';

const VoucherUpload = ({onUpload, onRemove, showVoucher=false }) => {
  const [file , setFile] = useState<any>(null);
  const fileRef = useRef<any>(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [showUpload, setShowUpload] = useState(true)

  const handleUpload = async(target) => {
    const url = await getBase64(target.files[0]);
    const data = { file:target.files[0], url}
    setFile(data)
    setShowUpload(false)
    onUpload(data)
  }

  const handleCancel = () => setPreviewVisible(false);

  const handleRemoveFile = ()=> {
    let fileName = file.file.name
    
    setPreviewImage('');
    setPreviewVisible(false);
    setPreviewTitle('');
    setShowUpload(true)
    setFile(false)
    onRemove(fileName)
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewVisible(true);
    setPreviewTitle(file.file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const getBase64 = (file): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
  
  return (
    <>
      { showVoucher && 
      <>
        <Row>
          {showUpload &&
            <>
              <input accept='image/*' onChange={({target}) => handleUpload(target)} ref={fileRef} type='file' style={{display:'none'}}/>
              <Button icon={<UploadOutlined />} onClick={()=> fileRef.current.click()}>Subir Comprobante De Pago</Button>
            </>
          }
        </Row>
        <Row gutter={[16, 16]}>
          {file && 
            <Col xl={6} lg={8} md={8} sm={8} xs={12}>
              <Card
                actions={[
                  <Button type='link' onClick={() => handleRemoveFile()} style={{ textAlign: 'right', width: '100%', color: 'red' }}>Eliminar<DeleteOutlined /></Button>,
                  <Button type='link' onClick={() => handlePreview(file)} style={{ textAlign: 'right', width: '100%' }}> Preview<EyeOutlined/></Button>
                ]}
                bodyStyle={{borderTop:'1px solid #00000026'}}
                hoverable
                style={{ width: '100%'}}
                cover={<img src={file?.url ? file?.url : file.type.includes('pdf') ? pdfImage : fileImage} alt='file-card' />}
              >
                {file?.file?.name ? file?.file?.name : file.includes('pdf') ? `Pdf` : `Imagen` }
              </Card>
            </Col>
          }
          <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
            <img alt="file-modal" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </Row>   
      </>
      }
    </>
  )
}

export default VoucherUpload