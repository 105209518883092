import React, { useState, useRef } from 'react';
import SearchTaxPayer from '../../../SearchTaxPayer';
import { createDiscountTaxPayer, fetchDiscountTaxPayer, updateDiscountEndDate } from '../../../../services/discount';
import { Descriptions, Table, Input, Button, Form, Typography, Row, Col, Select, DatePicker, Modal, Tooltip, Divider, InputNumber, message } from 'antd';
import { SearchOutlined, CloseOutlined, CheckOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words"
import { connect, useSelector } from 'react-redux';
import { State, States } from 'sigt';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import handlingMessage from '../../../../utils/handlingMessage';
import Item from 'antd/lib/list/Item';

const round2 = (number: number | string) =>{
  number = (typeof number !== 'number') ? parseFloat(number) : number
  return Math.round(((number) + Number.EPSILON) * 100) / 100
} 

const EditableCell:React.FC<EditableCellProps | any> = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  const inputNode = <DatePicker format='DD-MM-YYYY' style={{textAlignLast:'center'}} />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{margin: 0}}
          rules={[{required: true,message: `Por favor ingresar ${title}!`,}]}>
          {inputNode}
        </Form.Item>
      ) : (children)}
    </td>
  );
};

const DiscountTaxPayer: React.FC<DiscountTaxPayerProps> = ({brch}) => {
  const [contribuyente, setContribuyente] = useState<contribuyenteProps>();
  const [discountAE, setDiscountAE] = useState<actividades[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColum] = useState('');
  const [visible, setVisible] = useState(false);
  const { thm } = useSelector((state: State) => ({ thm: state.thm }));
  const searchInput = useRef<any>(null);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [loading, setLoading ] = useState(false);
  const isEditing = record => record.id === editingKey;

  const allowedBranch = [8,64];

  const onSubmitDiscount = async () => {
    let { ramos, from } = await form.validateFields();

    if (!ramos) return message.error('Debe agregar al menos 1 ramo');
    else{
      ramos = ramos.map((r) => ({id: r.ramo, porcDescuento: round2(r.porcDescuento / 100), descripcion: brch.branches.find((b) => b.id === r.ramo)?.descripcion }));
    }
    setLoading(true);
    handlingMessage({
      action: () => createDiscountTaxPayer({doc:contribuyente?.documento,typeDoc:contribuyente?.tipoDocumento, branches: ramos, from:moment(from).startOf('day').format('MM/DD/YYYY'), ref: contribuyente?.referenciaMunicipal}),
      key: 'createDiscount',
      loadingMessage: 'Creando descuento...',
      cb: ({contribuyente}) => {
        if(contribuyente){
          setDiscountAE([...contribuyente.ramos, ...discountAE]);
          form.resetFields();
          setVisible(false); 
        }
        setLoading(false);
      }
    })
  }

  const setDiscount = ({contribuyente}) => {
    if (contribuyente){
      setContribuyente(contribuyente);
      setDiscountAE(contribuyente.ramos);
    }
  }

  const save = async (idBrch, idRecord) => {
    try {
      const row = await editForm.validateFields();
      handlingMessage({
        action: () => updateDiscountEndDate({ to:moment(row.fechaFin).startOf('day').format('MM/DD/YYYY'), id: idRecord}),
        key:'endDiscount',
        loadingMessage: 'Actualizando Descuento',
        cb: ({status}) => {
          if(status === 200){
            const newData = [...discountAE];
            const indexb = newData.findIndex(b => idBrch === b.id);
            const indexd = newData[indexb].descuentos.findIndex(d => idRecord === d.id)
            newData[indexb].descuentos[indexd].fechaFin = moment(row.fechaFin).startOf('day').format('MM/DD/YYYY');
            newData.splice(indexb, 1, { ...newData[indexb] });
            setDiscountAE(newData);
          }
        }
      });
      setEditingKey('');
    } catch (errInfo) {
      throw errInfo;
    }
  };

  const expandedRowRender = ({descuentos, id}) => {
    const columns: ColumnsType<any> | any = [
      { 
        title: 'Descuento', 
        dataIndex: 'porcentajeDescuento', 
        align: 'center', 
        key: 'porcentajeDescuento', 
        render: (descuento) => `${round2(descuento* 100)}%`
      },  
      {
        title: "Fecha de inicio",
        dataIndex: "fechaInicio",
        key: "fechaInicio",
        ...getColumnSearchProps("fechaInicio"),
        render: (text) => moment(text).utcOffset('+0400').format('DD-MM-YYYY')
      },
      {
        title: 'Fecha Fin',
        dataIndex: 'fechaFin',
        key: 'fechaFin',
        editable:true,
        ...getColumnSearchProps('fechaFin'),
        render: (text) => text ? moment(text).utcOffset('+0400').format('DD-MM-YYYY') : 'No definida',
      },
      {
        key: "action",
        render: (text, record) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Tooltip placement="bottom" title="Confirmar">
                <Button type="link" icon={<CheckOutlined />} onClick={() => save(id,record.id)} />
              </Tooltip>
              <Tooltip placement="bottom" title="Cancelar">
                <Button type="link" icon={<CloseOutlined />} onClick={() => setEditingKey('')} />
              </Tooltip>                        
            </span>
          ) : (
              <span>
              <Tooltip placement="bottom" title="Editar">
                <Button type="link" icon={<EditOutlined />} onClick={() => edit(record)} />
              </Tooltip>
            </span>
          );
        }
      }
    ];

    const components = {
      body: {
        cell: EditableCell,
      }
    };

    const mergedColumns = columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: 'date',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record)
        })
      };
    });

    return (<Form form={editForm} initialValues={{from: moment()}}>
      <Table size='middle' style={{ margin: '4px 0 4px 83px' }} key={id} rowKey={({id}) => id} components={components} columns={mergedColumns} dataSource={descuentos} pagination={false} />
    </Form>);
  };


  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined onClick={() => {}} style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };
  
  
  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const columsBranch : ColumnsType<actividades> | any = [
    {
      title: "Ramo",
      dataIndex: "descripcion",
      key: "descripcion",
    }
  ] 

  const firstListItem = (fields, add) => {
    if(fields.length === 0) {
      setTimeout(() => add(), 10);
    }
  }

  return (
    <>
      {contribuyente ? <>
        <Typography.Text strong style={{fontSize: 16, marginBottom: 5}}>Contribuyente</Typography.Text>
        <Descriptions title='' >
          <Descriptions.Item label='Documento'>{`${contribuyente.tipoDocumento}-${contribuyente.documento}`}</Descriptions.Item>
          <Descriptions.Item label='R.I.M.'>{contribuyente.referenciaMunicipal}</Descriptions.Item>
          <Descriptions.Item label="Razón social">{contribuyente.razonSocial}</Descriptions.Item>
          <Descriptions.Item label="Denominacion Comercial">{contribuyente.denominacionComercial}</Descriptions.Item>
        </Descriptions>
          <Row justify='space-between' gutter={[16,8]}>
            <Col><Typography.Text style={{fontSize: 19}} strong>Descuento por ramos</Typography.Text></Col>
            <Col><Button type='primary' onClick={()=>setVisible(true)} icon={<PlusCircleOutlined/>}>Agregar Descuento</Button></Col>
          </Row>
          <Table rowKey={({id})=> id} size='middle' columns={columsBranch} dataSource={discountAE} expandable={{expandedRowRender}}/>
        <Modal bodyStyle={{height: '70vh', overflowY: 'scroll'}} centered onOk={onSubmitDiscount} width={700} okText='Finalizar' confirmLoading={loading} visible={visible} onCancel={()=>setVisible(false)} title='Nuevo Descuento'>
          <Form initialValues={{ from: moment() }} form={form} layout='vertical'>
            <Row gutter={[16, 8]} align='middle'>
              <Col span={24}>
                <Form.Item label='Fecha inicio' name='from' rules={[{required: true, message: 'Debe seleccionar una fecha de inicio.'}]}>
                  <DatePicker format={'DD-MM-YYYY'} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Divider style={{ marginLeft: -15 }} orientation='left'>Ramos</Divider>
                {<Form.List name='ramos'>
                  {(fields, { add, remove }) => (
                    <div>
                      {firstListItem(fields, add)}
                      <Row gutter={[16,8]}> 
                        {fields.map((field, index) => (
                          <React.Fragment key={index}>
                            <Col xs={24} xl={20}>
                              <Form.Item label='Ramo' name={[field.name, 'ramo']} rules={[{required:true, message: 'Debe seleccionar un ramo...'}]}>
                                <Select style={{ width: '100%' }} placeholder='Seleccione Ramo...'>
                                  {brch.branches.map((b) => allowedBranch.includes(b.id) ? (<Select.Option key={b.id} value={b.id} title={b.descripcion}>{b.descripcion}</Select.Option>) : null)}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} xl={4}>
                              <Form.Item name={[field.name, 'porcDescuento']} label='Descuento' rules={[{ required: true, message: 'Por favor ingrese un valor de descuento' }]}>
                                <InputNumber style={{width: '100%'}} min={0} max={100} step={1} />
                              </Form.Item>
                            </Col>
                            <Divider/>
                          </React.Fragment>
                        ))}
                      </Row>
                      <Row gutter={[16, 8]} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Col><Button type='dashed' onClick={() => add()}>Agregar ramo</Button></Col>
                        {fields.length > 1 && <Col><Button type='danger' onClick={() => remove(fields.length - 1)}>Eliminar ramo</Button></Col>}
                      </Row>
                    </div>
                  )}
                </Form.List>}
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
      : <SearchTaxPayer searchAction={fetchDiscountTaxPayer} setData={setDiscount} />
      }
    </>
  );  
};

const mapStateToProps = ({ brch }: State) => ({ brch });

export default connect(mapStateToProps)(DiscountTaxPayer);

interface DiscountTaxPayerProps {
  brch: States.Branches;
}

interface contribuyenteProps {
  id: number;
  documento: string;
  tipoDocumento: string;
  razonSocial: string;
  referenciaMunicipal: string;
  denominacionComercial: string;
  ramos: actividades[];
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: Item;
}

interface actividades {
  key: number;
  id: number;
  numeroReferencia?: number;
  descripcion: string;
  descuentos: { 
    id:number; 
    fechaInicio: string; 
    fechaFin?: string; 
    porcentajeDescuento: number;
  }[];
}
