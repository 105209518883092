import React, { CSSProperties } from 'react';

const initialStyle: CSSProperties = {
  width: 15,
  height: 15, 
  objectFit: 'contain'
};

const Euro: React.FC<EuroProps> = ({ style = {}, ...props }) => {
  const _styles = {
    ...initialStyle,
    ...style
  };

  return (
    <img src={require('../../assets/images/euro.png')} style={_styles} alt='Euro' {...props} />
  );
};

export default Euro;

interface EuroProps {
  style?: CSSProperties
}