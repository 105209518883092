import { RouteI, Institucion, User } from 'sigt';
import TableOfficials from './components/Tables/TableOfficial';
import TableCosts from './components/Tables/TableCost';
import ValidatePayments from './components/ValidatePayments';
import TableProcedure from './components/Tables/TableProcedure';
import Icon, {
  TeamOutlined,
  DollarOutlined,
  InboxOutlined,
  FolderOpenFilled,
  AlertOutlined,
  CreditCardFilled,
  FileAddOutlined,
  UserAddOutlined,
  FolderAddOutlined,
  BarChartOutlined,
  ReconciliationOutlined,
  ScheduleOutlined,
  UserOutlined,
  HomeOutlined,
  FireOutlined,
  PercentageOutlined,
  ImportOutlined,
  SwapOutlined,
  FileOutlined,
  ExceptionOutlined,
  BankOutlined,
  AppstoreAddOutlined,
  AccountBookOutlined,
  FolderViewOutlined,
  ContainerOutlined,
  CarOutlined,
  HomeFilled,
  CreditCardOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import AffairsForm from './components/Forms/AffairsForm';
import TableOrdinance from './components/Tables/TableOrdinance';
import TableFiscalValue from './components/Tables/TableFiscalValues';
import TableDestination from './components/Tables/TableDestination';
import OfficialPFForm from './components/Forms/PassangerFee/OfficialPFForm';
import PoliceTicketForm from './components/Forms/Ticket/PoliceTicketForm';
import IMATicketForm from './components/Forms/Ticket/IMATicketForm';
import TableBankCalendar from './components/Tables/TableBankCalendar';
import DeclareAnyTaxes from './components/Taxes/DeclareAnyTaxes';
import PendingRequests from './components/Taxes/PendingRequests';
import BenefitTribTaxes from './components/Taxes/BenefitTrib';
import AgreementTaxPayer from './components/Taxes/BenefitTrib/AgreementTaxPayer';
import InternalRegister from './components/Taxes/Internal/InternalRegister';
import InternalLicense from './components/Taxes/Internal/InternalLicense';
import TaxPayerUrbanEstates from './components/UrbanEstates/TaxPayerUrbanEstates';
import RegisterProperty from './components/UrbanEstates/RegisterProperty';
import ConsultTaxpayer from './components/Taxpayer/ConsultTaxpayer';
import InternalLinking from './components/Taxes/Internal/InternalLinking';
import InternalImport from './components/Taxes/Internal/InternalImport';
import TaxpayerServices from './components/MunicipalServices/TaxpayerServices';
import ActivityChange from './components/Taxes/ActivityChange';
import Fixes from './components/Taxes/Fixes/Fixes';
import Retentions from './components/Taxes/Retentions';
import Agents from './components/Taxes/Agents';
import TableReceipt from './components/Tables/TableReceipt';
import SpecialSettlements from './components/Taxes/Internal/SpecialSettlements';
import AmendSettlements from './components/Taxes/Amendment/AmendSettlements';
import AmendPayments from './components/Taxes/Amendment/AmendPayments';
import TableEditUser from './components/Tables/TableEditUser';
import SMSettlements from './components/Taxes/SMSettlements';
import TableSupport from './components/Tables/TableSupport';
import InternalPayments from './components/Taxes/Internal/InternalPayments';
import SubstituteStatement from './components/Taxes/SubstituteStatement';
import SupplementaryStatement from './components/Taxes/SupplementaryStatement';
import TableRetentions from './components/Tables/TableRetentions';
import TableCharge from './components/Tables/TableCharge';
import TableInspection from './components/Tables/TableInspection';
import Wallets from './components/Charges/Wallets';
import Scale from './components/Scale';
import CertificadeForm from './components/Forms/CertificadeForm';
import CertificadeView from './components/Forms/CertificadeView';
import TableActivities from './components/Tables/TableActivities';
import RegisterCodominium from './components/Condominiums/RegisterCodominium';
import TableCPUPayments from './components/Tables/TableCPUPayments';
import ConsultPenalty from './components/ConsultPenalty';
import LiqueurForm from './components/Forms/LiqueurForm';
import ConsultProperty from './components/UrbanEstates/ConsultProperty';
import BankStatements from './components/BankStatements';
import SolvA from './components/Taxes/SolvA';
import SolvB from './components/Taxes/SolvB';
import InternalBranchRegister from './components/Taxes/Internal/InternalBranchRegister';
import GetVehicleInfo from './components/Taxes/Vehicles/GetVehicleInfo';
import CreateCodCat from './components/UrbanEstates/CreateCodCat';
import GetVehicleByPlate from './components/Taxes/Vehicles/GetVehicleByPlate';
import PendingPayFine from './components/PendingPayFine';
import PayTaxes from './components/Taxes/PayTaxes';
import AmendFine from './components/Taxes/Amendment/AmendFine';
import ExternalInfo from './components/Taxpayer/ExternalInfo';
import InfoVehicle from './components/Taxes/Vehicles/InfoVehicle';
import GetTaxpayerInfo from './components/Taxpayer/components/GetTaxpayerInfo';
import GetFineInfo from './components/Taxpayer/components/GetFineInfo';
import ConsultFines from './components/ConsultFines';

enum UserType {
  SuperUser = 1,
  OfficialAdmin,
  Official,
  External,
  OfficialChief,
}

export enum CargoCpu {
  Administrador = 5,
  Funcionario,
  DirectorOMPU = 17,
  DirectorOMCAT,
  DirectorOMTU,
  AnalistaValuacionFiscal = 46,
  JefeValuacionFiscal,
  AnalistaCodigoCatastral = 93,
  JefeCodigoCatastral,
  AnalistaNomenclatura,
  JefeNomenclatura,
}

enum CargoSedemat {
  Cajero = 22,
  AnalistaPoliticasTributarias, // Analista de Actividad Economica
  Administrador, // Director de hacienda, Asistente de Dirección y Planificación
  JefeDeCaja,
  GerenteCobranzas, // Secretaria de Dirección
  GerentePoliticasTributarias,
  JefeServiciosMunicipales,
  JefeInmueblesUrbanos,
  JefeActividadesEconomicas,
  JefeAuditorias,
  JefeContribuyentesEspeciales,
  AnalistaCobranzas, //Analista de Aseo
  Validador,
  GerenteGeneral, // Encargado de Tecnología x2
  JefeDeCuadra, // Jefe de División de renta
  GerenteAdministracion, // Auditor de actividades económicas - Jefe de division de actividades economicas
  Soporte, // soporte técnico
  JefeFiscalizacion, // Fiscalizacion
  CajeroIntegral, //taquillero
  CajeroE, // Analista, Liquidador
  GerenteRecaudacion,
  AnalistaLicores = 44,
  GerenteLicores, // Jefe de oficinas de Licores !!PENDIENTE POR TERMINAR
  Auditor = 48, // Espectaculos publicos, Jefe de Publicidad, coordinadora de Publicidad
  JefeDeTransacciones, // Coordinador de taquilla
  AnalistaCondominios = 53,
  JefeDeZona, // Sub tesorero
  DirectorUrbanismo = 55,
  GerenteGeneralA,
  JefeContaduria = 89, // tesorero
  Master,
  ConsultorLiquidaciones,
  analistaLiquidacionesEspeciales = 97,
  analistaInmueblesUrbanos, // Inmueble urbano
  analistaConsultorInmuebles,
  analistaIngenieria = 100, // Ingeniería liquidaciones especiales
  analistaSolvencias = 101, // Solvencias liquidaciones especiales
  cobranza,
  analistaCatastro = 103,
  Fiscal,
  analistaOmpu = 106,
  analistaAmbiente = 107,
  Policia,
}

export enum CargoPolicia {
  Administrador = 13, // Master
  Funcionario,
  Director = 50,
  CajeroPolicia = 109,
  Soporte = 122,
  AnalistaSoporte = 124,
}

const canSupport = [
  CargoSedemat.Administrador,
  CargoSedemat.Master,
  CargoSedemat.Soporte,
  CargoSedemat.GerenteCobranzas,
  CargoSedemat.GerentePoliticasTributarias,
  CargoSedemat.GerenteRecaudacion,
  CargoSedemat.CajeroE,
  CargoSedemat.CajeroIntegral,
  CargoSedemat.JefeDeCaja,
  CargoSedemat.JefeContaduria,
  CargoSedemat.JefeDeCuadra,
  CargoSedemat.AnalistaLicores,
  CargoSedemat.analistaLiquidacionesEspeciales,
  CargoSedemat.Policia,
  CargoCpu.JefeCodigoCatastral,
  CargoCpu.JefeValuacionFiscal,
  CargoCpu.JefeNomenclatura,
];

export const getRoutes = (user: User, institutions: Institucion[]): RouteI[] => {
  const routes: RouteI[] = [];
  const moneySubmenu: any[] = [
    {
      path: 'finanzas/validacionDePagos',
      exact: true,
      title: 'Validación de Pagos',
      component: ValidatePayments,
    },
    {
      path: 'estadoDeCuentas',
      exact: true,
      title: 'Estado de Cuentas',
      component: BankStatements,
    },
  ];

  if ((user.tipoUsuario === UserType.OfficialAdmin && user.institucion?.id !== 0) || user.tipoUsuario === UserType.SuperUser) {
    if (UserType.OfficialAdmin === user.tipoUsuario) {
      if (user.institucion.nombreCorto !== 'SEDETEMA') {
        moneySubmenu.push({
          path: 'finanzas/costosDeTramites',
          exact: true,
          title: 'Costos de Tramites',
          component: TableCosts,
        });
      }

      if (user.institucion.nombreCorto === 'HACIENDA') {
        moneySubmenu.push({
          path: 'fechaBancaria',
          exact: false,
          title: 'Fechas Bancarias',
          component: TableBankCalendar,
        });
        moneySubmenu.push({
          path: 'finanzas/alicuotas',
          exact: true,
          title: 'Administrar Alicuotas',
          component: TableActivities,
        });
      }

      // if (user.institucion.nombreCorto === 'CPU') {
      //   moneySubmenu.push({
      //     path: 'finanzas/valoresFiscales',
      //     exact: true,
      //     title: 'Valores Fiscales',
      //     component: TableFiscalValue,
      //   });
      // }
    }

    if ((user.tipoUsuario === UserType.OfficialAdmin && user.institucion.id === 9) || user.institucion?.cargo?.id === 35)
      moneySubmenu.push({
        path: 'finanzas/baremo',
        exact: false,
        title: 'Baremo de Tarifas',
        component: Scale,
      });

    if (user.tipoUsuario === UserType.OfficialAdmin && user.institucion.id !== 3 && user.institucion.id !== 7)
      routes.push({
        path: 'finanzas',
        exact: true,
        icon: DollarOutlined,
        title: 'Finanzas',
        subMenu: moneySubmenu,
      });

    if (
      UserType.OfficialAdmin === user.tipoUsuario &&
      user.institucion.nombreCorto !== 'SEDETEMA' &&
      user.institucion.nombreCorto !== 'CPU' &&
      user.institucion.nombreCorto !== 'PMM'
    )
      moneySubmenu.push({
        path: 'ordenanzas',
        exact: false,
        title: 'Ordenanzas',
        component: TableOrdinance,
      });

    if (UserType.OfficialAdmin === user.tipoUsuario && user.institucion.nombreCorto === 'SEDETEMA')
      moneySubmenu.push({
        path: 'destinos',
        exact: false,
        title: 'Tasas de Destinos',
        component: TableDestination,
      });
  }

  if (user.institucion?.nombreCorto === 'PMM') {
    const correccionPagos = {
      path: 'correcciones/pagos',
      exact: true,
      title: 'Corrección de Pagos',
      component: AmendPayments,
    };

    const validarPago = {
      path: 'finanzas/validacionDePagos',
      exact: true,
      title: 'Validación de Pagos',
      component: ValidatePayments,
    };

    const consultaImpuestoMulta = {
      path: 'consulta/impuesto/multas',
      exact: true,
      title: 'Consultar Impuesto de Vehiculos y Multas',
      icon: FileSearchOutlined,
      component: ConsultPenalty,
    };

    const pagoMultaPolicia = {
      path: 'multas/pendientes',
      exact: false,
      title: 'Pago Multa Policía',
      component: PendingPayFine,
    };

    const liquidacionesEspeciales = {
      path: ['impuestos/especiales', 'impuestos/especiales/:id'],
      exact: true,
      title: 'Liquidaciones de Tasas e Impuestos',
      component: SpecialSettlements,
    };

    const liquidacionesPendientes = {
      path: 'impuestos/pendientes',
      exact: false,
      title: 'Liquidaciones Pendientes',
      component: PendingRequests,
    };

    const cierreDeCaja = {
      path: 'reportes/5',
      exact: false,
      title: 'Cierre de Caja',
    };

    const cierreTodasLasCaja = {
      path: 'reportes/6',
      exact: false,
      title: 'Cierre de Todas las Cajas',
    };

    const reporteMultaPorFuncionario = {
      path: 'reportes/19',
      exact: false,
      title: 'Multa por funcionario',
    };

    const reporteMultaPorFuncionarioConInfraccion = {
      path: 'reportes/20',
      exact: false,
      title: 'Multa por funcionario con infracciones',
    };

    const reporteMultaPorParroquia = {
      path: 'reportes/21',
      exact: false,
      title: 'Multa por parroquia',
    };

    const reporteMultaPorInfraccion = {
      path: 'reportes/22',
      exact: false,
      title: 'Multa por infracción',
    };

    const reporteMultaRecursosRecaudo = {
      path: 'reportes/23',
      exact: false,
      title: 'Recursos Recaudados',
    };

    const reporteMultaRPR = {
      path: 'reportes/24',
      exact: false,
      title: 'RPR Multa',
    };

    const correccionMultas = {
      path: ['correcciones/multas', 'correcciones/multas/:id'],
      exact: true,
      title: 'Corrección de Multas',
      component: AmendFine,
    };

    const consultaContribuyente = {
      path: 'contribuyente/consulta',
      exact: true,
      title: 'Consulta de Contribuyente',
      icon: UserOutlined,
      component: ConsultTaxpayer,
    };

    const subMenuReportes: RouteI[] = [];

    const reportes = {
      path: 'reportes',
      exact: true,
      title: 'Reportes',
      icon: BarChartOutlined,
      subMenu: subMenuReportes,
    };

    const menuSoporte = {
      path: 'soporte/inbox',
      exact: true,
      icon: ExceptionOutlined,
      title: 'Bandeja de Soporte',
      component: TableSupport,
    };

    const administrarUsuario = {
      path: 'funcionarios',
      exact: false,
      icon: TeamOutlined,
      title: 'Administrar Usuarios',
      component: TableOfficials,
    };

    const infoVehiculo = {
      path: 'vehiculo',
      exact: false,
      title: `Consultar vehiculo`,
      component: InfoVehicle,
    };

    const consultarMultas = {
      path: 'consulta/multa',
      exact: true,
      title: `Consultar multas`,
      component: ConsultFines,
    };

    switch (user.institucion.cargo.id) {
      case CargoPolicia.Administrador: {
        subMenuReportes.push(
          cierreTodasLasCaja,
          reporteMultaPorFuncionario,
          reporteMultaPorFuncionarioConInfraccion,
          reporteMultaPorParroquia,
          reporteMultaPorInfraccion,
          reporteMultaRecursosRecaudo,
          reporteMultaRPR
        );
        routes.push(
          consultaContribuyente,
          reportes,
          consultaImpuestoMulta,
          consultarMultas,
          correccionMultas,
          correccionPagos,
          liquidacionesEspeciales,
          liquidacionesPendientes,
          infoVehiculo,
          menuSoporte
        );
        break;
      }
      case CargoPolicia.Director: {
        subMenuReportes.push(
          cierreTodasLasCaja,
          reporteMultaPorFuncionario,
          reporteMultaPorFuncionarioConInfraccion,
          reporteMultaPorParroquia,
          reporteMultaPorInfraccion,
          reporteMultaRecursosRecaudo,
          reporteMultaRPR
        );
        routes.push(
          administrarUsuario,
          consultaContribuyente,
          reportes,
          consultaImpuestoMulta,
          consultarMultas,
          correccionMultas,
          correccionPagos,
          liquidacionesEspeciales,
          liquidacionesPendientes,
          infoVehiculo,
          menuSoporte
        );
        break;
      }
      case CargoPolicia.CajeroPolicia:
        routes.push(
          consultaContribuyente,
          pagoMultaPolicia,
          liquidacionesEspeciales,
          liquidacionesPendientes,
          cierreDeCaja,
          menuSoporte
        );
        break;
      case CargoPolicia.Funcionario:
        routes.push(consultaImpuestoMulta, consultaContribuyente, menuSoporte);
        break;
      case CargoPolicia.Soporte:
        subMenuReportes.push(
          cierreTodasLasCaja,
          reporteMultaPorFuncionario,
          reporteMultaPorFuncionarioConInfraccion,
          reporteMultaPorParroquia,
          reporteMultaPorInfraccion,
          reporteMultaRecursosRecaudo
        );
        routes.push(
          administrarUsuario,
          reportes,
          consultaContribuyente,
          consultaImpuestoMulta,
          consultarMultas,
          correccionMultas,
          liquidacionesPendientes,
          liquidacionesEspeciales,
          menuSoporte
        );
        break;
      case CargoPolicia.AnalistaSoporte:
        subMenuReportes.push(
          cierreTodasLasCaja,
          reporteMultaPorFuncionario,
          reporteMultaPorFuncionarioConInfraccion,
          reporteMultaPorParroquia,
          reporteMultaPorInfraccion,
          reporteMultaRecursosRecaudo
        );
        routes.push(
          reportes,
          consultaContribuyente,
          consultaImpuestoMulta,
          consultarMultas,
          correccionMultas,
          liquidacionesPendientes,
          liquidacionesEspeciales,
          menuSoporte
        );
        break;
    }
  }

  //MENU DE RUTAS SEDEMAT
  if (user.institucion?.nombreCorto === 'HACIENDA') {
    const havePrcd = [
      CargoSedemat.GerentePoliticasTributarias,
      CargoSedemat.GerenteGeneral,
      CargoSedemat.AnalistaPoliticasTributarias,
      // CargoSedemat.AnalistaLicores,
      CargoSedemat.GerenteLicores,
      CargoSedemat.GerenteRecaudacion,
      CargoSedemat.analistaLiquidacionesEspeciales,
      CargoSedemat.analistaInmueblesUrbanos,
      CargoSedemat.JefeDeCuadra,
      CargoSedemat.GerenteAdministracion,
    ];

    const subMenuCorrecciones: RouteI[] = [];

    const declararVehiculos = {
      path: 'impuestos/vehiculosInfo',
      exact: false,
      title: `Tramitar Vehiculos`,
      component: GetVehicleInfo,
    };

    const checkVehiculos = {
      path: 'impuestos/vehiculosPlaca',
      exact: false,
      title: `Vehiculos Por Placa`,
      component: GetVehicleByPlate,
    };

    const correccionPagos = {
      path: 'correcciones/pagos',
      exact: true,
      title: 'Corrección de Pagos',
      component: AmendPayments,
    };
    const menuSoporte = {
      path: 'soporte/inbox',
      exact: true,
      icon: ExceptionOutlined,
      title: 'Bandeja de Soporte',
      component: TableSupport,
    };
    const correccionUsuario = {
      path: 'correcciones/editar/usuario',
      exact: true,
      title: 'Editar Usuario de S.U.T.',
      component: TableEditUser,
    };
    const correcionContribuyente = {
      path: 'correcciones/editar/contribuyente',
      exact: true,
      title: 'Editar Contribuyente',
      component: ConsultTaxpayer,
    };
    const correccionLiquidaciones = {
      path: ['correcciones/liquidaciones', 'correcciones/liquidaciones/:id'],
      exact: true,
      title: 'Corrección de Liquidaciones',
      component: AmendSettlements,
    };

    const correcionesAdministrativas = {
      path: 'correcciones',
      exact: true,
      icon: SwapOutlined,
      title: 'Correcciones Administrativas',
      subMenu: subMenuCorrecciones,
    };

    const asignarCarteras = {
      path: 'cobranzasFiscalizacion/carteras',
      exact: true,
      title: 'Carteras de Cobranzas',
      component: Wallets,
    };

    const cobranzas = {
      path: 'cobranzasFiscalizacion/cobranzas',
      exact: true,
      title: 'Cobranzas',
      component: TableCharge,
    };

    const fiscalizacion = {
      path: 'cobranzasFiscalizacion/fiscalizacion',
      exact: true,
      title: 'Fiscalización',
      component: TableInspection,
    };

    const subMenuCobranzas: RouteI[] = [];

    const cobranzasFiscalizacion = {
      path: 'cobranzasFiscalizacion',
      exact: true,
      title:
        user.institucion.cargo.id === CargoSedemat.Administrador || user.institucion.cargo.id === CargoSedemat.Master
          ? 'Cobranzas y Fiscalización'
          : user.institucion.cargo.id !== CargoSedemat.JefeFiscalizacion
          ? 'Cobranzas'
          : 'Fiscalización',
      icon: AccountBookOutlined,
      subMenu: subMenuCobranzas,
    };

    const subMenuReporteCaja = [
      {
        path: 'reportes/5',
        exact: false,
        title: 'Cierre de Caja',
      },
      {
        path: 'reportes/recibos',
        exact: false,
        title: 'Historico de Recibos',
        component: TableReceipt,
      },
    ];

    const subMenuReporteCajaMod = [
      {
        path: 'reportes/recibos',
        exact: false,
        title: 'Historico de Recibos',
        component: TableReceipt,
      },
    ];

    const reporteInternoAcumulado = {
      path: 'reportes/1',
      exact: false,
      title: 'Interno de Recaudación Acumulada',
    };

    const reporteAcumuladoAlcaldia = {
      path: 'reportes/2',
      exact: false,
      title: 'Acumulado para Alcaldía',
    };

    const reportePlanillasVigentes = {
      path: 'reportes/3',
      exact: false,
      title: 'Planillas Vigentes y Pagadas',
    };

    const reporteIVA = {
      path: 'reportes/4',
      exact: false,
      title: 'Reporte de I.V.A',
    };

    const reportIngresoTransf = {
      path: 'reportes/8',
      exact: false,
      title: 'Ingreso por transferencias',
    };

    const reporteCondo = {
      path: 'reportes/9',
      exact: false,
      title: 'Reporte Ingreso por Condominios',
    };

    const reporteTransfBanco = {
      path: 'reportes/10',
      exact: false,
      title: 'Ingreso por transferencias por banco',
    };

    const reporteCondoDisclosed = {
      path: 'reportes/11',
      exact: false,
      title: 'Reporte de Condominios por Liquidaciones',
    };

    const reportRMP = {
      path: 'reportes/13',
      exact: false,
      title: 'Reporte Recaudado e Ingresado por MP',
    };

    const reportRID = {
      path: 'reportes/14',
      exact: false,
      title: 'Reporte Ingresado Detallado',
    };

    const reportTransfExternal = {
      path: 'reportes/15',
      exact: false,
      title: 'Reporte Transferencias por Externos',
    };

    const reportIDR = {
      path: 'reportes/16',
      exact: false,
      title: 'Reporte IDR',
    };

    const reportAttenPendTickets = {
      path: 'reportes/17',
      exact: false,
      title: 'Reporte Tickets Atendidos y Pendientes',
    };

    const subMenuReportes: RouteI[] = [];

    const reportes = {
      path: 'reportes',
      exact: true,
      title: 'Reportes',
      icon: BarChartOutlined,
      subMenu: subMenuReportes,
    };

    const subMenuProcesosDirectivos: RouteI[] = [];

    const beneficiosTributables = {
      path: 'beneficiosTributables',
      exact: true,
      title: 'Beneficios Fiscales',
      component: BenefitTribTaxes,
    };

    const registroSucursal = {
      path: 'contribuyente/registroSucursal',
      exact: true,
      title: 'Registro Sucursal',
      component: InternalBranchRegister,
    };

    const cambioAE = {
      path: 'contribuyente/cambioLicencia',
      exact: true,
      title: 'Cambio de Actividades Económicas',
      component: ActivityChange,
    };

    const reparos = {
      path: 'reparos',
      exact: true,
      title: 'Liquidar Reparos',
      component: Fixes,
    };

    const agentesRetencion = {
      path: 'agentes',
      exact: true,
      title: 'Agentes de Retención',
      component: Agents,
    };

    const procesosDirectivos = {
      path: 'procesosDirectivos',
      exact: false,
      icon: ReconciliationOutlined,
      title: 'Procesos Directivos',
      subMenu: subMenuProcesosDirectivos,
    };

    const liquidarImpuestos = {
      path: 'impuestos/declarar',
      exact: false,
      title: user.tipoUsuario === 3 ? 'Declarar Impuestos' : 'Liquidar Impuestos',
      component: DeclareAnyTaxes,
    };

    const liquidarRetenciones = {
      path: 'impuestos/retenciones',
      exact: false,
      title: 'Declarar Retenciones',
      component: Retentions,
    };

    const pagosCpu = {
      path: 'tramites/pagosCpu',
      exact: true,
      title: 'Trámites CPU por pagar',
      component: TableCPUPayments,
      icon: CreditCardOutlined,
    };

    const subMenuImpuestos: RouteI[] = [];

    const liquidacionesEspeciales = {
      path: ['impuestos/especiales', 'impuestos/especiales/:id'],
      exact: true,
      title: 'Liquidaciones de Tasas e Impuestos',
      component: SpecialSettlements,
    };

    const liquidacionSustitiva = {
      path: 'impuestos/sustitutiva',
      exact: true,
      title: 'Declaración Sustitutiva',
      component: SubstituteStatement,
    };

    const liquidacionComplementaria = {
      path: 'impuestos/complementaria',
      exact: true,
      title: 'Declaración Complementaria',
      component: SupplementaryStatement,
    };

    const consultaDePagos = {
      path: 'pagos/consulta',
      exact: true,
      title: 'Consulta de Pagos',
      component: InternalPayments,
    };

    const consultaDeRetenciones = {
      path: 'retenciones/consulta',
      exact: true,
      title: 'Consulta de Retenciones',
      component: TableRetentions,
    };

    const liquidacionesPendientes = {
      path: 'impuestos/pendientes',
      exact: false,
      title: 'Liquidaciones Pendientes',
      component: PendingRequests,
    };

    const pagoConvenios = {
      path: 'impuestos/convenios',
      exact: false,
      title: 'Convenios',
      component: AgreementTaxPayer,
    };

    const impuestos = {
      path: 'impuestos',
      exact: true,
      title: 'Liquidación de Impuestos',
      icon: FileAddOutlined,
      subMenu: subMenuImpuestos,
    };

    const SolvenciaA = {
      path: 'solvenciaA',
      exact: true,
      title: 'Solvencia A',
      component: SolvA,
    };

    const SolvenciaB = {
      path: 'solvenciaB',
      exact: true,
      title: 'Solvencia B',
      component: SolvB,
    };

    const certificados = {
      path: 'certificado',
      exact: true,
      title: 'Generar Certificados',
      icon: ContainerOutlined,
      subMenu: [
        {
          path: 'certificado/SOLA',
          exact: true,
          title: 'Solvencia A',
          component: CertificadeView,
        },
        {
          path: 'certificado/SM',
          exact: true,
          title: 'Servicios Municipales',
          component: CertificadeView,
        },
        {
          path: 'certificado/IU',
          exact: true,
          title: 'Inmueble Urbano',
          component: CertificadeView,
        },
        {
          path: 'certificado/EL/generar',
          exact: true,
          title: 'Licencia de Licores',
          component: CertificadeForm,
        },
      ],
    };

    const subMenuInmuebles: RouteI[] = [
      {
        path: 'inmueblesUrbanos/contribuyente',
        exact: true,
        title: 'Inmuebles por Contribuyente',
        component: TaxPayerUrbanEstates,
      },
    ];

    const inmuebles = {
      path: 'inmueblesUrbanos',
      exact: true,
      icon: HomeOutlined,
      title: 'Inmuebles Urbanos',
      subMenu: subMenuInmuebles,
    };

    const crearCodCat = {
      path: 'inmueblesUrbanos/codCat',
      exact: true,
      title: 'Crear Codigo Catastral',
      component: CreateCodCat,
    };

    const consultarInmueble = {
      path: 'inmueblesUrbanos/consultar',
      exact: false,
      title: 'Consultar Inmueble',
      component: ConsultProperty,
    };

    const registrarInmueble = {
      path: 'inmueblesUrbanos/registrar',
      exact: false,
      title: 'Registrar Inmueble',
      component: RegisterProperty,
    };

    const editarInmueble = {
      path: 'inmueblesUrbanos/editar',
      exact: false,
      title: 'Editar Inmueble',
      component: RegisterProperty,
    };

    const condominiums = {
      path: 'condominios',
      exact: true,
      icon: HomeFilled,
      title: 'Condominios',
      subMenu: [
        {
          path: 'condominios/registro',
          exact: true,
          title: 'Registro de Condominios',
          component: RegisterCodominium,
        },
      ],
    };

    const subMenuSM: RouteI[] = [
      {
        path: 'serviciosMunicipales/contribuyente',
        exact: true,
        title: 'Servicios Municipales por Contribuyente',
        component: TaxpayerServices,
      },
    ];

    const serviciosMunicipales = {
      path: 'serviciosMunicipales',
      exact: true,
      icon: FireOutlined,
      title: 'Servicios Municipales',
      subMenu: subMenuSM,
    };

    const nuevosContribuyentes = {
      path: 'contribuyente',
      exact: true,
      title: 'Nuevos Contribuyentes',
      icon: UserAddOutlined,
      subMenu: [
        {
          path: 'contribuyente/registro',
          exact: true,
          title: 'Registro de Contribuyente',
          component: InternalRegister,
        },
        {
          path: ['contribuyente/licencia', 'contribuyente/licencia/:id'],
          exact: true,
          title: 'Licencia de Actividades Económicas',
          component: InternalLicense,
        },
      ],
    };

    const licores = {
      path: '/licores',
      exact: true,
      title: 'Licencia de Licores',
      icon: FolderAddOutlined,
      subMenu: [
        {
          path: 'tramite/licores',
          exact: true,
          title: 'Solicitud de Licencia Licores',
          component: LiqueurForm,
        },
      ],
    };

    switch (user.institucion.cargo.id) {
      case CargoSedemat.Master:
        subMenuReportes.push(
          reporteInternoAcumulado,
          reporteAcumuladoAlcaldia,
          reportePlanillasVigentes,
          reporteIVA,
          reportIngresoTransf,
          reporteTransfBanco,
          reporteCondo,
          reporteCondoDisclosed,
          reportRMP,
          reportRID,
          reportTransfExternal,
          reportIDR,
          reportAttenPendTickets,
          ...subMenuReporteCaja,
          {
            path: 'reportes/6',
            exact: false,
            title: 'Cierre de Todas las Cajas',
            // component: Report,
          },
          {
            path: 'reportes/18',
            exact: false,
            title: 'Cierre de una Caja',
            // component: Report,
          }
        );
        subMenuCobranzas.push(asignarCarteras, fiscalizacion);
        subMenuProcesosDirectivos.push(beneficiosTributables, cambioAE, registroSucursal, agentesRetencion);
        subMenuImpuestos.push(
          liquidarImpuestos,
          liquidacionesEspeciales,
          reparos,
          liquidarRetenciones,
          liquidacionesPendientes,
          liquidacionComplementaria,
          liquidacionSustitiva,
          pagoConvenios,
          consultaDeRetenciones,
          consultaDePagos,
          SolvenciaA,
          SolvenciaB,
          declararVehiculos,
          checkVehiculos
        );
        subMenuCorrecciones.push(correccionLiquidaciones, correccionPagos, correccionUsuario, correcionContribuyente);
        subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble, crearCodCat);
        routes.push(
          procesosDirectivos,
          correcionesAdministrativas,
          cobranzasFiscalizacion,
          licores,
          inmuebles,
          condominiums,
          serviciosMunicipales,
          reportes,
          nuevosContribuyentes,
          impuestos,
          certificados
        );
        break;
      case CargoSedemat.Administrador:
        subMenuReportes.push(
          reporteInternoAcumulado,
          reporteAcumuladoAlcaldia,
          reportePlanillasVigentes,
          reporteIVA,
          reportIngresoTransf,
          reporteTransfBanco,
          reporteCondo,
          reportRMP,
          reportRID,
          reportTransfExternal,
          reportIDR
        );
        subMenuCobranzas.push(asignarCarteras, fiscalizacion);
        subMenuProcesosDirectivos.push(beneficiosTributables, cambioAE, agentesRetencion, registroSucursal);
        subMenuImpuestos.push(
          liquidarImpuestos,
          liquidacionesEspeciales,
          reparos,
          liquidarRetenciones,
          liquidacionesPendientes,
          liquidacionComplementaria,
          liquidacionSustitiva,
          pagoConvenios,
          consultaDeRetenciones,
          consultaDePagos,
          SolvenciaB,
          declararVehiculos
        );
        subMenuCorrecciones.push(correccionLiquidaciones, correccionPagos, correccionUsuario, correcionContribuyente);
        subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble);
        routes.push(
          procesosDirectivos,
          correcionesAdministrativas,
          cobranzasFiscalizacion,
          licores,
          inmuebles,
          condominiums,
          serviciosMunicipales,
          reportes,
          nuevosContribuyentes,
          impuestos,
          certificados
        );
        break;
      case CargoSedemat.GerenteGeneral:
        routes.push({
          path: 'funcionarios',
          exact: false,
          icon: TeamOutlined,
          title: 'Administrar Usuarios',
          component: TableOfficials,
        });
        subMenuReportes.push(
          // reporteInternoAcumulado,
          reporteAcumuladoAlcaldia,
          reportePlanillasVigentes,
          reporteIVA,
          reportIngresoTransf,
          reporteCondo
        );
        moneySubmenu.push({
          path: 'fechaBancaria',
          exact: false,
          title: 'Fechas Bancarias',
          component: TableBankCalendar,
        });
        subMenuCorrecciones.push(correccionLiquidaciones, correccionPagos, correccionUsuario, correcionContribuyente);
        subMenuProcesosDirectivos.push(beneficiosTributables, cambioAE, reparos, agentesRetencion);
        subMenuImpuestos.push(
          liquidarImpuestos,
          liquidacionesEspeciales,
          liquidarRetenciones,
          liquidacionesPendientes,
          pagoConvenios,
          consultaDeRetenciones,
          consultaDePagos
        );
        subMenuSM.push({
          path: 'serviciosMunicipales/tasas',
          exact: true,
          title: 'Baremo de tarifas',
          component: Scale,
        });
        subMenuInmuebles.push(registrarInmueble, editarInmueble);
        routes.push(
          {
            path: 'finanzas',
            exact: true,
            icon: DollarOutlined,
            title: 'Finanzas',
            subMenu: moneySubmenu,
          },
          procesosDirectivos,
          correcionesAdministrativas,
          inmuebles,
          licores,
          serviciosMunicipales,
          reportes,
          nuevosContribuyentes,
          impuestos
        );
        break;
      case CargoSedemat.GerenteGeneralA:
        subMenuReportes.push(
          // reporteInternoAcumulado,
          reporteAcumuladoAlcaldia,
          reportePlanillasVigentes,
          reporteIVA,
          reportIngresoTransf,
          reporteCondo
        );
        subMenuProcesosDirectivos.push(beneficiosTributables, cambioAE, reparos, agentesRetencion);
        subMenuImpuestos.push(
          liquidarImpuestos,
          liquidacionesEspeciales,
          liquidarRetenciones,
          liquidacionesPendientes,
          pagoConvenios,
          consultaDeRetenciones,
          consultaDePagos
        );
        routes.push(reportes, nuevosContribuyentes, impuestos);
        break;
      case CargoSedemat.GerenteAdministracion:
        // subMenuReportes.push(reporteInternoAcumulado);
        subMenuProcesosDirectivos.push(cambioAE, registroSucursal);
        subMenuCorrecciones.push(correccionUsuario, correcionContribuyente);
        subMenuImpuestos.push(
          liquidarImpuestos,
          liquidacionesEspeciales,
          reparos,
          liquidarRetenciones,
          liquidacionesPendientes,
          liquidacionComplementaria,
          liquidacionSustitiva,
          pagoConvenios,
          consultaDeRetenciones,
          consultaDePagos,
          declararVehiculos
          // SolvenciaA,
          // SolvenciaB
        );
        subMenuImpuestos.push(liquidacionesEspeciales);
        routes.push(
          nuevosContribuyentes,
          procesosDirectivos,
          correcionesAdministrativas,
          reportes,
          inmuebles,
          serviciosMunicipales,
          impuestos,
          menuSoporte
        );
        break;
      case CargoSedemat.GerenteCobranzas:
        subMenuProcesosDirectivos.push(beneficiosTributables, agentesRetencion, pagoConvenios);
        subMenuImpuestos.push(liquidacionesEspeciales);
        routes.push(procesosDirectivos, impuestos);
        break;
      case CargoSedemat.GerentePoliticasTributarias:
        subMenuCorrecciones.push(correccionUsuario, correcionContribuyente, cambioAE);
        subMenuProcesosDirectivos.push(beneficiosTributables);
        subMenuReportes.push(reporteAcumuladoAlcaldia, reportePlanillasVigentes, reporteIVA);
        subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble);
        subMenuImpuestos.push(
          liquidarImpuestos,
          reparos,
          liquidacionesEspeciales,
          liquidacionesPendientes,
          pagoConvenios,
          liquidacionComplementaria,
          liquidacionSustitiva
        );
        routes.push(
          correcionesAdministrativas,
          reportes,
          inmuebles,
          nuevosContribuyentes,
          impuestos,
          condominiums,
          procesosDirectivos
        );
        break;
      case CargoSedemat.GerenteRecaudacion:
        subMenuCorrecciones.push(correccionPagos);
        subMenuReportes.push(
          // reporteInternoAcumulado,
          reporteAcumuladoAlcaldia,
          reportePlanillasVigentes,
          reporteIVA,
          ...subMenuReporteCaja,
          {
            path: 'reportes/6',
            exact: false,
            title: 'Cierre de Todas las Cajas',
            // component: Report,
          }
        );
        subMenuImpuestos.push(
          liquidarImpuestos,
          liquidacionesPendientes,
          liquidacionesEspeciales,
          liquidacionComplementaria,
          liquidacionSustitiva,
          reparos,
          pagoConvenios,
          consultaDePagos
        );
        routes.push(reportes, correcionesAdministrativas, impuestos);
        break;
      case CargoSedemat.DirectorUrbanismo:
        subMenuReportes.push(
          // reporteInternoAcumulado,
          reporteAcumuladoAlcaldia,
          reportePlanillasVigentes,
          reporteIVA,
          reporteCondo,
          reporteCondoDisclosed
        );
        subMenuImpuestos.push(liquidacionesEspeciales, liquidacionesPendientes, pagoConvenios, consultaDePagos);
        routes.push(reportes, condominiums, nuevosContribuyentes, impuestos, menuSoporte);
        break;
      case CargoSedemat.JefeServiciosMunicipales:
        subMenuImpuestos.push(liquidarImpuestos, liquidacionComplementaria, liquidacionSustitiva);
        routes.push(serviciosMunicipales, impuestos);
        break;
      case CargoSedemat.JefeInmueblesUrbanos:
        subMenuImpuestos.push(liquidarImpuestos, liquidacionComplementaria, liquidacionSustitiva);
        routes.push(impuestos);
        break;
      case CargoSedemat.JefeActividadesEconomicas:
        subMenuCorrecciones.push(correccionUsuario, correcionContribuyente);
        subMenuImpuestos.push(
          liquidarImpuestos,
          reparos,
          liquidacionComplementaria,
          liquidacionSustitiva,
          liquidacionesPendientes,
          pagoConvenios
        );
        routes.push(correcionesAdministrativas, nuevosContribuyentes, impuestos);
        break;
      case CargoSedemat.JefeAuditorias:
        //nada
        break;
      case CargoSedemat.JefeContribuyentesEspeciales:
        subMenuProcesosDirectivos.push(agentesRetencion);
        subMenuImpuestos.push(
          liquidarImpuestos,
          liquidacionesPendientes,
          liquidacionesEspeciales,
          liquidarRetenciones,
          pagoConvenios
        );
        routes.push(procesosDirectivos, impuestos);
        break;
      case CargoSedemat.JefeDeTransacciones:
        subMenuReportes.push(
          // reporteInternoAcumulado,
          reporteAcumuladoAlcaldia,
          reportePlanillasVigentes,
          reporteIVA,
          reportIngresoTransf,
          reporteTransfBanco,
          reporteCondo,
          reporteCondoDisclosed
        );
        subMenuImpuestos.push(liquidacionComplementaria, liquidacionSustitiva, liquidacionesPendientes, consultaDePagos);
        routes.push(reportes, nuevosContribuyentes, impuestos);
        break;
      case CargoSedemat.JefeDeCaja:
        subMenuReporteCaja.push(
          {
            path: 'reportes/6',
            exact: false,
            title: 'Cierre de Todas las Cajas',
            // component: Report,
          },
          {
            path: 'reportes/18',
            exact: false,
            title: 'Cierre de una Caja',
            // component: Report,
          }
        );
        subMenuReportes.push(...subMenuReporteCaja, reportIngresoTransf, reportRMP);
        subMenuImpuestos.push(
          liquidarImpuestos,
          reparos,
          liquidacionesEspeciales,
          liquidacionesPendientes,
          liquidacionComplementaria,
          liquidacionSustitiva,
          pagoConvenios,
          consultaDePagos
        );
        routes.push(pagosCpu, reportes, impuestos);
        break;
      case CargoSedemat.JefeContaduria:
        subMenuReporteCaja.push({
          path: 'reportes/6',
          exact: false,
          title: 'Cierre de Todas las Cajas',
          // component: Report,
        });
        subMenuReportes.push(...subMenuReporteCaja, reportIngresoTransf, reporteTransfBanco, reportRMP, reportTransfExternal);
        subMenuCorrecciones.push(correccionPagos);
        routes.push(reportes, correcionesAdministrativas, {
          path: 'finanzas',
          exact: true,
          icon: DollarOutlined,
          title: 'Finanzas',
          subMenu: moneySubmenu,
        });
        break;
      case CargoSedemat.JefeDeCuadra:
        subMenuImpuestos.push(liquidacionesEspeciales);
        subMenuProcesosDirectivos.push(cambioAE);
        subMenuCobranzas.push(asignarCarteras, fiscalizacion);
        routes.push(
          nuevosContribuyentes,
          impuestos,
          serviciosMunicipales,
          procesosDirectivos,
          certificados,
          menuSoporte,
          cobranzasFiscalizacion
        );
        break;
      case CargoSedemat.Fiscal:
        subMenuImpuestos.push(liquidacionesEspeciales);
        subMenuCobranzas.push(asignarCarteras, fiscalizacion);
        routes.push(impuestos, menuSoporte, cobranzasFiscalizacion);
        break;
      case CargoSedemat.analistaOmpu:
        subMenuImpuestos.push(liquidacionesEspeciales);
        subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble, crearCodCat);
        routes.push(impuestos, inmuebles, menuSoporte);
        break;
      case CargoSedemat.analistaAmbiente:
        subMenuImpuestos.push(liquidacionesEspeciales);
        routes.push(impuestos, menuSoporte);
        break;
      case CargoSedemat.JefeFiscalizacion:
        subMenuCobranzas.push(asignarCarteras, fiscalizacion);
        routes.push(cobranzas);
        break;
      case CargoSedemat.ConsultorLiquidaciones:
        subMenuImpuestos.push(liquidacionesPendientes);
        routes.push(impuestos);
        break;
      case CargoSedemat.Cajero:
        subMenuReportes.push(...subMenuReporteCajaMod);
        subMenuImpuestos.push(liquidacionesPendientes, pagoConvenios, consultaDePagos);
        routes.push(pagosCpu, reportes, impuestos);
        break;
      case CargoSedemat.CajeroIntegral:
        subMenuReportes.push(...subMenuReporteCajaMod);
        subMenuImpuestos.push(
          liquidarImpuestos,
          liquidacionesPendientes,
          liquidacionesEspeciales,
          pagoConvenios,
          declararVehiculos,
          SolvenciaB
        );
        subMenuCorrecciones.push(correccionLiquidaciones, correcionContribuyente, correccionUsuario);
        routes.push(impuestos, reportes, correcionesAdministrativas, nuevosContribuyentes);
        break;
      case CargoSedemat.CajeroE:
        subMenuCorrecciones.push(correcionContribuyente, correccionUsuario);
        subMenuReportes.push(...subMenuReporteCajaMod);
        subMenuProcesosDirectivos.push(cambioAE);
        subMenuImpuestos.push(
          liquidarImpuestos,
          liquidacionesEspeciales,
          liquidacionesPendientes,
          consultaDePagos,
          pagoConvenios,
          declararVehiculos
        );
        routes.push(procesosDirectivos, reportes, correcionesAdministrativas, nuevosContribuyentes, impuestos, menuSoporte);
        break;
      case CargoSedemat.JefeDeZona:
        subMenuReportes.push(
          // reporteInternoAcumulado,
          reportIngresoTransf,
          reporteTransfBanco,
          reporteCondo,
          reporteCondoDisclosed,
          reportRMP
        );
        subMenuCorrecciones.push(correccionPagos);
        routes.push(reportes, correcionesAdministrativas);
        break;
      case CargoSedemat.AnalistaCondominios:
        subMenuReportes.push(...subMenuReporteCaja);
        subMenuImpuestos.push(liquidacionesEspeciales, liquidacionesPendientes, pagoConvenios, consultaDePagos);
        routes.push(reportes, condominiums, nuevosContribuyentes, impuestos);
        break;
      case CargoSedemat.analistaLiquidacionesEspeciales:
        subMenuImpuestos.push(
          liquidarImpuestos,
          liquidacionesEspeciales,
          reparos,
          liquidarRetenciones,
          liquidacionesPendientes,
          liquidacionComplementaria,
          liquidacionSustitiva,
          pagoConvenios,
          consultaDeRetenciones,
          consultaDePagos,
          SolvenciaA,
          SolvenciaB,
          declararVehiculos
        );
        subMenuInmuebles.push(consultarInmueble, registrarInmueble, editarInmueble);
        routes.push(impuestos, inmuebles);
        break;
      case CargoSedemat.analistaIngenieria:
        subMenuCorrecciones.push(correccionUsuario, correcionContribuyente);
        subMenuImpuestos.push(
          liquidarImpuestos,
          reparos,
          liquidacionComplementaria,
          liquidacionSustitiva,
          liquidacionesPendientes,
          liquidacionesEspeciales
        );
        routes.push(correcionesAdministrativas, nuevosContribuyentes, impuestos);
        break;
      case CargoSedemat.analistaSolvencias:
        subMenuCorrecciones.push(correccionUsuario, correcionContribuyente);
        subMenuImpuestos.push(
          // liquidarImpuestos,
          // reparos,
          // liquidacionComplementaria,
          // liquidacionSustitiva,
          liquidacionesPendientes,
          // liquidacionesEspeciales,
          SolvenciaA,
          SolvenciaB,
          declararVehiculos,
          pagoConvenios
        );
        routes.push(correcionesAdministrativas, nuevosContribuyentes, impuestos, inmuebles, {
          path: 'certificado',
          exact: true,
          title: 'Generar Certificados',
          icon: ContainerOutlined,
          subMenu: [
            {
              path: 'certificado/SOLA',
              exact: true,
              title: 'Solvencia A',
              component: CertificadeView,
            },
          ],
        });
        break;
      case CargoSedemat.AnalistaPoliticasTributarias:
        subMenuCorrecciones.push(correccionUsuario, correcionContribuyente);
        subMenuImpuestos.push(
          liquidarImpuestos,
          reparos,
          liquidacionComplementaria,
          liquidacionSustitiva,
          liquidacionesPendientes,
          liquidacionesEspeciales,
          declararVehiculos,
          pagoConvenios
        );
        subMenuProcesosDirectivos.push(registroSucursal);
        routes.push(correcionesAdministrativas, nuevosContribuyentes, impuestos, procesosDirectivos, menuSoporte);
        break;
      case CargoSedemat.analistaInmueblesUrbanos:
        subMenuImpuestos.push(liquidacionesEspeciales);
        subMenuInmuebles.push(consultarInmueble, editarInmueble, registrarInmueble, crearCodCat);
        routes.push(impuestos, inmuebles, menuSoporte);
        routes.push({
          path: 'contribuyente/consulta',
          exact: true,
          title: 'Consulta de Contribuyente',
          icon: UserOutlined,
          component: ConsultTaxpayer,
        });
        break;
      case CargoSedemat.analistaCatastro:
        subMenuImpuestos.push(liquidacionesEspeciales);
        subMenuInmuebles.push(consultarInmueble, editarInmueble, registrarInmueble, crearCodCat);
        routes.push(impuestos, inmuebles, menuSoporte);
        break;
      case CargoSedemat.analistaConsultorInmuebles:
        subMenuCorrecciones.push(correccionUsuario, correcionContribuyente);
        subMenuImpuestos.push(
          liquidarImpuestos,
          reparos,
          liquidacionComplementaria,
          liquidacionSustitiva,
          liquidacionesPendientes
        );
        subMenuInmuebles.pop();
        subMenuInmuebles.push(consultarInmueble);
        routes.push(correcionesAdministrativas, nuevosContribuyentes, impuestos, inmuebles);
        break;
      case CargoSedemat.AnalistaCobranzas:
        // subMenuImpuestos.push(
        //   liquidarImpuestos,
        //   liquidacionComplementaria,
        //   liquidacionSustitiva,
        //   liquidarRetenciones,
        //   liquidacionesPendientes,
        //   pagoConvenios,
        //   consultaDeRetenciones,
        //   consultaDePagos
        // );
        // subMenuCobranzas.push(cobranzas);
        subMenuCorrecciones.push(cambioAE);
        routes.push(correcionesAdministrativas);
        break;
      case CargoSedemat.cobranza:
        subMenuReportes.push(reportePlanillasVigentes);
        routes.push(
          {
            path: 'contribuyente/consulta',
            exact: true,
            title: 'Consulta de Contribuyente',
            icon: UserOutlined,
            component: ConsultTaxpayer,
          },
          reportes
        );
        break;
      case CargoSedemat.AnalistaLicores:
        subMenuImpuestos.push(liquidacionesEspeciales);
        routes.push(
          {
            path: 'certificado',
            exact: true,
            title: 'Generar Certificados',
            icon: ContainerOutlined,
            subMenu: [
              {
                path: 'certificado/EL/generar',
                exact: true,
                title: 'Licencia de Licores',
                component: CertificadeForm,
              },
            ],
          },
          impuestos
        );
        break;
      case CargoSedemat.Soporte:
        subMenuCorrecciones.push(correccionUsuario, correcionContribuyente, correccionLiquidaciones, correccionPagos);
        subMenuInmuebles.push(registrarInmueble, editarInmueble, consultarInmueble);
        // subMenuProcesosDirectivos.push(beneficiosTributables, cambioAE, agentesRetencion);
        subMenuImpuestos.push(
          liquidarImpuestos,
          liquidacionesPendientes,
          liquidacionesEspeciales,
          SolvenciaB,
          consultaDePagos,
          pagoConvenios
        );
        routes.push(
          correcionesAdministrativas,
          inmuebles,
          impuestos,
          declararVehiculos,
          // procesosDirectivos,
          // serviciosMunicipales,
          nuevosContribuyentes,
          // certificados,
          // menuSoporte,

          {
            path: 'funcionarios',
            exact: false,
            icon: TeamOutlined,
            title: 'Administrar Usuarios',
            component: TableOfficials,
          }
        );
        break;
      case CargoSedemat.Auditor:
        subMenuImpuestos.push(liquidacionesEspeciales);
        routes.push(impuestos, menuSoporte);
        break;
      case CargoSedemat.Validador:
        subMenuReportes.push(...subMenuReporteCaja);
        routes.push(
          {
            path: 'finanzas',
            exact: true,
            icon: DollarOutlined,
            title: 'Finanzas',
            subMenu: [
              {
                path: 'validacionDePagos',
                exact: true,
                title: 'Validación de Pagos',
                component: ValidatePayments,
              },
              {
                path: 'fechaBancaria',
                exact: false,
                title: 'Fechas Bancarias',
                component: TableBankCalendar,
              },
              {
                path: 'estadoDeCuentas',
                exact: true,
                title: 'Estado de Cuentas',
                component: BankStatements,
              },
            ],
          },
          reportes
        );
        break;
      case CargoSedemat.Policia:
        subMenuImpuestos.push(declararVehiculos);
        routes.push(impuestos);
    }
    if (user.institucion.cargo.id !== CargoSedemat.Validador && user.institucion.cargo.id !== CargoSedemat.cobranza)
      routes.push(
        {
          path: 'contribuyente',
          exact: true,
          title: 'Migración de Datos',
          icon: ImportOutlined,
          subMenu: [
            // {
            //   path: 'contribuyente/importar',
            //   exact: true,
            //   title: 'Importar contribuyente de GTIC',
            //   component: InternalImport,
            // },
            {
              path: 'contribuyente/enlace',
              exact: true,
              title: 'Enlace de Contribuyente',
              component: InternalLinking,
            },
          ],
        },
        {
          path: 'contribuyente/consulta',
          exact: true,
          title: 'Consulta de Contribuyente',
          icon: UserOutlined,
          component: ConsultTaxpayer,
        }
      );

    if (canSupport.includes(user.institucion.cargo.id)) routes.push(menuSoporte);

    if (user.tipoUsuario === UserType.OfficialAdmin || havePrcd.includes(user?.institucion?.cargo?.id || 0)) {
      routes.push({
        path: 'bandejas',
        exact: false,
        icon: InboxOutlined,
        title: 'Bandejas',
        component: TableProcedure,
      });
    }
  }
  //FIN MENU INTERNO SEDEMAT

  //MENU DE RUTAS CPU
  if (user.institucion?.nombreCorto === 'CPU') {
    const inmueblesUrbanos = {
      path: 'inmueblesUrbanos',
      exact: true,
      icon: HomeOutlined,
      title: 'Inmuebles Urbanos',
      subMenu: [
        {
          path: 'inmueblesUrbanos/registrar',
          exact: false,
          title: 'Registrar Inmueble',
          component: RegisterProperty,
        },
        {
          path: 'inmueblesUrbanos/editar',
          exact: false,
          title: 'Editar Inmueble',
          component: RegisterProperty,
        },
      ],
    };

    const subMenuReportes: RouteI[] = [];

    const reportes = {
      path: 'reportes',
      exact: true,
      title: 'Reportes',
      icon: BarChartOutlined,
      subMenu: subMenuReportes,
    };

    const reporteEvalTiempoPersonal = {
      path: 'reportes/12',
      exact: false,
      title: 'Reporte de tiempos del personal',
    };

    const menuSoporte = {
      path: 'soporte/inbox',
      exact: true,
      icon: ExceptionOutlined,
      title: 'Bandeja de Soporte',
      component: TableSupport,
    };

    switch (user.institucion.cargo.id) {
      case CargoCpu.Administrador:
        subMenuReportes.push(reporteEvalTiempoPersonal);
        routes.push(reportes);
        break;
      case CargoCpu.AnalistaValuacionFiscal:
        routes.push(inmueblesUrbanos);
        break;
      case CargoCpu.JefeValuacionFiscal:
        routes.push(inmueblesUrbanos, {
          path: 'finanzas/valoresFiscales',
          exact: true,
          icon: BankOutlined,
          title: 'Valores Fiscales',
          component: TableFiscalValue,
        });
        break;
      case CargoCpu.JefeCodigoCatastral:
        break;
      case CargoCpu.AnalistaCodigoCatastral:
        // const instCpu = institutions.  ((i) => i.nombreCorto === 'CPU');
        // const codCat = institutions[instCpu]?.tramitesDisponibles?.filter((t) => t.titulo.startsWith('Codigo Catastral'));
        // routes.push(
        //   {
        //     path: '/',
        //     exact: true,
        //     title: 'Codigo Catastral',
        //     subMenu: [
        //       {
        //         path: `tramite/${institutions[instCpu]?.id}/${codCat ? codCat[0].id : 0}`,
        //         exact: true,
        //         title: codCat ? (codCat[0].titulo.includes('para Casas') ? 'Para Casas' : 'Para Apartamentos') : 'XD',
        //       },
        //       {
        //         path: `tramite/${institutions[instCpu]?.id}/${codCat ? codCat[1].id : 0}`,
        //         exact: true,
        //         title: codCat ? (codCat[1].titulo.includes('para Casas') ? 'Para Casas' : 'Para Apartamentos') : 'XD',
        //       },
        //     ],
        //   },
        // );
        break;
      case CargoCpu.AnalistaNomenclatura:
        // const instCpu2 = institutions.findIndex((i) => i.nombreCorto === 'CPU');
        // const nom = institutions[instCpu2]?.tramitesDisponibles?.filter((t) => t.titulo.startsWith('Constancia de Nomenclatura'));
        // routes.push(
        //   {
        //     path: '/',
        //     exact: true,
        //     title: 'Nomenclatura',
        //     subMenu: [
        //       {
        //         path: `tramite/${institutions[instCpu2]?.id}/${nom ? nom[0].id : 0}`,
        //         exact: true,
        //         title: nom ? nom[0].titulo : 'XD',
        //       },
        //     ],
        //   },
        // );
        break;
      case CargoCpu.JefeNomenclatura:
        break;
    }

    if (canSupport.includes(user.institucion.cargo.id)) routes.push(menuSoporte);
  }
  //FIN MENU CPU

  if (user.tipoUsuario === UserType.OfficialAdmin && user.institucion.nombreCorto === 'SAGAS') {
    routes.push({
      path: 'serviciosMunicipales',
      exact: true,
      icon: FireOutlined,
      title: 'Servicios Municipales',
      subMenu: [
        {
          path: 'serviciosMunicipales/contribuyente',
          exact: true,
          title: 'Servicios Municipales por Contribuyente',
          component: TaxpayerServices,
        },
      ],
    });
    routes.push({
      path: 'liquidaciones/SAGAS',
      exact: true,
      icon: FileOutlined,
      title: 'Liquidaciones de Gas',
      component: SMSettlements,
    });
  }

  if (user.tipoUsuario === UserType.OfficialAdmin && user.institucion.nombreCorto === 'IMAU') {
    routes.push({
      path: 'liquidaciones/IMAU',
      exact: true,
      icon: FileOutlined,
      title: 'Liquidaciones de Aseo',
      component: SMSettlements,
    });
  }

  // if (
  //   (user.tipoUsuario === UserType.OfficialAdmin || user.tipoUsuario === UserType.OfficialChief) &&
  //   user.institucion.nombreCorto === 'CPU'
  // ) {
  //   const subMenu: RouteI[] = [
  //     {
  //       path: 'liquidaciones/CPU',
  //       exact: true,
  //       title: 'Liquidaciones de CPU',
  //       component: SMSettlements,
  //     },
  //   ];

  //   if (user.tipoUsuario === UserType.OfficialAdmin) {
  //     subMenu.push({
  //       path: ['impuestos/especiales', 'impuestos/especiales/:id'],
  //       exact: true,
  //       title: 'Liquidaciones Especiales',
  //       component: SpecialSettlements,
  //     });
  //   }

  //   routes.push({
  //     path: 'procesosDirectivos',
  //     exact: false,
  //     icon: ReconciliationOutlined,
  //     title: 'Procesos Directivos',
  //     subMenu,
  //   });
  // }

  if (user.tipoUsuario === UserType.External) {
    const inst: RouteI[] = institutions
      .filter(
        (i) =>
          i.nombreCorto !== 'ABMM' &&
          i.nombreCorto !== 'PMM' &&
          i.nombreCorto !== 'IMA' &&
          i.nombreCorto !== 'SEDETEMA' &&
          i.nombreCorto !== 'HACIENDA'
      )
      .map((i) => ({
        path: '/',
        exact: true,
        short: i.nombreCorto,
        title: i.nombreCompleto,
        subMenu: i.tramitesDisponibles
          ?.filter((t) => !t.titulo.includes('Permiso de Habitabilidad'))
          .filter((t) => !t.titulo.includes('Permiso de Condiciones Habitables'))
          .filter((t) => !t.titulo.includes('Codigo Catastral'))
          .filter((t) => !t.titulo.includes('Conformidad de la Edificación y Uso'))
          .map((t) => ({
            path: `tramite/${i.id}/${t.id}`,
            exact: true,
            title: t.titulo,
          })),
      }));

    const subMenuSedemat = [
      {
        path: '/',
        exact: true,
        title: 'Licencia de Actividades Económicas',
        icon: FolderAddOutlined,
        subMenu: [
          {
            path: 'tramite/9/28',
            exact: true,
            title: 'Solicitud de Licencia Permanente',
          },
          {
            path: 'tramite/9/36',
            exact: true,
            title: 'Solicitud de Licencia Provisional',
          },
        ],
      },
      {
        path: 'contrib/consultar',
        exact: true,
        title: 'Consulta de Contribuyente',
        icon: UserOutlined,
      },
      {
        path: 'impuestos/declarar',
        exact: true,
        title: 'Declarar Impuestos',
        icon: FileAddOutlined,
      },
      {
        path: 'impuestos/sustitutiva',
        exact: true,
        title: 'Declaración Sustitutiva',
        icon: SwapOutlined,
      },
      {
        path: 'impuestos/complementaria',
        exact: true,
        title: 'Declaración Complementaria',
        icon: AppstoreAddOutlined,
      },
      {
        path: 'impuestos/pagar',
        exact: true,
        title: 'Pagar Impuestos',
        icon: CreditCardFilled,
      },
      {
        path: 'impuestos/retenciones/consulta',
        exact: true,
        title: 'Consulta de Retenciones',
        icon: FolderViewOutlined,
      },
      {
        path: 'impuestos/convenios',
        exact: true,
        title: 'Convenios',
        icon: ScheduleOutlined,
      },
      {
        path: 'impuestos/vehiculos',
        exact: true,
        title: 'Vehículos',
        icon: CarOutlined,
      },
      {
        path: 'inmuebles/documentos/cargar',
        exact: true,
        title: 'Cargar documentos de Inmueble',
        icon: HomeOutlined,
      },
    ];

    const instNew: RouteI[] = [
      {
        path: '/',
        exact: true,
        short: 'HACIENDA',
        title: 'Alcaldia Del Municipio San Cristóbal',
        subMenu: user.contribuyente?.verificacionTelefono
          ? subMenuSedemat
          : [
              // {
              //   path: 'impuestos/enlazar',
              //   exact: true,
              //   title: 'Enlazar cuenta de HACIENDA',
              //   icon: LinkOutlined
              // },
              {
                path: 'tramite/9/27',
                exact: true,
                title: 'Registro de Contribuyente',
                icon: UserAddOutlined,
              },
            ],
        // subMenu: [
        //   {
        //     path: 'mantenimiento/mantenimiento',
        //     exact: true,
        //     title: 'Mantenimiento',
        //   },
        // ],
      },
    ];

    if (user.contribuyente?.esAgenteRetencion)
      subMenuSedemat.unshift({
        path: 'impuestos/retenciones',
        exact: true,
        title: 'Declarar Retenciones',
        icon: PercentageOutlined,
      });
    if (!!user.contribuyente)
      subMenuSedemat.push({
        path: 'reportes/7',
        exact: false,
        title: 'Reporte de Cierre Definitivo',
        icon: BarChartOutlined,
      });
    inst.push({
      path: '/',
      exact: true,
      short: 'HACIENDA',
      title: 'Servicio Desconcentrado Municipal de Administración Tributaria',
      subMenu: user.contribuyente?.verificacionTelefono
        ? subMenuSedemat
        : [
            // {
            //   path: 'impuestos/enlazar',
            //   exact: true,
            //   title: 'Enlazar cuenta de HACEINDA',
            //   icon: LinkOutlined
            // },
            {
              path: 'tramite/9/27',
              exact: true,
              title: 'Registro de Contribuyente',
              icon: UserAddOutlined,
            },
            {
              path: 'impuestos/vehiculos',
              exact: true,
              title: 'Vehículos',
              icon: CarOutlined,
            },
          ],
      // subMenu: [
      //   {
      //     path: 'mantenimiento/mantenimiento',
      //     exact: true,
      //     title: 'Mantenimiento',
      //   },
      // ],
    });

    // const instSagas = institutions.findIndex((i) => i.nombreCorto === 'SAGAS');
    // const permisoHab = institutions[instSagas]?.tramitesDisponibles?.filter((t) =>
    //   t.titulo.startsWith('Permiso de Habitabilidad')
    // );

    // inst[instSagas]?.subMenu?.push(
    //   ...[
    //     {
    //       path: '/',
    //       exact: true,
    //       title: 'Permiso de Habitabilidad',
    //       subMenu: [
    //         {
    //           path: `tramite//${permisoHab ? permisoHab[0].id : 0}`,
    //           exact: true,
    //           title: permisoHab
    //             ? permisoHab[0].titulo.includes('con Instalaciones')
    //               ? 'Con Instalación de Servicio de Gas'
    //               : 'Sin Instalación de Servicio de Gas'
    //             : 'XD',
    //         },
    //         {
    //           path: `tramite/${institutions[instSagas]?.id}/${permisoHab ? permisoHab[1].id : 0}`,
    //           exact: true,
    //           title: permisoHab
    //             ? permisoHab[1].titulo.includes('con Instalaciones')
    //               ? 'Con Instalación de Servicio de Gas'
    //               : 'Sin Instalación de Servicio de Gas'
    //             : 'XD',
    //         },
    //       ],
    //     },
    //   ]
    // );
    // const condHab = institutions
    // .find((i) => i.nombreCorto === 'SAGAS')
    // ?.tramitesDisponibles?.filter((t) => t.titulo.startsWith('Permiso de Condiciones Habitables'));
    // inst[instSagas]?.subMenu?.push(
    //   ...[
    //     {
    //       path: '/',
    //       exact: true,
    //       title: 'Permiso de Condiciones Habitables',
    //       subMenu: [
    //         {
    //           path: `tramite/${institutions[instSagas]?.id}/${condHab ? condHab[0].id : 0}`,
    //           exact: true,
    //           title: condHab
    //             ? condHab[0].titulo.includes('con Instalaciones')
    //               ? 'Con Instalación de Servicio de Gas'
    //               : 'Sin Instalación de Servicio de Gas'
    //             : 'XD',
    //         },
    //         {
    //           path: `tramite/${institutions[instSagas]?.id}/${condHab ? condHab[1].id : 0}`,
    //           exact: true,
    //           title: condHab
    //             ? condHab[1].titulo.includes('con Instalaciones')
    //               ? 'Con Instalación de Servicio de Gas'
    //               : 'Sin Instalación de Servicio de Gas'
    //             : 'XD',
    //         },
    //       ],
    //     },
    //   ]
    // );
    // const instCpu = institutions.findIndex((i) => i.nombreCorto === 'CPU');
    // const codCat = institutions[instCpu]?.tramitesDisponibles?.filter((t) => t.titulo.startsWith('Codigo Catastral'));
    // inst[instCpu]?.subMenu?.push(
    //   ...[
    //     {
    //       path: '/',
    //       exact: true,
    //       title: 'Codigo Catastral',
    //       subMenu: [
    //         {
    //           path: `tramite/${institutions[instCpu]?.id}/${codCat ? codCat[0].id : 0}`,
    //           exact: true,
    //           title: codCat ? (codCat[0].titulo.includes('para Casas') ? 'Para Casas' : 'Para Apartamentos') : 'XD',
    //         },
    //         {
    //           path: `tramite/${institutions[instCpu]?.id}/${codCat ? codCat[1].id : 0}`,
    //           exact: true,
    //           title: codCat ? (codCat[1].titulo.includes('para Casas') ? 'Para Casas' : 'Para Apartamentos') : 'XD',
    //         },
    //       ],
    //     },
    //   ]
    // );

    // const usoConforme = institutions[instCpu]?.tramitesDisponibles?.filter((t) =>
    //   t.titulo.startsWith('Conformidad de la Edificación y Uso')
    // );
    // inst[instCpu]?.subMenu?.push(
    //   ...[
    //     {
    //       path: '/',
    //       exact: true,
    //       title: 'Conformidad de la Edificación y Uso',
    //       subMenu: [
    //         {
    //           path: `tramite/${institutions[instCpu]?.id}/${usoConforme ? usoConforme[0].id : 0}`,
    //           exact: true,
    //           title: usoConforme
    //             ? usoConforme[0].titulo.includes('Industriales')
    //               ? 'Actividades Económicas Comerciales e Industriales'
    //               : usoConforme[0].titulo.includes('Educativas')
    //               ? 'Unidades Educativas'
    //               : 'Locales en Centros Comerciales'
    //             : 'XD',
    //         },
    //         {
    //           path: `tramite/${institutions[instCpu]?.id}/${usoConforme ? usoConforme[1].id : 0}`,
    //           exact: true,
    //           title: usoConforme
    //             ? usoConforme[1].titulo.includes('Industriales')
    //               ? 'Actividades Económicas Comerciales e Industriales'
    //               : usoConforme[1].titulo.includes('Educativas')
    //               ? 'Unidades Educativas'
    //               : 'Locales en Centros Comerciales'
    //             : 'XD',
    //         },
    //         {
    //           path: `tramite/${institutions[instCpu]?.id}/${usoConforme ? usoConforme[2].id : 0}`,
    //           exact: true,
    //           title: usoConforme
    //             ? usoConforme[2].titulo.includes('Industriales')
    //               ? 'Actividades Económicas Comerciales e Industriales'
    //               : usoConforme[2].titulo.includes('Educativas')
    //               ? 'Unidades Educativas'
    //               : 'Locales en Centros Comerciales'
    //             : 'XD',
    //         },
    //       ],
    //     },
    //   ]
    // );
    // inst[instCpu].subMenu = inst[instCpu]?.subMenu?.filter(
    //   (el) => !el.title.includes('RRI') && !el.title.includes('Conformidad')
    // );
    // instNew.push(inst[instCpu]);

    routes.push(
      {
        path: 'contribuyente/consulta',
        exact: true,
        title: 'Consulta de Contribuyente',
        icon: UserOutlined,
        component: ExternalInfo,
      },
      {
        path: 'impuestos/pagar',
        exact: true,
        title: 'Pagar Impuestos',
        icon: CreditCardFilled,
        component: PayTaxes,
      }
    );
  }

  if (user.tipoUsuario === UserType.OfficialAdmin || user.tipoUsuario === UserType.SuperUser) {
    routes.unshift({
      path: 'funcionarios',
      exact: false,
      icon: TeamOutlined,
      title: 'Administrar Usuarios',
      component: TableOfficials,
    });
  }

  if (user.institucion && user.institucion.id === 0 && user.tipoUsuario === UserType.OfficialAdmin) {
    routes.push({
      path: ['casosSociales', 'casosSociales/:id'],
      exact: true,
      title: 'Casos Sociales',
      icon: FolderOpenFilled,
      component: AffairsForm,
    });
  }

  if (user.institucion && user.institucion.nombreCorto === 'SEDETEMA' && user.tipoUsuario === UserType.Official) {
    routes.push({
      path: 'tasaDeSalida',
      exact: true,
      title: 'Tasa de Salida',
      icon: FolderOpenFilled,
      component: OfficialPFForm,
    });
  }

  //Rutas para bandeja de multas 6 = Ima y 7 = POLICIA
  if (user.institucion && (user.institucion.id === 6 || user.institucion.id === 7)) {
    if (
      user.institucion?.cargo?.id !== 52 &&
      ![CargoPolicia.CajeroPolicia, CargoPolicia.Soporte].includes(user.institucion?.cargo?.id ?? 0)
    ) {
      routes.push({
        path: 'crearMulta',
        exact: false,
        title: 'Crear Multa',
        icon: AlertOutlined,
        component: user.institucion.id === 7 ? PoliceTicketForm : IMATicketForm,
      });
    }
  }

  if (user.institucion?.id === 7 && user.institucion?.cargo?.id !== 51) {
    routes.push({
      path: 'bandejas',
      exact: false,
      icon: InboxOutlined,
      title: 'Bandejas',
      component: TableProcedure,
    });
  }

  if (user.institucion?.id !== 6 && user.institucion?.id !== 7 && user.institucion?.id !== 9) {
    routes.push({
      path: 'bandejas',
      exact: false,
      icon: InboxOutlined,
      title: 'Bandejas',
      component: TableProcedure,
    });
  }

  return routes;
};
