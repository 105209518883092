import React, { Fragment, useState } from 'react';
import { Modal, Row, Form, DatePicker, Col, Select, Input, Button, Divider, message } from 'antd';
import { useHistory, useParams } from 'react-router';
import handlingMessage from '../utils/handlingMessage';
import { BarChartOutlined } from '@ant-design/icons';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import {
  newReportPdf,
  reportCondo,
  newReportExcel,
  closureCash,
  clouserAllCash,
  newReportExcelIVA,
  reportDAE,
  reportTransf,
  reportTransfBank,
  reportCondoDisclosed,
  reportTimeFunctionary,
  reportRMP,
  reportRID,
  reportTransfExternal,
  reportAttenPendTickets,
  closureCashOne,
  reportFinesByOfficer,
  reportFinesByOfficerWithInfraction,
  reportFinesByParish,
  reportFinesByInfraction,
  reportFinesResourcesCollected,
  reportFinesRPR,
} from '../services/report';
import moment from 'moment';
import { Utils } from '../utils/validators';
import { useWindowDimensions } from '../utils/hooks';

const Report: React.FC<ReportProps> = ({ auth, brch }) => {
  const [bankState, setBankState] = useState();
  const [form] = Form.useForm();
  const disabledDate = (current) => current > moment().endOf('day');
  const [loading, setLoading] = useState(false);
  const params: any = useParams();
  const history = useHistory();
  const { width } = useWindowDimensions();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [atendPendTickets, setAtendPendTickets] = useState(true);

  const docTypeSelect = (
    <Form.Item
      noStyle
      name='tipoDocumento'
      rules={[{ required: true, message: 'Debe ingresar el tipo de documento del cajero' }]}
    >
      <Select>
        <Select.Option value='J'>J</Select.Option>
        <Select.Option value='G'>G</Select.Option>
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='P'>P</Select.Option>
      </Select>
    </Form.Item>
  );

  const handlerResquest = (type, token, values) => {
    switch (type) {
      case 3:
        return newReportExcel(values, token ? token : '');
      case 4:
        return newReportExcelIVA(values, token ? token : '');
      case 5:
        return closureCash(values.rango);
      case 6:
        return clouserAllCash(values);
      case 7:
        return reportDAE(auth.user?.contribuyente, values.rim, token);
      case 8:
        const [from, to] = values.rango;
        return reportTransf(
          { from: moment(from).utcOffset(-4).startOf('day'), to: moment(to).utcOffset(-4).endOf('day') },
          token
        );
      case 9:
        return reportCondo({ from: values.rango[0], to: values.rango[1] }, token);
      case 10:
        return reportTransfBank(
          { from: moment(values.rango[0]).format('YYYY/MM/DD'), to: moment(values.rango[1]).format('YYYY/MM/DD') },
          token,
          bankState
        );
      case 11:
        return reportCondoDisclosed({ from: values.rango[0], to: values.rango[1] }, token);
      case 12:
        return reportTimeFunctionary({ from: values.rango[0], to: values.rango[1] }, token);
      case 13:
        return reportRMP({ fecha: values.fecha.format('YYYY/MM/DD') }, token);
      case 14:
        return reportRID({ fecha: values.fecha.format('YYYY/MM/DD') }, token);
      case 15:
        return reportTransfExternal(
          { from: values.rango[0].format('YYYY/MM/DD'), to: values.rango[1].format('YYYY/MM/DD') },
          token
        );
      case 16:
        return newReportPdf(values, token ? token : '', 'IDR');
      case 17:
        return reportAttenPendTickets(
          { finished: atendPendTickets, from: values.rango[0].format('YYYY/MM/DD'), to: values.rango[1].format('YYYY/MM/DD') },
          token
        );
      case 18:
        return closureCashOne({ day: values.rango, tipoDocumento: values.tipoDocumento, documento: values.documento });
      case 19:
        return reportFinesByOfficer(
          { from: values.rango[0].format('YYYY-MM-DD'), to: values.rango[1].format('YYYY-MM-DD') },
          token
        );
      case 20:
        return reportFinesByOfficerWithInfraction(
          { from: values.rango[0].format('YYYY-MM-DD'), to: values.rango[1].format('YYYY-MM-DD') },
          token
        );
      case 21:
        return reportFinesByParish(
          { from: values.rango[0].format('YYYY-MM-DD'), to: values.rango[1].format('YYYY-MM-DD') },
          token
        );
      case 22:
        return reportFinesByInfraction(
          { from: values.rango[0].format('YYYY-MM-DD'), to: values.rango[1].format('YYYY-MM-DD') },
          token
        );
      case 23:
        return reportFinesResourcesCollected(
          { from: values.rango[0].format('YYYY-MM-DD'), to: values.rango[1].format('YYYY-MM-DD') },
          token
        );
      case 24:
        return reportFinesRPR({ from: values.rango[0].format('YYYY-MM-DD'), to: values.rango[1].format('YYYY-MM-DD') }, token);
      default:
        return newReportPdf(values, token ? token : '');
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const values = await form.validateFields();
    values.alcaldia = +params.type === 2;
    handlingMessage({
      action: () => {
        return handlerResquest(+params.type, auth.token, values);
      },
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: (data) => {
        if (data.status === 200) {
          const win = window.open(data.data.replaceAll('"', ''), '_blank');
          win?.focus();
        }
        setLoading(false);
      },
    });
  };

  const getReportDefault = (type: 'day' | 'month') => {
    form.setFieldsValue({ rango: [moment().utcOffset(-4).startOf(type), moment().utcOffset(-4).endOf(type)] });
    form.submit();
  };

  return (
    <React.Fragment>
      {[13, 14, 17].includes(+params.type) ? (
        <Modal
          maskClosable
          onCancel={() => history.goBack()}
          title='Reporte'
          confirmLoading={loading}
          visible
          bodyStyle={{ margin: '0 35px' }}
          footer={null}
        >
          {[13, 14].includes(+params.type) ? (
            <>
              <Form onFinish={handleSubmit} form={form} layout='vertical'>
                <Form.Item name={'fecha'}>
                  <DatePicker format='DD/MM/YYYY' style={{ width: '100%' }} disabledDate={disabledDate} />
                </Form.Item>
                <Button
                  loading={loading}
                  style={{ width: '100%', marginBottom: '10px' }}
                  onClick={() => handleSubmit()}
                  size={'large'}
                  type='primary'
                >
                  Obtener reporte
                </Button>
              </Form>
            </>
          ) : [17].includes(+params.type) ? (
            <>
              <Form onFinish={handleSubmit} form={form} layout='vertical'>
                <Form.Item
                  label={'Rango de fechas'}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresar fecha!',
                    },
                  ]}
                  name='rango'
                >
                  <RangePicker format='DD/MM/YYYY' style={{ width: '100%' }} disabledDate={disabledDate} />
                </Form.Item>
              </Form>
              <Row>
                <Col span={24}>
                  <Button
                    loading={loading}
                    style={{ width: '100%', marginBottom: '10px' }}
                    onClick={() => {
                      setAtendPendTickets(true);
                      handleSubmit();
                    }}
                    size={'large'}
                    type='primary'
                  >
                    Obtener reporte de tickets atendidos
                  </Button>
                </Col>
                <Col span={24}>
                  <Button
                    loading={loading}
                    style={{ width: '100%', marginBottom: '10px' }}
                    onClick={() => {
                      setAtendPendTickets(false);
                      handleSubmit();
                    }}
                    size={'large'}
                    type='primary'
                  >
                    Obtener reporte de tickets por atender
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Button
                loading={loading}
                style={{ width: '100%', marginBottom: '10px' }}
                onClick={() => handleSubmit()}
                size={'large'}
                type='primary'
              >
                Obtener reporte
              </Button>
            </>
          )}
        </Modal>
      ) : (
        <>
          <Row style={{ height: '100%', width: '100%' }} justify='center' align='middle'>
            <BarChartOutlined style={{ color: 'lightgrey', fontSize: 250, textAlign: 'center' }} />
          </Row>
          <Modal
            maskClosable
            onCancel={() => history.goBack()}
            title='Reporte'
            okText='Generar'
            confirmLoading={loading}
            onOk={() => form.submit()}
            visible
            bodyStyle={{ margin: '0 35px' }}
          >
            {+params.type !== 5 && +params.type !== 11 && +params.type !== 12 && +params.type !== 18 && (
              <>
                <Row justify='space-between' gutter={[16, 8]}>
                  <Col style={width < 768 ? { width: '100%' } : {}}>
                    <Button
                      loading={loading}
                      style={{ width: '100%' }}
                      onClick={() => getReportDefault('day')}
                      size={width < 768 ? 'middle' : 'small'}
                      type='primary'
                    >
                      Obtener reporte del día
                    </Button>
                  </Col>
                  <Col style={width < 768 ? { width: '100%' } : {}}>
                    <Button
                      loading={loading}
                      style={{ width: '100%' }}
                      onClick={() => getReportDefault('month')}
                      size={width < 768 ? 'middle' : 'small'}
                    >
                      Obtener reporte del mes
                    </Button>
                  </Col>
                </Row>
                <Divider />
              </>
            )}
            <Form onFinish={handleSubmit} form={form} layout='vertical'>
              <Row gutter={16}>
                <Col flex={1}>
                  {+params.type === 7 ? (
                    <Form.Item
                      label='R.I.M'
                      rules={[{ required: true, message: 'Por favor ingresar R.I.M!' }]}
                      normalize={Utils.normalize.toUpper}
                      name='rim'
                    >
                      <Input placeholder='R.I.M' />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label={+params.type === 5 || +params.type === 18 ? null : 'Rango de fechas'}
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingresar fecha!',
                        },
                      ]}
                      name='rango'
                    >
                      {+params.type === 5 || +params.type === 18 ? (
                        <DatePicker format='DD/MM/YYYY' style={{ width: '100%' }} disabledDate={disabledDate} />
                      ) : (
                        <RangePicker format='DD/MM/YYYY' style={{ width: '100%' }} disabledDate={disabledDate} />
                      )}
                    </Form.Item>
                  )}
                  {+params.type === 10 && (
                    <Form.Item>
                      <Select placeholder={'Seleccione un banco'} onChange={(e) => setBankState(e)}>
                        <Select.Option value={1}>Banco Occidental de Descuento</Select.Option>
                        <Select.Option value={2}>Banesco Banco Universal</Select.Option>
                        <Select.Option value={3}>Banco Nacional de Crédito</Select.Option>
                        <Select.Option value={22}>Banco Plaza</Select.Option>
                        <Select.Option value={19}>Banco Mercantil C.A.</Select.Option>
                        <Select.Option value={23}>Banco Provincial BBVA</Select.Option>
                        <Select.Option value={25}>Bancrecer S.A. Banco De Desarrollo</Select.Option>
                        <Select.Option value={11}>Banco de Venezuela S.A.C.A. Banco Universal</Select.Option>
                        <Select.Option value={12}>Bancaribe C.A. Banco Universal</Select.Option>
                        <Select.Option value={6}>Banco Activo Banco Comercial, C.A.</Select.Option>
                        <Select.Option value={35}>Mibanco Banco de Desarrollo, C.A.</Select.Option>
                      </Select>
                    </Form.Item>
                  )}
                </Col>
              </Row>
              {+params.type === 3 ? (
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item name='ramo'>
                      <Select placeholder='Ramo'>
                        {brch.branches?.map((option) => (
                          <Option key={option.id} value={option.id}>
                            {option.descripcion}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}
              {+params.type === 18 ? (
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label='Documento de Identidad'
                      name='documento'
                      rules={[{ required: true, message: 'Debe ingresar el documento de identidad del cajero' }]}
                    >
                      <Input placeholder='Documento de Identidad' addonBefore={docTypeSelect} />
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}
            </Form>
          </Modal>
        </>
      )}
    </React.Fragment>
  );
};
interface ReportProps {
  auth: States.Auth;
  brch: States.Branches;
}

const mapStateToProps = ({ auth, brch }: State) => ({ auth, brch });

export default connect(mapStateToProps)(Report);
