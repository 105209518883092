import axios, { AxiosResponse } from 'axios';
import { SetStatsAction, SET_STATS, StatsType } from './actionsTypes';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { States } from 'sigt';
const server = process.env.REACT_APP_SERVER_URL;

export const setStats = (stats: States.Stats['stats']): SetStatsAction => ({ type: SET_STATS, payload: stats });

export const fetchStats = (token: string): ThunkAction<Promise<void>, {}, {}, StatsType> =>
  async (dispatch: ThunkDispatch<{}, {}, StatsType>): Promise<void> => {
    try {
      const response: AxiosResponse<{ stats: States.Stats['stats']}> = await axios.get(`${server}/stats`, { headers: { Authorization: `Bearer ${token}` } });
      dispatch(setStats(response.data.stats));
    } catch(e) {
      throw e;
    }
  };