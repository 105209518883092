import React, { useEffect, useState } from 'react';
import { States, State, Fine } from 'sigt';
import { connect } from 'react-redux';
import {
  Card,
  Collapse,
  Divider,
  Typography,
  Button,
  Modal,
  Row,
  Col,
  message,
  Alert,
  Descriptions,
  InputNumber,
  Input,
} from 'antd';
import { useWindowDimensions } from '../../../utils/hooks';
import { useParams, useHistory } from 'react-router';
import moment from 'moment';
import { DeleteFilled } from '@ant-design/icons';
import axios, { AxiosResponse } from 'axios';
// import TextArea from 'antd/lib/input/TextArea';
import GetFineInfo from '../../Taxpayer/components/GetFineInfo';
// import { generateUniqueId } from '../../../utils/function';
import { changeFineCertificate, changeFineStatus, deleteFineInfraction } from '../../../redux/actions/procedures';
import { UT } from '../../Forms/Ticket/PoliceTicketForm';
import { CargoPolicia } from '../../../routes';
const server = process.env.REACT_APP_SERVER_URL;

const AmendFine: React.FC<AmendFineProps> = ({ thm, auth, deleteFineInfraction, changeFineStatus, changeFineCertificate }) => {
  const [activeKey, setActiveKey] = useState<string>('');
  const [deleting, setDeleting] = useState<{ showModal: boolean; index: Number; infraccion: any }>({
    showModal: false,
    index: 0,
    infraccion: null,
  });
  const [loading, setLoading] = useState(false);
  const [fine, setFine] = useState<Fine>();
  const [observaciones, setObservaciones] = useState<string | null>(null);
  const [deleteFine, setDeleteFine] = useState(false);

  const { width } = useWindowDimensions();
  const { id } = useParams<{ id: any }>();
  const history = useHistory();

  const [montoMultaUT, setMontoMultaUT] = useState(0);
  const [unidades, setUnidades] = useState(0);

  const canDelete = [13, CargoPolicia.Director];

  type typeOfApproval = 'SERVICIO COMUNITARIO' | 'DECLARADO CON LUGAR';

  useEffect(() => {
    if (!(history.location.state as any)?.multa || (history.location.state as any)?.multa?.id !== id) {
      history.push('/dashboard/correcciones/multas');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const multa = (history.location.state as any)?.multa;
    setFine(multa);
    restStateDelete();
  }, [history.location]);

  const { Panel } = Collapse;

  const deleteInfraccion = async () => {
    setLoading(true);
    try {
      // if (!observaciones) {
      //   message.error('Debe colocar una observación al momento de eliminar una infracción');
      //   return;
      // }
      const _fine = Object.assign({}, fine);
      let newFine: any = null;
      if (_fine) {
        const infraccion = _fine?.datos?.funcionario?.infraccion;
        if (!infraccion || infraccion.length === 1) return message.error('La multa debe tener al mas una infracción');

        const _infraccion = _fine?.datos?.funcionario?.infraccion.filter((f, i) => i !== deleting.index);

        if (_infraccion.length === 1 && _infraccion.some((s) => s?.numeral?.ut)) {
          if (!unidades) return message.error('El campo unidad es requerido');
          _infraccion[0].unidad = unidades;
        }

        const datos = {
          ..._fine?.datos,
          funcionario: {
            ..._fine?.datos?.funcionario,
            infraccion: _infraccion,
          },
        };

        newFine = { ..._fine, datos };
      }

      const response: AxiosResponse = await axios.patch(
        `${server}/fines/delete/infraction`,
        { fine: newFine },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );

      if (response.data.status === 200) {
        const { id, datos } = newFine;
        setFine(newFine);
        deleteFineInfraction(id, datos?.funcionario?.infraccion ?? []);
        message.success('Infracción eliminada de manera exitosa.');
      }
    } catch (e) {
      console.log(e);
      message.error(e.response?.data?.message || 'Error al eliminar la infracción');
    } finally {
      setLoading(false);
      restStateDelete();
    }
  };

  const restStateDelete = () => {
    // setObservaciones('');
    setDeleting({ showModal: false, index: 0, infraccion: null });
    setUnidades(0);
    setMontoMultaUT(0);
  };

  const handlerBackButton = () => {
    restStateDelete();
    history.push('/dashboard/correcciones/multas');
  };

  const handleUnidad = (value) => {
    const infraccion = fine?.datos?.funcionario?.infraccion;

    if (infraccion[0]?.numeral?.ut) {
      const monto = UT * Number(value || 1);
      setMontoMultaUT(monto);
    }
    setUnidades(value);
  };

  const handleModalDeleteFine = () => {
    setDeleteFine(!deleteFine);
    setObservaciones('');
  };

  const handleDeleteFine = async () => {
    try {
      setLoading(true);

      if (!observaciones) {
        message.error('Debe colocar una observación al momento de eliminar una multa');
        return;
      }

      const response: AxiosResponse = await axios.patch(
        `${server}/fines/delete/fine/${fine?.id}`,
        { observaciones },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );

      if (response.data.status === 200) {
        message.success('Multa eliminada de manera exitosa.');
        handlerBackButton();
        handleModalDeleteFine();
      }
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al eliminar la multa');
    } finally {
      setLoading(false);
    }
  };

  const approveFineByType = async (type: typeOfApproval) => {
    try {
      setLoading(true);

      const response: AxiosResponse = await axios.post(
        `${server}/fines/approveFineByType`,
        { idFine: id, type },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );

      if (response.data.status === 200) {
        message.success('Multa aprobada exitosamente');
        changeFineStatus(response.data.multa.id, response.data.multa.estado);
        if (response.data.multa.certificado) {
          changeFineCertificate(response.data.multa.id, response.data.multa.certificado);
        }
        history.push('/dashboard/bandejas?tipo=multas');
      } else {
        message.error(response?.data?.message);
      }
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al aprobar la multa');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Corrección de Multas'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      {!id && <GetFineInfo />}
      {id && fine && (
        <>
          <Row gutter={[16, 16]} justify='end'>
            <Col>
              <Button type='primary' onClick={() => approveFineByType('SERVICIO COMUNITARIO')} loading={loading}>
                Solvente Por Servicio Comunitario
              </Button>
            </Col>
            <Col>
              <Button type='primary' onClick={() => approveFineByType('DECLARADO CON LUGAR')} loading={loading}>
                Solvente Por Declarado Con Lugar
              </Button>
            </Col>
            {auth.user?.institucion.cargo.id !== CargoPolicia.AnalistaSoporte && (
              <Col>
                <Button type='danger' onClick={handleModalDeleteFine} loading={loading}>
                  Eliminar Multa
                </Button>
              </Col>
            )}
          </Row>

          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Descriptions bordered column={2} title='Datos de la Multa'>
                <Descriptions.Item label='Código Multa'>{fine?.codigoMulta ?? ''}</Descriptions.Item>
                <Descriptions.Item label='Fecha de Creación'>
                  {fine?.fechaCreacion ? moment(fine?.fechaCreacion).format('DD/MM/YYYY') : ''}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={24}>
              <Descriptions bordered column={2} title='Datos del infractor'>
                <Descriptions.Item label='Documento'>
                  {/^\d+$/.test(fine?.cedula ?? 'NO APLICA')
                    ? `${fine?.datos?.funcionario?.nacionalidad}-${fine?.datos?.funcionario?.cedula}`
                    : 'NO APLICA'}
                </Descriptions.Item>
                <Descriptions.Item label='Nombre'>{fine?.datos?.funcionario?.nombreCompleto ?? 'NO APLICA'}</Descriptions.Item>
                <Descriptions.Item label='Fecha de Nacimiento'>
                  {fine?.datos?.funcionario?.fechaNacimiento
                    ? moment(fine?.datos?.funcionario?.fechaNacimiento).format('DD-MM-YYYY')
                    : 'NO APLICA'}
                </Descriptions.Item>
                <Descriptions.Item label='Correo Electrónico'>
                  {fine?.datos?.funcionario?.correo ?? 'NO APLICA'}
                </Descriptions.Item>
                <Descriptions.Item label='Teléfono Fijo'>{fine?.datos?.funcionario?.telefono ?? 'NO APLICA'}</Descriptions.Item>
                <Descriptions.Item label='Teléfono Movil'>
                  {fine?.datos?.funcionario?.telefonoMovil ?? 'NO APLICA'}
                </Descriptions.Item>
                <Descriptions.Item label='Tipo de Licencia'>
                  {fine?.datos?.funcionario?.tipoLicencia ?? 'NO APLICA'}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={24}>
              <Descriptions bordered column={4} title='Datos del Vehiculo'>
                <Descriptions.Item label='Placa'>{fine?.datos?.funcionario?.placa ?? 'NO APLICA'}</Descriptions.Item>
                <Descriptions.Item label='Marca'>{fine?.datos?.funcionario?.marca ?? 'NO APLICA'}</Descriptions.Item>
                <Descriptions.Item label='Modelo'>{fine?.datos?.funcionario?.modelo ?? 'NO APLICA'}</Descriptions.Item>
                <Descriptions.Item label='Año'>{fine?.datos?.funcionario?.ano ?? 'NO APLICA'}</Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={24}>
              <Descriptions bordered column={2} title='Datos de la infracción'>
                <Descriptions.Item label='Lugar'>{fine?.datos?.funcionario?.lugar ?? 'NO APLICA'}</Descriptions.Item>
                <Descriptions.Item label='Parroquia'>{fine?.datos?.funcionario?.parroquia ?? 'NO APLICA'}</Descriptions.Item>
                <Descriptions.Item label='Fecha y Hora'>
                  {moment(fine?.datos?.funcionario?.fechaHora).format('DD-MM-YYYY hh:mm A') ?? 'NO APLICA'}
                </Descriptions.Item>
                <Descriptions.Item label='Detalles'>{fine?.datos?.funcionario?.detalle ?? 'NO APLICA'}</Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>

          {fine?.datos?.funcionario?.infraccion && fine?.datos?.funcionario?.infraccion.length > 0 && (
            <>
              <Divider orientation='left' style={{ marginLeft: 0, marginBottom: 10 }}>
                <Typography.Title level={4}>Infracciones</Typography.Title>
              </Divider>
              <Collapse accordion activeKey={activeKey} onChange={(c) => setActiveKey(c as string)}>
                {fine?.datos?.funcionario?.infraccion?.map((f, index) => (
                  <Panel
                    header={`Infracción #${index + 1} `}
                    key={index}
                    forceRender
                    extra={
                      canDelete.includes(auth.user?.institucion?.cargo?.id || 0) && (
                        <Button
                          type='danger'
                          onClick={() => {
                            setDeleting({ showModal: true, index, infraccion: f });
                          }}
                          loading={loading}
                        >
                          Eliminar
                        </Button>
                      )
                    }
                  >
                    <Descriptions column={1} bordered>
                      <Descriptions.Item label='Gaceta'>{f?.gaceta?.descripcion ?? 'NO APLICA'}</Descriptions.Item>
                      <Descriptions.Item label='Articulo'>{f?.articulo?.articulo ?? 'NO APLICA'}</Descriptions.Item>
                      <Descriptions.Item label='Numeral'>
                        {f?.numeral ? `${f?.numeral?.numeral}: ${f?.numeral?.descripcion}` : 'NO APLICA'}
                      </Descriptions.Item>
                      <Descriptions.Item label='Literal'>
                        {f?.literal ? `${f?.literal?.literal}: ${f?.literal?.descripcion}` : 'NO APLICA'}
                      </Descriptions.Item>
                    </Descriptions>
                  </Panel>
                ))}
              </Collapse>
            </>
          )}

          <Button onClick={handlerBackButton} style={{ marginTop: 10 }}>
            Atrás
          </Button>
        </>
      )}
      <Modal
        visible={deleting.showModal}
        onCancel={() => restStateDelete()}
        title='Eliminar Infracción'
        width={width < 992 ? '100%' : '40%'}
        onOk={() => deleteInfraccion()}
        okButtonProps={{ icon: <DeleteFilled />, children: 'Eliminar', type: 'danger', loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Alert
              style={{ fontWeight: 'bolder' }}
              type='error'
              message='ESTA ACCIÓN ES POTENCIALMENTE DESTRUCTIVA Y NO ES REVERSIBLE.'
            />
          </Col>
          {/* <Col span={24}>
            <TextArea
              value={observaciones ?? ''}
              onChange={(e) => setObservaciones(e.target.value)}
              style={{ marginTop: '20px' }}
              rows={4}
              placeholder='Observaciones...'
              autoSize={{ minRows: 4, maxRows: 6 }}
              allowClear
            />
          </Col> */}
          <Col>
            <Descriptions column={1} bordered>
              <Descriptions.Item label='Gaceta'>{deleting?.infraccion?.gaceta?.descripcion ?? 'NO APLICA'}</Descriptions.Item>
              <Descriptions.Item label='Articulo'>{deleting?.infraccion?.articulo?.articulo ?? 'NO APLICA'}</Descriptions.Item>
              <Descriptions.Item label='Numeral'>
                {deleting?.infraccion?.numeral
                  ? `${deleting?.infraccion?.numeral?.numeral}: ${deleting?.infraccion?.numeral?.descripcion}`
                  : 'NO APLICA'}
              </Descriptions.Item>
              <Descriptions.Item label='Literal'>
                {deleting?.infraccion?.literal
                  ? `${deleting?.infraccion?.literal?.literal}: ${deleting?.infraccion?.literal?.descripcion}`
                  : 'NO APLICA'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        {fine &&
          deleting &&
          !deleting?.infraccion?.numeral?.ut &&
          fine?.datos?.funcionario?.infraccion?.some((s) => s?.numeral?.ut) && (
            <Row style={{ marginTop: '15px' }} gutter={[16, 16]}>
              <Col span={24}>
                <Alert
                  style={{ fontWeight: 'bolder' }}
                  type='info'
                  message='Esta multa posee una infraccion con un calculo de unidades en base al valor UT, antes de eliminar la infraccion debe ingresar la unidades correspondiente a la infraccion'
                />
              </Col>
              <Col>
                <Descriptions column={1} bordered>
                  <Descriptions.Item label='Unidades'>
                    <InputNumber
                      onChange={(value) => {
                        handleUnidad(value);
                      }}
                      placeholder='Unidades'
                      type='number'
                      step='1'
                      style={{ width: '100%' }}
                      value={unidades}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label='Monto Por Unidad MMV:'>{UT}</Descriptions.Item>
                  <Descriptions.Item label='Monto A Pagar MMV:'>{montoMultaUT}</Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          )}
      </Modal>
      <Modal
        visible={deleteFine}
        title='Eliminar Multa'
        width={width < 992 ? '100%' : '40%'}
        onOk={() => handleDeleteFine()}
        onCancel={handleModalDeleteFine}
        okButtonProps={{ icon: <DeleteFilled />, children: 'Eliminar', type: 'danger', loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <Row style={{ marginTop: '15px' }} gutter={[16, 16]}>
          <Col span={24}>
            <Alert
              style={{ fontWeight: 'bolder' }}
              type='error'
              message='ESTA ACCIÓN ES POTENCIALMENTE DESTRUCTIVA Y NO ES REVERSIBLE.'
            />
          </Col>
          <Col span={24}>
            <Input.TextArea
              value={observaciones ?? ''}
              onChange={(e) => setObservaciones(e.target.value)}
              style={{ marginTop: '20px' }}
              rows={4}
              placeholder='Observaciones...'
              autoSize={{ minRows: 4, maxRows: 6 }}
              allowClear
            />
          </Col>
        </Row>
      </Modal>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps, { deleteFineInfraction, changeFineStatus, changeFineCertificate })(AmendFine);

interface AmendFineProps {
  thm: States.ThemeColors;
  auth: States.Auth;
  deleteFineInfraction;
  changeFineStatus;
  changeFineCertificate;
}
