import React from "react";
import { Result, Card } from "antd";

const NotFound: React.FC = () => {
  return (
    <Card>
      <Result
        status="404"
        title="Error 404 No encontrado"
        subTitle="La ruta a la cual quiere acceder no existe o es invalida"
      />
    </Card>
  );
};

export default NotFound;
