import React, { useState, useEffect } from 'react';
import { Card, Form, Divider, Typography, Row, Col, Input, Select, DatePicker, Checkbox, Button, message } from 'antd';
import { useWindowDimensions } from '../../utils/hooks';
import { Utils } from '../../utils/validators';
import moment from 'moment';
import { State, States } from 'sigt';
import { connect } from 'react-redux';
import { SaveOutlined } from '@ant-design/icons';
import { createSocialAffair, updateSocialAffair } from '../../redux/actions/procedures';
import { useParams, useHistory } from 'react-router';

const AffairsForm: React.FC<AffairsFormProps> = ({ parishes, auth, createSocialAffair, procedures, updateSocialAffair, thm, editable = true }) => {
  const [nacionalidadSolicitante, setNacionalidadSolicitante] = useState<string>('V');
  const [nacionalidad, setNacionalidad] = useState<string>('V');
  const [pobInd, setPobInd] = useState<boolean>(false);
  const [discapacidad, setDiscapacidad] = useState<boolean>(false);
  const [isMenor, setIsMenor] = useState<boolean>(false);
  const [poseeCedula, setPoseeCedula] = useState<boolean>(false);
  const [nacionalidadMenor, setNacionalidadMenor] = useState<string>('V');
  const [discapacidadMenor, setDiscapacidadMenor] = useState<boolean>(false);
  const [empleadoAlcaldia, setEmpleadoAlcaldia] = useState<boolean>(false);
  const [estado, setEstado] = useState<string>('iniciado');

  const [form] = Form.useForm();
  const { width } = useWindowDimensions();
  const { id } = useParams();
  const history = useHistory();

  const instance = procedures.find(p => p.id === parseInt(id || '0'));

  useEffect(() => {
    if(procedures.length > 0 && !instance && id) {
      history.push('/dashboard/bandejas');
    } else if(instance) {
      setEstado(instance.estado);
      const _datos = Object.assign({}, instance.datos);
      Object.keys(_datos).forEach(k => {
        if(typeof _datos[k] === 'object') {
          Object.keys(_datos[k]).forEach(sk => _datos[`${k}.${sk}`] = _datos[k][sk]);
          delete _datos[k];
        } 
      });
      Object.keys(_datos).forEach(k => {
        if(k.includes('fecha')) _datos[k] = moment(_datos[k]);
      });
      if(_datos.hasOwnProperty('menorDeEdad.poseeCedula')) {
        setIsMenor(true);
        setPoseeCedula(_datos['menorDeEdad.poseeCedula']);
        setDiscapacidadMenor(_datos['menorDeEdad.discapacidad']);
        setNacionalidadMenor(_datos.nacionalidadMenor);
      }
      setPobInd(_datos.poblacionIndigena);
      setDiscapacidad(_datos.discapacidad);
      setEmpleadoAlcaldia(_datos.empleadoAlcaldia);
      setNacionalidad(_datos.nacionalidadBeneficiario);
      setNacionalidadSolicitante(_datos.nacionalidadSolicitante);
      form.setFieldsValue(_datos);
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [id, procedures]);

  const handleSubmit = async () => {
    const values = await form.validateFields();
    values['poblacionIndigena'] = pobInd;
    values['discapacidad'] = discapacidad;
    values['menorDeEdad.poseeCedula'] = poseeCedula;
    values['menorDeEdad.discapacidad'] = discapacidadMenor;
    values['empleadoAlcaldia'] = empleadoAlcaldia;
    values.isMenor = isMenor;
    values.nacionalidadSolicitante = nacionalidadSolicitante;
    values.nacionalidadMenor = nacionalidadMenor;
    values.nacionalidadBeneficiario = nacionalidad;
    formatValues(values);
    Object.keys(values).forEach(k => {
      if(moment.isMoment(values[k])) {
        values[k] = values[k].toISOString();
      }
    });
    !instance ? 
      createSocialAffair({ caso: { datos: values, tipoTramite: 0 } }, auth.token || '') : 
      updateSocialAffair({ caso: { id, estado, datos: values } }, auth.token || '');
    message.success(instance ? 'Caso social actualizado de manera exitosa' : 'Caso social creado de manera exitosa');
    history.push('/dashboard/bandejas');
  };

  const formatValues = (values) => {
    values.menorDeEdad = values.isMenor ? {
      nombreCompleto: values['menorDeEdad.nombreCompleto'],
      poseeCedula: values['menorDeEdad.poseeCedula'],
      fechaNacimiento: values['menorDeEdad.fechaNacimiento'] ? values['menorDeEdad.fechaNacimiento'].toISOString() : undefined,
      cedula: values['menorDeEdad.cedula'],
      edad: values['menorDeEdad.edad'],
      nacionalidad: values['menorDeEdad.nacionalidad'],
      sexo: values['menorDeEdad.sexo'],
      nivelEscolarizacion: values['menorDeEdad.nivelEscolarizacion'],
      discapacidad: values['menorDeEdad.discapacidad'],
      tipoDiscapacidad: values['menorDeEdad.tipoDiscapacidad'],
      areaDeSalud: values['menorDeEdad.areaDeSalud'],
      patologiaActual: values['menorDeEdad.patologiaActual']
    } : undefined;
    values.solicitante = {
      nombreCompleto: values['solicitante.nombreCompleto'],
      cedula: values['solicitante.cedula'],
      direccion: values['solicitante.direccion']
    };
    Object.keys(values).forEach(k => {
      if(k.startsWith('menorDeEdad.') || k.startsWith('solicitante.')) delete values[k]
    })
  };

  const cedulaSolicitanteAddon = (
    <Select open={!editable ? false : undefined} defaultValue='V' value={nacionalidadSolicitante} onChange={(e) => setNacionalidadSolicitante(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
    </Select>
  );

  const cedulaAddon = (
    <Select open={!editable ? false : undefined} defaultValue='V' value={nacionalidad} onChange={(e) => setNacionalidad(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
    </Select>
  );

  const cedulaMenorAddon = (
    <Select open={!editable ? false : undefined} defaultValue='V' value={nacionalidadMenor} onChange={(e) => setNacionalidadMenor(e)} disabled={!poseeCedula}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
    </Select>
  );

  const getStates = () => {
    switch(instance?.estado) {
      case 'iniciado': 
        return (
          <React.Fragment>
            <Select.Option value='iniciado'>Iniciado</Select.Option>
            <Select.Option value='visto'>Visto</Select.Option>
            <Select.Option value='aprobado'>Aprobado</Select.Option>
            <Select.Option value='negado'>Negado</Select.Option>
          </React.Fragment>
        );
      case 'porrevisar':
        return (
          <React.Fragment>
            <Select.Option value='porrevisar'>Por Revisar</Select.Option>
            <Select.Option value='visto'>Visto</Select.Option>
            <Select.Option value='aprobado'>Aprobado</Select.Option>
            <Select.Option value='negado'>Negado</Select.Option>
          </React.Fragment>
        );
      case 'visto':
        return (
          <React.Fragment>
            <Select.Option value='visto'>Visto</Select.Option>
            <Select.Option value='aprobado'>Aprobado</Select.Option>
            <Select.Option value='negado'>Negado</Select.Option>
          </React.Fragment>
        );
      case 'aprobado': 
        return (
          <React.Fragment>
            <Select.Option value='aprobado'>Aprobado</Select.Option>
            <Select.Option value='atendido'>Atendido</Select.Option>
          </React.Fragment>
        );
      case 'atendido': return <Select.Option value='atendido'>Atendido</Select.Option>
      case 'negado': return <Select.Option value='negado'>Negado</Select.Option>
      default: return null;
    }
  }

  const selectEstado = (
    <Col xs={24} xl={4}>
      <Select placeholder='Estado' open={!editable ? false : undefined} defaultValue={instance?.estado} value={estado} onChange={(e) => setEstado(e)}>
        {getStates()}
      </Select>
    </Col>
  );

  const copyValues = () => {
    form.setFieldsValue({
      'nombreCompleto': form.getFieldValue('solicitante.nombreCompleto'),
      'cedula': form.getFieldValue('solicitante.cedula')
    });
    setNacionalidad(nacionalidadSolicitante);
  };

  return (
    <Card style={{ height: '100%' }} title='Casos Sociales' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}}>
      <Form scrollToFirstError form={form} layout='vertical' style={{ padding: width < 992 ? '0 10px' : '0px 20px' }} onFinish={handleSubmit}>
        <Row gutter={24}>
          {instance &&
          <React.Fragment>
            <Typography.Title level={4}>
              Estado del Caso Social: &nbsp;
            </Typography.Title>
            {selectEstado}
          </React.Fragment>}
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Datos del Solicitante
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={6}>
            <Form.Item label='Nombre y Apellido' name='solicitante.nombreCompleto' rules={[{ required: true, message: 'Debe ingresar el nombre del solicitante' }]}>
              <Input readOnly={!editable} placeholder='Nombre y Apellido' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item label='Cedula' name='solicitante.cedula' rules={[{ required: true, message: 'Debe ingresar el numero de cedula del solicitante' }, 
              { validator: Utils.validators.cedula }]} normalize={Utils.normalize.isNumber}>
              <Input readOnly={!editable} placeholder='Cedula' addonBefore={cedulaSolicitanteAddon} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
            <Form.Item label='Dirección' name='solicitante.direccion' rules={[{ required: true, message: 'Debe ingresar la dirección del solicitante' }]}>
              <Input readOnly={!editable} placeholder='Dirección' />
            </Form.Item>
          </Col>
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Identificación del Caso
            </Typography.Title>
          </Divider>
          {editable && <Col span={24}>
            <Button style={{ marginBottom: 15 }} type='primary' onClick={() => copyValues()}>Es la misma persona que solicita</Button>
          </Col>}
          <Col xs={24} xl={8}>
            <Form.Item label='Nombres y Apellidos' name='nombreCompleto' rules={[{ required: true, message: 'Debe ingresar el nombre completo y apellido' }]}>
              <Input readOnly={!editable} placeholder='Nombres y Apellidos' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Cedula' name='cedula' rules={[{ required: true, message: 'Debe ingresar el numero de cedula' }, 
              { validator: Utils.validators.cedula }]} normalize={Utils.normalize.isNumber}>
              <Input readOnly={!editable} placeholder='Cedula' addonBefore={cedulaAddon} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Fecha de Nacimiento' name='fechaNacimiento' rules={[{ required: true, message: 'Debe ingresar la fecha de nacimiento' }]}>
              <DatePicker disabled={!editable} style={{ width: '100%' }} placeholder='Fecha de Nacimiento' disabledDate={(current) => current > moment().endOf('day')} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={4}>
            <Form.Item label='Edad' name='edad' rules={[{ required: true, message: 'Debe ingresar la edad' }]} normalize={Utils.normalize.isNumber}>
              <Input readOnly={!editable} placeholder='Edad' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item label='Nacionalidad' name='nacionalidad' rules={[{ required: true, message: 'Debe ingresar la nacionalidad' }]}>
              <Input readOnly={!editable} placeholder='Nacionalidad' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={4}>
            <Form.Item label='Sexo' name='sexo' rules={[{ required: true, message: 'Debe ingresar el sexo' }]}>
              <Select open={!editable ? false : undefined} placeholder='Sexo'>
                <Select.Option value='true'>Masculino</Select.Option>
                <Select.Option value='false'>Femenino</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={4}>
            <Form.Item label='Población Indígena' name='poblacionIndigena'>
              <Checkbox disabled={!editable} checked={pobInd} onChange={(e) => setPobInd(e.target.checked)}>Población Indígena</Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item label='Etnia' name='etnia' rules={[{ required: pobInd, message: 'Debe ingresar la etnia' }]}>
              <Input readOnly={!editable} placeholder='Etnia' disabled={!pobInd} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item label='Profesión' name='profesion' rules={[{ required: true, message: 'Debe ingresar la profesión' }]}>
              <Input readOnly={!editable} placeholder='Profesión' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item label='Oficio' name='oficio' rules={[{ required: true, message: 'Debe ingresar el oficio' }]}>
              <Input readOnly={!editable} placeholder='Oficio' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={4}>
            <Form.Item label='Estado Civil' name='estadoCivil' rules={[{ required: true, message: 'Debe ingresar el estado civil' }]}>
              <Select open={!editable ? false : undefined} placeholder='Estado Civil'>
                <Select.Option value='casado'>Casado</Select.Option>
                <Select.Option value='soltero'>Soltero</Select.Option>
                <Select.Option value='divorciado'>Divorciado</Select.Option>
                <Select.Option value='viudo'>Viudo</Select.Option>
                <Select.Option value='union'>Unión Estable de Hecho</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Nivel de Instrucción' name='nivelInstruccion' rules={[{ required: true, message: 'Debe ingresar el nivel de instrucción' }]}>
              <Select open={!editable ? false : undefined} placeholder='Nivel de Instrucción'>
                <Select.Option value='analfabeto'>Analfabeto</Select.Option>
                <Select.Option value='primaria'>Primaria</Select.Option>
                <Select.Option value='bachiller'>Bachiller</Select.Option>
                <Select.Option value='tsu'>TSU</Select.Option>
                <Select.Option value='universitaria'>Universitaria</Select.Option>
                <Select.Option value='postgrado'>Post Grado</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={4}>
            <Form.Item label='Discapacidad' name='discapacidad'>
              <Checkbox disabled={!editable} checked={discapacidad} onChange={(e) => setDiscapacidad(e.target.checked)}>Discapacidad</Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item label='La discapacidad es' name='estadoDiscapacidad' rules={[{ required: discapacidad, message: 'Debe indicar si la discapacidad es temporal o permanente' }]}>
              <Select open={!editable ? false : undefined} placeholder='La discapacidad es' disabled={!discapacidad}>
                <Select.Option value='permanente'>Permanente</Select.Option>
                <Select.Option value='temporal'>Temporal</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item label='Tipo de Discapacidad' name='tipoDiscapacidad' rules={[{ required: discapacidad, message: 'Debe ingresar el tipo de discapacidad' }]}>
              <Select open={!editable ? false : undefined} placeholder='Tipo de Discapacidad' disabled={!discapacidad}>
                <Select.Option value='auditiva'>Auditiva</Select.Option>
                <Select.Option value='visual'>Visual</Select.Option>
                <Select.Option value='motora'>Motora</Select.Option>
                <Select.Option value='cognitiva'>Cognitiva</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Condición Laboral' name='condicionLaboral' rules={[{ required: true, message: 'Debe ingresar la condición laboral' }]}>
              <Select open={!editable ? false : undefined} placeholder='Condición Laboral'>
                <Select.Option value='publico'>Empleado Público</Select.Option>
                <Select.Option value='privado'>Empleado Privado</Select.Option>
                <Select.Option value='desempleado'>Desempleado</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={4}>
            <Form.Item label='Empleado de la Alcaldía' name='empleadoAlcaldia'>
              <Checkbox disabled={!editable} checked={empleadoAlcaldia} onChange={(e) => setEmpleadoAlcaldia(e.target.checked)}>Empleado de la Alcaldía</Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Asignaciones Económicas' name='asignacionesEconomicas' rules={[{ required: true, message: 'Debe ingresar las asignaciones económicas' }]}>
              <Select open={!editable ? false : undefined} placeholder='Asignaciones Económicas'>
                <Select.Option value='ivss'>Pensión IVSS</Select.Option>
                <Select.Option value='amorMayor'>Pensión <b>"Con Amor Mayor"</b></Select.Option>
                <Select.Option value='bonos'>Sistema de Bonos de la Patria</Select.Option>
                <Select.Option value='otros'>Otros</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
            <Form.Item label='Razón de la Solicitud' name='razonDeSolicitud' rules={[{ required: true, message: 'Debe ingresar la razón de la solicitud' }]}>
              <Input readOnly={!editable} placeholder='Razón de la Solicitud' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
            <Form.Item label={<span>Patología Actual <b>(SOLO SI APLICA)</b></span>} name='patologiaActual'>
              <Input readOnly={!editable} placeholder='Patología Actual' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item label='Área de Salud' name='areaDeSalud' rules={[{ required: true, message: 'Debe ingresar el área de salud' }]}>
              <Select open={!editable ? false : undefined} placeholder='Área de Salud'>
                <Select.Option value='traumatologia'>Traumatología</Select.Option>
                <Select.Option value='ginecoObstetricia'>Gineco-Obstetricia</Select.Option>
                <Select.Option value='psiquiatria'>Psiquiatría</Select.Option>
                <Select.Option value='oncologia'>Oncología</Select.Option>
                <Select.Option value='cardiologia'>Cardiología</Select.Option>
                <Select.Option value='neurologia'>Neurología</Select.Option>
                <Select.Option value='otros'>Otros</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Información de Contacto
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={10}>
            <Form.Item label='Dirección' name='direccion' rules={[{ required: true, message: 'Debe ingresar la dirección' }]}>
              <Input readOnly={!editable} placeholder='Dirección' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item label='Parroquia' name='parroquia' rules={[{ required: true, message: 'Debe seleccionar una Parroquia' }]}>
              <Select open={!editable ? false : undefined} placeholder='Parroquia' showSearch>
                {parishes.map(p => 
                  <Select.Option value={p.nombre} key={p.id}>
                    {p.nombre}
                  </Select.Option>  
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Teléfono' name='telefono' rules={[{ required: true, message: 'Debe ingresar el teléfono' }, 
            { validator: Utils.validators.phoneNumber }]} normalize={Utils.normalize.isNumber}>
              <Input readOnly={!editable} placeholder='Teléfono' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Correo Eléctronico' name='email' rules={[{ required: true, message: 'Debe ingresar el correo eléctronico' }, 
            { validator: Utils.validators.email }]}>
              <Input readOnly={!editable} placeholder='Correo Eléctronico' />
            </Form.Item>
          </Col>
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Identificación del caso si es menor de edad
            </Typography.Title>
            <Col xs={24} xl={8}>
              <Checkbox disabled={!editable} checked={isMenor} onChange={(e) => setIsMenor(e.target.checked)}>El beneficiario del caso es un menor de edad</Checkbox>
            </Col>
          </Divider>
          {isMenor && <React.Fragment>
            <Col xs={24} xl={10}>
              <Form.Item label='Nombres y Apellidos' name='menorDeEdad.nombreCompleto' rules={[{ required: true, message: 'Debe ingresar los nombres y apellidos del menor' }]}>
                <Input readOnly={!editable} placeholder='Nombre y Apellidos' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={4}>
              <Form.Item label='Posee cedula' name='menorDeEdad.poseeCedula'>
                <Checkbox disabled={!editable} checked={poseeCedula} onChange={(e) => setPoseeCedula(e.target.checked)}>Posee cedula</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} xl={10}>
              <Form.Item label='Cedula' name='menorDeEdad.cedula' rules={[{ required: poseeCedula, message: 'Debe ingresar el numero de cedula del menor' }, 
                { validator: Utils.validators.cedula }]} normalize={Utils.normalize.isNumber}>
                <Input readOnly={!editable} placeholder='Cedula' addonBefore={cedulaMenorAddon} disabled={!poseeCedula} />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item label='Fecha de Nacimiento' name='menorDeEdad.fechaNacimiento' rules={[{ required: true, message: 'Debe ingresar la fecha de nacimiento del menor' }]}>
                <DatePicker disabled={!editable} style={{ width: '100%' }} placeholder='Fecha de Nacimiento' disabledDate={(current) => current > moment().endOf('day')} />
              </Form.Item>
            </Col>
            <Col xs={24} xl={4}>
              <Form.Item label='Edad' name='menorDeEdad.edad' rules={[{ required: true, message: 'Debe ingresar la edad' }]} normalize={Utils.normalize.isNumber}>
                <Input readOnly={!editable} placeholder='Edad' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={6}>
              <Form.Item label='Nacionalidad' name='menorDeEdad.nacionalidad' rules={[{ required: true, message: 'Debe ingresar la nacionalidad' }]}>
                <Input readOnly={!editable} placeholder='Nacionalidad' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={6}>
              <Form.Item label='Sexo' name='menorDeEdad.sexo' rules={[{ required: true, message: 'Debe ingresar el sexo' }]}>
                <Select open={!editable ? false : undefined} placeholder='Sexo'>
                  <Select.Option value='true'>Masculino</Select.Option>
                  <Select.Option value='false'>Femenino</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item label='Nivel de Escolarización' name='menorDeEdad.nivelEscolarizacion' rules={[{ required: true, message: 'Debe ingresar el nivel de escolarización' }]}>
                <Select open={!editable ? false : undefined} placeholder='Nivel de Escolarización'>
                  <Select.Option value='inicial'>Inicial</Select.Option>
                  <Select.Option value='basica'>Básica</Select.Option>
                  <Select.Option value='media'>Media</Select.Option>
                  <Select.Option value='diversificada'>Diversificada</Select.Option>
                  <Select.Option value='noaplica'>No Aplica</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={4}>
              <Form.Item label='Discapacidad' name='menorDeEdad.discapacidad'>
                <Checkbox disabled={!editable} checked={discapacidadMenor} onChange={(e) => setDiscapacidadMenor(e.target.checked)}>Discapacidad</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} xl={6}>
              <Form.Item label='Tipo de Discapacidad' name='menorDeEdad.tipoDiscapacidad' rules={[{ required: discapacidadMenor, 
                message: 'Debe ingresar el tipo de discapacidad del menor' }]}>
                <Select open={!editable ? false : undefined} placeholder='Tipo de Discapacidad' disabled={!discapacidadMenor}>
                  <Select.Option value='auditiva'>Auditiva</Select.Option>
                  <Select.Option value='visual'>Visual</Select.Option>
                  <Select.Option value='motora'>Motora</Select.Option>
                  <Select.Option value='cognitiva'>Cognitiva</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={6}>
              <Form.Item label='Área de Salud' name='menorDeEdad.areaDeSalud' rules={[{ required: true, message: 'Debe ingresar el área de salud' }]}>
                <Select open={!editable ? false : undefined} placeholder='Área de Salud'>
                  <Select.Option value='traumatologia'>Traumatología</Select.Option>
                  <Select.Option value='ginecoObstetricia'>Gineco-Obstetricia</Select.Option>
                  <Select.Option value='psiquiatria'>Psiquiatría</Select.Option>
                  <Select.Option value='oncologia'>Oncología</Select.Option>
                  <Select.Option value='cardiologia'>Cardiología</Select.Option>
                  <Select.Option value='neurologia'>Neurología</Select.Option>
                  <Select.Option value='otros'>Otros</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item label={<span>Patología Actual <b>(SOLO SI APLICA)</b></span>} name='menorDeEdad.patologiaActual'>
                <Input readOnly={!editable} placeholder='Patología Actual' />
              </Form.Item>
            </Col>
          </React.Fragment>}
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Tipo de Ayuda y Referencia
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={6}>
            <Form.Item label='Tipo de Ayuda' name='tipoAyuda' rules={[{ required: true, message: 'Debe ingresar el tipo de ayuda' }]}>
              <Select open={!editable ? false : undefined} placeholder='Tipo de Ayuda' showSearch>
                <Select.Option value='alimentos'>Alimentos</Select.Option>
                <Select.Option value='salud'>Salud</Select.Option>
                <Select.Option value='ayudas tecnicas'>Ayudas Técnicas</Select.Option>
                <Select.Option value='kit recien nacido'>Kit para Recién Nacido</Select.Option>
                <Select.Option value='visita social'>Visita Social</Select.Option>
                <Select.Option value='equipo peluqueria'>Equipos para Peluquería</Select.Option>
                <Select.Option value='juguetes articulos deportivos'>Juguetes y Artículos Deportivos</Select.Option>
                <Select.Option value='electrodomesticos'>Electrodomésticos</Select.Option>
                <Select.Option value='servicios funerarios'>Servicios Funerarios</Select.Option>
                <Select.Option value='servicios publicos'>Servicios Publicos</Select.Option>
                <Select.Option value='ayudas economicas'>Ayudas Económicas</Select.Option>
                <Select.Option value='empleo'>Empleo</Select.Option>
                <Select.Option value='becas estudiantiles'>Becas Estudiantiles</Select.Option>
                <Select.Option value='teconología'>Tecnología</Select.Option>
                <Select.Option value='servicio transporte'>Servicio de Transporte</Select.Option>
                <Select.Option value='kit escolar'>Kit Escolar</Select.Option>
                <Select.Option value='estudios de alto costo'>Estudios de Alto Costo</Select.Option>
                <Select.Option value='tanques'>Tanques</Select.Option>
                <Select.Option value='enseres'>Enseres</Select.Option>
                <Select.Option value='pañales para adultos'>Pañales para Adultos</Select.Option>
                <Select.Option value='otros'>Otros</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={18}>
            <Form.Item label='Especifique el Tipo de Ayuda' name='tipoAyudaDesc'>
              <Input readOnly={!editable} placeholder='Especifique el Tipo de Ayuda' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Referido por' name='referidoPor' rules={[{ required: true, message: 'Debe seleccionar por quien fue referido' }]}>
              <Select open={!editable ? false : undefined} placeholder='Referido por'>
                <Select.Option value='desarrollo social'>Director General de Desarrollo Social</Select.Option>
                <Select.Option value='director alcaldia'>Director General de la Alcaldía</Select.Option>
                <Select.Option value='directores generales'>Directores Generales</Select.Option>
                <Select.Option value='concejal'>Concejal</Select.Option>
                <Select.Option value='oaci'>Atención al Ciudadano</Select.Option>
                <Select.Option value='despacho'>Despacho del Alcalde</Select.Option>
                <Select.Option value='avanzada'>Avanzada</Select.Option>
                <Select.Option value='taquilla'>Taquilla</Select.Option>
                <Select.Option value='otro'>Otro</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {editable && <Button type='primary' icon={<SaveOutlined />} onClick={form.submit}>Guardar Caso Social</Button>}
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ parishes: state.prsh.parish, auth: state.auth, thm: state.thm, procedures: state.prcd.procedures })

export default connect(mapStateToProps, { createSocialAffair, updateSocialAffair })(AffairsForm);

interface AffairsFormProps {
  parishes: States.Parish['parish']
  auth: States.Auth
  thm: States.ThemeColors
  createSocialAffair: (data: any, token: string) => Promise<void>
  procedures: States.Procedures['procedures']
  updateSocialAffair: (data: any, token: string) => Promise<void>
  editable?: boolean
}