import React, { useState } from 'react';
import { connect } from 'react-redux';
import { States, State } from 'sigt';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Card, Typography, Row, Col, Divider, Button } from 'antd';
import moment from 'moment';
import { useWindowDimensions } from '../../utils/hooks';
import { useRouteMatch, useParams, useHistory } from 'react-router';
import { updateProcedure } from '../../redux/actions/procedures';
import handlingMessage from '../../utils/handlingMessage';

const InspectionForm : React.FC <InspectionFormProps> = ({auth, prcd, thm, updateProcedure}) => {
  const [report, setReport] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { width } = useWindowDimensions();
  const history = useHistory();
  useRouteMatch('/dashboard/procesarTramiteInspeccion/:idTramite');
  const { idTramite } = useParams<{ idTramite: any }>();

  const selectedPrcd = prcd.procedures.find(p => p.id === parseInt(idTramite || "0"));

  const onInspection = ({aprobado, rebotado} : {aprobado: boolean, rebotado?: boolean}) => {
    setLoading(true);
    const data = { 
      tramite: 
        { estado: 'enrevision', idTramite, tipoTramite: selectedPrcd?.tipoTramite,
          revision : { aprobado, observaciones: report },
          rebotado 
        } 
    };
    handlingMessage({
      action: () => updateProcedure(data, auth.token || '' ),
      key: 'denegar',
      loadingMessage: 'Realizando operación...',
      cb: () => {
        history.push('/dashboard/bandejas');
        setLoading(false);
      }          
    });
  }

  return <Card style={{ height: '100%' }} title={selectedPrcd?.nombreTramiteLargo} bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
  headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}}>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography.Text strong>Codigo de Tramite: {selectedPrcd?.codigoTramite}</Typography.Text>
      <Typography.Text strong>Fecha: {moment(selectedPrcd?.fechaCreacion).format('DD-MM-YYYY')}</Typography.Text>
    </div>
    <Divider orientation='left'>
      <Typography.Title level={4}>Informe de inspección</Typography.Title>
    </Divider>
    <div style={{ padding: width < 992 ? '0 10px' : '0px 20px' }} >
      <Row>
        <Col span={24}>
          <ReactQuill value={report || ''} onChange={(e) => setReport(e)} style={{ height: '40vh', marginBottom: '50px' }} />
        </Col>
      </Row>
      <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
        <Col>
          <Button onClick={() => history.goBack()}>Atrás</Button>
        </Col>
        <Row gutter={12}>
          <Col>
            <Button loading={loading} type='danger' onClick={() => onInspection({aprobado: false})}>Rechazar</Button>
          </Col>
          <Col>
            <Button loading={loading} type='primary' onClick={()=> onInspection({aprobado: true})}>Aprobar</Button>
          </Col>
        </Row>
      </Row>
    </div> 
  </Card>
}

const mapStateToProps = (state: State) => ({ auth: state.auth, prcd: state.prcd, thm: state.thm })

export default connect(mapStateToProps, {updateProcedure})(InspectionForm);

interface InspectionFormProps {
  auth: States.Auth
  prcd: States.Procedures
  thm: States.ThemeColors
  updateProcedure: (data: any, token: string) => Promise<void>
}