import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const getUserVehicles = async (data, token) => {
  try {
    const url = data.rim ? `getByRim/${data.rim}` : `getByRif/${data.doc}/${data.pref}`;
    const response = await axios.get(`${server}/vehicles/${url}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getVehicleByPlate = async (data, token) => {
  try {
    const response = await axios.get(`${server}/vehicles/getByPlate/${data.placa}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserVh = async (data, token) => {
  try {
    const params = `doc=${data.doc}&pref=${data.pref}`
    const response = await axios.get(`${server}/settlements/search/taxPayer?${params}`,{ headers: { Authorization: `Bearer ${token}` } });
    
    let dataContribuyente;
    const {razonSocial, documento, id} = response.data.contribuyentes[0];
    
    if(data.rim){
      dataContribuyente = response.data.contribuyentes[0].sucursales.filter(s => s.referenciaMunicipal === data.rim);
      if(!dataContribuyente[0]) throw Error('El contribuyente no posee un rim')
      dataContribuyente[0].razonSocial = razonSocial
      dataContribuyente[0].documento = documento
      dataContribuyente[0].idContribuyente = id
    }
    
    return data.rim ? {contribuyentes: dataContribuyente, status: response.data.status} : response.data;
  } catch (error) {
    throw error;
  }
}

export const getUser = async (data, token) => {
  try {
    const params = new URLSearchParams(data);
    const response = await axios.get(`${server}/user/search?${params}`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const editUser = async (data, token, id) => {
  try {
    const body = {usuario: data}
    const response = await axios.put(`${server}/user/${id}`, body,{ headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export const unLink = async (token, id) => {
  try {
    const response = await axios.patch(`${server}/user/contributor/${id}`, {},{ headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const blockUser = async (token, bloqueado, id) => {
  try {
    const response = await axios.patch(`${server}/user/block/${id}`, {bloqueado},{ headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const verifyNumber = async (cellphone, token, id) => {
  try {
    const response = await axios.post(`${server}/user/verify/${id}`, {cellphone},{ headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}