import React from 'react';
import { Select } from 'antd';

const PurposeSelect: React.FC<PurposeSelectProps> = ({ onChange, value, placeholder, disabled = false }) => {
  return (
    <Select value={value} onChange={onChange} placeholder={placeholder} disabled={disabled}>
      <Select.Option value='Actualización'>Actualización</Select.Option>
      <Select.Option value='Mismo Número'>Mismo Número</Select.Option>
      <Select.Option value='Nuevo Inmueble'>Nuevo Inmueble</Select.Option>
      <Select.Option value='Anexo'>Anexo</Select.Option>
      <Select.Option value='Local'>Local</Select.Option>
      <Select.Option value='Otro'>Otro</Select.Option>
    </Select>
  );
};

export default PurposeSelect;

interface PurposeSelectProps {
  onChange: (e: any) => any
  value: any
  placeholder: string
  disabled?: boolean
}