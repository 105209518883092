import { States } from "sigt";
import { CostTypes, SET_COST, SET_DESTINATION_COSTS, CREATE_DESTINATION_COST, EDIT_DESTINATION_COST } from "../actions/actionsTypes";

const intitialState: States.Costs = {
  costs: null,
  destinations: [],
  error: {}
};

export default (state = intitialState, action: CostTypes): States.Costs => {
  switch(action.type) {
    case SET_COST: return { ...state, costs: action.payload };
    case SET_DESTINATION_COSTS: return { ...state, destinations: action.payload }
    case CREATE_DESTINATION_COST: return {...state, destinations: [...state.destinations, action.payload]}
    case EDIT_DESTINATION_COST: 
      const dcIndex = state.destinations.findIndex((o) => o.id === action.payload.id);
      state.destinations[dcIndex] = Object.assign({}, action.payload);
      return {...state, destinations: [...state.destinations] };
    default: return state;
  }
};