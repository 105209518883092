import React, { useState, ReactNodeArray, useEffect } from 'react';
import {
  Form,
  Typography,
  Input,
  Button,
  Row,
  Col,
  Collapse,
  Slider,
  InputNumber,
  Divider,
  DatePicker,
  Empty,
  Descriptions,
  Card,
  message,
  Tooltip,
} from 'antd';
import { InfoCircleTwoTone, CheckOutlined, CloseOutlined, RollbackOutlined } from '@ant-design/icons';
import { fetchSettlementsForBenefit } from '../../../services/requests';
import RadioGroup from 'antd/lib/radio/group';
import RadioButton from 'antd/lib/radio/radioButton';
import moment from 'moment';
import SearchTaxPayer from '../../SearchTaxPayer';
import axios from 'axios';
import _ from 'lodash';
import { useHistory, useParams, useLocation } from 'react-router';
import { updateProcedure, setProcedure } from '../../../redux/actions/procedures';
import { State, States } from 'sigt';
import { useWindowDimensions } from '../../../utils/hooks';
import { connect, useSelector } from 'react-redux';
import handlingMessage from '../../../utils/handlingMessage';
import UserSelect from '../../Forms/components/UserSelect';
import Petro from '../../Icons/Petro';
const server = process.env.REACT_APP_SERVER_URL;

const { Panel } = Collapse;

const round8 = (number: number | string): string => {
  number = typeof number !== 'number' ? parseFloat(number) : number;
  return Number(number).toFixed(8);
};

const formatCurrency = (number: number | string) => {
  number = typeof number !== 'number' ? parseFloat(number) : number;
  return new Intl.NumberFormat('de-DE').format(Math.round((number + Number.EPSILON) * 100) / 100);
};

const initProcedure = async (tramite) => {
  try {
    const response = await axios.post(
      `${server}/procedures/init`,
      { tramite },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const BenefitTaxPayer: React.FC<BenefitTaxPayerProps> = ({ prcd, thm, auth, coin, updateProcedure, setProcedure }) => {
  const [contribuyente, setContribuyente] = useState<ContribuyenteProps | null>();
  const [activePanel, setActivePanel] = useState<number[]>([]);
  const [selectedBenefits, setSelectedBenefits] = useState({});
  const [loading, setLoading] = useState(false);
  const [benefitForm] = Form.useForm();
  const [form] = Form.useForm();
  const { idTramite } = useParams<{ idTramite: any }>();
  const selectedPrcd = prcd.procedures.find((p) => p.id === parseInt(idTramite || '0'));
  const location = useLocation();
  const history = useHistory();
  const [rejecting, setRejecting] = useState(true);
  const { width } = useWindowDimensions();

  const petro = useSelector((state: State) => state.coin.petro);

  useEffect(() => {
    let activesPanel: number[] = [];
    let selectedBenefits = {};
    let formValues;
    if (selectedPrcd) {
      formValues = selectedPrcd.datos?.funcionario?.beneficios;
      setContribuyente(selectedPrcd.datos?.funcionario?.contribuyente);
      Object.keys(selectedPrcd.datos?.funcionario?.beneficios).forEach((key) => {
        if (formValues[key].benefit === 'convenio') {
          Object.keys(formValues[key]).forEach((sk) => {
            if (sk.includes('fecha')) formValues[key][sk] = moment(formValues[key][sk]);
          });
        }
        selectedBenefits = { ...selectedBenefits, [key]: selectedPrcd.datos?.funcionario?.beneficios[key]?.benefit };
        if (selectedPrcd.datos?.funcionario?.beneficios[key].benefit)
          activesPanel.push(typeof key === 'number' ? key : parseInt(key));
      });
      benefitForm.setFieldsValue(formValues);
      setActivePanel(activesPanel);
      setSelectedBenefits(selectedBenefits);
    }
    //eslint-disable-next-line
  }, [selectedPrcd]);

  const onFinish = async () => {
    const values = await benefitForm.validateFields();
    let tramite;
    let beneficios: any[] = [];
    let error = false;
    if (rejecting) {
      Object.keys(values).forEach((id: string) => {
        const liq = contribuyente?.liquidaciones.find((l) => l.id === parseInt(id));
        if (values[id].benefit === 'convenio') {
          let accm = 0;
          Object.keys(values[id]).forEach((v) => {
            if (v.endsWith('monto')) {
              accm += typeof values[id][v] === 'string' ? parseFloat(values[id][v]) : values[id][v];
            }
          });
          if (+round8(accm) < +(liq?.monto || '0')) {
            error = true;
            message.error(
              `El monto total de las porciones del convenio creado para el ramo ${
                liq?.ramo || ''
              } no corresponde al total de la deuda`,
              5
            );
          }
        }
      });
    }

    if (!error) {
      if (selectedPrcd && location.pathname.includes('/dashboard/benefitTributables/revision')) {
        Object.keys(values).forEach((key) => {
          if (!values[key].benefit || values[key].benefit === 'none') {
            beneficios.push({ idRamo: key, tipoBeneficio: 'pagoCompleto' });
          } else if (values[key].benefit === 'convenio') {
            let porciones: any[] = [];
            Object.keys(values[key]).forEach((subkey) => {
              if (subkey.includes('_')) {
                let [porcionKey, valueKey] = subkey.split('_');
                let exist = _.findIndex(porciones, (o) => o.porcion === porcionKey);
                if (exist !== -1)
                  porciones[exist] = {
                    ...porciones[exist],
                    [valueKey]: valueKey.includes('fecha')
                      ? moment(values[key][subkey]).format('MM-DD-YYYY')
                      : values[key][subkey],
                  };
                else
                  porciones.push({
                    porcion: porcionKey,
                    [valueKey]: valueKey.includes('fecha')
                      ? moment(values[key][subkey]).format('MM-DD-YYYY')
                      : values[key][subkey],
                  });
              }
            });
            beneficios.push({ idRamo: key, tipoBeneficio: values[key].benefit, porciones });
          } else if (values[key].benefit === 'descuento') {
            beneficios.push({ idRamo: key, tipoBeneficio: values[key].benefit, porcDescuento: values[key].descuento / 100 });
          } else {
            beneficios.push({ idRamo: key, tipoBeneficio: values[key].benefit });
          }
        });

        tramite = {
          idTramite: selectedPrcd.id,
          tipoTramite: selectedPrcd.tipoTramite,
          estado: selectedPrcd.estado,
          revision: { aprobado: rejecting },
          datos: {
            funcionario: {
              beneficios,
              contribuyente: contribuyente,
            },
          },
        };
      } else {
        tramite = {
          datos: {
            beneficios: values,
            contribuyente: contribuyente,
            usuario: form.getFieldValue('usuario'),
          },
          tipoTramite: 26,
          documento: contribuyente?.documento,
          tipoDocumento: contribuyente?.tipoDocumento,
          rim: contribuyente?.registroMunicipal,
        };
      }

      setLoading(true);
      handlingMessage({
        action: () =>
          selectedPrcd && location.pathname.includes('/dashboard/benefitTributables/revision')
            ? updateProcedure({ tramite, idUsuario: auth.user?.id }, auth.token || '')
            : initProcedure(tramite),
        loadingMessage: 'Procesando...',
        key: 'updateProcedure',
        cb: (data) => {
          if (data?.tramite && !location.pathname.includes('benefitTributables/revision')) {
            setProcedure(data.tramite);
          }
          if (auth.user?.tipoUsuario === 2) {
            history.push('/dashboard/bandejas?tipo=beneficios');
          } else {
            setContribuyente(null);
          }
          setLoading(false);
        },
      });
    }
  };

  const onChangePerc = (id: number, value: number | undefined, monto: string | number) => {
    const idValues = benefitForm.getFieldValue(id);
    if (value) {
      const montoTotal = typeof monto === 'number' ? monto : parseFloat(monto);
      const totalBeforeDesc = montoTotal - (value / 100) * montoTotal;
      benefitForm.setFieldsValue({
        [id]: {
          ...idValues,
          totalBeforeDesc: round8(totalBeforeDesc),
          totalBeforeDescBs: formatCurrency(+round8(totalBeforeDesc) * petro),
        },
      });
    }
  };

  const renderSelectableBenefits = (id: number, monto: string) => {
    return (
      <Row gutter={10}>
        <Col>
          <Typography.Text> Beneficio: </Typography.Text>
        </Col>
        <Col>
          <Form.Item noStyle name={[id, 'benefit']}>
            <RadioGroup
              style={width < 480 ? { display: 'grid' } : {}}
              buttonStyle='solid'
              size='small'
              defaultValue='none'
              onChange={({ target: { value } }) => {
                if (value === 'descuento')
                  benefitForm.setFieldsValue({
                    [id]: {
                      descuento: 1,
                      totalBeforeDesc: round8(parseFloat(monto) * 0.99),
                      totalBeforeDescBs: formatCurrency(+round8(parseFloat(monto) * 0.99) * petro),
                    },
                  });
                setSelectedBenefits({ ...selectedBenefits, [id]: value });
                if (value !== 'none') {
                  setActivePanel([...activePanel, id]);
                } else {
                  setActivePanel(activePanel.filter((e) => e !== id));
                }
              }}
            >
              <RadioButton value='remision'>Remisión de deuda</RadioButton>
              <RadioButton value='convenio'>Convenio de pago</RadioButton>
              <RadioButton value='descuento'>Descuento</RadioButton>
              <RadioButton value='none'>No Aplicar</RadioButton>
            </RadioGroup>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const renderRemision = () => {
    return (
      <Typography.Text strong>
        <InfoCircleTwoTone /> Esta deuda será remitida{' '}
      </Typography.Text>
    );
  };

  const Convenio: React.FC<any> = ({ id, monto, cantPagos = 2 }) => {
    const [pagos, setPagos] = useState(cantPagos);
    const [inputAmount, setInputAmount] = useState<number>(0);

    useEffect(() => {
      getAmount();
      //eslint-disable-next-line
    }, []);

    const getAmount = () => {
      let accm = 0;
      const idValues = benefitForm.getFieldValue(id);
      Object.keys(idValues).forEach((e) => {
        if (e.endsWith('monto'))
          if (idValues[e] && !isNaN(idValues[e])) accm += typeof idValues[e] === 'string' ? parseFloat(idValues[e]) : idValues[e];
      });
      setInputAmount(+round8(accm));
    };

    const renderList = () => {
      const ele: ReactNodeArray = [];
      for (let i = 0; i < pagos && i < 10; i++) {
        ele.push(
          <React.Fragment key={i}>
            <Divider />
            <Row style={{ flexWrap: 'nowrap' }} key={i} gutter={[16, 16]}>
              <Col>
                <Button type='primary' shape='circle' icon={i + 1} />
              </Col>
              <Col flex={1}>
                <Row gutter={16}>
                  <Col xs={24} lg={12} xl={7}>
                    <Form.Item
                      label='Monto'
                      name={[id, `${i + 1}_monto`]}
                      rules={[
                        { required: true, message: 'Debe ingresar un monto' },
                        {
                          validator: (rule, value) =>
                            value && inputAmount > monto
                              ? Promise.reject(`Esta excediendo el monto por una diferencia de ${round8(inputAmount - monto)}`)
                              : Promise.resolve(),
                        },
                      ]}
                    >
                      <InputNumber style={{ width: '100%' }} onChange={() => getAmount()} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12} xl={7}>
                    <Form.Item
                      label='Fecha'
                      name={[id, `${i + 1}_fechaDePago`]}
                      rules={[{ required: true, message: 'Seleccione fecha de pago' }]}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'DD-MM-YYYY'}
                        disabledDate={(current) => current < moment().startOf('day')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </React.Fragment>
        );
      }
      return ele;
    };

    return (
      <>
        <Row gutter={[50, 16]} align='stretch'>
          <Col>
            <Typography.Title level={4}>Pagos</Typography.Title>
          </Col>
          <Col>
            <Row align='middle'>
              <span>
                <Typography.Text>Cantidad:</Typography.Text>
              </span>
              <Form.Item style={{ marginBottom: 0, marginLeft: 4 }} name={[id, 'pagos']}>
                <InputNumber
                  defaultValue={2}
                  onChange={(value) => typeof value === 'number' && setPagos(value)}
                  min={2}
                  max={10}
                />
              </Form.Item>
            </Row>
          </Col>
          <Col span={24}>
            <Typography.Text>Monto restante: </Typography.Text>
            <Typography.Text strong>{monto - inputAmount < 0 ? 0 : round8(monto - inputAmount)}</Typography.Text>
          </Col>
        </Row>
        {renderList()}
      </>
    );
  };

  const renderDescuento = (id: number, monto: string | number) => {
    return (
      <>
        <Typography.Text strong style={{ marginBottom: 8 }}>
          Descuento:
        </Typography.Text>
        <Row gutter={[16, 16]} align='middle'>
          <Col xs={24} lg={6} xl={4}>
            <Form.Item name={[id, 'descuento']} rules={[{ required: true, message: 'Debe establecer un valor' }]}>
              <InputNumber onChange={(value) => onChangePerc(id, value, monto)} min={1} max={50} />
            </Form.Item>
          </Col>
          <Col xs={22} lg={14} xl={10}>
            <Form.Item name={[id, 'descuento']}>
              <Slider
                onChange={(value) => (typeof value === 'number' ? onChangePerc(id, value, monto) : 0)}
                min={1}
                max={50}
                marks={{
                  1: '1%',
                  12: '12%',
                  25: '25%',
                  37: '37%',
                  50: '50%',
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Typography.Text strong>
          <InfoCircleTwoTone /> Monto despues de aplicar Descuento:{' '}
        </Typography.Text>
        <Row align='middle'>
          <Col>Petros:</Col>
          <Col>
            <Form.Item style={{ display: 'inline' }} noStyle name={[id, 'totalBeforeDesc']}>
              <Input readOnly style={{ border: 'none', color: 'red' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row align='middle'>
          <Col>Bs:</Col>
          <Col>
            <Form.Item style={{ display: 'inline' }} noStyle name={[id, 'totalBeforeDescBs']}>
              <Input readOnly style={{ border: 'none', color: 'red' }} />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };

  const content = (
    <>
      {!contribuyente && (
        <SearchTaxPayer
          searchAction={fetchSettlementsForBenefit}
          setData={({ contribuyente }) => setContribuyente(contribuyente)}
        />
      )}
      {contribuyente ? (
        <>
          <Descriptions title='Contribuyente'>
            <Descriptions.Item label='Razon Social '>{contribuyente?.razonSocial.replace(' null', '')}</Descriptions.Item>
            <Descriptions.Item label='Documento '>
              {contribuyente.tipoDocumento}-{contribuyente.documento}
            </Descriptions.Item>
            {contribuyente.registroMunicipal && (
              <Descriptions.Item label='R.I.M '>{contribuyente.registroMunicipal}</Descriptions.Item>
            )}
          </Descriptions>
          <Divider />
          {contribuyente.usuarios?.length > 0 && auth.user?.tipoUsuario !== 4 && (
            <Form style={{ marginBottom: 40 }} form={form} layout='vertical'>
              <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
                <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                  Usuario de S.U.T.
                </Typography.Title>
              </Divider>
              <UserSelect users={contribuyente.usuarios} form={benefitForm} />
            </Form>
          )}
          <Form onFinish={onFinish} form={benefitForm} layout='vertical'>
            <Row gutter={[8, 20]}>
              <Collapse style={{ width: '100%' }} activeKey={activePanel}>
                {contribuyente.liquidaciones?.map((e) => (
                  <Panel
                    key={e.id}
                    header={
                      <span>
                        <Row gutter={[0, 8]}>
                          <Typography.Text style={{ marginRight: 30 }} strong>
                            {e.ramo.toUpperCase()}
                          </Typography.Text>
                        </Row>
                        Monto:{' '}
                        <Typography.Text
                          type='danger'
                          delete={selectedBenefits[e.id] === 'remision' || selectedBenefits[e.id] === 'descuento'}
                        >
                          <Tooltip title={formatCurrency(Number(+e.monto * coin.petro).toFixed(2))}>{round8(e.monto)}</Tooltip>
                        </Typography.Text>
                        <Petro style={{ marginLeft: 4 }} />
                      </span>
                    }
                    showArrow={false}
                    extra={renderSelectableBenefits(e.id, e.monto)}
                  >
                    {selectedBenefits[e.id] === 'remision' ? (
                      renderRemision()
                    ) : selectedBenefits[e.id] === 'convenio' ? (
                      <Convenio id={e.id} monto={e.monto} cantPagos={benefitForm.getFieldValue(e.id)?.pagos || 2} />
                    ) : selectedBenefits[e.id] === 'descuento' ? (
                      renderDescuento(e.id, e.monto)
                    ) : null}
                  </Panel>
                ))}
              </Collapse>
            </Row>
            {location.pathname.includes('/dashboard/benefitTributables/revision/') ? (
              <Row justify='space-between'>
                <Col>
                  <Button onClick={() => history.goBack()} icon={<RollbackOutlined />}>
                    Atras
                  </Button>
                </Col>
                <Col>
                  <Row gutter={16}>
                    <Col>
                      <Button
                        htmlType='submit'
                        type='danger'
                        onClick={() => setRejecting(false)}
                        loading={loading}
                        icon={<CloseOutlined />}
                      >
                        Rechazar
                      </Button>
                    </Col>
                    <Col>
                      <Button htmlType='submit' type='primary' loading={loading} icon={<CheckOutlined />}>
                        Aprobar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Button htmlType='submit' type='primary' loading={loading} icon={<CheckOutlined />}>
                Finalizar
              </Button>
            )}
          </Form>
        </>
      ) : (
        <Empty style={{ marginTop: 50 }} />
      )}
    </>
  );

  return location.pathname.includes('/dashboard/benefitTributables/revision/') ? (
    <Card
      style={{ height: '100%' }}
      title={selectedPrcd?.nombreTramiteLargo}
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      {content}
    </Card>
  ) : (
    content
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth, prcd: state.prcd, coin: state.coin });

export default connect(mapStateToProps, { updateProcedure, setProcedure })(BenefitTaxPayer);

interface BenefitTaxPayerProps {
  thm: States.ThemeColors;
  auth: States.Auth;
  prcd: States.Procedures;
  coin: States.Coins;
  updateProcedure: (data: any, token: string) => Promise<void>;
  setProcedure: Function;
}

interface ContribuyenteProps {
  id: number;
  tipoDocumento: 'V' | 'E' | 'J' | 'G';
  documento: string;
  registroMunicipal?: string;
  razonSocial: string;
  denomComercial: string;
  siglas: string;
  sector: string;
  direccion: string;
  puntoReferencia: string;
  verificado: boolean;
  liquidaciones: Array<{ id: number; ramo: string; monto: string }>;
  totalDeuda: number;
  usuarios: { id: number; correo: string }[];
}
