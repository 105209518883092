import React, { useEffect, useState, useRef } from 'react';
import { EditOutlined, UserAddOutlined, SearchOutlined, UnlockOutlined, LockOutlined } from '@ant-design/icons';
import { Table, Divider, Tooltip, Button, Popconfirm, Card, Row, Input } from 'antd';
import { connect } from 'react-redux';
import { State, States, Response } from 'sigt';
import { useHistory } from 'react-router-dom';
import { fetchOfficials, deleteOfficial } from '../../redux/actions/official';
import { useWindowDimensions } from '../../utils/hooks';
import Highlighter from 'react-highlight-words';
import handlingMessage from '../../utils/handlingMessage';
import '../../assets/css/components/TableOfficial.css';

const TableOfficial: React.FC<PropsTable> = ({ thm, fetchOfficials, auth, ofc, deleteOfficial, inst }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColum] = useState('');
  const searchInput = useRef<any>(null);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const { width } = useWindowDimensions();
  const editOfc = (id) => {
    history.push(`/dashboard/funcionarios/editar/${id}`);
  };
  const data: DataArray[] = [];

  const deleteOfc = (id, bloqueado) => {
    handlingMessage({
      action: () => deleteOfficial(id, bloqueado, auth.token),
      key: 'delete',
      loadingMessage: 'Realizando operacion...',
      cb: (data) => {},
    });
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined onClick={() => {}} style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) => record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'Cedula',
      dataIndex: 'ced',
      key: 'ced',
      ...getColumnSearchProps('ced'),
    },
    {
      title: 'Nombre completo',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Telefono',
      dataIndex: 'phone',
      key: 'phone',
      ...getColumnSearchProps('phone'),
    },
    {
      title: 'Cargo',
      dataIndex: 'cargo',
      key: 'cargo',
      ...getColumnSearchProps('cargo'),
    },
    {
      title: 'Acciones',
      key: 'action',
      render: ({ key, bloqueado }) => (
        <span>
          <Tooltip placement='bottom' title='Editar usuario'>
            <Button type='link' icon={<EditOutlined />} onClick={() => editOfc(key)} />
          </Tooltip>
          <Divider type='vertical' />
          <Popconfirm title='Estas seguro？' onConfirm={() => deleteOfc(key, bloqueado)} okText='Si' cancelText='No'>
            <Tooltip placement='bottom' title={bloqueado ? 'Desbloquear usuario' : 'Bloquear usuario'}>
              <Button type='link' icon={bloqueado ? <LockOutlined /> : <UnlockOutlined />} />
            </Tooltip>
          </Popconfirm>
        </span>
      ),
    },
  ];

  if (width < 650) delete columns[2];

  useEffect(() => {
    aux();
    // eslint-disable-next-line
  }, [fetchOfficials, auth.token]);

  const aux = async () => {
    if (auth.token && auth.user?.tipoUsuario) {
      await fetchOfficials(auth.token, auth.user?.tipoUsuario);
    }
    setLoading(false);
  };

  ofc.officials?.map((official) =>
    data.push({
      key: official.id,
      ced: official.nacionalidad + '-' + official.cedula,
      name: official.nombreCompleto,
      username: official.nombreUsuario,
      phone: official.telefono,
      bloqueado: official.bloqueado,
      cargo: official?.institucion?.cargo?.descripcion || 'N/A',
    })
  );

  // useEffect(() => {
  //   console.log('12')
  //   setLoading(data.length > 0 ? false : true)
  // }, [data]);

  return (
    <Card
      title='Ver Funcionarios'
      headStyle={{ backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: '#fff' }}
      bodyStyle={{ overflowY: 'auto' }}
    >
      <Row gutter={[0, 20]}>
        <Button type='primary' onClick={() => history.push('/dashboard/funcionarios/crear')} icon={<UserAddOutlined />}>
          Crear Usuario
        </Button>
      </Row>
      <Table
        style={{ height: '100%' }}
        size='middle'
        loading={loading}
        pagination={{ pageSize: 7 }}
        columns={columns}
        dataSource={data}
      />
    </Card>
  );
};
const mapStateToProps = ({ auth, ofc, thm, inst }: State) => ({ auth, ofc, thm, inst });

export default connect(mapStateToProps, { fetchOfficials, deleteOfficial })(TableOfficial);

interface PropsTable {
  fetchOfficials: (token, id) => Promise<Response>;
  deleteOfficial: (id, bloqueado: boolean, token) => Promise<Response>;
  auth: States.Auth;
  ofc: States.Officials;
  thm: States.ThemeColors;
  inst: States.Institutions;
}

interface DataArray {
  key: number;
  ced: string;
  name: string;
  username: string;
  phone: string;
  bloqueado?: boolean;
  cargo: string;
}
