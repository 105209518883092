import {
  SEND_NOTIFICATION,
  SET_NOTIFICATIONS_AS_READ,
  SET_NOTIFICATIONS,
  USER_NOTIFICATION,
  NotificationTypes,
  AUTH_USER_LOGOUT
} from "./../actions/actionsTypes";
import * as R from "ramda";
import { States } from "sigt";

const aux = localStorage.getItem("hasNotifications")

const initialState: States.Notifications = {
  list: [],
  hasNotifications: aux ? JSON.parse(aux) : false
};

const reducer = (state = initialState, action: NotificationTypes): States.Notifications => {
  switch (action.type) {
    case SEND_NOTIFICATION: return { ...state, list: [action.payload, ...state.list] };
    case SET_NOTIFICATIONS: return { ...state, list: action.payload };
    case SET_NOTIFICATIONS_AS_READ:
      const newState = R.clone(state.list);
      return {
        ...state,
        list: newState.map(el => {
          el.status = action.payload;
          return el;
        })
      };    
    case USER_NOTIFICATION:
      localStorage.setItem("hasNotifications", `${action.payload}`);
      return { ...state, hasNotifications: action.payload };
    case AUTH_USER_LOGOUT:
      return { list: [], hasNotifications: false };
    default: return state;
  }
};

export default reducer;
