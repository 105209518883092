import React, { useState, useEffect } from 'react';
import { Select, Form, Checkbox, Row, Col, Alert } from 'antd';
import { FormInstance } from 'antd/lib/form';

const UserSelect: React.FC<UserSelectProps> = ({ users = [], form }) => {
  const [show, setShow] = useState(users.length > 0);

  useEffect(() => {
    if(!show) form.setFieldsValue({ usuario: undefined });
    if(users.length > 0) form.setFieldsValue({ usuario: users[0].id })
    // eslint-disable-next-line
  }, [show]);
  
  return (
    <> 
      <Row>
        <Col xs={24} xl={12}>
          <Alert message="Si el contribuyente posee un usuario de S.U.T. debe seleccionar esta opción " type="warning" showIcon 
            style={{ marginTop: 5, marginBottom: 15 }} />
        </Col>
      </Row>
      <Checkbox checked={show} onChange={(e) => setShow(e.target.checked)}>El contribuyente posee un usuario de S.U.T.</Checkbox>
      {show &&
      <Row gutter={24}>
        <Col xl={8} xs={24}>
          <Form.Item name='usuario' label='Usuario de S.U.T.' rules={[{ required: true, message: 'Debe incluir el correo del usuario de S.U.T.' }]}>
            <Select placeholder='Seleccione el usuario'>
              {users.map(u => 
                <Select.Option key={u.id} value={u.id}>{u.correo}</Select.Option>  
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>}
    </>
  );
};

export default UserSelect;

interface UserSelectProps {
  onChange?: (e: any) => any
  users: { id: number, correo: string }[]
  form: FormInstance
};