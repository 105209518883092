import React, { useEffect } from "react";
import { Input, Form, Button, Card, Row, Col } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { States } from 'sigt';
import { Utils } from "../utils/validators";
import handlingMessage from '../utils/handlingMessage';
import { newPassword, recoverPassword } from '../services/forgotPassword'
import { UserOutlined } from "@ant-design/icons";

const LoginForm: React.FC<LoginFormProps> = ({ auth }) => {
	const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const queryParams = useQuery();
  const key = queryParams.get('recvId');
  const [form] = Form.useForm();

	useEffect(() => {
		if (auth.token) {
			history.push("/dashboard");
		}
  }, [auth.token, history]);
  
  const comparePassword  = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('Las contraseñas no coinciden!');
    } else {
      callback();
    }
  };

	const handleSubmit = async () => {
		let values = await form.validateFields();
    values = key ? {...values, recvId: key} : values;
    handlingMessage({
      action: () => ( key ? newPassword(values) :recoverPassword(values)),
      key: "recover",
      loadingMessage: "Realizando operacion...",
      cb: (data) => { history.push('/ingresar') }
    });
  };
  
	return (
		<Card title="Recuperar Contraseña" style={{ minWidth: 320, width: 350 }}>
			<Form className="login-form" onFinish={handleSubmit} form={form}>
        {key ?(
        <Row>
          <Col>
            <Form.Item rules={[{ required: true, message: 'Por favor ingresar contraseña!' },
              { validator: Utils.validators.password }]} name='password'>
                <Input.Password style={{width:'100%'}} maxLength={16} placeholder='Contraseña' />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item rules={[{ required: true, message: 'Por favor corfirmar tu contraseña!' },
              { validator: comparePassword }]} name='confirm'>
                <Input.Password maxLength={16} style={{width:'100%'}} placeholder='Confirmar Contraseña' />
            </Form.Item>
          </Col>
        </Row>) : (
          <Form.Item rules={[{ required: true, message: "Por favor ingresa tu correo" }, { validator: Utils.validators.email }]} name='email'>
            <Input
              prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Correo"
						/>
				  </Form.Item>)}
        <Row gutter={12}>
          <Col span={12}>
            <Button onClick={() => history.goBack()} style={{ width: '100%' }}>
              Atras
            </Button>
          </Col>
          <Col span={12}>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              {key ? 'Confirmar' : 'Enviar correo'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

const mapStateToProps = ({ auth }) => ({ auth });

const WrappedForgetForm = connect(mapStateToProps, null)(LoginForm);

export default WrappedForgetForm;

interface LoginFormProps {
  recoverPassword: Function;
	newPassword: Function;
	auth: States.Auth;
}
