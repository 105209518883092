import React from 'react';
import { Form, Input, Button, Col, Row, Divider } from 'antd';
import { Utils } from '../../../utils/validators';

const DistroFormList: React.FC<DistroFormListProps> = ({ name }) => {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <div>
          {fields.map((field, index) => (
            <React.Fragment key={index}>
              <Form.Item required={true} key={field.key} style={{ width: '100%' }}>
                <Row gutter={24} style={{ padding: '0 20px' }}>
                  <Col xs={24} xl={4}>
                    <Form.Item name={[field.name, 'cantidad']} fieldKey={field.fieldKey} label='Cantidad' rules={[{ required: true, message: 'Por favor ingrese la cantidad' }]} 
                      normalize={Utils.normalize.isNumber}>
                      <Input placeholder='Cantidad' />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item name={[field.name, 'descripcion']} fieldKey={field.fieldKey} label='Descripción' validateTrigger={['onChange', 'onBlur']} rules={[{ required: true, message: 'Por favor ingrese la descripción de la distribución' }]}>
                      <Input placeholder='Descripción' />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              {index < (fields.length - 1) && <Divider />}
            </React.Fragment>
          ))}
          <Row gutter={[16,16]}>
            {fields.length > 0 && <Col><Button type='danger' onClick={() => remove(fields.length - 1)}>Eliminar Distribución</Button></Col> }
            <Col><Button type='dashed' onClick={() => add()}>Agregar Distribución</Button></Col>
          </Row>
        </div>
      )}
    </Form.List>
  );
};

export default DistroFormList;

export interface DistroFormListProps {
  name: string
}