import React, { useState, useRef } from 'react';
import SearchTaxPayer from '../../../SearchTaxPayer';
import { fetchExonerationTaxPayer, updateExonerationEndDate } from '../../../../services/exonerations';
import { Descriptions, Table, Input, Button, Form, Typography, Row, Col, Select, DatePicker, Modal, Alert, Tooltip } from 'antd';
import { SearchOutlined, CloseOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words"
import { useSelector } from 'react-redux';
import { State } from 'sigt';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import handlingMessage from '../../../../utils/handlingMessage';
import { createExonerationTaxPayer } from '../../../../services/exonerations';
import Item from 'antd/lib/list/Item';

const EditableCell:React.FC<EditableCellProps | any> = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps
}) => {
  const inputNode = <DatePicker format='DD-MM-YYYY' style={{textAlignLast:'center'}} />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{margin: 0}}
          rules={[{required: true,message: `Por favor ingresar ${title}!`,}]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ExoTaxPayer: React.FC = () => {
  const [contribuyente, setContribuyente] = useState<contribuyenteProps>();
  const [exoneracionGen, setExoneracionGen] = useState<exonreacionGenProps | any>();
  const [exoneracionAE, setExoneracionAE] = useState<actividades[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColum] = useState('');
  const [visible, setVisible] = useState(false);
  const [typeExo, setTypeExo] = useState('selectiva');
  const { thm } = useSelector((state: State) => ({ thm: state.thm }));
  const searchInput = useRef<any>(null);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [loading, setLoading ] = useState(false);
  const isEditing = record => record.key === editingKey;

  const onSubmitExoneration = async () => {
    let {activities,from} = await form.validateFields();

    const act = activities ? activities.map((id)=>contribuyente?.actividades.find((l)=>l.id === id)) : undefined;
    setLoading(true);
    handlingMessage({
      action: () => createExonerationTaxPayer({doc:contribuyente?.documento,typeDoc:contribuyente?.tipoDocumento,activities: act,from:moment(from).toISOString(), ref: contribuyente?.referenciaMunicipal}),
      key: 'createExo',
      loadingMessage: 'Creando exoneracion...',
      cb: async () => {
        try {
          setExonerations(await fetchExonerationTaxPayer({docType: contribuyente?.tipoDocumento, rim: contribuyente?.referenciaMunicipal , document: contribuyente?.documento }))
        } catch (e) {
          throw e
        }
        setVisible(false); 
        setLoading(false);
        form.resetFields();
      }
    })
  }

  const setExonerations = ({data: { contribuyente, exoneracionesDeActividadesEconomicas, exoneracionGeneral }}) => {
    if (contribuyente){
      setContribuyente(contribuyente);
      setExoneracionAE(exoneracionesDeActividadesEconomicas.map((ele,index) => ({...ele, key:index})));
      setExoneracionGen(exoneracionGeneral);
    }
  }

  const save = async (key) => {
    if (key === 'GENERAL'){
      const values = await editForm.validateFields();
      handlingMessage({
        action: () => updateExonerationEndDate({id: exoneracionGen?.id, to:values.from}),
        key:'endExoneration',
        loadingMessage: 'Actualizando Exoneracion',
        cb: () => {setExoneracionGen({...exoneracionGen, fechaFin:values.from})}
      })
      setEditingKey('');
    }else{
      try {
        const row = await editForm.validateFields();
        const newData = [...exoneracionAE];
        const index = newData.findIndex(item => key === item.id);
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
          const values = newData[index];
          handlingMessage({
            action: () => updateExonerationEndDate({id: values.id,to:values.fechaFin}),
            key:'endExoneration',
            loadingMessage: 'Actualizando Exoneracion',
            cb: () => {setExoneracionAE(newData)}
          })
          setEditingKey('');
        }
      } catch (errInfo) {
        throw errInfo
      }
    }
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined onClick={() => {}} style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };
  
  
  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const components = {
    body: {
      cell: EditableCell,
    }
  };

  const columnsExoAE : ColumnsType<actividades> | any= [
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
      ...getColumnSearchProps('descripcion')
    },
    {
      title: "Fecha de inicio",
      dataIndex: "fechaInicio",
      key: "fechaInicio",
      ...getColumnSearchProps("fechaInicio"),
      render: (text) => moment(text).utcOffset('+0400').format('DD-MM-YYYY')
    },
    {
      title: 'Fecha Fin',
      dataIndex: 'fechaFin',
      key: 'fechaFin',
      editable:true,
      ...getColumnSearchProps('fechaFin'),
      render: (text) => text ? moment(text).utcOffset('+0400').format('DD-MM-YYYY') : 'No definida',
    },  
    {
      key: "action",
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Tooltip placement="bottom" title="Confirmar">
              <Button type="link" icon={<CheckOutlined />} onClick={() => save(record.id)} />
            </Tooltip>
            <Tooltip placement="bottom" title="Cancelar">
              <Button type="link" icon={<CloseOutlined />} onClick={() => setEditingKey('')} />
            </Tooltip>                        
          </span>
        ) : (
            <span>
            <Tooltip placement="bottom" title="Editar">
              <Button type="link" icon={<EditOutlined />} onClick={() => edit(record)} />
            </Tooltip>
          </span>
        );
      }
    }
  ] 
  
  const mergedColumns = columnsExoAE.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => ({
        record,
        inputType: 'date',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  return (
    <>
      {contribuyente ? <>
        <Typography.Text strong style={{fontSize: 16, marginBottom: 5}}>Contribuyente</Typography.Text>
        <Descriptions title=''>
          <Descriptions.Item label='Documento'>{`${contribuyente.tipoDocumento}-${contribuyente.documento}`}</Descriptions.Item>
          <Descriptions.Item label='R.I.M.'>{contribuyente.referenciaMunicipal}</Descriptions.Item>
          <Descriptions.Item label="Razón social">{contribuyente.razonSocial}</Descriptions.Item>
          <Descriptions.Item label="Denominacion Comercial">{contribuyente.denominacionComercial}</Descriptions.Item>
        </Descriptions>
        <Form form={editForm} initialValues={{from: moment()}}>
          {exoneracionGen?.fechaInicio !== undefined ? 
            <Alert style={{marginBottom: 16}} message="Exoneracion general activa" type="info" showIcon
              description={
                <Row justify='space-between' align='middle'>
                  <Col>Fecha de inicio: {moment(exoneracionGen?.fechaInicio).format('DD-MM-YYYY')}</Col>
                  {editingKey === 'GENERAL' ? <Form.Item name='from' style={{margin: 0}} rules={[{required: true, message: `Por favor ingresar fecha fin!`,}]}>
                      <DatePicker format='DD-MM-YYYY' style={{textAlignLast:'center'}} />
                    </Form.Item>
                    :  <Col>Fecha fin: { exoneracionGen?.fechaFin ? moment(exoneracionGen?.fechaFin).format('DD-MM-YYYY') : 'No definida'}</Col>
                  }
                  {<Col>
                    {editingKey === 'GENERAL' ? <>
                      <Tooltip placement="bottom" title="Confirmar">
                        <Button type="link" style={{border: 'none', color: '#87d068'}} ghost icon={<CheckOutlined />} onClick={() => save('GENERAL')} />
                      </Tooltip>
                      <Tooltip placement="bottom" title="Cancelar">
                        <Button type="link" style={{border: 'none'}} danger ghost icon={<CloseOutlined />} onClick={() => setEditingKey('')} />
                      </Tooltip>
                    </> 
                    : <Tooltip placement="bottom" title="Editar">
                      <Button type="link" style={{border: 'none', color: 'grey'}} ghost icon={<EditOutlined />} onClick={() => setEditingKey('GENERAL')} />
                    </Tooltip>
                    }   
                  </Col>
                  }
                </Row>
              } 
            />
            : null
          }
          <Row justify='space-between'>
            <Col><Typography.Text style={{fontSize: 19}} strong>Actividades economicas exoneradas</Typography.Text></Col>
            <Col><Button type='primary' onClick={()=>setVisible(true)}>Exonerar</Button></Col>
          </Row>
          <Table components={components} rowKey={({id})=>id} size='middle' columns={mergedColumns} dataSource={exoneracionAE} />
        </Form>
        <Modal onOk={onSubmitExoneration} width={700} okText='Exonerar' confirmLoading={loading} visible={visible} onCancel={()=>setVisible(false)} title='Nueva exoneracion'>
          <Form initialValues={{from: moment(), tipoExoneracion:'selectiva'}} form={form} layout='vertical' onFinish={onSubmitExoneration}>
            <Row gutter={8}>
              <Col xs={24} md={12}>
                <Form.Item label='Tipo de exoneracion' name='tipoExoneracion' > 
                  <Select style={{width: '100%'}} onChange={()=>{setTypeExo(form.getFieldValue('tipoExoneracion'))}}>
                    <Select.Option value='selectiva'>Selectiva</Select.Option>
                    <Select.Option value='general'>Total</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label='Fecha inicio' name='from'>
                  <DatePicker format={"DD-MM-YYYY"} /> 
                </Form.Item>
              </Col>
              {typeExo === 'selectiva' && 
                <Col span={24}>
                  <Form.Item style={{marginTop:8}} label='Actividad Economica' name='activities' rules={[{required: true, message: 'Debe seleccionar al menos una actividad'}]}>
                    <Select placeholder='Seleccione las actividades economicas a exonerar...' allowClear filterOption={(value: string, option) => option?.title?.includes(value.toUpperCase())} style={{ width: '100%' }} mode='multiple'>
                      {contribuyente.actividades?.map(({id,descripcion})=>(
                        <Select.Option key={id} value={id} title={descripcion.toUpperCase()}>{descripcion.toUpperCase()}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              }
            </Row>
          </Form>
        </Modal>
      </>
      : <SearchTaxPayer searchAction={fetchExonerationTaxPayer} setData={setExonerations} />
      }
    </>
  );  
};

export default ExoTaxPayer;

interface contribuyenteProps {
  id: number;
  documento: string;
  tipoDocumento: string;
  razonSocial: string;
  referenciaMunicipal: string;
  denominacionComercial: string;
  actividades: actividades[];
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: Item;
}

interface actividades {
  id: number;
  numeroReferencia?: number;
  descripcion: string;
  fechaInicio?: string;
  fechaFin?: string;
}

interface exonreacionGenProps {
  fechaInicio: string;
  id: number;
  fechaFin?: string;
}
