import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { List, Avatar, Typography, Card } from 'antd';
import moment from 'moment';

const { Text } = Typography;

const formatCurrency = (number: number | string) => { 
  number = (typeof number !== 'number') ? parseFloat(number) : number
  return new Intl.NumberFormat('de-DE').format(Math.round(((number) + Number.EPSILON) * 100) / 100)
};

const ValidatedList: React.FC<ValidatedListProps> = props => {
  const { listData } = props;
  return (
    <List
      itemLayout="vertical"
      size="default"
      grid={{ md: 2 }}
      bordered={listData.length === 0}
      dataSource={listData}
      renderItem={item => (
        <List.Item key={item.id} style={{ padding: '0 4px' }}>
          <Card>
            <List.Item.Meta
              avatar={<Avatar style={{ backgroundColor: '#87d068' }} icon={<CheckOutlined />} />}
              title={`ID Pago: ${item.id}`}
              description={
                <div>
                  <Text>{item.codigoTramite ? `Trámite relacionado: ${item.codigoTramite}` : item.codigoMulta ? `Multa relacionada: ${item.codigoMulta}` : item.nacionalidad && item.documento ? `Declaración de Impuesto para: ${item.nacionalidad}-${item.documento}` : ''}</Text>
                  <br />
                  <Text type="secondary" strong >Datos de Pago:</Text>
                  <br />
                  <Text type="secondary" strong >Monto: {formatCurrency(item.monto)}</Text>
                  <br />
                  <Text type="secondary" strong >Rerencia: {item.referencia}</Text>
                  <br />
                  <Text type="secondary" strong >Fecha: {moment(item.fechaDePago).format('DD/MM/YYYY')}</Text>
                </div>
              }
            />
          </Card>
        </List.Item>
      )}
    />
  );
};

export default ValidatedList;

interface ValidatedListProps {
  listData: Pagos[];
}

interface Pagos {
  monto: number;
  id: number;
  aprobado: boolean;
  idBanco: number;
  idTramite: number;
  referencia: string;
  fechaDePago: string;
  fechaDeAprobacion: string;
  codigoTramite?: string;
  codigoMulta?: string;
  documento?: string;
  nacionalidad?: string
}
