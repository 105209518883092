import { Utils } from './validators';
import { Input, Select, DatePicker, Checkbox, Switch, InputNumber } from 'antd';
import { InputProps, TextAreaProps } from 'antd/lib/input';
import { SelectProps } from 'antd/lib/select';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { UserOutlined } from '@ant-design/icons';
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';
import { DatePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import OwnersFormList, { OwnersFormListProps } from '../components/Forms/components/OwnersFormList';
import ParishSelect from '../components/Forms/components/ParishSelect';
import FileUpload, { FileUploadProps } from '../components/Forms/components/FileUpload';
import SolvencyEstateType from '../components/Forms/components/SolvencyEstateType';
import SimpleEstimation from '../components/Forms/components/SimpleEstimation';
import SocietyTypeSelect from '../components/Forms/components/SocietyTypeSelect';
import VehicleTypeSelect from '../components/Forms/components/VehicleTypeSelect';
import PurposeSelect from '../components/Forms/components/PurposeSelect';
import NomenclatureCode from '../components/Forms/components/NomenclatureCode';
import ShiftSelect from '../components/Forms/components/ShiftSelect';
import DistroFormList from '../components/Forms/components/DistroFormList';
import TaxpayerTypeSelect from '../components/Forms/components/TaxpayerTypeSelect';
import EconomicActivities from '../components/Forms/components/EconomicActivities';
import TaxpayerSocietyType from '../components/Forms/components/TaxpayerSocietyType';
import LicenseTypeSelect from '../components/Forms/components/LicenseTypeSelect';
import MonotaxSwitch from '../components/Forms/components/MonotaxSwitch';
import ProcessSelect from '../components/Forms/components/ProcessSelect';

export const getValidators = (id: string, tipo: number, prcd?: number, func?: any, tipoTramite?: number, cargo?: number) =>
  validations[id](tipo, prcd, func, tipoTramite, cargo);

const readOnlyCharges = [18, 36]

const validations: Validations<Partial<FormItemProps>> = {
  cedula: (tipo, _, a, t, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa tu cedula' }, { validator: Utils.validators.cedula }],
      normalize: Utils.normalize.isNumber,
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Cedula', maxLength: 10, readOnly: tipo !== 4
     } as InputProps,
      icon: UserOutlined,
      addonBefore: {
        Component: Select,
        props: { disabled: tipo !== 4 || readOnlyCharges.includes(cargo || 0) } as InputProps,
        name: 'nacionalidad',
        options: ['V', 'E'],
      },
    },
  }),
  nombre: (tipo, _, a, t, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa tu nombre' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nombre', readOnly: tipo !== 4 || readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  cedulaORif: (tipo, _, a, t, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa el RIF o numero de cedula' }, { validator: Utils.validators.cedula }],
      normalize: Utils.normalize.isNumber,
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Cedula o RIF', maxLength: 10, readOnly: tipo !== 4 || readOnlyCharges.includes(cargo || 0) } as InputProps,
      icon: UserOutlined,
      addonBefore: {
        Component: Select,
        props: { disabled: readOnlyCharges.includes(cargo || 0)} as InputProps,
        name: 'prefix',
        options: ['V', 'E', 'J', 'G'],
      },
    },
  }),
  tipoProceso: (t, _, a, tipoTramite, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor especifique el tipo de proceso a realizar' }],
    },
    extension: {
      Component: ProcessSelect,
      props: { tipoTramite, cargo, readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  nombreORazon: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa el nombre o razon social' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nombre o Razon Social', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  direccion: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa la direccion' }],
    },
    extension: { 
      Component: Input,
      props: { placeholder: 'Direccion', readOnly: readOnlyCharges.includes(cargo || 0)} as InputProps,
    },
  }),
  puntoReferencia: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa el punto de referencia' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Punto de Referencia', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  sector: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa el sector' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Sector', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  parroquia: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa la parroquia' }],
    },
    extension: {
      Component: ParishSelect,
      props: { placeholder: 'Parroquia', disabled: readOnlyCharges.includes(cargo || 0)} as SelectProps<any>,
    },
  }),
  metrosCuadrados: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa los metros cuadrados' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Metros Cuadrados', maxLength: 6, type: 'number', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  telefono: (tipo, _, a, tr, cargo) => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el numero de telefono' },
        { validator: Utils.validators.phoneNumber },
      ],
      normalize: Utils.normalize.isNumber,
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Telefono', readOnly: tipo !== 4 || readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  correo: (tipo, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa el correo electronico' }, { validator: Utils.validators.email }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Correo Electronico', readOnly: tipo !== 4 || readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  contacto: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa el nombre del contacto' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Contacto', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  codigoTramite: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresar el código del trámite' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Ej: CPU-0000-0000', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  horario: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa el horario' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Horario', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  ubicadoEn: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa la ubicacion' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Ubicado en', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  rif: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa el RIF' }, { validator: Utils.validators.rif }],
      normalize: Utils.normalize.isRif,
    },
    extension: {
      Component: Input,
      props: { placeholder: 'RIF', maxLength: 10, readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
      icon: UserOutlined,
      addonBefore: {
        Component: Select,
        props: {disabled: readOnlyCharges.includes(cargo || 0)},
        name: 'prefix',
        options: ['V', 'E', 'J', 'G'],
      },
    },
  }),
  razonSocial: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa la razon social' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Razon Social', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  tipoOcupacion: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa el tipo de ocupacion' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Tipo de Ocupacion', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  areaConstruccion: (_, id, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa el area de construcción' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Area', readOnly: (id !== 14 && id !== 15) || readOnlyCharges.includes(cargo || 0), type: 'number' } as InputProps,
    },
  }),
  numeroProyecto: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa el numero de proyecto' }],
      normalize: Utils.normalize.isNumber,
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Numero de Proyecto', maxLength: 10, readOnly: readOnlyCharges.includes(cargo || 0)} as InputProps,
    },
  }),
  fechaAprobacion: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa el numero de proyecto' }],
    },
    extension: {
      Component: DatePicker,
      props: { style: { width: '100%' }, disabledDate: (current) => current > moment().endOf('day'), disabled: readOnlyCharges.includes(cargo || 0) } as DatePickerProps,
    },
  }),
  nombreObra: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingrese el nombre de la obra' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nombre de la Obra', readOnly: readOnlyCharges.includes(cargo || 0)} as InputProps,
    },
  }),
  observaciones: (tipo, _, a, tr, cargo) => ({
    validations: {
      rules: tipo !== 4 ? [{ required: false, message: 'Por favor ingrese las observaciones del tramite' }] : undefined,
    },
    extension: {
      Component: Input.TextArea,
      props: { placeholder: 'Observaciones', style: { resize: 'none' }, readOnly: readOnlyCharges.includes(cargo || 0) } as TextAreaProps,
    },
  }),
  observacionProceso: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: false }],
    },
    extension: {
      Component: Input.TextArea,
      props: { placeholder: 'Observaciones', style: { resize: 'none' }, readOnly: readOnlyCharges.includes(cargo || 0) } as TextAreaProps,
    },
  }),
  informe: (t, _, a, tr, cargo) => ({
    validations: {},
    extension: {
      Component: Input,
      props: { style: { height: '200px', marginBottom: '50px' } },
      rte: true,
      valuePropName: 'x',
    },
  }),
  codigoPermisoConstruccion: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingrese el codigo del permiso de construccion' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Codigo de Permiso de Construccion', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  fechaPermisoConstruccion: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingrese la fecha del permiso de construccion' }],
    },
    extension: {
      Component: DatePicker,
      props: { style: { width: '100%' }, disabledDate: (current) => current > moment().endOf('day'), disabled: readOnlyCharges.includes(cargo || 0) } as DatePickerProps,
    },
  }),
  aforo: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingrese el aforo' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Aforo', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  propietarios: (tipo, _, a, tipoTramite, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar los propietarios' }],
    },
    extension: {
      Component: OwnersFormList,
      props: { name: 'propietarios', tipoTramite, cargo, disabled: readOnlyCharges.includes(cargo || 0) } as OwnersFormListProps,
    },
  }),
  tipoInmueble: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el tipo de inmueble' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Tipo de Inmueble', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  parroquiaEdificio: (tipo, _, a, tipoTramite, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar la parroquia' }],
    },
    extension: {
      Component: ParishSelect,
      props: { tipoTramite, cargo, disabled: readOnlyCharges.includes(cargo || 0)},
    },
  }),
  nombreConjunto: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el nombre del conjunto residencial' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nombre del Conjunto Residencial', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  cantidadEdificios: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar la cantidad de edificios' }],
      normalize: Utils.normalize.isNumber,
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Cantidad de Edificios', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  nombreEdificio: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el nombre del edificio' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nombre del Edificio', readOnly: readOnlyCharges.includes(cargo || 0)} as InputProps,
    },
  }),
  cantidadPisos: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar la cantidad de pisos del edificio' }],
      normalize: Utils.normalize.isNumber,
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Cantidad de Pisos', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  pisoApto: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el piso del apartamento' }],
      normalize: Utils.normalize.isNumber,
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Piso donde se encuentra el apartamento', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  cantidadAptosPiso: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar la cantidad de apartamentos en el piso' }],
      normalize: Utils.normalize.isNumber,
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Cantidad de Apartamentos en el Piso', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  numeroApto: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el numero del apartamento' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Numero del Apartamento', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  nomenclaturaEdificio: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar la nomenclatura del edificio' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nomenclatura del Edificio', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  ubicacionEdificio: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar la ubicacion del edificio' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Ubicacion del Edificio', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  circuito: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el circuito' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Circuito', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  datosRegistro: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar los datos del registro' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Datos del Registro', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  areaTerreno: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el area del terreno' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Area del Terreno', type: 'number', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  plano: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el plano' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Plano', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  croquis: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el croquis de ubicacion' }],
    },
    extension: {
      Component: FileUpload,
      props: { disabled: readOnlyCharges.includes(cargo || 0) ,documentName: 'CROQUIS_UBICACION.png' } as FileUploadProps,
      valuePropName: 'fileList',
      getValueFromEvent: (e) => (Array.isArray(e) ? e : e && e.fileList),
    },
  }),
  codCat: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el código catastral' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Código Catastral', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  tipoInmuebleSolvencia: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe indicar la existencia de terreno o construcciones en el inmueble o parcela' }],
    },
    extension: {
      Component: SolvencyEstateType,
      props: { placeholder: 'Tipo de Inmueble', disabled: readOnlyCharges.includes(cargo || 0)},
    },
  }),
  estimacionSimple: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar los datos de estimación simple' }],
    },
    extension: {
      Component: SimpleEstimation,
      props: {disabled: readOnlyCharges.includes(cargo || 0)},
    },
  }),
  numeroBohio: (tipo, _, a, tr, cargo) => ({
    validations: {
      rules: tipo !== 4 ? [{ required: true, message: 'Por favor ingrese el numero de bohío' }] : undefined,
      normalize: Utils.normalize.isNumber,
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Numero de Bohío', readOnly: readOnlyCharges.includes(cargo || 0) },
    },
  }),
  detallesBohio: (t, _, a, tr, cargo) => ({
    validations: {},
    extension: {
      Component: Input,
      props: { placeholder: 'Detalles del Bohío', readOnly: readOnlyCharges.includes(cargo || 0)},
    },
  }),
  fechaApartado: (tipo, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingrese la fecha del apartado del bohío' }],
    },
    extension: {
      Component: DatePicker,
      props: {
        style: { width: '100%' },
        disabledDate: (current) => current <= moment().startOf('day'),
        disabled: tipo !== 4 || readOnlyCharges.includes(cargo || 0),
      } as DatePickerProps,
    },
  }),
  nombreOrganizacion: (tipo, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingrese el nombre de la organización' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nombre Legal de la Organización', readOnly: tipo !== 4 || readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  tipoSociedad: (tipo, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingrese el tipo de sociedad de la organización' }],
    },
    extension: {
      Component: SocietyTypeSelect,
      props: { placeholder: 'Tipo de Sociedad', disabled: tipo !== 4 || readOnlyCharges.includes(cargo || 0) },
    },
  }),
  tipoTransporte: (tipo, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingrese el tipo de transporte' }],
    },
    extension: {
      Component: VehicleTypeSelect,
      props: { placeholder: 'Tipo de Transporte', disabled: tipo !== 4 || readOnlyCharges.includes(cargo || 0) },
    },
  }),
  nombreRepresentante: (tipo, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa el nombre del representante legal' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nombre del Representante Legal', readOnly: tipo !== 4 || readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  objeto: (tipo, _, a, tr, cargo) => ({
    validations: {},
    extension: {
      Component: Input,
      props: { placeholder: 'Objeto de Actividad Económica', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  fechaTimbre: (t, _, a, tr, cargo) => ({
    validations: {},
    extension: {
      Component: DatePicker,
      props: { style: { width: '100%' }, disabledDate: (current) => current > moment().endOf('day'), disabled: readOnlyCharges.includes(cargo || 0) } as DatePickerProps,
    },
  }),
  bancoTimbre: (tipo, _, a, tr, cargo) => ({
    validations: {},
    extension: {
      Component: Input,
      props: { placeholder: 'Banco Timbre Fiscal', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  montoTimbre: (tipo, _, a, tr, cargo) => ({
    validations: {},
    extension: {
      Component: Input,
      props: { placeholder: 'Monto Timbre Fiscal', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  cedulaRepresentante: (tipo, _, a, tr, cargo) => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa la cedula del representante legal' },
        { validator: Utils.validators.cedula },
      ],
      normalize: Utils.normalize.isNumber,
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Cedula del Representante Legal', maxLength: 10, readOnly: tipo !== 4 || readOnlyCharges.includes(cargo || 0) } as InputProps,
      icon: UserOutlined,
      addonBefore: {
        Component: Select,
        props: { disabled: tipo !== 4 || readOnlyCharges.includes(cargo || 0) } as InputProps,
        name: 'nacionalidadRepresentante',
        options: ['V', 'E'],
      },
    },
  }),
  telefonoRepresentante: (tipo, _, a, tr, cargo) => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el numero de telefono del representante legal' },
        { validator: Utils.validators.phoneNumber },
      ],
      normalize: Utils.normalize.isNumber,
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Telefono del Representante Legal', readOnly: tipo !== 4 || readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  finalidad: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa la finalidad de la solicitud' }],
    },
    extension: {
      Component: PurposeSelect,
      props: { placeholder: 'Finalidad de la Solicitud', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  frente: (t, _, a, tr, cargo) => ({
    validations: {},
    extension: {
      Component: Input,
      props: { placeholder: 'Frente del Inmueble/Parcela', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  linderoFrente: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el lindero del frente' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Frente', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  linderoFondo: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el lindero del fondo' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Fondo', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  linderoIzquierda: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el lindero de la izquierda' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Izquierda', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  linderoDerecha: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el lindero de la derecha' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Derecha', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  sitio: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el sitio' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Sitio', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  numeroPlaca: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el numero de placa' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Numero de Placa', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  codigoNomenclatura: (t, _, a, tr, cargo) => ({
    validations: {},
    extension: {
      Component: NomenclatureCode,
      props: {disabled: readOnlyCharges.includes(cargo || 0)},
    },
  }),
  denominacion: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar la denominación o razón social' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Denominación o Razón Social', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  actividadComercial: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar la actividad comercial' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Actividad Comercial', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  direccionInmueble: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar la dirección del inmueble' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Dirección', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  observacion: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: false }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Observaciones', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  parroquiaInmueble: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa la parroquia' }],
    },
    extension: {
      Component: ParishSelect,
      props: { placeholder: 'Parroquia', disabled: readOnlyCharges.includes(cargo || 0) } as SelectProps<any>,
    },
  }),
  telefonoInmueble: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el numero de teléfono' },
        { validator: Utils.validators.phoneNumber },
      ],
      normalize: Utils.normalize.isNumber,
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Teléfono', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  correoInmueble: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa el correo electrónico' }, { validator: Utils.validators.email }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Correo Electrónico', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  nombreInstitucion: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el nombre de la institución' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nombre de la Institución', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  representanteInstitucion: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el representante legal o director de la institución' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Representante Legal o Director de la Institución', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  turno: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa el turno' }],
    },
    extension: {
      Component: ShiftSelect,
      props: { placeholder: 'Turno', disabled: readOnlyCharges.includes(cargo || 0) } as SelectProps<any>,
    },
  }),
  nivelEducativo: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el nivel educativo' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nivel Educativo', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  direccionPlantel: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar la dirección del plantel' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Dirección', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  direccionEmpresa: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar la dirección de la empresa' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Dirección', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  parroquiaEmpresa: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa la parroquia' }],
    },
    extension: {
      Component: ParishSelect,
      props: { placeholder: 'Parroquia', disabled: readOnlyCharges.includes(cargo || 0) } as SelectProps<any>,
    },
  }),
  telefonoEmpresa: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [
        { required: true, message: 'Por favor ingresa el numero de teléfono' },
        { validator: Utils.validators.phoneNumber },
      ],
      normalize: Utils.normalize.isNumber,
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Teléfono', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  correoEmpresa: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Por favor ingresa el correo electrónico' }, { validator: Utils.validators.email }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Correo Electrónico', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  nombreEmpresaComercio: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el nombre del comercio o empresa' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Nombre de la Empresa o Comercio', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  usoConforme: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el uso conforme' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Uso Conforme', readOnly:  readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  metrosCuadradosConstruccion: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el area de construcción' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Area de Construcción', readOnly: true } as InputProps,
    },
  }),
  planoConstruccion: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el plano de construcción' }],
    },
    extension: {
      Component: FileUpload,
      props: { disabled: readOnlyCharges.includes(cargo || 0), documentName: 'PLANO_CONSTRUCCION.png' } as FileUploadProps,
      valuePropName: 'fileList',
      getValueFromEvent: (e) => (Array.isArray(e) ? e : e && e.fileList),
    },
  }),
  distribucion: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar la distribución' }],
    },
    extension: {
      Component: DistroFormList,
      props: { name: 'distribucion', disabled: readOnlyCharges.includes(cargo || 0) } as OwnersFormListProps,
    },
  }),
  documentoIdentidad: (tipo, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar su documento de identidad' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Documento de Identidad', maxLength: 10, readOnly: tipo !== 4 || readOnlyCharges.includes(cargo || 0) } as InputProps,
      icon: UserOutlined,
      addonBefore: {
        Component: Select,
        props: {disabled: readOnlyCharges.includes(cargo || 0)},
        name: 'tipoDocumento',
        options: ['V', 'J', 'E', 'G', 'P'],
      },
    },
  }),
  denominacionComercial: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar la denominación comercial' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Denomincación Comercial', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  siglas: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe incluir las siglas' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Siglas', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  solvenciaRRI: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: false }],
    },
    extension: {
      Component: Checkbox,
      props: { children: 'Solvencia con RRI', checked: true, disabled: readOnlyCharges.includes(cargo || 0) },
    },
  }),
  inputRRI: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true }],
    },
    extension: {
      Component: Input,
      props: { placeholder: `Código del RRI`, maxLength: 30, readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  tipoContribuyente: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el tipo de contribuyente' }],
    },
    extension: {
      Component: TaxpayerTypeSelect,
      props: { placeholder: 'Tipo de Contribuyente', disabled: readOnlyCharges.includes(cargo || 0) } as SelectProps<any>,
    },
  }),
  actividadesEconomicas: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar las actividades económicas' }],
    },
    extension: {
      hidden: true,
      Component: EconomicActivities,
      props: { disabled: readOnlyCharges.includes(cargo || 0) },
    },
  }),
  capitalSuscrito: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el capital suscrito' }],
    },
    extension: {
      Component: Input,
      props: { placeholder: 'Capital Suscrito', type: 'number', readOnly: readOnlyCharges.includes(cargo || 0) } as InputProps,
    },
  }),
  tipoSociedadContrib: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el tipo de sociedad' }],
    },
    extension: {
      Component: TaxpayerSocietyType,
      props: { placeholder: 'Tipo de Sociedad', disabled: readOnlyCharges.includes(cargo || 0) } as SelectProps<any>,
    },
  }),
  estadoLicencia: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe ingresar el tipo de licencia' }],
    },
    extension: {
      Component: LicenseTypeSelect,
      props: {disabled: readOnlyCharges.includes(cargo || 0), placeholder: 'Tipo de Licencia' },
    },
  }),
  esMonotributo: (t, _, a, tr, cargo) => ({
    validations: {
      rules: [{ required: true, message: 'Debe indicar si el R.I.M. declara montributo.' }],
    },
    extension: {
      Component: MonotaxSwitch,
      props: { placeholder: 'Declara monotributo', disabled: readOnlyCharges.includes(cargo || 0) },
    },
  }),
};

interface Validations<T> {
  [P: string]: (
    type?: number,
    prcd?: number,
    func?: any,
    tipoTramite?: number,
    cargo?: number
  ) => {
    validations: T;
    extension: ExtendedDecoratorOptions;
  };
}

export interface ExtendedDecoratorOptions {
  Component:
    | typeof Input
    | typeof DatePicker
    | typeof Input.TextArea
    | typeof OwnersFormList
    | typeof ParishSelect
    | typeof ProcessSelect
    | typeof FileUpload
    | typeof SolvencyEstateType
    | typeof SimpleEstimation
    | typeof SocietyTypeSelect
    | typeof VehicleTypeSelect
    | typeof PurposeSelect
    | typeof NomenclatureCode
    | typeof ShiftSelect
    | typeof DistroFormList
    | typeof TaxpayerTypeSelect
    | typeof EconomicActivities
    | typeof TaxpayerSocietyType
    | typeof LicenseTypeSelect
    | typeof Checkbox
    | typeof Switch;
  props: any;
  rte?: boolean;
  icon?: React.ForwardRefExoticComponent<AntdIconProps & React.RefAttributes<HTMLSpanElement>>;
  valuePropName?: string;
  hidden?: boolean;
  getValueFromEvent?: (e: any) => any;
  addonBefore?: {
    Component: typeof Select;
    name: string;
    props?: SelectProps<any>;
    options?: string[];
  };
}
