import { States } from "sigt";
import { OrdinancesTypes, SET_ORDINANCES, DELETE_ORDINANCE, CREATE_ORDINANCE, EDIT_ORDINANCE, AUTH_USER_LOGOUT } from "../actions/actionsTypes";

const intitialState: States.Ordenances = {
  ordinances: [],
};

export default (state = intitialState, action: OrdinancesTypes): States.Ordenances => {
  switch(action.type) {
    case SET_ORDINANCES: return { ...state, ordinances: action.payload };
    case CREATE_ORDINANCE: return {...state, ordinances: [action.payload, ...state.ordinances] }
    case EDIT_ORDINANCE: 
      const oIndex = state.ordinances.findIndex((o) => o.id === action.payload.id);
      state.ordinances[oIndex] = Object.assign({}, action.payload);
      return {...state, ...state.ordinances };
    case DELETE_ORDINANCE:
      const ordc = state.ordinances ? state.ordinances : [];
      return {
        ...state,
        ordinances: ordc.filter(e => e.id !== action.payload)
      };

    case AUTH_USER_LOGOUT: return intitialState;
    default: return state;
  }
};