export const ejido = {
   uso:[
        {id: 1, value:'A - PARCELA USADA PARA VIVIENDA Y OCUPADA POR EL ARRENDATARIO.'},
        {id: 2, value:'B - PARCELA USADA PARA VIVIENDA Y NO OCUPADA POR EL ARRENDATARIO.'},
        {id: 3, value:'C - PARCELA USADA PARA OTROS USOS DISTINTOS A LO DE VIVIENDA Y OCUPADA POR EL ARRENDATARIO.'},
        {id: 4, value:'D - PARCELA USADA PARA OTROS USOS DISTINTOS A LO DE VIVIENDA Y NO OCUPADA POR EL ARRENDATARIO.'}
    ],
    clase: [
        {id: 1,	value: 'EU - TERRENO EJIDO URBANO.'},
        {id: 2,	value: 'ER- TERRENO EJIDO RURAL.'}
    ],
    tenencia: [
        {id: 1, value: 'A - ARRENDATARIO'},
        {id: 2, value: 'S - SUBARRENDATARIO'}

    ]
}

export const mercado = {
    mercados: [
        {id: 1, value: 'MERCADO LA VILLA'},
        {id: 2, value: 'MERCADO LA ERMITA'},
        {id: 3, value: 'MERCADO LA GUAYANA'},
        {id: 4, value: 'MERCADO SANTA TERESA'},
        {id: 5, value: 'CEMENTERIO MUNICIPAL'},
        {id: 6, value: 'LOCALES ALCALDIA'}

    ],
    local: [
        {id: 1, value: 'LOCAL'},
        {id: 2, value: 'PUESTO'},
        {id: 3, value: 'PUESTO DE MERCADO'},
        {id: 4, value: 'PUESTO EXTERNO DE MERCADO'},
        {id: 5, value: 'DEPOSITO'}
    ],
    tipo_ae: [
        {id: 1,  value: 'ALIMENTO PARA AVES'},
        {id: 2,  value: 'ALMUERZOS'},
        {id: 3,  value: 'AVES VIVAS'},
        {id: 4,  value: 'BODEGON VIVERES'},
        {id: 5,  value: 'CAFÉ MOLIDO'},
        {id: 6,  value: 'CAFETERIA'},
        {id: 7,  value: 'CAFETIN'},
        {id: 8,  value: 'CARNICERIA'},
        {id: 9,  value: 'CERRAJERIA'},
        {id: 10, value:	'CHARCUTERIA'},
        {id: 11, value:	'COCINA'},
        {id: 12, value:	'COMIDA'},
        {id: 13, value:	'CONDIMENTOS'},
        {id: 14, value:	'CONFECCIONES'},
        {id: 15, value:	'CUARTO FRIO'},
        {id: 16, value:	'DEPOSITO'},
        {id: 17, value:	'DULCES'},
        {id: 18, value:	'FARMACIA'},
        {id: 19, value:	'FLORES'},
        {id: 20, value:	'FRUTERIA'},
        {id: 21, value:	'GENERICOS'},
        {id: 22, value:	'HAYACAS'},
        {id: 23, value:	'HIERBAS'},
        {id: 24, value:	'JUGO DE NARANJA'},
        {id: 25, value:	'MANICURE PEDICURE'},
        {id: 26, value:	'MERCERIA'},
        {id: 27, value:	'PAGO MOVIL'},
        {id: 28, value:	'PANADERIA Y PASTELERIA'},
        {id: 29, value:	'PASTELES'},
        {id: 30, value:	'PASTILLA'},
        {id: 31, value:	'PELUQUERIA'},
        {id: 32, value:	'PERFUMERIA ESOTERICA'},
        {id: 33, value:	'PESCADERIA'},
        {id: 34, value:	'QUINCALLA'},
        {id: 35, value:	'REFRESQUERIA'},
        {id: 36, value:	'REPARACION DE ELECTRODOMESTICOS'},
        {id: 37, value:	'SERVICIO TECNICO CELULARES'},
        {id: 38, value:	'SIN INFORMACION'},
        {id: 39, value:	'TIENDA NATURISTA'},
        {id: 40, value:	'TORTAS'},
        {id: 41, value:	'UNIFORMES'},
        {id: 42, value:	'VARIEDADES'},
        {id: 43, value:	'VARIOS'},
        {id: 44, value:	'VELAS'},
        {id: 45, value:	'VENTA DE ROPA'},
        {id: 46, value:	'VERDURAS Y FRUTAS'},
        {id: 47, value:	'VISCERAS'},
        {id: 48, value:	'VIVERES'},
        {id: 49, value:	'VIVERES Y VARIOS'}
    ]
}

export const cementerio = {
    area_servicio: [
        {id: 1,	 indicador: 0.01 , value: 'CUARTEL Z.', sector: 1},
        {id: 2,	 indicador: 0.01 , value: 'CUARTEL IZQUIERDO', sector: 1},
        {id: 3,	 indicador: 0.01 , value: 'PRIMERO CENTRAL', sector: 1},
        {id: 4,	 indicador: 0.01 , value: 'SEGUNDO CENTRAL', sector: 1},
        {id: 5,	 indicador: 0.005 , value: 'CUARTEL 2DO IZQUIERDO', sector: 2},
        {id: 6,	 indicador: 0.005 , value: 'CUARTEL 3RO CENTRO', sector: 2},
        {id: 7,	 indicador: 0.005 , value: 'CUARTEL N', sector: 2},
        {id: 8,	 indicador: 0.005 , value: 'CUARTEL O', sector: 2},
        {id: 9,	 indicador: 0.003 , value: 'CUARTEL 3RO IZQUIERDO', sector: 3},
        {id: 10, indicador: 0.003 , value: 'CUARTEL 4TO IZQUIERDO', sector: 3},
        {id: 11, indicador: 0.003 , value: 'CUARTEL 4TO CENTRO', sector: 3},
        {id: 12, indicador: 0.003 , value: 'CUARTEL 5TO CENTRO', sector: 3},
        {id: 13, indicador: 0.003 , value: 'PROMERO DERECHA', sector: 3},
        {id: 14, indicador: 0.003 , value: 'TERCERO DERECHA', sector: 3},
    ],
    tenencia: [
        {id: 1, value: 'A - ARRENDATARIO'},
        {id: 2, value: 'S - SUBARRENDATARIO'}
    ],
    sector: [
        {id: 1, value: 'SECTOR A'},
        {id: 2, value: 'SECTOR B'},
        {id: 3, value: 'SECTOR C'}
    ]
}

export const quiosco = {
    objeto: [
        {id: 1 , value: 'AGENCIA DE LOTERIA'},
        {id: 2,  value: 'ALQUILER DE TELEFONOS'},
        {id: 3,  value: 'AREPITAS'},
        {id: 4,  value: 'ARTICULOS ESCOLARES'},
        {id: 5,  value: 'BISUTERIA'},
        {id: 6,  value: 'BOLSOS'},
        {id: 7,  value: 'CASOS VARIOS'},
        {id: 8,  value: 'CASOS VARIOS (FRUTAS, VERDURAS Y FLORES)'},
        {id: 9,  value: 'CASOS VARIOS (REPARACION DE CELULAR)'},
        {id: 10, value: 'CASOS VARIOS (VENTA DE LOTERIAS)'},
        {id: 11, value: 'CHICHA'},
        {id: 12, value: 'CHICHA Y PASTELES'},
        {id: 13, value: 'CHORIPANES'},
        {id: 14, value: 'CHUCHERIAS Y REFRESCOS'},
        {id: 15, value: 'CHURROS'},
        {id: 16, value: 'COMIDA'},
        {id: 17, value: 'COMIDA RAPIDA'},
        {id: 18, value: 'COMIDA RAPIDA (EMPANADAS, REFRESCO, CAFE)'},
        {id: 19, value: 'COMIDA RAPIDA Y REFRESCO'},
        {id: 20, value: 'COMIDA RAPIDA(POSTRES, JUGOS)'},
        {id: 21, value: 'CONFITERIA'},
        {id: 22, value: 'CONFITERIA Y CHUCHERIAS'},
        {id: 23, value: 'CONFITERIA Y PERIODICOS'},
        {id: 24, value: 'CONFITERIAS Y REFRESCOS'},
        {id: 25, value: 'DULCES'},
        {id: 26, value: 'DULCES Y ALQUILER DE TELEFONO'},
        {id: 27, value: 'DULCES Y REFRESCOS'},
        {id: 28, value: 'EMPANADAS Y JUGOS'},
        {id: 29, value: 'FRUTAS'},
        {id: 30, value: 'FRUTAS Y JUGOS'},
        {id: 31, value: 'FRUTAS Y TISANA'},
        {id: 32, value: 'HAMBURGUESAS'},
        {id: 33, value: 'HELADOS, QUESILLOS'},
        {id: 34, value: 'KIOSCO'},
        {id: 35, value: 'KIOSCO DE PERROS Y HAMBURGUESAS'},
        {id: 36, value: 'KIOSCO DE REVISTAS Y PERIODICOS'},
        {id: 37, value: 'LOTERIAS'},
        {id: 38, value: 'MORCILLAS'},
        {id: 39, value: 'PASTELES Y CHUCHERIAS'},
        {id: 40, value: 'PASTELES Y JUGOS DE NARANJA'},
        {id: 41, value: 'PERROS CALIENTE Y HAMBURGUESAS'},
        {id: 42, value: 'PERIODICOS REFRESCOS Y CHUCHERIAS'},
        {id: 43, value: 'PRENSA REFRESCO GOLOSINAS'},
        {id: 44, value: 'QUINCALLERIA'},
        {id: 45, value: 'REVISTAS Y PERIODICOS'},
        {id: 46, value: 'VENTA DE COMIDA RAPIDA'},
        {id: 47, value: 'VENTA DE MORCILLAS'},
        {id: 48, value: 'VENTA DE PASTELES Y REFRESCOS'}
    ],
    tipo:[
        {id: 1, value: 'A - HASTA 1,50 M2'},
        {id: 2, value: 'B - DESDE 1,51 M2 HASTA 3,00 M2'},
        {id: 3, value: 'C - DESDE 3,01 M2 HASTA 4,50 M2'},
        {id: 4, value: 'D - DESDE 4,51 M2 HASTA 6,00 M2'},
        {id: 5, value: 'E - MAS DE 6,00 M2'},
    ],
    zona: [
        {id: 1, value: 'COMERCIAL'},
        {id: 2, value: 'RESIDENCIAL'},
    ]
}