import React, { useEffect, useState, useRef } from 'react';
import { States, State } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import { connect } from 'react-redux';
import { Card, message, Table, DatePicker, Col, Button, Row, Input, Descriptions, Tag, Divider, Typography } from 'antd';
import { useLocation } from 'react-router';
import axios from 'axios';
import { ColumnsType } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import Highlighter from "react-highlight-words"
import { CalendarOutlined, SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
const server = process.env.REACT_APP_SERVER_URL;

const SMSettlements: React.FC<SMSettlementsProp> = ({ thm, auth }) => {
  const [settlements, setSettlements] = useState<{ [date: string]: any[] }>({});
  const [filtered, setFiltered] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [keys, setKeys] = useState<Moment[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [date, setDate] = useState<string>(moment().utcOffset('-4').startOf('month').format('MM-DD-YYYY'));
  const inputRef = useRef<any>(null);

  const getState = (estado) => {
    if(estado === 'ingresardatos') return { name: 'En espera de pago', color: 'cyan' };
    else if(estado === 'validando') return { name: 'Validando pago', color: 'warning' };
    else return { name: 'Solvente', color: 'green' };
  };

  const filterBranches: any[] = [];
  const filterStatus:any[] = [
    {
      value:'ingresardatos',
      text:'En espera de pago'
    },
    {
      value:'validando',
      text:'Validando pago'
    },
    {
      value:'finalizado',
      text:'Solvente'
    },
  ];

  const { width } = useWindowDimensions();
  const location = useLocation();

  const institution = location.pathname.split('/')[location.pathname.split('/').length - 1];
  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);
  const showIVA = institution === 'IMAU' || institution === 'SAGAS';

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={inputRef}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        if (inputRef.current) {
          inputRef.current?.select();
        }
      }
    },
    render: text =>  searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
    ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnFilterDate = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      setKeys(selectedKeys);
      return (
      <Row style={{width:250, padding: 8}} gutter={[8,8]}>
        <Col span={24}>
          <DatePicker.RangePicker style={{width:'100%'}} format='DD-MM-YYYY'  placeholder={['Fecha inicio ','Fecha fin']} size='small' 
            onChange={(values)=>{setSelectedKeys(values)}} value={selectedKeys} 
          />
        </Col>
        <Col span={12}>
          <Button style={{width:'100%'}} type='primary' icon={<SearchOutlined />} size='small' onClick={() => { confirm(); }}>
            Buscar
          </Button>
        </Col>
        <Col span={12}>
          <Button style={{width:'100%'}} onClick={() => clearFilters()} size='small'>
            Restablecer
          </Button>
        </Col>
      </Row> 
    )},
    filterIcon: filtered => (
      <CalendarOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) => {
      return moment(record[dataIndex]).utc().format('DD-MM-YYYY') === keys[0]?.utc().format('DD-MM-YYYY') 
        || moment(record[dataIndex]).utc().isBetween(keys[0]?.utc(), keys[1]?.utc(), undefined, '[]')
    },
  });

  const columns: ColumnsType<any> = [
    {
      title: 'Documento de Identidad',
      dataIndex: 'contribuyente',
      ...getColumnSearchProps('documento'),
      render: (contrib) => <span>{contrib.tipoDocumento}-{contrib.documento}</span>
    },
    {
      title: 'Razón Social',
      dataIndex: 'razonSocial',
      ...getColumnSearchProps('razonSocial'),
      render: (razonSocial) => <span>{razonSocial}</span>
    },
    {
      title: 'Fecha de Liquidación',
      dataIndex: 'fechaLiquidacion',
      ...getColumnFilterDate('fechaLiquidacion'),
      render: (date) => <span>{moment(date).utc().format('DD/MM/YYYY')}</span>
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      render: (monto) => <span>Bs. {formatCurrency(+monto)}</span>
    },
    {
      title: 'Ramo',
      dataIndex: 'ramo',
      filters:filterBranches,
      onFilter: (value, record) => record.ramo.toLowerCase().indexOf((value as string).toLowerCase()) === 0,
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      filters:filterStatus,
      onFilter: (value, record) => record.estado.toLowerCase().indexOf((value as string).toLowerCase()) === 0,
      render: (estado) => <Tag style={{ width: '100%', textAlign: 'center' }} color={getState(estado).color}>{getState(estado).name}</Tag>
    }
  ]

  useEffect(() => {
    if(!settlements[date]) {
      fetchSettlements();
    }
    // eslint-disable-next-line
  }, [date]);

  const fetchSettlements = async () => {
    setLoading(true);
    const params = new URLSearchParams({ date });
    try {
      const response = await axios.get(`${server}/services/settlements/${institution}?${params}`, { headers: { Authorization: `Bearer ${auth.token}` } });
      message.success('Liquidaciones obtenidas de manera exitosa');
      setSettlements((s) => ({ ...s, [date]: response.data.liquidaciones }));
      setFiltered(response.data.liquidaciones);
    } catch(e) {
      message.error(e.response?.data?.message || 'Error al obtener liquidaciones');
    } finally {
      setLoading(false);
    }
  };
  
  (settlements[date] ? settlements[date] : []).forEach((ele)=> {
    if (typeof _.find(filterBranches,(e) => e.value === ele.ramo) !== 'object' ) filterBranches.push({ value: ele.ramo, text: ele.ramo });
  });

  return (
    <Card style={{ height: '100%' }} title={institution === 'SAGAS' ? 'Liquidaciones de Gas' : institution === 'IMAU' ? 'Liquidaciones de Aseo' : 'Liquidaciones de CPU'} 
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', padding: '0px 40px' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}}>
      <Row style={{ marginTop: 20 }}>
        <Col>
          <Typography.Text strong>Fecha:</Typography.Text>
          <DatePicker picker='month' value={moment(date)} onChange={v => setDate(v?.startOf('month').format('MM-DD-YYYY') || moment().format('MM-DD-YYYY'))} format='MM/YYYY'
            style={{ marginLeft: 10 }} disabled={loading} disabledDate={(current) => current > moment().endOf('month') || current < moment('08/01/2020')} />
        </Col>
      </Row>
      <Table bordered columns={columns} pagination={{ pageSize: 10 }} 
        dataSource={(settlements[date] || []).map(s => {
          s.razonSocial = s.contribuyente.razonSocial;
          s.documento = s.contribuyente.documento;
          return s;
        })} onChange={(pag, filt, sorter, extra) => setFiltered(extra.currentDataSource)}
        rowKey='id' loading={loading} style={{ marginTop: 20 }} />
      <Divider orientation='left' style={{ marginLeft: -10, marginBottom: 10 }}>
        <Typography.Title level={4}>Liquidaciones Vigentes</Typography.Title>
      </Divider>  
      <Row>
        <Col xl={12} xs={24}>
          <Descriptions bordered column={1}>
            {showIVA ?
            <>
              <Descriptions.Item label='Cantidad de Liquidaciones'>{filtered.filter(f => f.estado !== 'finalizado').length} Liquidacion(es)</Descriptions.Item>
              <Descriptions.Item label='Sub-Total'>Bs. {formatCurrency(filtered.filter(f => f.estado !== 'finalizado').reduce((prev, current) => prev + +current.monto, 0) / 1.16)}</Descriptions.Item>
              <Descriptions.Item label='I.V.A.'>Bs. {formatCurrency(filtered.filter(f => f.estado !== 'finalizado').reduce((prev, current) => prev + +current.monto, 0) / 1.16 * 0.16)}</Descriptions.Item>
              <Descriptions.Item label='Total'>Bs. {formatCurrency(filtered.filter(f => f.estado !== 'finalizado').reduce((prev, current) => prev + +current.monto, 0))}</Descriptions.Item>
            </> : 
            <>
              <Descriptions.Item label='Total'>Bs. {formatCurrency(filtered.filter(f => f.estado !== 'finalizado').reduce((prev, current) => prev + +current.monto, 0))}</Descriptions.Item>
            </>}
          </Descriptions>
        </Col>
        {width > 992 && <Col span={12} />}
      </Row>
      <Divider orientation='left' style={{ marginLeft: -10, marginBottom: 10, marginTop: 20 }}>
        <Typography.Title level={4}>Liquidaciones Pagadas</Typography.Title>
      </Divider>  
      <Row>
        <Col xl={12} xs={24}>
          <Descriptions bordered column={1}>
            {showIVA ? 
            <>
              <Descriptions.Item label='Cantidad de Liquidaciones'>{filtered.filter(f => f.estado === 'finalizado').length} Liquidacion(es)</Descriptions.Item>
              <Descriptions.Item label='Sub-Total'>Bs. {formatCurrency(filtered.filter(f => f.estado === 'finalizado').reduce((prev, current) => prev + +current.monto, 0) / 1.16)}</Descriptions.Item>
              <Descriptions.Item label='I.V.A.'>Bs. {formatCurrency(filtered.filter(f => f.estado === 'finalizado').reduce((prev, current) => prev + +current.monto, 0) / 1.16 * 0.16)}</Descriptions.Item>
              <Descriptions.Item label='Total'>Bs. {formatCurrency(filtered.filter(f => f.estado === 'finalizado').reduce((prev, current) => prev + +current.monto, 0))}</Descriptions.Item>
            </> :
            <>
              <Descriptions.Item label='Total'>Bs. {formatCurrency(filtered.filter(f => f.estado === 'finalizado').reduce((prev, current) => prev + +current.monto, 0))}</Descriptions.Item>
            </>}
          </Descriptions>
        </Col>
        {width > 992 && <Col span={12} />}
      </Row>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps)(SMSettlements);

interface SMSettlementsProp {
  thm: States.ThemeColors
  auth: States.Auth
}