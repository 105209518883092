import React, { SetStateAction, Dispatch } from 'react';
import { Form, Row, Col, Select, Divider, Button, Typography, InputNumber, Collapse } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useWindowDimensions } from '../../../utils/hooks';
import { connect } from 'react-redux';
import { State, States } from 'sigt';

const MultipleActivites: React.FC<MultipleActivitesProps> = ({ form, index, ae, setTotal }) => {
  const { width } = useWindowDimensions();

  const firstListItem = (fields, add) => {
    if(fields.length === 0) {
      setTimeout(() => add(), 10);
    }
  };
  const setTotalHandle = () => {
    let total = 0;
    const values = form.getFieldsValue();
    for(let key in values){
      if(parseFloat(key))
        for(let index of values[key]){
          if(index)
            for(let item of index){
              total += item.monto;
            }
        }
    }
    setTotal(total);
  }
  
  const handlingAdd = (add) => {
    add();
  }
  const handlingRemove = (fields, remove) => {
    remove(fields.length - 1);
   } 
  return (
    <React.Fragment>
      <Form.List name={`${index}`} >
        {(fields, { add, remove }) => (
          <div>
            {firstListItem(fields, add)}
            {fields.map((field, index) => (
              <React.Fragment key={field.key}>
                <Divider style={{ margin: 5, marginLeft: -5 }} orientation='left'>
                  <Typography.Text strong style={{ marginLeft: 5 }}>
                    Reparo {index + 1}
                  </Typography.Text>
                </Divider>
                  <Row gutter={16} style={{ padding: '0 20px' }}>
                    <Col xl={16} xs={24}>
                      <Form.Item label='Aforo' name={[field.name, 'aforo']} key={index} rules={[{ required: true, message: 'Debe indicar el Aforo' }]}>
                        <Select filterOption={(value: string, option) => option?.title?.includes(value.toUpperCase())} showSearch style={{width:'100%'}} placeholder='Aforo' >{ae.activities.map((ele, index)=><Select.Option title={ele.descripcion.toUpperCase()} key={index} value={ele.id}>{ele.descripcion}</Select.Option>)}</Select>
                      </Form.Item>
                    </Col>
                    <Col xl={8} xs={24}>
                      <Form.Item label='Monto' name={[field.name, `monto`]} key={index} rules={[{ required: true, message: 'Por favor ingrese el monto' }]}>
                        <InputNumber placeholder='Monto' formatter={value => `${value}`.replace(/[A-z]+$/, '')} onChange={()=> setTimeout(()=> setTotalHandle(), 300)}  step={1} style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>                    
                  </Row>
              </React.Fragment>
            ))}
            <Row style={{width: width > 992 ? '50%' : '100%'}} gutter={[16, 16]} justify='center' align='middle'>
              {<Col>
                  <Button onClick={() => handlingAdd(add)}>Agregar Aforo</Button>
                </Col>}
                {fields.length > 1 &&
                <Col>
                  <Button type='danger' onClick={() => handlingRemove(fields, remove)}>Eliminar Aforo</Button>
                </Col>}
            </Row>
          </div>
        )}
      </Form.List>
    </React.Fragment>
  );
};

const MothsActivites: React.FC<MothsActivitesProps> = ({ form, ele, date, ae, setTotal, setLoading }) => {

  const firstListItem = (fields, add, ele) => {
    if(fields.length < ele.length) {
      setTimeout(() => add(), 1);
    }else{
      setTimeout(() => setLoading(), 1);
    }
  };

  return (
    <React.Fragment>
      <Form.List name={`${date}`} >
        {(fields, { add, remove }) => (
          <div>
            {firstListItem(fields, add,ele)}
            {fields.map((field, index) => (
              <React.Fragment key={field.key}>
                <Collapse>
                  <Collapse.Panel header={ele[index]?.title} key={index}>
                    <MultipleActivites setTotal={setTotal} form={form} index={index} ae={ae} />
                  </Collapse.Panel>
                </Collapse>
              </React.Fragment>
            ))}
          </div>
        )}
        </Form.List>
    </React.Fragment>
  );
};
const mapStateToProps = ({ ae }: State) => ({ ae })

export default connect(mapStateToProps)(MothsActivites);


interface MultipleActivitesProps {
  index: number
  ae: States.AES
  form: FormInstance
  setTotal: Dispatch<SetStateAction<number>>
}
interface MothsActivitesProps {
  setTotal: Dispatch<SetStateAction<number>>
  date: number
  ae: States.AES
  ele: any[]
  setLoading: () => void
  form: FormInstance
}