import React from 'react';
import { Card, Typography, Row, Col, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const PDP: React.FC<PDPProps> = ({ data, loading }) => {
  const formatNumber = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  return (
    <Card bodyStyle={{ paddingTop: 10 }} loading={loading}>
      <Typography.Text strong style={{ display: 'inline-block', marginBottom: 8 }}>Promedio Días para Pago (PDP)</Typography.Text> <br />
      <Row style={{ margin: 5 }}>
        <Col span={7}>
          <Typography.Text style={{ fontSize: 16, fontWeight: 'lighter', textAlign: 'center', width: '100%', color: 'rgba(0, 0, 0, 0.6)' }}>
            PDP
            <Tooltip title='Promedio Días para Pago: Representa el promedio de días que demoran los contribuyentes en realizar pagos vencidos.'>
              <QuestionCircleOutlined style={{ margin: '0 5px' }} />
            </Tooltip>
          </Typography.Text> <br />
          <Typography.Text strong style={{ fontSize: 18, textAlign: 'center', width: '100%', color: 'black' }}>
            {formatNumber(data?.promedio || 0)}
          </Typography.Text> 
        </Col>
        <Col span={17}>
          <Typography.Text style={{ fontSize: 16, fontWeight: 'lighter', textAlign: 'center', width: '100%', color: 'rgba(0, 0, 0, 0.6)' }}>
            Límite Superior
            <Tooltip title='Mayor numero de días que se demoró un contribuyente en realizar un pago vencido.'>
              <QuestionCircleOutlined style={{ margin: '0 5px' }} />
            </Tooltip>
          </Typography.Text> <br />
          <Typography.Text strong style={{ fontSize: 18, textAlign: 'center', width: '100%', color: 'black' }}>
            {formatNumber(data?.limiteSuperior || 0)} días
          </Typography.Text> 
        </Col>
      </Row>
    </Card>
  );
};

export default PDP;

interface PDPProps {
  data?: {
    anio: number
    mes: string
    limiteSuperior: number
    promedio: number
  }
  loading: boolean
}