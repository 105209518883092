import React, { useState } from 'react';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import axios from 'axios';
import { RcCustomRequestOptions, UploadFile } from 'antd/lib/upload/interface';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import { FormInstance } from 'antd/lib/form';

const FileUpload: React.FC<FileUploadProps> = ({ disabled, documentName, fileList, onChange, auth, form }) => {
  const { idTramite } = useParams();
  const [previewImage, setPreviewImage] = useState<string | undefined>(undefined);

  const customRequest = async (options: RcCustomRequestOptions) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/uploads/procedures/${idTramite}`;
    const data = new FormData();
    data.append('media', options.file);
    const config = {
      headers: { 'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s' }
    };
    const response = await axios.post(url, data, config);
    setPreviewImage(response.data.archivos[0]);
    options.onSuccess(response.data, options.file);
  };

  const transformFile = (file: string | File | Blob) => {
    const blob = (file as File).slice(0, (file as File).size, 'image/png');
    return new File([blob], documentName, { type: 'image/png' });
  };

  const onPreview = () => previewImage ? window.open(previewImage, '_blank') : fileList ? window.open(fileList[0]?.thumbUrl, '_blank') : () => {}

  const onDelete = async () => {
    const values = form.getFieldsValue();
    const prop = Object.keys(values).filter(k => Array.isArray(values[k])).find(k => values[k] && values[k][0] ? values[k][0].name === documentName : undefined);
    const url = `${process.env.REACT_APP_SERVER_URL}/uploads/${idTramite}`;
    const config = {
      headers: { 'Authorization': `Bearer ${auth.token}` }
    };
    axios.put(url, { file: `tramites/${idTramite}/${documentName}`, prop }, config);
  };

  return (
    <Upload name='media' listType='picture' accept='image/*' customRequest={customRequest} transformFile={transformFile} onPreview={onPreview}
      multiple={false} fileList={fileList} onChange={onChange} onRemove={onDelete}>
      {((fileList && fileList.length === 0) || !fileList) && !disabled && 
      <Button>
        <UploadOutlined /> Click para subir foto
      </Button>}
    </Upload>
  );
};

const mapStateToProps = (state: State) => ({ auth: state.auth });

export default connect(mapStateToProps)(FileUpload);

export interface FileUploadProps {
  disabled?: boolean
  documentName: string
  fileList?: UploadFile<any>[]
  onChange: (s: any) => any
  auth: States.Auth
  form: FormInstance
}