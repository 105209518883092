import { Button, Card, Checkbox, Col, Collapse, Descriptions, Empty, Form, Input, Popconfirm, Select, message } from 'antd';
import { Row } from 'antd/es/grid';
import React, { useState } from 'react';
import { useWindowDimensions } from '../../../utils/hooks';
import { State, States, Taxpayer, Vehicle } from 'sigt';
import { connect } from 'react-redux';
import handlingMessage from '../../../utils/handlingMessage';
import { getUserVehicles, getUserVh, getVehicleByPlate } from '../../../services/users';
import moment from 'moment';

const InfoVehicle: React.FC<InfoVehicleProps> = ({ thm, auth }) => {
  const [form] = Form.useForm();
  const { width } = useWindowDimensions();
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [users, setUsers] = useState<Taxpayer[]>([]);
  const [load, setLoad] = useState<boolean>(false);
  const [searchCheck, setSearchCheck] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoad(true);
      const values = await form.validateFields();
      for (let item in values) (values[item] === undefined || null) && delete values[item];
      if (values.doc) {
        handlingMessage({
          action: () => Promise.all([getUserVh(values, auth.token), getUserVehicles(values, auth.token)]),
          key: 'submit',
          loadingMessage: 'Realizando operación...',
          cb: (data) => {
            const users = data[0];
            const vehicles = data[1];
            if (users && users.status === 200 && vehicles.status === 200) {
              setUsers(users.contribuyentes);
              setVehicles(vehicles.vehiculos);
              console.log(users);
            }
          },
        });
      }
      if (values.placa) {
        handlingMessage({
          action: () => getVehicleByPlate(values, auth.token),
          key: 'submit',
          loadingMessage: 'Realizando operación...',
          cb: (data) => {
            if (data.status === 200) {
              setVehicles(data.vehiculos);
            }
          },
        });
      }
    } catch (error) {
      console.log('infoVehiculo', error);
      message.error('Error al consultar información del vehiculo');
    } finally {
      setLoad(false);
    }
  };

  const docTypeSelect = (
    <Form.Item noStyle name='pref'>
      <Select>
        <Select.Option value='J'>J</Select.Option>
        <Select.Option value='G'>G</Select.Option>
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='P'>P</Select.Option>
      </Select>
    </Form.Item>
  );

  const DetailsVehicles = () => {
    return (
      <Col span={24}>
        {vehicles.length <= 0 ? (
          <Empty
            style={{
              padding: '100px 0 0 0',
              margin: '20px 0 0 0',
              height: 400,
              border: '1px solid lightgrey',
              borderRadius: 5,
            }}
            description='No posee vehículos registrados'
          />
        ) : (
          <Collapse>
            {vehicles.map((v) => (
              <Collapse.Panel header={`PLACA: ${v.placa?.toUpperCase()} - MODELO: ${v.modelo?.toUpperCase()}`} key={v.id}>
                <Descriptions column={width > 992 ? 2 : 1} layout={width > 768 ? 'horizontal' : 'vertical'} bordered key={v.id}>
                  <Descriptions.Item label='Placa'>{v?.placa?.toUpperCase() ?? 'N/A'}</Descriptions.Item>
                  <Descriptions.Item label='Categoría'>{v?.categoria ?? 'N/A'}</Descriptions.Item>
                  <Descriptions.Item label='Subcategoría'>{v?.subcategoria ?? 'N/A'}</Descriptions.Item>
                  <Descriptions.Item label='Marca'>{v?.marca ?? 'N/A'}</Descriptions.Item>
                  <Descriptions.Item label='Modelo'>{v?.modelo ?? 'N/A'}</Descriptions.Item>
                  <Descriptions.Item label='Nombre Propietario'>{v?.nombrePropietario ?? 'N/A'}</Descriptions.Item>
                  <Descriptions.Item label='Cedula Propietario'>{v?.cedulaPropietario ?? 'N/A'}</Descriptions.Item>
                  <Descriptions.Item label='Color'>{v?.color ?? 'N/A'}</Descriptions.Item>
                  <Descriptions.Item label='Peso'>{v?.peso || v?.peso_vehiculo}</Descriptions.Item>
                  <Descriptions.Item label='Cilindraje'>{v?.cilindraje || v?.cilindraje_vehiculo}</Descriptions.Item>
                  <Descriptions.Item label='Año'>{v?.anio ?? 'N/A'}</Descriptions.Item>
                  <Descriptions.Item label='Fecha de Adquisición'>
                    {v?.fechaAdquisicion ? moment(v?.fechaAdquisicion).utcOffset(4).format('YYYY') : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label='Fecha de Inicio'>
                    {v?.fechaInicio ? moment(v?.fechaInicio).utcOffset(4).format('YYYY') : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label='Serial de Carroceria'>{v?.serialCarroceria ?? 'N/A'}</Descriptions.Item>
                  <Descriptions.Item label='Serial de motor'>{v?.serialMotor || v?.serial_motor_vehiculo}</Descriptions.Item>
                </Descriptions>
              </Collapse.Panel>
            ))}
          </Collapse>
        )}
      </Col>
    );
  };

  const handleSearch = () => {
    setSearchCheck(!searchCheck);
    setUsers([]);
    setVehicles([]);
    form.resetFields();
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Consulta Vehículo'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Form form={form} layout='vertical' onFinish={handleSubmit}>
        <Row gutter={16} justify='end'>
          <Col>
            <Button onClick={handleSearch}>{`Búsqueda por ${searchCheck ? 'placa' : 'documento'}`}</Button>
          </Col>
        </Row>
        <Row gutter={16}>
          {searchCheck ? (
            <>
              <Col xs={24} xl={6}>
                <Form.Item label='Documento de Identidad' name='doc'>
                  <Input placeholder='Documento de Identidad' addonBefore={docTypeSelect} />
                </Form.Item>
              </Col>
              <Col xs={24} xl={6}>
                <Form.Item label='Registro municipal' name='rim'>
                  <Input placeholder='Registro municipal' />
                </Form.Item>
              </Col>
            </>
          ) : (
            <Col xs={24} xl={6}>
              <Form.Item label='Placa' name='placa'>
                <Input placeholder='Placa' />
              </Form.Item>
            </Col>
          )}
          <Col xs={6} md={4} xl={4} style={{ alignSelf: 'center', paddingTop: '15px' }}>
            <Button type='primary' loading={load} htmlType='submit'>
              Buscar
            </Button>
          </Col>
        </Row>
        <Row>
          {searchCheck ? (
            users.map((u) => (
              <Col span={24}>
                <Collapse>
                  <Collapse.Panel key={u.id} header={`Documento: ${u.tipoDocumento}${u.documento}`}>
                    <DetailsVehicles />
                  </Collapse.Panel>
                </Collapse>
              </Col>
            ))
          ) : (
            <DetailsVehicles />
          )}
        </Row>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

interface InfoVehicleProps {
  thm: States.ThemeColors;
  auth: States.Auth;
}

export default connect(mapStateToProps)(InfoVehicle);
