import React, { useCallback, useEffect, useState } from 'react';
import { Card, Tabs, Table, Button, InputNumber, Input, Form, Tooltip, message, Spin, Row, Select } from 'antd';
import { useWindowDimensions } from '../../utils/hooks';
import { AppstoreAddOutlined, BankOutlined, CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/interface';
import axios, { AxiosResponse } from 'axios';
import { State, States } from 'sigt';
import { FiscalResources } from '../Forms/components/SimpleEstimation';
import { connect, useSelector } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import Petro from '../Icons/Petro';
const server = process.env.REACT_APP_SERVER_URL;
const { Option } = Select;

const round8 = (number: number | string): number => {
  number = typeof number !== 'number' ? parseFloat(number) : number;
  return +Number(number).toFixed(8);
};

const EditableCell: React.FC<EditableCellProps | any> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber type='number' min={0} /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[{ required: true, message: `Por favor ingresar el valor fiscal` }]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const BuildingTable: React.FC<{
  data: FiscalResources['anos']['']['construcciones'];
  form: FormInstance;
  petro: States.Coins['petro'];
  year: number;
}> = ({ data, petro, form, year }) => {
  const [editingKey, setEditingKey] = useState('');
  const [loading, setLoading] = useState(false);
  const isEditing = (record) => record.key === editingKey;

  const { auth } = useSelector((state: State) => ({ auth: state.auth }));

  const saveValue = async (record: any) => {
    const body = {
      activo: {
        valorFiscal: form.getFieldValue('valorFiscal'),
      },
    };
    const complete = message.loading('Modificando valor fiscal...', 0);
    try {
      setLoading(true);
      const res = await axios.patch(`${server}/taxValues/constructions/${record.tipoConstruccion.id}?year=${year}`, body, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      if (res.status === 200) {
        message.success('Valor fiscal modificado exitosamente');
        record.valorFiscal = round8(+body.activo.valorFiscal);
        setEditingKey('');
      }
    } catch (e) {
      message.error('Error al actualizar el valor fiscal');
    } finally {
      complete();
      setLoading(false);
    }
  };

  const columns: any = [
    {
      title: 'Modelo de Construcción',
      dataIndex: 'tipoConstruccion',
      key: 'tipoConstruccion',
      render: (record) => <span>{record.modeloConstruccion}</span>,
    },
    {
      title: 'Valor Fiscal',
      dataIndex: 'valorFiscal',
      key: 'valorFiscal',
      render: (record) => (
        <span>
          <Petro style={{ marginRight: 8 }} />
          {record}
        </span>
      ),
      editable: true,
    },
    {
      title: 'Acciones',
      dataIndex: 'operacion',
      render: (text, record) =>
        !isEditing(record) ? (
          <Button
            type='link'
            onClick={() => {
              form.setFieldsValue({ ...record });
              setEditingKey(record.key);
            }}
          >
            Editar
          </Button>
        ) : !loading ? (
          <span>
            <Tooltip title='Confirmar'>
              <Button type='link' disabled={loading} icon={<CheckOutlined />} onClick={() => saveValue(record)} />
            </Tooltip>
            <Tooltip placement='bottom' title='Cancelar'>
              <Button type='link' disabled={loading} icon={<CloseOutlined />} onClick={() => setEditingKey('')} />
            </Tooltip>
          </span>
        ) : (
          <Spin indicator={<LoadingOutlined />} style={{ marginLeft: 20 }} />
        ),
    },
  ];

  const transformedColumns = columns.map((c) =>
    !c.editable
      ? c
      : {
          ...c,
          onCell: (record) => ({
            inputType: 'number',
            dataIndex: c.dataIndex,
            title: c.title,
            editing: isEditing(record),
          }),
        }
  );

  return <Table columns={transformedColumns} dataSource={data} components={{ body: { cell: EditableCell } }} />;
};

const LandSubTable: React.FC<{ data: any; form: FormInstance; petro: States.Coins['petro']; year: number }> = ({
  data,
  petro,
  form,
  year,
}) => {
  const [editingKey, setEditingKey] = useState('');
  const [loading, setLoading] = useState(false);
  const isEditing = (record) => record.key === editingKey;

  const { auth } = useSelector((state: State) => ({ auth: state.auth }));

  const saveValue = async (record: any) => {
    const body = {
      activo: {
        valorFiscal: form.getFieldsValue(['terreno', 'valorFiscal']).terreno.valorFiscal,
      },
    };
    const complete = message.loading('Modificando valor fiscal...', 0);
    try {
      setLoading(true);
      const res = await axios.patch(`${server}/taxValues/grounds/${record.id}?year=${year}`, body, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      if (res.status === 200) {
        message.success('Valor fiscal modificado exitosamente');
        record.terreno.valorFiscal = round8(+body.activo.valorFiscal);
        setEditingKey('');
      }
    } catch (e) {
      message.error('Error al actualizar el valor fiscal');
    } finally {
      complete();
      setLoading(false);
    }
  };

  const columns = [
    { title: 'Sector', dataIndex: 'descripcion', key: 'descripcion' },
    {
      title: 'Valor Fiscal',
      dataIndex: ['terreno', 'valorFiscal'],
      key: 'valorFiscal',
      render: (record) => (
        <span>
          <Petro style={{ marginRight: 8 }} />
          {record}
        </span>
      ),
      editable: true,
    },
    {
      title: 'Acciones',
      dataIndex: 'operacion',
      render: (text, record) =>
        !isEditing(record) ? (
          <Button
            type='link'
            onClick={() => {
              form.setFieldsValue({ ...record });
              setEditingKey(record.key);
            }}
          >
            Editar
          </Button>
        ) : !loading ? (
          <span>
            <Tooltip title='Confirmar'>
              <Button type='link' disabled={loading} icon={<CheckOutlined />} onClick={() => saveValue(record)} />
            </Tooltip>
            <Tooltip placement='bottom' title='Cancelar'>
              <Button type='link' disabled={loading} icon={<CloseOutlined />} onClick={() => setEditingKey('')} />
            </Tooltip>
          </span>
        ) : (
          <Spin indicator={<LoadingOutlined />} style={{ marginLeft: 20 }} />
        ),
    },
  ];

  const transformedColumns = columns.map((c) =>
    !c.editable
      ? c
      : {
          ...c,
          onCell: (record) => ({
            inputType: 'number',
            dataIndex: c.dataIndex,
            title: c.title,
            editing: isEditing(record),
          }),
        }
  );

  return (
    <Table columns={transformedColumns} dataSource={data} pagination={false} components={{ body: { cell: EditableCell } }} />
  );
};

const LandTable: React.FC<{
  data: FiscalResources['anos']['']['parroquias'];
  form: FormInstance;
  petro: States.Coins['petro'];
  year: number;
}> = ({ data, petro, form, year }) => {
  const columns: ColumnsType<any> = [
    { title: 'Parroquia', dataIndex: 'descripcion', key: 'descripcion' },
    { title: 'Sectores', dataIndex: 'sectores', key: 'sectores', render: (record) => <span>{record.length}</span> },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data}
      expandable={{
        expandedRowRender: (record) => (
          <LandSubTable
            petro={petro}
            data={record.sectores.map((s) => {
              s.key = s.id;
              return s;
            })}
            form={form}
            year={year}
          />
        ),
      }}
    />
  );
};

interface IYears {
  index: number;
  year: number;
}

const TableFiscalValue: React.FC<TableFiscalValueProps> = ({ thm, coin, auth }) => {
  const [indexOfYearSelected, setIndexOfYearSelected] = useState<number>(0);
  const [buildingData, setBuildingData] = useState<FiscalResources['anos']['']['construcciones'] | []>([]);
  const [landData, setLandData] = useState<any[]>([]);
  const [years, setYears] = useState<IYears[]>([]);

  const handleYearSelect = (actualIndex: number) => {
    setIndexOfYearSelected(actualIndex);
  };

  useEffect(() => {
    const numberOfYears = 5;
    const years: IYears[] = [];
    for (let index = 0; index < numberOfYears; index++) {
      years.push({
        year: new Date().getFullYear() - index,
        index,
      });
    }
    setYears(years);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();

  const getResources = useCallback(async () => {
    const response: AxiosResponse<{ datos: FiscalResources }> = await axios.get(`${server}/taxValues/resources`, {
      headers: { Authorization: `Bearer ${auth.token}` },
    });
    // setYear(Object.keys(response.data.datos.anos)[0]);
    setLandData(
      response.data.datos.anos[Object.keys(response.data.datos.anos)[4 - indexOfYearSelected]].parroquias.map((p) => {
        p.key = p.id;
        p.sectores = p.sectores.map((s) => ({
          ...s,
          terreno: { ...s.terreno, valorFiscal: round8(s.terreno.valorFiscal) },
        }));
        return p;
      })
    );
    setBuildingData(
      response.data.datos.anos[Object.keys(response.data.datos.anos)[4 - indexOfYearSelected]].construcciones.map((c) => {
        c.key = c.id;
        c.valorFiscal = round8(c.valorFiscal);
        return c;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexOfYearSelected]);

  useEffect(() => {
    getResources();
  }, [getResources, indexOfYearSelected]);

  return (
    <Card
      style={{ height: 'max-content' }}
      title='Administrar Valores Fiscales'
      headStyle={{ backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: '#fff' }}
      bodyStyle={{ overflowY: 'auto' }}
    >
      <Tabs defaultActiveKey='1' size='large'>
        <Tabs.TabPane
          tab={
            <span>
              <AppstoreAddOutlined />
              Terreno
            </span>
          }
          key='1'
        >
          {landData && (
            <Form form={form} component={false} initialValues={{ factorTerreno: 1 }}>
              <Row gutter={12} style={{ justifyContent: 'flex-end', marginBottom: 10, marginRight: 2 }}>
                <Select placeholder='Año' style={{ width: 120 }} onChange={handleYearSelect}>
                  {years.map((year) => (
                    <Option value={year.index}>{String(year.year)}</Option>
                  ))}
                </Select>
              </Row>
              <LandTable petro={coin.petro} data={landData} form={form} year={new Date().getFullYear() - indexOfYearSelected} />
            </Form>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span>
              <BankOutlined />
              Construcción
            </span>
          }
          key='2'
        >
          {buildingData && (
            <Form form={form} component={false} initialValues={{ factorConstruccion: 1 }}>
              <Row gutter={12} style={{ justifyContent: 'flex-end', marginBottom: 10, marginRight: 2 }}>
                <Select placeholder='Año' style={{ width: 120 }} onChange={handleYearSelect}>
                  {years.map((year) => (
                    <Option value={year.index}>{String(year.year)}</Option>
                  ))}
                </Select>
              </Row>
              <BuildingTable
                petro={coin.petro}
                data={buildingData}
                form={form}
                year={new Date().getFullYear() - indexOfYearSelected}
              />
            </Form>
          )}
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ auth: state.auth, thm: state.thm, coin: state.coin });

export default connect(mapStateToProps)(TableFiscalValue);

interface TableFiscalValueProps {
  auth: States.Auth;
  thm: States.ThemeColors;
  coin: States.Coins;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: Item;
}

interface Item {
  key: number;
  name: string;
  cost: number;
}
