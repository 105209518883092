import React, { useState } from 'react';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import { Card, Form, Row, Divider, Typography, Col, Input, Select, Button, message } from 'antd';
import { useWindowDimensions } from '../../../utils/hooks';
import ParishSelect from '../../Forms/components/ParishSelect';
import TaxpayerTypeSelect from '../../Forms/components/TaxpayerTypeSelect';
import { Utils } from '../../../utils/validators';
import axios from 'axios';
import { useHistory } from 'react-router';
const server = process.env.REACT_APP_SERVER_URL;

const InternalRegister: React.FC<InternalRegisterProps> = ({ thm, auth }) => {
  const [tipoDocumento, setTipoDocumento] = useState('J');
  const [loading, setLoading] = useState(false);

  const { width } = useWindowDimensions();
  const history = useHistory();
  const [form] = Form.useForm();

  const tipoAddon = (
    <Select value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value ='G'>G</Select.Option>
      <Select.Option value ='P'>P</Select.Option>
    </Select>
  );

  const submit = async () => {
    const values = await form.validateFields();
    values.tipoDocumento = tipoDocumento;
    try {
      setLoading(true);
      const response = await axios.post(`${server}/settlements/internal`, values, { headers: { Authorization: `Bearer ${auth.token}` } });
      message.success('Contribuyente registrado de manera exitosa')
      history.push(`/dashboard/contribuyente/licencia/${response.data.contribuyente?.id}`, { contribuyente: response.data.contribuyente });
    } catch(e) {
      message.error(e.response?.data?.message || 'Error al registrar contribuyente');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card style={{ height: '100%' }} title='Registro de Contribuyente' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', paddingTop: 5, overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 1200 ? '0 10px' : '0 20px', color: 'white'}}>
      <Form layout='vertical' form={form} scrollToFirstError onFinish={submit}>
        <Row gutter={24} style={{ paddingLeft: 15, paddingRight: 15 }}>
          <Divider orientation='left' style={{ marginLeft: -10 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Datos del Contribuyente
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={8}>
            <Form.Item label='Documento de Identidad' name='doc' rules={[{ required: true, message: 'Por favor ingrese el documento de identidad.' }]}>
              <Input addonBefore={tipoAddon} placeholder='Documento de Identidad' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Razón Social' name='razonSocial' rules={[{ required: true, message: 'Por favor ingrese la razón social.' }]}>
              <Input placeholder='Razón Social' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Denominación Comercial' name='denominacionComercial' rules={[{ required: true, message: 'Por favor ingrese la denominación comercial.' }]}>
              <Input placeholder='Denominación Comercial' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Siglas' name='siglas'>
              <Input placeholder='Siglas' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Tipo de Contribuyente' name='tipoContribuyente' rules={[{ required: true, message: 'Por favor ingrese el tipo de contribuyente.' }]}>
              <TaxpayerTypeSelect placeholder='Tipo de Contribuyente' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} style={{ paddingLeft: 15, paddingRight: 15 }}>
          <Divider orientation='left' style={{ marginLeft: -10 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Datos de Ubicación
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={14}>
            <Form.Item label='Dirección' name='direccion' rules={[{ required: true, message: 'Por favor ingrese la dirección.' }]}>
              <Input placeholder='Dirección' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={10}>
            <Form.Item label='Punto de Referencia' name='puntoReferencia' rules={[{ required: true, message: 'Por favor ingrese el punto de referencia.' }]}>
              <Input placeholder='Punto de Referencia' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Parroquia' name='parroquia' rules={[{ required: true, message: 'Por favor ingrese la Parroquia.' }]}>
              <ParishSelect  />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Sector' name='sector' rules={[{ required: true, message: 'Por favor ingrese el sector.' }]}>
              <Input placeholder='Sector' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} style={{ paddingLeft: 15, paddingRight: 15 }}>
          <Divider orientation='left' style={{ marginLeft: -10 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Datos de Contacto
            </Typography.Title>
          </Divider>
          <Col xs={24} xl={8}>
            <Form.Item label='Correo Electrónico' name='correo' rules={[{ required: true, message: 'Por favor ingrese el correo electrónico.' }, { validator: Utils.validators.email }]}>
              <Input placeholder='Correo Electrónico' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Teléfono' name='telefono' rules={[{ required: true, message: 'Por favor ingrese el teléfono.' }]} normalize={Utils.normalize.isNumber}>
              <Input placeholder='Teléfono' />
            </Form.Item>
          </Col>
        </Row>
        <Button type='primary' loading={loading} onClick={form.submit}>Registrar</Button>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps)(InternalRegister);

interface InternalRegisterProps {
  thm: States.ThemeColors
  auth: States.Auth
}