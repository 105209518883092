import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Col, Select, Form, Input, message, Table, Tooltip, Modal, Descriptions } from 'antd';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import { useWindowDimensions } from '../../utils/hooks';
import handlingMessage from '../../utils/handlingMessage';
import { getUser, editUser, unLink, verifyNumber, blockUser } from '../../services/users';
import { ColumnsType } from 'antd/lib/table/Table';
import { EditOutlined, UserOutlined, PhoneFilled, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Utils } from '../../utils/validators';


const TableEditUser: React.FC<TableEditUserProps> = ({ thm, auth }) => {
  const [form] = Form.useForm();
  const [load, setLoad] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const { width } = useWindowDimensions();
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState<any | null>();

  useEffect(() => {
    form.setFieldsValue({pref:'V'});
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async() => {
    const values = await form.validateFields();
    for(let item in values)
      (values[item] === undefined || null) && delete values[item]
    if(!values.email && !values.doc){
      message.warning({ duration:2, content:'Debe ingresar usuario o documento para realizar la busqueda' })
    } else {
      setLoad(true);
        handlingMessage({
            action: () => getUser(values, auth.token),
            key: 'submit',
            loadingMessage: 'Realizando operacion...',
            cb: (data) => {
                if(data.status === 200){
                    setUsers(data.usuarios)
                  }
                  setLoad(false);
                }
             });
          }
  }


  const columns:ColumnsType<any> = [
    {
      title: "Documento",
      dataIndex: "documento",
      key: "documento",
    },
    {
      title: "Usuario",
      dataIndex: "nombreUsuario",
      key: "nombreUsuario",
    },
    {
      dataIndex: "acciones",
      align:'center',
      key: "acciones",
      render: (_, record) => (<Tooltip title='Editar' visible={visible}>
      <Button 
        type="primary" shape='round' 
        onMouseOver={() => width > 440 ? setVisible(false) : setVisible(true)} 
        onMouseLeave={()=> setVisible(false)}
        icon={<EditOutlined />} 
        size='small'
        onClick={()=> setUser(record?.id ? record : null)}>
        {width > 440 ? 'Editar' : null}
      </Button>
    </Tooltip>)
    },
  ]

  const docTypeSelect = (
    <Form.Item noStyle name='pref'>
      <Select>
        <Select.Option value='J'>J</Select.Option>
        <Select.Option value='G'>G</Select.Option>
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='P'>P</Select.Option>
      </Select>
    </Form.Item>
  );
  const reset = () => {
    setUsers([]);
    setUser(null);
    form.resetFields();
    form.setFieldsValue({pref:'V'});
  }
  return(
    <>
    <Card style={{ height: '100%' }} title='Editar Usuario' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
     headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}}>
        <Form form={form} layout='vertical' onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col xs={24} xl={10}>
              <Form.Item label='Documento de Identidad' name='doc'>
                <Input placeholder='Documento de Identidad' addonBefore={docTypeSelect} />
              </Form.Item>
            </Col>
            <Col xs={18} md={20}  xl={10}>
              <Form.Item name='email' normalize={Utils.normalize.toLower} label='Usuario de S.U.T'>
                <Input placeholder='Correo' />
              </Form.Item>
            </Col>
            <Col xs={6} md={4} xl={4} style={{alignSelf:'center', paddingTop:'15px'}}>
                <Button type='primary' loading={load} htmlType="submit">Buscar</Button>
            </Col>         
          </Row>
          <Row>
            <Col span={24}>
              <Table
              style={{ height: '100%' }}
              size='middle'
              pagination={{ pageSize: 5 }}
              loading={load}
              columns={columns}
              dataSource={users}
              rowKey={e => e.id}
              />
            </Col>
          </Row>
        </Form>
      </Card>
      {user && <FormUserModal reset={()=> reset()} user={user} back={()=> setUser(null)} auth={auth} />}
      </>
  )
}

const FormUserModal = ({back, user, reset, auth}) => {
  const [loading, setLoading] = useState(false);
  const [verify, setVerify] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [block, setBlock] = useState<boolean>(user.bloqueado)
  const [form] = Form.useForm();

  const docTypeSelect = (
    <Form.Item noStyle name='tipoDocumento' rules={[{ required: true, message: 'Debe ingresar el tipo de documento del contribuyente' }]}>
      <Select>
        <Select.Option value='J'>J</Select.Option>
        <Select.Option value='G'>G</Select.Option>
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='P'>P</Select.Option>
      </Select>
    </Form.Item>
  );
  useEffect(() => {
    // console.log(user)
    form.setFieldsValue(user)
    // eslint-disable-next-line
  }, [])

  const handleBlock = async() => {
    await form.validateFields();
    setBlock(!block);
    handlingMessage({
      action: () => blockUser(auth.token, block, user.id),
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb:(data) => {
        setLoading(false);
        if(data.status === 200)reset();
      },
    });
  }
  const handleSubmit = async() => {
    const values = await form.validateFields();
    setLoading(true);
    handlingMessage({
      action: () => editUser(values, auth.token, user.id),
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb:(data) => {
        setLoading(false);
        if(data.status === 200)reset();
      },
    });
  }
  const unLinkUser = () => {
    setDisabled(true);
    handlingMessage({
      action: () => unLink(auth.token, user.id),
      key: 'unlink',
      loadingMessage: 'Realizando operacion...',
      cb:(data) => {
        setLoading(false);
        if(data.status === 200)reset();
      },
    });
  }
  const check = async() => {
    setVerify(true);
    const values = await form.validateFields();
    handlingMessage({
      action: () => verifyNumber(values.telefono,auth.token, user.id),
      key: 'unlink',
      loadingMessage: 'Realizando operacion...',
      cb:(data) => {
        setVerify(false);
        if(data.status === 200)reset();
      },
    });
  }
  
  const btns = () => {
    if(user?.contribuyente){
      if(!user?.contribuyente?.verificacionTelefono){
        return ([<Button key='check'loading={verify} disabled={loading || disabled} onClick={()=> check()}>Verificar</Button>,
        <Button key='unLink' onClick={()=> unLinkUser()} loading={disabled} disabled={loading || verify}>Desenlazar</Button>,
        <Button key='submit' type='primary' loading={loading} disabled={disabled || verify} onClick={handleSubmit}>Guardar</Button>])
      }else{
        return([
        <Button key='block' icon={block ? <UnlockOutlined /> : <LockOutlined />} disabled={disabled || loading} onClick={handleBlock}>{block ? 'Desbloquear' : 'Bloquear'}</Button>,
        <Button key='unLink' onClick={()=> unLinkUser()} loading={disabled} disabled={loading}>Desenlazar</Button>,
        <Button key='submit' type='primary' loading={loading} disabled={disabled} onClick={handleSubmit}>Guardar</Button>])
      }
    }else{
      return ([<Button key='submit' type='primary' loading={loading} disabled={disabled} onClick={handleSubmit}>Guardar</Button>])
    }
  }

  return(
    <Modal  maskClosable onCancel={()=> back()} title='Editar Usuario de S.U.T' footer={btns()} visible >
      <Form form={form} onFinish={handleSubmit}>
      <Row gutter={16}>
        <Col xl={12} md={12} xs={24}>
          <Form.Item name='nombreUsuario' normalize={Utils.normalize.toLower} rules={[{ required: true, message: 'Por favor ingresar correo!' }, { validator: Utils.validators.email }]}>
            <Input placeholder='Correo' />
          </Form.Item>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Form.Item name='nombreCompleto' rules={[{ required: true, message: 'Por favor ingresa tu Razon Social!' }]}>
            <Input placeholder='Razon Social' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item rules={[{ required: true, message: 'Por favor ingresar tu documento de identidad!' }, { validator: Utils.validators.cedula }]}
             name='documento'>
            <Input addonBefore={docTypeSelect}  maxLength={10} placeholder="Documento de Identidad"  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item rules={[{ required: true, message: 'Por favor ingresar tu telefono!' }, { validator: Utils.validators.phoneNumber }]}
            normalize={Utils.normalize.isNumber} name='telefono' validateTrigger='onBlur'>
            <Input addonBefore='+58'  maxLength={10} placeholder="Documento de Identidad"  prefix={<PhoneFilled style={{ color: 'rgba(0,0,0,.25)' }} />} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
        <Form.Item rules={[{ required: true, message: 'Por favor ingresar tu direccion!' }]} name='direccion'>
            <Input.TextArea maxLength={250} style={{ resize: 'none' }} placeholder="Direccion" />
          </Form.Item>
        </Col>
      </Row>
      {user?.contribuyente && <Descriptions title="Datos del contribuyente" bordered  column={1} size='small'>
        <Descriptions.Item label='RIF' span={1}>{user?.contribuyente?.tipoDocumento}-{user?.contribuyente?.documento}</Descriptions.Item>
        <Descriptions.Item label='Razon Social' span={1}>{user?.contribuyente?.razonSocial}</Descriptions.Item>
        <Descriptions.Item label='Dirección' span={1}>{user?.contribuyente?.direccion}</Descriptions.Item>
      </Descriptions>}
      </Form>
    </Modal>
  )
}

interface TableEditUserProps {
  auth: States.Auth
  thm: States.ThemeColors
}

const mapStateToProps = ({ thm, auth }: State) => ({ thm, auth })

export default connect(mapStateToProps)(TableEditUser);