import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const linkVehicle = async (data, token) => {
    try {
      const response = await axios.post(`${server}/vehicles/link`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
};

export const unLinkVehicle = async (data, token) => {
    try {
      const response = await axios.post(`${server}/vehicles/unlink`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
};

export const setInitDateVehicle = async (body : { id:number, date?: string, rim?: string, taxpayer: number | undefined}) => {
  try{
    const response = await axios.patch(`${server}/vehicles/date`, body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    return response.data;
  }catch(err){
    throw err;
  }
}