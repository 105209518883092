import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Input, Select, Button, Table, Tooltip } from 'antd';
import { connect, useDispatch } from 'react-redux';
import { State, States } from 'sigt';
import { useWindowDimensions } from '../../../utils/hooks';
import { message } from 'antd';
import handlingMessage from '../../../utils/handlingMessage';
import { getUser, getUserVehicles, getUserVh } from '../../../services/users';
import { ColumnsType } from 'antd/lib/table/Table';
import { CarOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';

const GetVehicleInfo: React.FC<GetVehicleInfoProps> = ({ thm, auth }) => {
  const [form] = Form.useForm();
  const { width } = useWindowDimensions();
  const [users, setUsers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [load, setLoad] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({ pref: 'V' });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    const values = await form.validateFields();
    for (let item in values) (values[item] === undefined || null) && delete values[item];
    if (!values.doc) {
      message.warning({ duration: 2, content: 'Debe ingresar usuario o documento para realizar la busqueda' });
    } else {
      setLoad(true);
      handlingMessage({
        action: () => Promise.all([getUserVh(values, auth.token), getUserVehicles(values, auth.token)]),
        key: 'submit',
        loadingMessage: 'Realizando operacion...',
        cb: (data) => {
          console.log(data, 'changing user for taxpayer');
          const users = data[0];
          const vehicles = data[1];
          if (users && users.status === 200 && vehicles.status === 200) {
            setUsers(users.contribuyentes);
            setVehicles(vehicles.vehiculos);
            console.log(users)
          }
          setLoad(false);
        },
      });
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Documento',
      dataIndex: 'documento',
      key: 'documento',
    },
    {
      title: 'Contribuyente',
      dataIndex: 'razonSocial',
      key: 'razonSocial',
    },
    {
      title: 'Registro Municipal',
      dataIndex: 'referenciaMunicipal',
      key: 'referenciaMunicipal',
      render: (r) => r ? r : 'N/A'
    },
    {
      dataIndex: 'acciones',
      align: 'center',
      key: 'acciones',
      render: (_, record) => (
        <Tooltip title='Tramitar Vehículos' visible={visible}>
          <Button
            type='primary'
            shape='round'
            onMouseOver={() => (width > 440 ? setVisible(false) : setVisible(true))}
            onMouseLeave={() => setVisible(false)}
            icon={<CarOutlined />}
            size='small'
            onClick={() => {
              const user: any = users[0];
              dispatch({ type: 'SET_ID', payload: +user.id });
              dispatch({ type: 'SET_CONTRIBUYENTE', payload: {id: user.idContribuyente, referenciaMunicipal: user.referenciaMunicipal} });
              dispatch({ type: 'SET_ISRIM', payload: !!user.referenciaMunicipal });
              dispatch({ type: 'SET_VEHICLES', payload: vehicles });
              history.push('/dashboard/impuestos/vehiculos', { user });
            }}
          >
            {width > 440 ? 'Tramitar' : null}
          </Button>
        </Tooltip>
      ),
    },
  ];

  const docTypeSelect = (
    <Form.Item noStyle name='pref'>
      <Select>
        <Select.Option value='J'>J</Select.Option>
        <Select.Option value='G'>G</Select.Option>
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='P'>P</Select.Option>
      </Select>
    </Form.Item>
  );
  return (
    <Card
      style={{ height: '100%' }}
      title='Tramitar Vehículos'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Form form={form} layout='vertical' onFinish={handleSubmit}>
        <Row gutter={16}>
          <Col xs={24} xl={10}>
            <Form.Item label='Documento de Identidad' name='doc'>
              <Input placeholder='Documento de Identidad' addonBefore={docTypeSelect} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={10}>
            <Form.Item label='Registro municipal' name='rim'>
              <Input placeholder='Registro municipal' />
            </Form.Item>
          </Col>
          <Col xs={6} md={4} xl={4} style={{ alignSelf: 'center', paddingTop: '15px' }}>
            <Button type='primary' loading={load} htmlType='submit'>
              Buscar
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              style={{ height: '100%' }}
              size='middle'
              pagination={{ pageSize: 5 }}
              loading={load}
              columns={columns}
              dataSource={users}
              rowKey={(e) => e.id}
            />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps)(GetVehicleInfo);

interface GetVehicleInfoProps {
  thm: States.ThemeColors;
  auth: States.Auth;
}

interface User {
  id: number;
}
