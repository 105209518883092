import React from 'react';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import { useWindowDimensions } from '../../utils/hooks';
import { Card } from 'antd';
import GetTaxpayerInfo from './components/GetTaxpayerInfo';
import { useRouteMatch } from 'react-router';

const ExternalInfo: React.FC<ExternalInfoProps> = ({ thm }) => {
  const { width } = useWindowDimensions();
  useRouteMatch('contrib/consultar');

  return (
    <Card style={{ height: '100%' }} title='Consulta de Contribuyente' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', padding: '10px 40px' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}}>
      <GetTaxpayerInfo />
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm });

export default connect(mapStateToProps)(ExternalInfo);

interface ExternalInfoProps {
  thm: States.ThemeColors
}