import React from 'react';
import { Select } from 'antd';

interface IProcessObject {
  id: number;
  nombre: ProcessName;
}

type ProcessName = 'Aprobar' | 'Inspeccionar' | 'Corregir';
type ProcessNameServer = 'inspeccion' | 'correccion' | 'revision' | 'enproceso' | null;

export const processNameSelector = (process: ProcessName, tipoTramite: any, cargo: any): ProcessNameServer => {
  if (process === 'Aprobar') return (tipoTramite === 43 && cargo === 93) ? 'enproceso' : 'revision';
  if (process === 'Corregir') return 'correccion';
  if (process === 'Inspeccionar') return 'inspeccion';
  return null;
};

const ProcessSelect: React.FC<ProcessSelectProps> = ({ value, onChange, disabled = false, tipoTramite, cargo }) => {
  const process: IProcessObject[] = [
    { id: 1, nombre: 'Aprobar' },
    { id: 2, nombre: 'Corregir' },
    { id: 3, nombre: 'Inspeccionar'},
  ];
  (tipoTramite === 43 && cargo === 93) && process.pop()
  return (
    <Select placeholder={'Seleccione el Proceso'} value={value} onChange={onChange} disabled={disabled}>
      {process.map((p) => (
        <Select.Option value={p.nombre} key={p.id}>
          {p.nombre}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ProcessSelect;

interface ProcessSelectProps {
  tipoTramite?: number,
  cargo?: number,
  value?: any;
  onChange?: (s: any) => any;
  disabled?: boolean;
}
