import { States } from "sigt";
import { RequestType, SET_REQUESTS, ADD_REQUEST, PAY_REQUEST, AUTH_USER_LOGOUT, CHANGE_REQ_STATUS, REBATE_REQUEST } from "../actions/actionsTypes";

const initialState: States.Requests = {
  requests: []
};

export default (state = initialState, action: RequestType) => {
  switch(action.type) {
    case SET_REQUESTS: return { ...state, requests: action.payload };
    case ADD_REQUEST: return { ...state, requests: [...state.requests, action.payload] };
    case CHANGE_REQ_STATUS: {
      const { id, status } = action.payload;
      const _requests = [...state.requests];
      const rIndex = _requests.findIndex(r => r.id === id);
      _requests[rIndex] = {
        ..._requests[rIndex],
        estado: status as any
      };
      return {
        ...state,
        requests: _requests
      }
    };
    case REBATE_REQUEST:
      const _requests = [...state.requests];
      const rIndex = _requests.findIndex(r => r.id === action.payload.id);
      _requests[rIndex] =  {..._requests[rIndex], rebajado: action.payload.rebajado, montoPetro: action.payload.montoPetro, liquidaciones: action.payload.liquidaciones };
      return {...state, requests: _requests};
    case PAY_REQUEST: {
      const _requests = [...state.requests];
      const rIndex = _requests.findIndex(r => r.id === action.payload);
      _requests[rIndex] = {
        ..._requests[rIndex],
        pagado: true,
        estado: 'validando'
      };
      return {
        ...state,
        requests: _requests
      }
    };
    case AUTH_USER_LOGOUT: 
      return initialState;
    default: return state;
  }
}