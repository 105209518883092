import React, { useEffect, Fragment, useState } from 'react';
import { Card, Form, Button, Row, Col, Modal, DatePicker, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { createHoliday } from '../../redux/actions/holidays';
import { State, States } from 'sigt';
import { useLocation } from 'react-router';
import handlingMessage from '../../utils/handlingMessage';
import moment from 'moment';

const BankCalendarForm: React.FC<BankCalendarFormProps> = ({ thm, auth, createHoliday, modal }) => {
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  const [disable, setDisable] = useState(false)
  //moods
  const dashboard = location.pathname.includes('/dashboard');
  const title = 'Nueva Fecha Bancaria';



  useEffect(() => {
    if (auth.token && !dashboard) {
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  }, [auth.token, location.pathname]);

  const renderWrapper = () => {
    if (modal) 
      return <Modal title={title} footer={null} width={600} visible centered style={{ maxHeight: "700px" }} onCancel={() => history.goBack()}>
        {content}
      </Modal>

    return <Card title={title || undefined} style={{ width: !dashboard ? 550 : undefined }} bodyStyle={{ padding: dashboard ? '30px 50px' : '24px' }} headStyle={dashboard ? { backgroundColor: thm.primaryColor, color: '#fff' } : {}}>
      {content}
    </Card>
  }

  const handleSubmit = async () => {
    setDisable(true);
    const values = await form.validateFields();
    values.fecha = moment(values.fecha).toDate();
    handlingMessage({
      action: () => createHoliday(values, auth.token ? auth.token: ''),
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: data => (data.status === 200) || (data.status === 201) ? history.push('/dashboard/fechaBancaria') : setDisable(false)
    });
  };


  const content = (
    <Form onFinish={handleSubmit} form={form}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item rules={[{ required: true, message: 'Por favor ingresar una descripcion!' }]} name="descripcion">
            <Input placeholder="Descripcion" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item rules={[{ required: true, message: 'Por favor ingresar fecha!' }]} name="fecha">
            <DatePicker format='DD-MM-YYYY' placeholder="Fecha" style={{width:'100%'}} />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center" style={{ textAlign: 'center' }} align="middle" gutter={16}>
        <Col span={dashboard && !modal ? 6 : 12}>
          <Button disabled={disable} style={{ width: '100%' }} type="primary" htmlType="submit">
            {'Crear Fecha'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
  
  return <Fragment>{renderWrapper()}</Fragment>
};

const mapStateToProps = ({ thm, auth, hlds }: State) => ({ thm, auth, hlds });

export default connect(mapStateToProps, { createHoliday })(BankCalendarForm);

interface BankCalendarFormProps {
  createHoliday: (values, token:string) => Promise<void>;
  auth: States.Auth;
  thm: States.ThemeColors;
  modal?: boolean;
}
