import React, { useEffect, useState, useRef } from "react";
import { EditOutlined, CheckOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Card, Input, Button, Table, Tooltip, Form, InputNumber } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { connect } from "react-redux";
import { State, States } from 'sigt';
import { fetchCost, editCost } from '../../redux/actions/cost';
import handlingMessage from '../../utils/handlingMessage';
import { useWindowDimensions } from '../../utils/hooks';
import Highlighter from 'react-highlight-words';
import Petro from "../Icons/Petro";

const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

const EditableCell:React.FC<EditableCellProps | any> = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber type="number" min={0} step={0.1} /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{margin: 0}}
          rules={[{required: true,message: `Por favor ingresar ${title}!`,}]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TableCost: React.FC<PropsTable> = ({ thm, fetchCost, auth, cst, coin, editCost }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColum] = useState('');
  const searchInput = useRef<any>(null);
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [dataSource, setData] = useState<Item[]>([]);
  const isEditing = record => record.key === editingKey;
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setLoading(dataSource.length > 0 ? false : true)
  // }, [dataSource]);

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: "Nombre completo",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name")
    },
    {
      title: "Tarifa",
      dataIndex: "cost",
      key: "cost",
      editable:true,
      render: (price) => <Tooltip title={`${formatCurrency(+(Number(+price * coin.petro).toFixed(2)))} Bs.` } ><span style={{cursor: 'pointer'}} >{price}<Petro style={{ marginLeft: 8 }}/></span></Tooltip>
    },
    {
      title: "Acciones",
      key: "action",
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Tooltip placement="bottom" title="Confirmar">
              <Button type="link" icon={<CheckOutlined />} onClick={() => save(record.key)} />
            </Tooltip>
            <Tooltip placement="bottom" title="Cancelar">
              <Button type="link" icon={<CloseOutlined />} onClick={() => setEditingKey('')} />
            </Tooltip>                        
          </span>
        ) : (
            <span>
            <Tooltip placement="bottom" title="Editar">
              <Button type="link" icon={<EditOutlined />} onClick={() => edit(record)} />
            </Tooltip>
          </span>
        );
      },
    }
  ];

  const data: Item[] = [];
  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  useEffect(() => {
    aux()
    // eslint-disable-next-line
  }, [fetchCost, auth.token, auth.user]);

  cst.costs?.filter(e => e.pagoPrevio === true).map(cost => data.push({
    key: cost.id,
    name: cost.titulo,
    cost: cost.petro
  }));

  useEffect(() => {
    setData(data);
    // eslint-disable-next-line
  }, [cst.costs]);

  const aux = async() => {
    if(auth.user) {
      await fetchCost(auth.token, auth.user?.institucion?.id);
    }
    setLoading(false);
  }
  
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'cost' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex(item => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        const values = newData[index];
        handlingMessage({
          action: () => (editCost(values, auth.token, values.key)),
          key: "submit",
          loadingMessage: "Realizando operacion...",
          cb: () => {}
        });
        setData(newData);
        setEditingKey('');
      }
      
    } catch (errInfo) {
      // TODO: wtf
    }
  };
  const components = {
    body: {
      cell: EditableCell,
    }
  };
  return (
    <Card style={{ height: '100%' }} title="Administrar costos" 
      headStyle={{ backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: "#fff" }}>
        <Form form={form} component={false}> 
          <Table
          components={components}
          style={{ height: "100%" }}
          size="middle"
          loading={loading}
          pagination={{pageSize: 8}}
          columns={mergedColumns}
          dataSource={dataSource}
          />
        </Form>
    </Card>
  );
};

const mapStateToProps = ({ auth, cst, thm, coin }: State) => ({ auth, cst, thm, coin });

export default connect(mapStateToProps, { fetchCost, editCost })(TableCost);

interface PropsTable {
  fetchCost: (token, id) => Promise<Response>;
  editCost: (values, token, id) => Promise<Response>;
  auth: States.Auth;
  cst: States.Costs;
  thm: States.ThemeColors;
  coin: States.Coins;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: Item;
}

interface Item {
  key: number;
  name: string;
  cost: number;
}
