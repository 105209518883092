import { SetAgreementsAction, SET_AGREEMENTS, PAY_AGREEMENT, AgreementsType } from "./actionsTypes";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import axios, { AxiosResponse } from "axios";
import { Agreement, AgreementPart } from "sigt";
const server = process.env.REACT_APP_SERVER_URL;

export const setAgreements = (agreements: Agreement[]): SetAgreementsAction => ({ type: SET_AGREEMENTS, payload: agreements });
export const payAgreement = (fragment: AgreementPart) => ({ type: PAY_AGREEMENT, payload: fragment });

export const fetchAgreements = (): ThunkAction<Promise<void>, {}, {}, AgreementsType> => 
  async (dispatch: ThunkDispatch<{}, {}, AgreementsType>): Promise<void> => {
    try {
      const response: AxiosResponse<{ convenios: Agreement[] }> = await axios.get(`${server}/settlements/agreements`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      dispatch(setAgreements(response.data.convenios));
    } catch(e) {
      throw e;
    }
  }

