import { CreditCardFilled, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Descriptions, Divider, Form, Input, message, Row, Select, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Axios from 'axios';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { State, States } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import MultipleTransactions from '../Forms/components/MultipleTransactions';
import TooltipCoin from '../TooltipCoin';
import { formatCurrency, sedematBanks } from './PaySpecificTax';
const server = process.env.REACT_APP_SERVER_URL;

const SolvB: React.FC<SolvBProps> = ({ auth, thm }) => {
  const [tipoDocumento, setTipoDocumento] = useState('V');
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [solB, setSolB] = useState<SolvBResponse>({
    contribuyente: null,
    sucursales: [],
  });
  const [selectedConOrSuc, setSelectedConOrSuc] = useState<{ selectedCon: IContributer | null; selectedSuc: ISucursal | null }>({
    selectedCon: null,
    selectedSuc: null,
  });
  const [data, setData] = useState<any>({});

  const insitutions = useSelector((state: State) => state.inst.institutions);
  const pesoBS = useSelector((state: State) => state.coin.peso);
  const dolar = useSelector((state: State) => state.coin.dolar);
  const euro = useSelector((state: State) => state.coin.euro);
  // const montoAPagar = useSelector((state: State) => state.inst.institutions?.find(i => i.id === 9)?.tramitesDisponibles?.find(t => t.id === 113)?.costo || 0)
  const montoBase = +(useSelector((state: State) => state.cst.costs?.find((c) => c.id === 113))?.costo || 0);

  const getMontoAPagar = (tipoDocumento: string): number => {
    if (tipoDocumento.toLowerCase() === 'v') return montoBase;
    return montoBase * 5;
  };

  const selectedInstitution = insitutions?.find((i) => i.id === 9);

  const [searchForm] = Form.useForm();
  const [payForm] = Form.useForm();

  // const montoAPagar: number = 332 // viene del back

  // useEffect(() => {
  //   console.log('data: ', data)
  // }, [data])

  // useEffect(() => {
  //   console.log('payForm: ', payForm)
  // }, [payForm])

  const pay = async () => {
    if (!(selectedConOrSuc.selectedCon || selectedConOrSuc.selectedSuc)) return;
    const values = await payForm.validateFields();
    Object.keys(values).forEach((k) => {
      if (moment.isMoment(values[k])) {
        values[k] = values[k].toISOString();
      }
    });
    const pagos = Array.isArray(payForm.getFieldValue('pagos')) ? cloneDeep(payForm.getFieldValue('pagos')) || [] : [];
    if (pagos.filter((p) => p).find((p) => p.metodoPago === 'TRANSFERENCIA' && !sedematBanks.includes(p.destino))) {
      return message.error('Los metodos de pago de transferencia y cheque deben ser realizados solo a los bancos de HACIENDA');
    }
    pagos
      .filter((p) => p)
      .forEach((p) => {
        Object.keys(p).forEach((k) => {
          if (moment.isMoment(p[k])) {
            p[k] = p[k].toISOString();
          }
        });
      });
    console.log(pagos);
    pagos.forEach((pago) => (pago.costo = pago.metodoPago === 'EFECTIVO PESO' ? pago.costo * pesoBS : pago.costo));
    pagos.forEach((pago) => (pago.costo = pago.metodoPago === 'EFECTIVO DOLAR' ? pago.costo * dolar : pago.costo));
    pagos.forEach((pago) => (pago.costo = pago.metodoPago === 'EFECTIVO EURO' ? pago.costo * euro : pago.costo));

    pagos.forEach((pago) => {
      if (pago?.metodoPago?.includes('EFECTIVO')) {
        delete pago.banco;
        delete pago.destino;
        delete pago.referencia;
      }
    });

    if (calcTotal() < getMontoAPagar(tipoDocumento))
      return message.error('La suma de los montos de las transacciones debe ser igual o mayor al total de la liquidación');
    setLoading(true);
    const complete = message.loading('Creando pago para la declaración de impuestos');
    try {
      const tramite: any = {
        // pagos: pagos.map(p => p.metodoPago === 'EFECTIVO PESO' ? ({ ...p, metodoPago: 'EFECTIVO' }) : p),
        pago: pagos[0],
        contribuyente: selectedConOrSuc.selectedCon,
      };
      if (selectedConOrSuc.selectedSuc) {
        tramite.contribuyente.sucursal = selectedConOrSuc.selectedSuc;
      }
      const response = await Axios.post(
        `${server}/solvencies/b`,
        { tramite },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      window.open(response?.data?.tramite?.certificado, '_blank');
      message.success('Pago registrado de manera exitosa');
      setSelectedConOrSuc({
        selectedCon: null,
        selectedSuc: null,
      });
      setSolB({
        contribuyente: null,
        sucursales: [],
      });
      searchForm.resetFields();
      payForm.resetFields();
      // auth.user?.tipoUsuario === 4 ? history.push('/dashboard/impuestos/pagar') : history.push('/dashboard/impuestos/pendientes');
    } catch (e) {
      message.error(
        e.response?.data?.message
          ? (e.response.data.message as string).includes('referencia')
            ? 'La referencia bancaria ya ha sido utilizada.'
            : e.response.data.message
          : 'Error al crear el pago'
      );
    } finally {
      setLoading(false);
      complete();
    }
  };

  const calcTotal = () =>
    data.pagos?.reduce((acc, p) => {
      if (!p) return 0;
      if (p.metodoPago === 'EFECTIVO PESO') {
        const amount = +p.costo * +pesoBS;
        return acc + amount;
      }
      if (p.metodoPago === 'EFECTIVO DOLAR') {
        const amount = +p.costo * +dolar;
        return acc + amount;
      }
      if (p.metodoPago === 'EFECTIVO EURO') {
        const amount = +p.costo * +euro;
        return acc + amount;
      }
      return acc + +p.costo;
    }, 0) || 0;

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  const search = async () => {
    const values = await searchForm.validateFields();
    const { razonSocial, doc } = values;
    if (razonSocial || doc) {
      try {
        setSearching(true);
        const params = `tipoDocumento=${tipoDocumento.trim()}&documento=${doc.trim()}`;
        const response = await Axios.get<{ data: SolvBResponse }>(`${server}/solvencies/b?${params}`, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        setSolB(response.data.data);
      } catch (e) {
        message.error(e.response?.data?.message || 'Error al buscar contribuyentes');
      } finally {
        setSearching(false);
      }
    } else {
      message.warn('Debe ingresar algún parametro para la busqueda de contribuyente');
    }
  };

  const columns: ColumnsType<ISucursal> = [
    {
      title: 'Referencia Municipal',
      dataIndex: 'referencia_municipal',
    },
    {
      title: 'Denominación Comercial',
      dataIndex: 'denominacion_comercial',
    },
    {
      title: 'Nombre del Representante',
      dataIndex: 'nombre_representante',
    },
    {
      title: 'Acciones',
      render: (_, record: ISucursal) => (
        <Button type='primary' onClick={() => sendData(record)} icon={<EyeOutlined />}>
          Generar Solvencia
        </Button>
      ),
    },
  ];

  const sendData = async (sucursal?) => {
    try {
      let tramite: any = {
        contribuyente: solB.contribuyente,
      };
      if (sucursal) {
        tramite.contribuyente.sucursal = sucursal;
      } else {
        delete tramite.contribuyente.sucursal;
      }

      const response = await Axios.post(
        `${server}/solvencies/b`,
        { tramite },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      message.success('Solvencia registrada de manera exitosa');
      setSolB({
        contribuyente: null,
        sucursales: [],
      });
      searchForm.resetFields();
    } catch (e) {
      message.error(e.response?.data?.message ? e.response.data.message : 'Error al enviar los datos de la solvencia');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Solvencia B'
      bodyStyle={{
        height: 'calc(100% - 88px',
        overflowY: 'scroll',
        overflowX: 'hidden',
        padding: '10px 40px',
      }}
      headStyle={{
        height: 64,
        backgroundColor: thm.primaryColor,
        padding: width < 992 ? '0 10px' : '0 20px',
        color: 'white',
      }}
    >
      {/* {!(selectedConOrSuc.selectedCon || selectedConOrSuc.selectedSuc) ? */}
      <>
        <Form layout='vertical' form={searchForm}>
          <Row gutter={24}>
            <Col xs={24} xl={7}>
              <Form.Item label='Documento de Identidad' name='doc'>
                <Input placeholder='Documento de Identidad' addonBefore={tipoAddon} />
              </Form.Item>
            </Col>

            <Col xl={6} xs={12}>
              <Button
                onClick={() => search()}
                style={{ marginTop: width < 1200 ? 0 : 38, marginBottom: width < 1200 ? 20 : 0 }}
                loading={searching}
                icon={<SearchOutlined />}
                type='primary'
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>

        {solB.contribuyente && (
          <>
            <Typography.Title style={{ marginLeft: '16px' }} level={4}>
              Datos del Contribuyente
            </Typography.Title>
            <Descriptions title='' bordered column={{ xl: 24 }}>
              <Descriptions.Item label='Documento de Identidad' span={8}>
                {solB.contribuyente?.tipo_documento
                  ? `${solB.contribuyente?.tipo_documento}-${solB.contribuyente?.documento}`
                  : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label='Razón Social' span={8}>
                {solB.contribuyente?.razon_social || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label='Denominación Comercial' span={8}>
                {solB.contribuyente?.denominacion_comercial || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label='Siglas' span={8}>
                {solB.contribuyente?.siglas || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label='Tipo de Contribuyente' span={8}>
                {solB.contribuyente?.tipo_contribuyente || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item style={{ margin: 'auto' }}>
                <Button type='primary' onClick={() => sendData()} icon={<EyeOutlined />}>
                  Generar Solvencia
                </Button>
              </Descriptions.Item>
            </Descriptions>
          </>
        )}

        {solB.sucursales.length !== 0 && (
          <>
            <Divider></Divider>
            <Row gutter={24}>
              <Typography.Title style={{ marginLeft: '16px' }} level={4}>
                Referencias Municipales (R.I.M.)
              </Typography.Title>
              <Col span={24}>
                <Table columns={columns} dataSource={solB?.sucursales} bordered loading={searching} />
              </Col>
            </Row>
          </>
        )}
      </>
      {/*:
         <>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
            <Typography.Text strong>Razón Social: {selectedConOrSuc.selectedCon?.razon_social}</Typography.Text>
            <Typography.Text strong>
              Documento: {selectedConOrSuc.selectedCon?.tipo_documento}-{selectedConOrSuc.selectedCon?.documento}
              {selectedConOrSuc.selectedSuc?.referencia_municipal && (
                <React.Fragment>
                  <br />
                  R.I.M: {selectedConOrSuc.selectedSuc?.referencia_municipal}
                </React.Fragment>
              )}
            </Typography.Text>
          </div>
          <Button type='default' onClick={() => setSelectedConOrSuc({
            selectedCon: null,
            selectedSuc: null
          })}>
            Atrás
          </Button>
          <Divider orientation='left' style={{ marginLeft: -10 }}>
            <Typography.Title level={4}>Datos de Pago</Typography.Title>
          </Divider>
          <TooltipCoin priceBs={getMontoAPagar(tipoDocumento)} dolarBs={dolar} pesoBs={pesoBS} euroBs={euro}>
            <Typography.Text strong style={{ fontSize: "1.2rem"}}>
              El monto de la Solvencia B es de Bs {formatCurrency(getMontoAPagar(tipoDocumento))}
            </Typography.Text>
          </TooltipCoin>
          <Form layout='vertical' style={{ marginTop: 20, marginBottom: 16 }} form={payForm} initialValues={{ metodoPago: 'TRANSFERENCIA' }} onValuesChange={(c, v) => { searchForm.setFieldsValue(v); setData(v) }}>
            <MultipleTransactions selectedReqType='TRAMITE' selectedInstitution={selectedInstitution} form={payForm} data={data} onlyOne={true}/>
          </Form>
          <Row>
              <Divider orientation='left' style={{ marginLeft: 0 }}>
                <Typography.Title level={4}>Total Ingresado</Typography.Title>
              </Divider>
              <Col xl={24} xs={24}>
                <Descriptions bordered>
                  <Descriptions.Item label='Total Ingresado' style={{ fontSize: "1.2rem"}}>
                    <TooltipCoin priceBs={calcTotal()} dolarBs={dolar} pesoBs={pesoBS} euroBs={euro}>
                      Bs.{' '}
                      {console.log('data: ', data) + '' === 'undefined' &&
                        formatCurrency(calcTotal())}
                    </TooltipCoin>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Divider orientation='left' style={{ marginLeft: 0 }}>
                <Typography.Title level={4}>Monto Restante</Typography.Title>
              </Divider>
              <Col xl={24} xs={24}>
                <Descriptions bordered>
                  <Descriptions.Item
                    label={
                      calcTotal()
                        >
                          +getMontoAPagar(tipoDocumento).toFixed(2)
                        ? 'Total a Favor'
                        : 'Total Restante'
                    }
                    style={{
                      color:
                          calcTotal()
                          >=
                         +getMontoAPagar(tipoDocumento).toFixed(2)
                          ? '#86E92B'
                          : 'red',
                          fontSize: "1.2rem"
                    }}
                  >
                    <TooltipCoin priceBs={Math.abs(calcTotal() - +getMontoAPagar(tipoDocumento).toFixed(2))} dolarBs={dolar} pesoBs={pesoBS} euroBs={euro}>
                      Bs.{' '}
                      {formatCurrency(
                        (
                          calcTotal()
                          -
                          +getMontoAPagar(tipoDocumento).toFixed(2)) *
                        (
                          calcTotal()
                          >
                          +getMontoAPagar(tipoDocumento).toFixed(2)
                          ? 1
                          : -1)
                      )}
                    </TooltipCoin>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
              
              <Col>
                {data?.pagos?.length > 0 && (
                  <Button
                    type='primary'
                    loading={loading}
                    style={{ marginTop: 15 }}
                    onClick={() => pay()}
                    icon={<CreditCardFilled />}
                  >
                    Pagar
                  </Button>
                )}
              </Col>
            </Row>
        </>
      } */}
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ auth: state.auth, thm: state.thm });

export default connect(mapStateToProps)(SolvB);

interface SolvBProps {
  auth: States.Auth;
  thm: States.ThemeColors;
}

interface SolvBResponse {
  contribuyente: IContributer | null;
  sucursales: ISucursal[];
}

export interface ISucursal {
  id_registro_municipal: string;
  id_contribuyente: number;
  referencia_municipal: string;
  fecha_aprobacion: string;
  telefono_celular: string;
  telefono_habitacion: string | null;
  email: string;
  denominacion_comercial: string;
  nombre_representante: string;
  actualizado: boolean;
  capital_suscrito: number;
  tipo_sociedad: string;
  estado_licencia: 'PERMANENTE' | 'TEMPORAL';
  direccion: string;
  id_parroquia: number;
  es_monotributo: boolean;
}

export interface IContributer {
  id_contribuyente: number;
  tipo_documento: string;
  documento: number;
  razon_social: string;
  denominacion_comercial: string;
  siglas: string;
  id_parroquia: number;
  sector: string;
  direccion: string;
  punto_referencia: string;
  verificado: boolean;
  tipo_contribuyente: 'JURIDICO' | 'NATURAL' | 'GUBERNAMENTAL' | 'EXTRANJERO';
  es_agente_retencion: boolean;
  fecha_ultima_actualizacion: string;
  sucursal?: ISucursal;
}

export interface IInmueble {
  id_inmueble: number;
  cod_catastral: string;
  direccion: string;
  id_parroquia: number;
  metros_construccion: string;
  metros_terreno: string;
  fecha_creacion: string;
  fecha_actualizacion: string;
  fecha_ultimo_avaluo: string | null;
  tipo_inmueble: 'RESIDENCIAL' | 'COMERCIAL' | 'INDUSTRIAL';
  id_registro_municipal: number | null;
  posee_gas: boolean;
  relacion_contribuyente: string | number | null;
  id_liquidacion_fecha_inicio: number | null;
  dir_doc: string | null;
  cod_tramite: string | null;
  avaluo: number;
}
