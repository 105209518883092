import React, { useEffect, useState, useRef, useContext } from 'react';
import { EditTwoTone, SearchOutlined, UserAddOutlined } from '@ant-design/icons';
import { Card, Input, Button, Table, Form, Tag, Select, Tooltip, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { Inspection, State, States, User } from 'sigt';
import { fetchInspections, createInspection, updateInspection } from '../../redux/actions/charges';
import { fetchOfficials } from '../../redux/actions/official';
import handlingMessage from '../../utils/handlingMessage';
import { useWindowDimensions } from '../../utils/hooks';
import Highlighter from 'react-highlight-words';
import { ColumnsType } from 'antd/lib/table/Table';
import moment from 'moment';
import '@ant-design/compatible/assets/index.css';

const EditableContext = React.createContext<any>(null);

const EditableRow: React.FC<any> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<any> = ({ title, editable, children, dataIndex, record, type, handleSave, options, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>();
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const cases = () => {
    switch (dataIndex) {
      case 'idUsuario':
        return (
          <Select ref={inputRef} open onChange={save} onBlur={save} >
            {options?.map((o:User) => <Select.Option value={o.id} key={o.id}><Tooltip title={o.nombreCompleto}>{o.nombreCompleto}</Tooltip></Select.Option>)}
          </Select>
        );
      case 'estado':
        return (
          <Select ref={inputRef} open onChange={save} onBlur={save} >
            <Select.Option value='PENDIENTE' style={{backgroundColor:'lightgrey', color:'#fff', textAlign:'center'}}>PENDIENTE</Select.Option>
            <Select.Option value='FISCALIZADO' style={{backgroundColor:'#00c875', color:'#fff', textAlign:'center'}}>FISCALIZADO</Select.Option>
            <Select.Option value='NOTIFICADO' style={{backgroundColor:'#108ee9', color:'#fff', textAlign:'center'}}>NOTIFICADO</Select.Option>
            <Select.Option value='CERRADO' style={{backgroundColor:'#faad14', color:'#fff', textAlign:'center'}}>CERRADO</Select.Option>
            <Select.Option value='NO_UBICADO' style={{backgroundColor:'#e2445c', color:'#fff', textAlign:'center'}}>NO UBICADO</Select.Option>
          </Select>
        );
      case 'medida':
        return (
          <Select ref={inputRef} open onChange={save} onBlur={save} >
            <Select.Option value='NOTIFICACION' style={{backgroundColor:'lightgrey', color:'#fff', textAlign:'center'}}>NOTIFICACION</Select.Option>
            <Select.Option value='MULTA' style={{backgroundColor:'#faad14', color:'#fff', textAlign:'center'}}>MULTA</Select.Option>
            <Select.Option value='CIERRE' style={{backgroundColor:'#e2445c', color:'#fff', textAlign:'center'}}>CIERRE</Select.Option>
          </Select>
        );
      default:
        return (
          <Select ref={inputRef} open onChange={save} onBlur={save} >
            <Select.Option value='true' style={{backgroundColor:'#00c875', color:'#fff', textAlign:'center'}}>SI</Select.Option>
            <Select.Option value='false' style={{backgroundColor:'#e2445c', color:'#fff', textAlign:'center'}}>NO</Select.Option>
          </Select>
        )
    }
  };

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      record[dataIndex] !== values[dataIndex] && handleSave({ ...record, ...values });
    } catch (errInfo) {
      // console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: dataIndex === 'idUsuario' ? false : true,
            message: `${title} is required.`,
          },
        ]}
      >
        {cases()}
      </Form.Item>
    ) : (
      <div className='editable-cell-value-wrap' style={{ padding: '0 10px' }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const TableInspection: React.FC<PropsTable> = ({ thm, chrg, auth, ofc, fetchInspections, createInspection, updateInspection, fetchOfficials }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColum] = useState('');
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [tipoDocumento, setTipoDocumento] = useState('J');
  const searchInput = useRef<any>(null);
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const [insertForm] = Form.useForm();
  const inspectors = ofc.officials.filter((o) => o.cargo === 39 || o.cargo === 48);

  const getFiscalizations = async () => await fetchInspections(auth.token || '');
  const getOfficials = async () => await fetchOfficials(auth.token || '', auth.user?.tipoUsuario);

  useEffect(() => {
    if(auth){
      getFiscalizations();
      getOfficials();
    }
    //eslint-disable-next-line
  }, [auth]);

  const getState = (estado) => {
    switch (estado) {
      case 'COBRANZA':
        return { name: 'COBRANZA', color: 'warning' };
      case 'FISCALIZACION':
        return { name: 'FISCALIZACION', color: 'purple' };
      case 'FISCALIZADO':
        return { name: 'FISCALIZADO', color: 'green' };
      case 'NOTIFICACION': 
        return { name: 'NOTIFICACION', color: 'default' };
      case 'NOTIFICADO': 
        return { name: 'NOTIFICADO', color: 'geekblue' };
      case 'CIERRE': 
        return { name: 'CIERRE', color: 'red' };
      case 'MULTA':
        return { name: 'MULTA', color: 'warning' };
      case 'SI':
        return { name: 'SI', color: 'green' };
      case 'NO':
        return { name: 'NO', color: 'red' };
      case 'NO_UBICADO':
        return { name: 'NO UBICADO', color: 'red' };
      case 'PENDIENTE':
        return { name: 'PENDIENTE', color: 'default' };
      case 'CERRADO':
        return { name: 'CERRADO', color: 'warning' };
      default:
        return { name: 'No asignado', color: 'default' };
    }
  };

  const filterState = [
    { value: 'PENDIENTE', text: <Tag style={{width:100, textAlign:'center'}} color='default'>PENDIENTE</Tag> },
    { value: 'FISCALIZADO', text: <Tag style={{width:100, textAlign:'center'}} color='green'>FISCALIZADO</Tag> },
    { value: 'NOTIFICADO', text: <Tag style={{width:100, textAlign:'center'}} color='geekblue'>NOTIFICADO</Tag> },
    { value: 'CERRADO', text: <Tag style={{width:100, textAlign:'center'}} color='warning'>CERRADO</Tag> },
    { value: 'NO_UBICADO', text: <Tag style={{width:100, textAlign:'center'}} color='red'>NO UBICADO</Tag> },
  ];

  const filterCase = [
    { value: 'NOTIFICACION', text: <Tag style={{width:100, textAlign:'center'}} color='default'>NOTIFICACION</Tag> },
    { value: 'MULTA', text: <Tag style={{width:100, textAlign:'center'}} color='warning'>MULTA</Tag> },
    { value: 'CIERRE', text: <Tag style={{width:100, textAlign:'center'}} color='red'>CIERRE</Tag> },
  ];

  const filterType = [
    { value: 'COBRANZA', text: <Tag style={{width:100, textAlign:'center'}} color='warning'>COBRANZA</Tag> },
    { value: 'FISCALIZACION', text: <Tag style={{width:100, textAlign:'center'}} color='purple'>FISCALIZACION</Tag> },
  ];

  const filterBoolean = [
    { value: true, text: <Tag style={{width:80, textAlign:'center'}} color='green'>SI</Tag> },
    { value: false, text: <Tag style={{width:80, textAlign:'center'}} color='red'>NO</Tag> }
  ];

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) => record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text?.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const tipoAddon = (
    <Select defaultValue='J' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  const columns: ColumnsType<any> | any = [
    {
      title: 'Razon Social',
      dataIndex: 'razonSocial',
      align: 'center',
      width: 340,
      fixed: 'left',
      ...getColumnSearchProps('razonSocial'),
    },
    {
      title: 'RIF',
      dataIndex: 'rif',
      width: 110,
      ...getColumnSearchProps('rif'),
    },
    {
      title: 'RIM',
      width: 110,
      dataIndex: 'rim',
      ...getColumnSearchProps('rim'),
    },
    {
      title: 'Fiscal',
      dataIndex: 'idUsuario',
      editable: true,
      width: 150,
      options: inspectors,
      render: (id) => <span style={{ cursor: 'pointer' }} >{inspectors.find((ins) => ins.id === id)?.nombreCompleto || 'N/A'} <EditTwoTone style={{ marginLeft: 8 }}/></span>

    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      width: 140,
      filters: filterType,
      onFilter: (value, record) => record.tipo === value,
      render: (tipo) => {
        const { color, name } = getState(tipo);
        return <span style={{ width: '100%', textAlign: 'center' }}>
          <Tag style={{ width: '100%', cursor: 'pointer' }} color={color}>
            {name}
          </Tag>
        </span>
      },
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      width: 120,
      render: (fecha) => moment(fecha).format('DD/MM/YYYY')
    },
    {
      title: 'Medida',
      dataIndex: 'medida',
      width: 140,
      editable: true,
      filters: filterCase,
      onFilter: (value, record) => record.medida === value,
      render: (medida) => {
        const { color, name } = getState(medida);
        return <span style={{ width: '100%', textAlign: 'center' }}>
          <Tag style={{ width: '100%', cursor: 'pointer' }} color={color}>
            {name}
          </Tag>
        </span>
      },
    },
    {
      title: 'Telefono',
      dataIndex: 'telefono',
      width: 120,
      ...getColumnSearchProps('telefono'),
    },
    {
      title: 'Dirección',
      dataIndex: 'direccion',
      width: 380,
      render: (dir) => <Tooltip title={dir} placement='top'>{dir}</Tooltip>
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      width: 150,
      editable: true,
      filters: filterState,
      onFilter: (value, record) => record.estado === value,
      render: (estado) => {
        const { color, name } = getState(estado);
        return <span style={{ width: '100%', textAlign: 'center' }}>
          <Tag style={{ width: '100%', cursor: 'pointer' }} color={color}>
            {name}
          </Tag>
        </span>
      }
    },
    {
      title: 'Auditoria',
      dataIndex: 'auditoria',
      width: 120,
      editable: true,
      filters: filterBoolean,
      onFilter: (value, record) => record.auditoria === value,
      render: (auditoria: boolean) => {
        const { color, name } = getState(auditoria ? 'SI' : 'NO');
        return <span style={{ width: '100%', textAlign: 'center' }}>
          <Tag style={{ width: '100%', cursor: 'pointer' }} color={color}>
            {name}
          </Tag>
        </span>
      }
    },
    {
      title: 'Compareció',
      dataIndex: 'comparecio',
      width: 120,
      editable: true,
      filters: filterBoolean,
      onFilter: (value, record) => record.comparecio === value,
      render: (comparecio: boolean) => {
        const { color, name } = getState(comparecio ? 'SI' : 'NO');
        return <span style={{ width: '100%', textAlign: 'center' }}>
          <Tag style={{ width: '100%', cursor: 'pointer' }} color={color}>
            {name}
          </Tag>
        </span>
      }
    },
  ];

  const handleSave = (inspection) => {
    setLoading(true);
    handlingMessage({
      action: () => updateInspection(auth.token || '',inspection),
      key: 'updateInspection',
      loadingMessage: 'Actualizando ...',
      cb: () => {
        setLoading(false);
      }
    }); 
  };

  const onCreateInspection = async () => {
    const values = await insertForm.validateFields();
    setCreating(true);
    handlingMessage({
      action: () => createInspection(auth.token || '',{ typeDoc: tipoDocumento, doc: values.documento, rim: values.rim }),
      key: 'createInspection',
      loadingMessage: 'Agregando Fiscalizacion...',
      cb: () => {
        setCreating(false);
      }
    }); 
  }

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
        options: col.options
      }),
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <Card style={{ height: '100%' }} bodyStyle={{ overflowY: 'auto' }} title='Fiscalización' headStyle={{ backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: '#fff' }}>
      <Form scrollToFirstError layout='vertical' form={insertForm} onFinish={onCreateInspection}>
        <Row gutter={[24,8]}>
          <Col xs={24} lg={7}>
            <Form.Item name='documento' label='Documento de Identidad' rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}>
              <Input placeholder='Documento de Identidad' addonBefore={tipoAddon} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item name='rim' label='Referencia Municipal' rules={[{ required: true, message: 'Debe ingresar una referencia municipal' }]}>
              <Input placeholder='RIM' />
            </Form.Item>
          </Col>
          <Col xs={24} lg={7}>
            <Button loading={creating} style={{ marginTop: width < 992 ? 0 : 40 }} htmlType='submit' type='primary' icon={<UserAddOutlined />}>
              Agregar Fiscalización
            </Button>
          </Col>
        </Row>
      </Form>
      <Form form={form} component={false}>
        <Table
          components={components}
          scroll={{ x: 700, y: width < 992 ? '25vh' : '45vh' }}
          size='small'
          loading={loading}
          pagination={{ pageSize: 8 }}
          columns={mergedColumns}
          dataSource={chrg.inspections.map(e=> ({...e, telefono: e.telefono ? e.telefono : 'N/A'}))}
          rowKey={({idFiscalizacion}) => idFiscalizacion}
          bordered
        />
      </Form>
    </Card>
  );
};

const mapStateToProps = ({ auth, chrg, thm, ofc }: State) => ({ auth, chrg, thm, ofc });

export default connect(mapStateToProps, { fetchInspections, createInspection, updateInspection, fetchOfficials })(TableInspection);

interface PropsTable {
  fetchOfficials: (token, id) => Promise<any>;
  fetchInspections: (token: string) => Promise<Response>;
  createInspection: (token: string, taxPayer: { typeDoc: string, doc: number, rim: string } ) => Promise<Response>;
  updateInspection: (token: string, inspection: Inspection) => Promise<Response>;
  ofc: States.Officials;
  auth: States.Auth;
  thm: States.ThemeColors;
  chrg: States.Charges;
}
