import React, { useState } from 'react';
import { Card, Form, Divider, Typography, Row, Col, Select, Input, Button, message } from 'antd';
import { State, States } from 'sigt';
import { connect } from 'react-redux';
import { useWindowDimensions } from '../../../utils/hooks';
import { Utils } from '../../../utils/validators';
import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

const InternalLinking: React.FC<InternalLinkingProps> = ({ thm, auth }) => {
  const [tipoDocumento, setTipoDocumento] = useState('J');
  const [loading, setLoading] = useState(false);

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  const link = async () => {
    const values = await form.validateFields();
    setLoading(true);
    const body = {
      ...values,
      tipoDocumento
    };
    try {
      await axios.post(`${server}/settlements/linking`, { datosEnlace: body }, { headers: { Authorization: `Bearer ${auth?.token}` } });
      message.success('Enlace realizado de manera exitosa');
    } catch(e) {
      message.error(e.response?.data?.error || e.respose?.data?.message || 'Error en el servidor');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card style={{ height: '100%' }} title='Enlace de Contribuyente' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
    headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 1200 ? '0 10px' : '0 20px', color: 'white'}}>
      <Form form={form} layout='vertical' scrollToFirstError onFinish={link}>
        <Divider orientation='left' style={{ marginLeft: -10 }}>
          <Typography.Title level={4}>Datos del Contribuyente</Typography.Title>
        </Divider>
        <Row gutter={24}>
          <Col xl={7} xs={24}>
            <Form.Item label='Tipo de Contribuyente' name='tipoContribuyente' rules={[{ required: true, message: 'Debe indicar el tipo de contribuyente' }]}>
              <Select placeholder='Tipo de Contribuyente'>
                <Select.Option value='NATURAL'>Natural</Select.Option>
                <Select.Option value='JURIDICO'>Jurídico</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={7}>
            <Form.Item label='Documento de Identidad' name='documento' rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}>
              <Input placeholder='Documento de Identidad' addonBefore={tipoAddon} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={7}>
            <Form.Item label='R.I.M.' name='referenciaMunicipal'>
              <Input placeholder='R.I.M.' />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation='left' style={{ marginLeft: -10 }}>
          <Typography.Title level={4}>Usuario de S.U.T.</Typography.Title>
        </Divider>
        <Row gutter={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Col span={24} style={{ marginBottom: 10 }}>
            <Typography.Text strong>
              Para el enlace de un contribuyente con un usuario de SUT, dicho contribuyente debe poseer un usuario en el sistema.
            </Typography.Text>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Correo Electrónico' name='username' rules={[{ required: true, message: 'Debe ingresar el correo electrónico' }, { validator: Utils.validators.email }]}>
              <Input placeholder='Correo Electrónico' />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Button type='primary' loading={loading} onClick={form.submit}>Enlazar</Button>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps)(InternalLinking);

interface InternalLinkingProps {
  thm: States.ThemeColors
  auth: States.Auth
}