import { Categoria, States, VehicleType, subCategoria } from 'sigt';
import {
  VehiclesType,
  SET_BRANDS,
  SET_TYPES,
  SET_VEHICLES,
  SET_VEHICLE,
  DELETE_VEHICLE,
  UPDATE_VEHICLE,
  AUTH_USER_LOGOUT,
  AuthLogoutAction,
  SET_ID,
  SET_ISRIM,
  SET_CONTRIBUYENTE,
} from '../actions/actionsTypes';

const getSubcategories = (arr) => {
  const pivot: VehicleType[] = [...arr];
  let subCategories: subCategoria[] = [];
  pivot.forEach((el) => {
    el.subcategoria.forEach((cat) => {
      subCategories = [...subCategories, cat];
    });
  });
  return subCategories;
};

const initialState: States.Vehicles = {
  vehicles: [],
  brands: [],
  types: [],
  subCategories: [],
};

export default (state = initialState, action: VehiclesType | AuthLogoutAction): States.Vehicles => {
  switch (action.type) {
    case SET_ID:
      return { ...state, id: action.payload };
    case SET_ISRIM:
      return { ...state, isRim: action.payload };
    case SET_CONTRIBUYENTE:
      return { ...state, contribuyente: action.payload };
    case SET_VEHICLES:
      return { ...state, vehicles: action.payload };
    case SET_VEHICLE:
      return { ...state, vehicles: [...state.vehicles, action.payload] };
    case DELETE_VEHICLE: {
      const _vehicles = [...state.vehicles].filter((v) => v.id !== action.payload);
      return { ...state, vehicles: _vehicles };
    }
    case UPDATE_VEHICLE: {
      const _vehicles = [...state.vehicles].map((v) => (v.id === action.payload.id ? action.payload : v));
      return { ...state, vehicles: _vehicles };
    }
    case SET_BRANDS:
      return { ...state, brands: action.payload };
    case SET_TYPES:
      return { ...state, types: action.payload, subCategories: getSubcategories(action.payload) };
    case AUTH_USER_LOGOUT:
      return { ...state, vehicles: [] };
    default:
      return state;
  }
};
