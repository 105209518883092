import React, { useState, useEffect, useRef, RefObject, createRef } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Form, Input, Button, Descriptions, Divider, Typography, message, Select, Checkbox } from 'antd';
import { State, States } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import moment from 'moment';
import handlingMessage from '../../utils/handlingMessage';
import { generateCodCat, getDataIU, getProperty } from '../../services/estates';
import { Utils } from '../../utils/validators';
import axios from 'axios';
import FormItem from '@ant-design/compatible/lib/form/FormItem';
import { DeleteOutlined } from '@ant-design/icons';
import { forEach } from 'lodash';
const server = process.env.REACT_APP_SERVER_URL;

const CreateCodCat: React.FC<CreateCodCatProps> = ({ thm, auth, prsh, coin }) => {
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [property, setPropertyA] = useState<any>(null);
  const [linderoActual, setLinderoActual] = useState<any>({
    norte:'',
    sur:'',
    este:'',
    oeste:'',
  });
  const [codcat, setCodCat] = useState<any>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [selectedParr, setSelectedParr] = useState<number>(1);
  const [dataIU, setDataIU] = useState<any>();
  const [perimetro, setPerimetro] = useState(false);
  const [files, setFiles] = useState<{ [P: string]: File } | null>(null);

  const recaudosRef = useRef<RefObject<HTMLImageElement>[]>([]);
  const ref = React.createRef<HTMLInputElement>();

  const handleFiles = (e, name: string, i: number) => {
    const file = e.target.files[0];
    if (file) {
      const fr = new FileReader();
      const blob = (file as File).slice(0, (file as File).size, file.type);
      setFiles({
        ...files,
        [name]: new File([blob], `${name}.${file.name.split('.')[file.name.split('.').length - 1]}`, { type: file.type }),
      });
      fr.readAsDataURL(file);
      fr.onload = (e) => {
        if (file.type.includes('image')) recaudosRef.current[i]?.current?.setAttribute('src', e.target?.result?.toString() || '');
        else if (file.type.includes('excel')) {
          recaudosRef.current[i]?.current?.setAttribute('src', require('../../assets/images/excel.png'));
          recaudosRef.current[i]?.current?.style.setProperty('width', '80px');
          recaudosRef.current[i]?.current?.style.setProperty('height', '80px');
        } else if (file.type.includes('pdf')) {
          recaudosRef.current[i]?.current?.setAttribute('src', require('../../assets/images/pdf.png'));
          recaudosRef.current[i]?.current?.style.setProperty('width', '80px');
          recaudosRef.current[i]?.current?.style.setProperty('height', '80px');
        } else {
          recaudosRef.current[i]?.current?.setAttribute('src', require('../../assets/images/file.png'));
          recaudosRef.current[i]?.current?.style.setProperty('width', '80px');
          recaudosRef.current[i]?.current?.style.setProperty('height', '80px');
        }
      };
    }
  };

  const handleLindero = (name: string, value: string) => {
    setLinderoActual({...linderoActual, [name]:value });
    form2.setFields([{name:['linderos','actual',`${name}`], value:value}]);
  };

  const deleteFile = (name: string, i: number) => {
    const _files = Object.assign({}, files);
    delete _files[name];
    setFiles(_files);
    recaudosRef.current[i] = createRef();
  };


  useEffect(() => {
    getDataIU(auth.token)
      .then((res) => setDataIU(res))
      .catch((error) => message.error('Error al momento de obtener los datos'));
  }, []);

  useEffect(() => {
    if(property){
      form2.setFieldsValue({
        'avaluoTerreno': property.avaluos[0].avaluoTerreno,
        'avaluoConstruccion': property.avaluos[0]?.avaluoConstruccion,
      });
      form2.setFields([{name:['datosFisicos', 'descripcionUso'], value:['Acueducto','Acera','Telefono','Electricidad','Transporte',
      'Cable Tv','Cloacas','Septico','Alum. Pub.','Pavimento','Elec. Indust.','Otro']}]);
      setCodCat({
        efed: String(property.codigoCatastral).substring(0,2),
        mun: String(property.codigoCatastral).substring(2,4),
        prr: String(property.codigoCatastral).substring(4,6),
        amb: String(property.codigoCatastral).substring(6,9),
        sec: String(property.codigoCatastral).substring(9,12),
        ssec: String(property.codigoCatastral).substring(12,15),
        man: String(property.codigoCatastral).substring(15,18),
        par: String(property.codigoCatastral).substring(18,21),
        sbp: String(property.codigoCatastral).substring(21,24),
        niv: String(property.codigoCatastral).substring(24,27),
        und: String(property.codigoCatastral).substring(27),
      });
    }
  }, [property]);

  useEffect(() => {
    if(codcat){
      Object.entries(codcat).forEach(([key, value]) => {
        form2.setFields([{name:['codCat',`${key}`], value:`${value}`}])
      });
      let parcode = Number(codcat.prr === '02' ? '03' : ( codcat.prr === '03' ? '02' : codcat.prr));
      form2.setFields([{name:['datos','parroquia'], value:parcode}]);
      setSelectedParr(parcode);
      
    };
  },[codcat])

  const searchProperty = async () => {
    setDisabled(true);
    const values = await form.validateFields();
    handlingMessage({
      action: () => getProperty(values.codCatSearch, auth.token),
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: (data) => {
        if (data.status === 200) {
          const inmueble = { ...data.inmueble };
          if (!inmueble.clasificacion || !inmueble.metrosConstruccion || !inmueble.metrosTerreno)
            throw new Error('El inmueble debe ser actualizado');
          inmueble.avaluos = inmueble.avaluos.map((a) => ({ ...a, avaluo: +Number(a.avaluo * coin.petro).toFixed(2) }));
          setPropertyA(inmueble);
        }
        setDisabled(false);
      },
    });
  };

  const handleSubmit = async () => {
    try {
      const values = await form2.getFieldsValue();
      values.perimetro = perimetro;
      values.inmueble = property.id;
      console.log(values);

      const fd = new FormData();
      let fileCount = 0;
      if (files) Object.keys(files).forEach((k) => {
        fd.append('recaudos', files[k]);
        fileCount++;
      });
      if(fileCount > 0){
        const resp = await axios.post(
          `${server}/uploads/special/support/${property.id}`,
          fd,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
      }

      handlingMessage({
        action: () => generateCodCat(values, auth.token),
        key: 'submit',
        loadingMessage: 'Realizando operación...',
        cb: (data) => {
          if (data.status === 200) {
            const win = window.open(data.data.url, '_blank');
            win?.focus();
          }
        },
      });
    } catch (e) {
      throw e;
    }
  };

  const urlCertificate = () => {
    let codigoCatastral = property?.codigoCatastral ?? 'N/A';
    let metrosTerreno = property?.metrosTerreno ?? 'N/A';
    let metrosConstruccion = property?.metrosConstruccion ?? 'N/A';
    let clasificacion = property?.clasificacion ?? 'N/A';
    let avaluoTerreno = property?.avaluos[0]?.avaluoTerreno ? Number(property?.avaluos[0]?.avaluoTerreno ?? 0).toFixed(8) : 'N/A';
    let avaluoConstruccion = property?.avaluos[0].avaluoConstruccion
      ? Number(property?.avaluos[0].avaluoConstruccion ?? 0).toFixed(8)
      : 'N/A';
    let direccion = property?.direccion ?? 'N/A';
    let parroquia =
      dataIU?.parishes && property?.codigoCatastral
        ? dataIU?.parishes.find((p) => p.id_parroquia === +property?.codigoCatastral.slice(4, 6)).nombre
        : 'N/A';
    let tipoInmueble = property?.tipoInmueble ?? 'N/A';
    let relativo = property?.relativo ?? 'N/A';

    return `${server}/reportSolvRRI?codigoCatastral=${codigoCatastral}&metrosTerreno=${metrosTerreno}&metrosConstruccion=${metrosConstruccion}&clasificacion=${clasificacion}&avaluoTerreno=${avaluoTerreno}&avaluoconstruccion=${avaluoConstruccion}&direccion=${direccion}&parroquia=${parroquia}&tipoInmueble=${tipoInmueble}&relativo=${relativo}`;
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Consultar Inmueble'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Form form={form} onFinish={searchProperty} layout='vertical'>
        <Row style={{ paddingLeft: 16 }} gutter={16}>
          <Col span={12}>
            <Form.Item
              label='Código de identificación del Inmueble'
              name='codCatSearch'
              normalize={Utils.normalize.toUpper}
              rules={[{ required: true, message: 'Debe ingresar el Código de identificación del Inmueble' }]}
            >
              <Input placeholder='Código de identificación del Inmueble' />
            </Form.Item>
          </Col>
          <Col span={12} style={{ alignSelf: 'center', paddingTop: '15px' }}>
            <Button type='primary' disabled={disabled} htmlType='submit'>
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>
      {property && (
        <>
          <Form form={form2} onFinish={handleSubmit} layout='vertical'>
            <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
              <Typography.Title level={4}>Datos de Control</Typography.Title>
            </Divider>
            <Row gutter={24}>
              <Col xs={10}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  name={['datos', 'nroControl']}
                  label='NRO CONTROL'
                >
                  <Input type='number' />
                </Form.Item>
              </Col>
              <Col xs={10}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  name={['datos', 'nroRecibo']}
                  label='NRO RECIBO'
                >
                  <Input type='number' />
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
              <Typography.Title level={4}>Codigo Catastral Anterior</Typography.Title>
            </Divider>
            <Row gutter={24} style={{ paddingLeft: '16px' }}>
              <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} name={['oldCodCat', 'prr']} label='prr'>
                <Col span={24} xs={16} xl={16}>
                  <Input maxLength={3} />
                </Col>
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} name={['oldCodCat', 'sec']} label='sec'>
                <Col span={24} xs={16} xl={16}>
                  <Input maxLength={3} />
                </Col>
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} name={['oldCodCat', 'man']} label='man'>
                <Col span={24} xs={16} xl={16}>
                  <Input maxLength={3} />
                </Col>
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} name={['oldCodCat', 'par']} label='par'>
                <Col span={24} xs={16} xl={16}>
                  <Input maxLength={3} />
                </Col>
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} name={['oldCodCat', 'sbp']} label='sbp'>
                <Col span={24} xs={16} xl={16}>
                  <Input maxLength={3} />
                </Col>
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} name={['oldCodCat', 'niv']} label='niv'>
                <Col span={24} xs={16} xl={16}>
                  <Input maxLength={3} />
                </Col>
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} name={['oldCodCat', 'und']} label='und'>
                <Col span={24} xs={16} xl={16}>
                  <Input maxLength={3} />
                </Col>
              </Form.Item>
            </Row>
            <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
              <Typography.Title level={4}>Codigo Catastral</Typography.Title>
            </Divider>
            <Row gutter={24} style={{ paddingLeft: '16px' }}>
              <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} name={['codCat', 'efed']} label='EFed'>
                <Col span={24} xs={16} xl={16}>
                  <Input value={codcat?.efed} onChange={(e)=>{setCodCat({...codcat,efed:e.target.value})}} maxLength={3} />
                </Col>
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} name={['codCat', 'mun']} label='Mun'>
                <Col span={24} xs={16} xl={16}>
                  <Input value={codcat?.mun} onChange={(e)=>{setCodCat({...codcat,mun:e.target.value})}} maxLength={3} />
                </Col>
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} name={['codCat', 'prr']} label='Prr'>
                <Col span={24} xs={16} xl={16}>
                  <Input value={codcat?.prr} onChange={(e)=>{setCodCat({...codcat, prr:e.target.value})}} maxLength={3} />
                </Col>
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} name={['codCat', 'amb']} label='Amb'>
                <Col span={24} xs={16} xl={16}>
                  <Input value={codcat?.amb} onChange={(e)=>{setCodCat({...codcat,amb:e.target.value})}} maxLength={3} />
                </Col>
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} name={['codCat', 'sec']} label='Sec'>
                <Col span={24} xs={16} xl={16}>
                  <Input value={codcat?.sec} onChange={(e)=>{setCodCat({...codcat,sec:e.target.value})}} maxLength={3} />
                </Col>
              </Form.Item>
              <Form.Item  rules={[{ required: true, message: 'Campo Requerido' }]} name={['codCat', 'ssec']} label='Ssec'>
                <Col span={24} xs={16} xl={16}>
                  <Input value={codcat?.ssec} onChange={(e)=>{setCodCat({...codcat,ssec:e.target.value});}} maxLength={3} />
                </Col>
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} name={['codCat', 'man']} label='Man'>
                <Col span={24} xs={16} xl={16}>
                  <Input value={codcat?.man} onChange={(e)=>{setCodCat({...codcat,man:e.target.value})}} maxLength={3} />
                </Col>
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} name={['codCat', 'par']} label='Par'>
                <Col span={24} xs={16} xl={16}>
                  <Input value={codcat?.par} onChange={(e)=>{setCodCat({...codcat,par:e.target.value})}} maxLength={3} />
                </Col>
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} name={['codCat', 'sbp']} label='Sbp'>
                <Col span={24} xs={16} xl={16}>
                  <Input value={codcat?.sbp} onChange={(e)=>{setCodCat({...codcat,sbp:e.target.value})}} maxLength={3} />
                </Col>
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} name={['codCat', 'niv']} label='Niv'>
                <Col span={24} xs={16} xl={16}>
                  <Input value={codcat?.niv} onChange={(e)=>{setCodCat({...codcat,niv:e.target.value})}} maxLength={3} />
                </Col>
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} name={['codCat', 'und']} label='Und'>
                <Col span={24} xs={16} xl={16}>
                  <Input value={codcat?.und} onChange={(e)=>{setCodCat({...codcat,und:e.target.value})}} maxLength={3} />
                </Col>
              </Form.Item>
            </Row>
            <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
              <Typography.Title level={4}>Datos del Representante Legal</Typography.Title>
            </Divider>
            <Row gutter={24}>
              <Col xs={24} xl={16}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  name={['datos', 'representantes']}
                  label='Representante(s)'
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>
              <Col xs={24} xl={16}>
                <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} name={['datos', 'cedulas']} label='Cedula(s)'>
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} xl={16}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  name={['datos', 'direccion']}
                  label='Dirección'
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
              <Typography.Title level={4}>Datos del Documento de Registro</Typography.Title>
            </Divider>
            <Row gutter={24}>
              <Col xs={6} xl={16}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  name={['datos', 'parroquia']}
                  label='Parroquia'
                >
                  <Select value={selectedParr} onChange={(value) => setSelectedParr(value)}>
                    {prsh.parish.map((elem) => (
                      <Select.Option key={elem.id} value={elem.id}>
                        {elem.nombre}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={6} xl={16}>
                <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} label='Número' name={['datos', 'numero']}>
                  <Input type='number' />
                </Form.Item>
              </Col>
              <Col xs={6} xl={16}>
                <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} label='Tomo' name={['datos', 'tomo']}>
                  <Input/>
                </Form.Item>
              </Col>
              <Col xs={6} xl={16}>
                <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} label='Folio/Matrícula' name={['datos', 'folio']}>
                  <Input/>
                </Form.Item>
              </Col>
              <Col xs={6} xl={16}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  label='Protocolo'
                  name={['datos', 'protocolo']}
                >
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
              <Typography.Title level={4}>Linderos Originales</Typography.Title>
            </Divider>
            <Row gutter={24}>
              <Col xs={12} xl={16}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  label='Norte'
                  name={['linderos', 'original', 'norte']}
                >
                  <Input onChange={(e) => handleLindero('norte',e.target.value)} />
                </Form.Item>
              </Col>
              <Col xs={12} xl={16}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  label='Sur'
                  name={['linderos', 'original', 'sur']}
                >
                  <Input onChange={(e) => handleLindero('sur',e.target.value)} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12} xl={16}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  label='Este'
                  name={['linderos', 'original', 'este']}
                >
                  <Input onChange={(e) => handleLindero('este',e.target.value)} />
                </Form.Item>
              </Col>
              <Col xs={12} xl={16}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  label='Oeste'
                  name={['linderos', 'original', 'oeste']}
                >
                  <Input onChange={(e) => handleLindero('oeste',e.target.value)} />
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
              <Typography.Title level={4}>Linderos Actuales</Typography.Title>
            </Divider>
            <Row gutter={24}>
              <Col xs={12} xl={16}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  label='Norte'
                  name={['linderos', 'actual', 'norte']}
                >
                  <Input value={linderoActual.norte} onChange={(e) => handleLindero('norte',e.target.value)} />
                </Form.Item>
              </Col>
              <Col xs={12} xl={16}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  label='Sur'
                  name={['linderos', 'actual', 'sur']}
                >
                  <Input value={linderoActual.sur} onChange={(e) => handleLindero('sur',e.target.value)} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12} xl={16}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  label='Este'
                  name={['linderos', 'actual', 'este']}
                >
                  <Input value={linderoActual.este} onChange={(e) => handleLindero('este',e.target.value)} />
                </Form.Item>
              </Col>
              <Col xs={12} xl={16}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  label='Oeste'
                  name={['linderos', 'actual', 'oeste']}
                >
                  <Input value={linderoActual.oeste} onChange={(e) => handleLindero('oeste',e.target.value)} />
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
              <Typography.Title level={4}>Datos Físicos</Typography.Title>
            </Divider>
            <Row gutter={24}>
              <Col xs={12} xl={16}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  label='Tenencia de Terreno'
                  name={['datosFisicos', 'terreno']}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12} xl={16}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  label='Tenencia de Construcción'
                  name={['datosFisicos', 'construccion']}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12} xl={16}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  label='Régimen de Propiedad'
                  name={['datosFisicos', 'propiedad']}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12} xl={16}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  label='Régimen Familiar'
                  name={['datosFisicos', 'familiar']}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  label='Descripción de Uso'
                  name={['datosFisicos', 'descripcionUso']}
                >
                  <Select mode='multiple'>
                    <Select.Option value='Comercio al Detal'>Comercio al Detal</Select.Option>
                    <Select.Option value='Agropecuaria'>Agropecuaria</Select.Option>
                    <Select.Option value='Comercio al Mayor'>Comercio al Mayor</Select.Option>
                    <Select.Option value='Otro'>Otro</Select.Option>
                    <Select.Option value='Industrial'>Industrial</Select.Option>
                    <Select.Option value='Servicio'>Servicio</Select.Option>
                    <Select.Option value='Residencial'>Residencial</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  label='Servicios Públicos'
                  name={['datosFisicos', 'serviciosPublicos']}
                >
                  <Select mode='multiple' defaultValue={['Acueducto','Acera','Telefono','Electricidad','Transporte',
                  'Cable Tv','Cloacas','Septico','Alum. Pub.','Pavimento','Elec. Indust.','Otro']}>
                    <Select.Option value='Acueducto'>Acueducto</Select.Option>
                    <Select.Option value='Acera'>Acera</Select.Option>
                    <Select.Option value='Telefono'>Teléfono</Select.Option>
                    <Select.Option value='Electricidad'>Electricidad</Select.Option>
                    <Select.Option value='Transporte'>Transporte</Select.Option>
                    <Select.Option value='Cable Tv'>Cable TV</Select.Option>
                    <Select.Option value='Cloacas'>Cloacas</Select.Option>
                    <Select.Option value='Septico'>Séptico</Select.Option>
                    <Select.Option value='Alum. Pub.'>Alum. Pub.</Select.Option>
                    <Select.Option value='Pavimento'>Pavimento</Select.Option>
                    <Select.Option value='Elec. Indust.'>Elec. Indust.</Select.Option>
                    <Select.Option value='Otro'>Otro</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12} xl={16}>
                <Form.Item
                  rules={[{ required: property?.clasificacion === 'EJIDO' ? true : false, message: 'Campo Requerido' }]}
                  label='Nro de Arrendamiento de Ejido:'
                  name={['datosFisicos', 'arrendamiento']}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={12}>
                <Form.Item name='perimetro'>
                  <Checkbox
                    checked={perimetro}
                    onChange={(e) => {
                      console.log(e);
                      setPerimetro(e.target.checked);
                    }}
                  >
                    Se encuentra fuera del perímetro
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
              <Typography.Title level={4}>Avalúos</Typography.Title>
            </Divider>
            <Row gutter={12}>
              <Col xs={6}>
                <Form.Item rules={[{ required: true, message: 'Campo Requerido' }]} label='Avalúo Terreno' name='avaluoTerreno'>
                  <Input type='number' step={0.01} />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item
                  rules={[{ required: true, message: 'Campo Requerido' }]}
                  label='Avalúo Construcción'
                  name='avaluoConstruccion'
                >
                  <Input type='number' step={0.01} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={12}>
                <Typography.Text>{`Metros terreno: ${property?.metrosTerreno}`}</Typography.Text>
                <Typography.Text>{`Metros contrucción: ${property?.metrosConstruccion}`}</Typography.Text>
              </Col>
            </Row>
            <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
              <Typography.Title level={4}>Observaciones</Typography.Title>
            </Divider>
            <Row gutter={12}>
              <Col xs={24} xl={16}>
                <Form.Item rules={[{ required: false}]} label='Observaciones' name='observaciones'>
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>
              {files && files.hasOwnProperty(`Mapa`) ? (
                <Row>
                  <Col xs={24} xl={6}>
                  <Card
                    cover={<img alt='' ref={recaudosRef.current[0]} />}
                    bodyStyle={{ padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  >
                    <Typography.Text strong style={{ textAlign: 'center' }}>
                      {`Mapa`}
                    </Typography.Text>
                    <Button
                      type='link'
                      onClick={() => deleteFile(`Mapa`, 0)}
                      style={{ textAlign: 'right', width: '100%', color: 'red' }}
                    >
                      Eliminar
                      <DeleteOutlined />
                    </Button>
                  </Card>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs={24} xl={6}>
                    <input
                    hidden
                    ref={ref}
                    type='file'
                    onChange={(e) => handleFiles(e,`Mapa` ,0)}
                  />
                  <Button type='primary' style={{ marginTop: 9, marginBottom: 9}} onClick={() => ref.current?.click()}>
                    Cargar Mapa
                  </Button>  
                  </Col> 
                </Row>
              )}
            <Button type='primary' htmlType='submit'>
              Generar Planilla de Código
            </Button>
          </Form>
        </>
      )}
    </Card>
  );
};

interface CreateCodCatProps {
  auth: States.Auth;
  thm: States.ThemeColors;
  prsh: States.Parish;
  coin: States.Coins;
}

const mapStateToProps = ({ thm, auth, prsh, coin }: State) => ({ thm, auth, prsh, coin });

export default connect(mapStateToProps)(CreateCodCat);
