import React, { useEffect, useState } from 'react';
import ResultError from './ResultError';
import { useParams } from 'react-router';
import { Typography, Descriptions, Collapse, Spin } from 'antd';
import {  CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
import DisplayDoc from './DisplayDoc';
import { useWindowDimensions } from '../utils/hooks';
import { validateSedemat } from '../services/validateDoc';

const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

const ValidateDoc: React.FC = () => {
  const params = useParams<any>();
  const [data, setData] = useState<Liquidacion>();
  const [open, setOpen] = useState('');
  const [loading, setLoading] = useState(true)
  const { width } = useWindowDimensions();

  const getData = async () => {
    try {
      const docs = await validateSedemat(params.id)
      setData(docs);
      setLoading(false);
    }catch(e) {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(params.id) {
      getData();
    }
    //eslint-disable-next-line
  }, [])

  const loader = (<Spin indicator={<LoadingOutlined style={{ marginLeft: '50%', fontSize: '10rem', color:'#fff' }} spin />} />)
  const response = (data) ? (
    <DisplayDoc url={data.certificado} expand={open === '1'} content={()=>
      <Collapse onChange={(key)=> {setOpen(key[0]); }} activeKey={open}>
        <Collapse.Panel 
          key={1}
          header={<Typography.Title style={{margin:0}} level={4} >Certificado válido <CheckCircleFilled style={{color: "#52c41a"}} /></Typography.Title>}>
          <Descriptions style={{height:width < 600 ?'15vh':'auto', overflow:'auto'}} size="small">
            <Descriptions.Item key={0} span={width < 600 ? 1:3}><Typography.Text strong style={{margin:0}}>Contribuyente</Typography.Text></Descriptions.Item>
            <Descriptions.Item key={1} label="Tipo">{data.tipoContribuyente}</Descriptions.Item>
            <Descriptions.Item key={2} label="Documento">{`${data.nacionalidad || data.tipoDocumento}-${data.documento}`}</Descriptions.Item>
            {data.rim ? <Descriptions.Item key={3} label="RIM">{data.rim}</Descriptions.Item> : null}
            <Descriptions.Item key={4} span={width < 600 ? 1:3}><Typography.Text strong style={{margin:0}}>Liquidación</Typography.Text></Descriptions.Item>
            <Descriptions.Item key={5} label="ID">{data.idLiquidacion}</Descriptions.Item>
            <Descriptions.Item key={6} label="Tipo">{data.tipoLiquidacion || data.descripcionRamo}</Descriptions.Item>
            <Descriptions.Item key={7} label="Monto">{formatCurrency(data.montoLiquidacion)}</Descriptions.Item>
            <Descriptions.Item key={8} label="Fecha correspondiente">{`${data.mes || data.datos?.fecha?.month}/${data.anio || data.datos?.fecha?.year}`}</Descriptions.Item>
          </Descriptions>
        </Collapse.Panel>
      </Collapse>}
    />
  ) : (loading) ? loader:(<ResultError />)
  return (
    <div style={{ minWidth: 320, width: data ? '90%' : 'auto'}}>{response}</div>
  );
}

export default ValidateDoc;

interface Liquidacion {
  id: number;
  usuario: number;
  documento: string;
  rim: string;
  nacionalidad: string;
  tipoContribuyente: string;
  aprobado: boolean;
  pagado: boolean;
  fechaCreacion: string;
  totalSolicitud: number;
  idLiquidacion: number;
  montoLiquidacion: number;
  recibo: string;
  certificado: string;
  mes: string;
  anio: number;
  idProcedimiento: number;
  tipoLiquidacion: string;
  planillaSolvencia: string;
  planillaRecibo: string;
  tipoDocumento: string;
  descripcionRamo: string;
  datos: any;
}