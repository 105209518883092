import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const fetchInstitutions = async (token) => {
  try {
    const response = await axios.get(`${server}/institutions`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

