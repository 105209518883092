import axios, { AxiosResponse } from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { CoinsType, SET_DOLAR, SET_EURO, SET_PESO, SET_PETRO } from './actionsTypes';
const server = process.env.REACT_APP_SERVER_URL;

export const setPetro = (petro: number): CoinsType<typeof SET_PETRO> => ({ type: SET_PETRO, payload: petro });

export const fetchPetro = (): ThunkAction<Promise<any>, {}, {}, CoinsType<typeof SET_PETRO>> =>
  async (dispatch: ThunkDispatch<{}, {}, CoinsType<typeof SET_PETRO>>): Promise<any> => {
    try {
      const response: AxiosResponse = await axios.get(`${server}/values/petro`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      dispatch(setPetro(parseFloat(response.data.petro)));
      return response.data;
    } catch(e) {
      throw e;
    }
  };

export const updatePetro = (value:number): ThunkAction<Promise<any>, {}, {}, CoinsType<typeof SET_PETRO>> =>
  async (dispatch: ThunkDispatch<{}, {}, CoinsType<typeof SET_PETRO>>): Promise<any> => {
    try {
      const response: AxiosResponse = await axios.patch(`${server}/values/petro`, { value } , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      if(response.data.petro) dispatch(setPetro(parseFloat(response.data.petro)));
      return response.data;
    } catch(e) {
      throw e;
    }
  };

  
  
  export const setPeso = (peso: number): CoinsType<typeof SET_PESO> => ({ type: SET_PESO, payload: peso });

  export const fetchPeso = (): ThunkAction<Promise<any>, {}, {}, CoinsType<typeof SET_PESO>> =>
    async (dispatch: ThunkDispatch<{}, {}, CoinsType<typeof SET_PESO>>): Promise<any> => {
      try {
        const response: AxiosResponse = await axios.get(`${server}/values/peso`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
        dispatch(setPeso(parseFloat(response.data.peso)));
        return response.data;
      } catch(e) {
        throw e;
      }
    };
  
  export const updatePeso = (value:number): ThunkAction<Promise<any>, {}, {}, CoinsType<typeof SET_PESO>> =>
    async (dispatch: ThunkDispatch<{}, {}, CoinsType<typeof SET_PESO>>): Promise<any> => {
      try {
        const response: AxiosResponse = await axios.patch(`${server}/values/peso`, { value } , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
        if(response.data.peso) dispatch(setPeso(parseFloat(response.data.peso)));
        return response.data;
      } catch(e) {
        throw e;
      }
    };
     
  export const setDolar = (dolar: number): CoinsType<typeof SET_DOLAR> => ({ type: SET_DOLAR, payload: dolar });

  export const fetchDolar = (): ThunkAction<Promise<any>, {}, {}, CoinsType<typeof SET_DOLAR>> =>
    async (dispatch: ThunkDispatch<{}, {}, CoinsType<typeof SET_DOLAR>>): Promise<any> => {
      try {
        const response: AxiosResponse = await axios.get(`${server}/values/dolar`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
        dispatch(setDolar(parseFloat(response.data.usd)));
        return response.data;
      } catch(e) {
        throw e;
      }
    };
  
  export const updateDolar = (value:number): ThunkAction<Promise<any>, {}, {}, CoinsType<typeof SET_DOLAR>> =>
    async (dispatch: ThunkDispatch<{}, {}, CoinsType<typeof SET_DOLAR>>): Promise<any> => {
      try {
        const response: AxiosResponse = await axios.patch(`${server}/values/dolar`, { value } , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
        if(response.data.usd) dispatch(setDolar(parseFloat(response.data.usd)));
        return response.data;
      } catch(e) {
        throw e;
      }
    };
     
  export const setEuro = (euro: number): CoinsType<typeof SET_EURO> => ({ type: SET_EURO, payload: euro });

  export const fetchEuro = (): ThunkAction<Promise<any>, {}, {}, CoinsType<typeof SET_EURO>> =>
    async (dispatch: ThunkDispatch<{}, {}, CoinsType<typeof SET_EURO>>): Promise<any> => {
      try {
        const response: AxiosResponse = await axios.get(`${server}/values/euro`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
        dispatch(setEuro(parseFloat(response.data.euro)));
        return response.data;
      } catch(e) {
        throw e;
      }
    };
  
  export const updateEuro = (value:number): ThunkAction<Promise<any>, {}, {}, CoinsType<typeof SET_EURO>> =>
    async (dispatch: ThunkDispatch<{}, {}, CoinsType<typeof SET_EURO>>): Promise<any> => {
      try {
        const response: AxiosResponse = await axios.patch(`${server}/values/euro`, { value } , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
        if(response.data.euro) dispatch(setEuro(parseFloat(response.data.euro)));
        return response.data;
      } catch(e) {
        throw e;
      }
    };
  
