import React from 'react';
import { Card, Typography, Row, Col, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const TNL: React.FC<TNLProps> = ({ data, loading }) => {
  const formatNumber = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  return (
    <Card bodyStyle={{ paddingTop: 10 }} loading={loading}>
      <Typography.Text strong style={{ display: 'inline-block', marginBottom: 8 }}>Tasa Nuevas Licencias (TNL)</Typography.Text> <br />
      <Row style={{ margin: 5 }}>
        <Col span={7}>
          <Typography.Text style={{ fontSize: 16, fontWeight: 'lighter', textAlign: 'center', width: '100%', color: 'rgba(0, 0, 0, 0.6)' }}>
            TNL
            <Tooltip title='Tasa de Nuevas Licencias: Representa el coeficiente entre la cantidad de licencias nuevas en el mes actual y la cantidad de licencias nuevas
              en el mes anterior. Los valores menores a 1 representan un decrecimiento en el numero de nuevas licencias en el mes y viceversa.'>
              <QuestionCircleOutlined style={{ margin: '0 5px' }} />
            </Tooltip>
          </Typography.Text> <br />
          <Typography.Text strong style={{ fontSize: 18, textAlign: 'center', width: '100%', color: 'black' }}>
            {formatNumber(data?.coeficiente || 0)}
          </Typography.Text> 
        </Col>
        <Col span={17}>
          <Typography.Text style={{ fontSize: 16, fontWeight: 'lighter', textAlign: 'center', width: '100%', color: 'rgba(0, 0, 0, 0.6)' }}>
            Cantidad de Licencias
            <Tooltip title='Cantidad de nuevas licencias en este mes.'>
              <QuestionCircleOutlined style={{ margin: '0 5px' }} />
            </Tooltip>
          </Typography.Text> <br />
          <Typography.Text strong style={{ fontSize: 18, textAlign: 'center', width: '100%', color: 'black' }}>
            {formatNumber(data?.valor || 0)}
          </Typography.Text> 
        </Col>
      </Row>
    </Card>
  );
};

export default TNL;

interface TNLProps {
  data?: {
    anio: number
    mes: string
    coeficiente: number
    valor: number
  }
  loading: boolean
}