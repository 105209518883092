import axios, { AxiosResponse } from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { BranchesType, SET_BRANCHES, SET_BRANCHES_CATEGORY } from './actionsTypes';
import { Branch, BranchCategory } from 'sigt';
const server = process.env.REACT_APP_SERVER_URL;

export const setBranches = (branches: Branch[]): BranchesType => ({ type: SET_BRANCHES, payload: branches });
export const setBranchesCategory = (branches: BranchCategory[]): BranchesType => ({
  type: SET_BRANCHES_CATEGORY,
  payload: branches,
});

export const fetchBranches =
  (token: string, userId: number): ThunkAction<Promise<void>, {}, {}, BranchesType> =>
  async (dispatch: ThunkDispatch<{}, {}, BranchesType>): Promise<void> => {
    try {
      const response: AxiosResponse<{ data: Branch[] }> = await axios.get(
        `${server}/branches?all=${[39, 38, 24, 25, 90].includes(userId) ? 1 : 0}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      dispatch(setBranches(response.data.data.sort((a, b) => (a.descripcion > b.descripcion ? 1 : -1))));
    } catch (e) {
      throw e;
    }
  };

export const fetchBranchesPolice =
  (token: string, userId: number): ThunkAction<Promise<void>, {}, {}, BranchesType> =>
  async (dispatch: ThunkDispatch<{}, {}, BranchesType>): Promise<void> => {
    try {
      const response: AxiosResponse<{ data: BranchPolice }> = await axios.get(`${server}/branches/police`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch(setBranches(response.data.data.branches.sort((a, b) => (a.descripcion > b.descripcion ? 1 : -1))));
      dispatch(setBranchesCategory(response.data.data.branchesCategory.sort((a, b) => (a.descripcion > b.descripcion ? 1 : -1))));
    } catch (e) {
      throw e;
    }
  };

interface BranchPolice {
  branches: Branch[];
  branchesCategory: BranchCategory[];
}
