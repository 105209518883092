import React from 'react';
import { Select } from 'antd';

const LicenseTypeSelect: React.FC<SolvencyEstateTypeProps> = ({ disabled, value, onChange, placeholder, showCesante = false }) => {
  return (
    <Select value={value} onChange={onChange} placeholder={placeholder} disabled={disabled}>
      <Select.Option value='PROVISIONAL'>Provisional</Select.Option>
      <Select.Option value='PERMANENTE'>Permanente</Select.Option>
      {showCesante && <Select.Option value='CESANTE'>Cesante</Select.Option>}
      {showCesante && <Select.Option value='NO PASIVO'>No Pasivo</Select.Option>}
      {showCesante && <Select.Option value='INHABILITADA'>Inhabilitada</Select.Option>}
      {showCesante && <Select.Option value='SANCIONADO'>Sancionado</Select.Option>}
      {showCesante && <Select.Option value='TEMPORAL'>Temporal</Select.Option>}
    </Select>
  );
};

export default LicenseTypeSelect;

interface SolvencyEstateTypeProps {
  disabled?: boolean
  value?: any
  onChange?: (e: any) => any
  placeholder?: string
  showCesante?: boolean
};