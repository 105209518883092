import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { State, States } from 'sigt';
import { fetchWallet } from '../../redux/actions/charges';
import TableCharge from '../Tables/TableCharge';

const WalletDetails : React.FC<WalletDetailsProps> = ({auth, chrg,fetchWallet}) => {
  const { id } : { id:string } = useParams();
  const [loading, setLoading] = useState(false);
  const isAR = chrg.wallets.find(e => e.idCartera === +id)?.esAr ? chrg.wallets.find(e => e.idCartera === +id)?.esAr : !!chrg.collections[1]?.pago_ret;
  
  const getWallet = async () => {
    setLoading(true);
    await fetchWallet(auth.token, id);
    setLoading(false);
  };

  useEffect(() => {
    getWallet();
    //eslint-disable-next-line
  },[])

  return <TableCharge loadingW={loading} editable={false} walletId={id} isAR={isAR}/>;
}

const mapStateToProps = ({ chrg, thm, auth } : State ) => ({ chrg, thm, auth });

export default connect(mapStateToProps, { fetchWallet })(WalletDetails);

interface WalletDetailsProps {
  auth: States.Auth;
  chrg: States.Charges;
  fetchWallet: (token,id) => Promise<Response>;
}