import { Tooltip } from 'antd'
import React from 'react'

const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

const TooltipCoin = ({priceBs, dolarBs, pesoBs, euroBs, children}) => {
  const title = `${formatCurrency(priceBs / dolarBs)} $ | ${formatCurrency(priceBs / pesoBs)} COP | ${formatCurrency(priceBs / euroBs)} €`;
    return (
    <Tooltip title={title}>
        { children }
    </Tooltip>
  )
}

export default TooltipCoin;