import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const newRepair = async (data, token) => {
  try {
    const response = await axios.post(`${server}/repairs/init`, data,{ headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getRepairs = async (data, token) => {
  try {
    const response = await axios.get(`${server}/repairs?doc=${data.doc}&ref=${data.ref}&pref=${data.pref}`,{ headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}
