import axios, { AxiosResponse } from 'axios';
import { Parroquia } from 'sigt';
import { ParishTypes, SET_PARISHES } from './actionsTypes';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
const server = process.env.REACT_APP_SERVER_URL;

export const setParishes = (parishes: Parroquia[]): ParishTypes => ({ type: SET_PARISHES, payload: parishes });

export const fetchParishes = (): ThunkAction<Promise<void>, {}, {}, ParishTypes> =>
  async (dispatch: ThunkDispatch<{}, {}, ParishTypes>): Promise<void> => {
    try {
      const response: AxiosResponse<FetchParishesResponse> = await axios.get(`${server}/parishes`);
      dispatch(setParishes(response.data.parroquias));
    } catch(e) {
      throw e;
    }
  };

interface FetchParishesResponse {
  parroquias: Parroquia[]
}