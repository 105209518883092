import React from 'react';
import { Select } from 'antd';

const VehicleTypeSelect: React.FC<VehicleTypeSelectProps> = ({ onChange, value, placeholder, disabled = false }) => {
  return (
    <Select value={value} onChange={onChange} placeholder={placeholder} disabled={disabled}>
      <Select.Option value='CPP'>Carro por Puesto (CPP)</Select.Option>
      <Select.Option value='MB'>Micro Bus (MB)</Select.Option>
      <Select.Option value='AB'>Autobús (AB)</Select.Option>
      <Select.Option value='MIXTA'>Mixta</Select.Option>
    </Select>
  );
};

export default VehicleTypeSelect;

interface VehicleTypeSelectProps {
  onChange: (e: any) => any
  value: any
  placeholder: string
  disabled?: boolean
}