import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const getFineArticule = async (token) => {
  try {
    const response = await axios.get(`${server}/fines/articules`, { headers: { Authorization: `Bearer ${token}` } });
    const res = response?.data.data;

    let newData = {
      articules: res?.articules,
      numeral: res?.numeral,
      gaceta: res?.gaceta,
      literal: res?.literal,
    };

    return newData;
  } catch (error) {
    throw error;
  }
};

export const getFineForPayment = async (token, idMulta) => {
  try {
    const response = await axios.get(`${server}/fines/fineForPayment/${idMulta}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.fine;
  } catch (error) {
    throw error;
  }
};

export const getFineCertificate = async (token, idMulta: number) => {
  try {
    const response = await axios.get(`${server}/fines/receipt/${idMulta}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
