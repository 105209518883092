import React from 'react';
import { Card, Typography, Row, Col } from 'antd';
import { MiniProgress } from 'ant-design-pro/lib/Charts';

const Top1000: React.FC<Top100Props> = ({ data, loading }) => {
  const formatNumber = (number: number) => new Intl.NumberFormat('de-DE').format(number);
  const porcentaje = +((data?.pagado || 0) / (data?.total || 1)).toFixed(2);

  return (
    <Card bodyStyle={{ paddingTop: 10, paddingBottom: 5 }} loading={loading}>
      <Typography.Text strong style={{ display: 'inline-block', marginBottom: 8 }}>Top Contribuyentes</Typography.Text> <br />
      <Row style={{ margin: 5 }}>
        <Col span={7}>
          <Typography.Text style={{ fontWeight: 'lighter', textAlign: 'center', width: '100%', color: 'rgba(0, 0, 0, 0.6)' }}>
            Total de Contribuyentes
          </Typography.Text> <br />
          <Typography.Text strong style={{ fontSize: 18, textAlign: 'center', width: '100%', color: 'black' }}>
            {formatNumber(data?.total || 0)}
          </Typography.Text> 
        </Col>
        <Col span={7}>
          <Typography.Text style={{ fontWeight: 'lighter', textAlign: 'center', width: '100%', color: 'rgba(0, 0, 0, 0.6)' }}>
            Declarado / Pagado
          </Typography.Text> <br />
          <Typography.Text strong style={{ fontSize: 18, textAlign: 'center', width: '100%', color: 'black' }}>
            {formatNumber(data?.liquidado || 0)} / {formatNumber(data?.pagado || 0)}
          </Typography.Text> 
        </Col>
        <Col span={7}>
          <Typography.Text style={{ fontWeight: 'lighter', textAlign: 'center', width: '100%', color: 'rgba(0, 0, 0, 0.6)' }}>
            Porcentaje Pagado
          </Typography.Text> <br />
          <Typography.Text strong style={{ fontSize: 18, textAlign: 'center', width: '100%', color: 'black' }}>
            {formatNumber(+(porcentaje * 100).toFixed(2))}%
          </Typography.Text> 
        </Col>
      </Row>
      {/* <Divider /> */}
      <Row style={{ margin: 10 }}>
        <Col span={24}>
          <MiniProgress percent={porcentaje * 100} strokeWidth={20} target={90} targetLabel='Objetivo' style={{ margin: '0px 100px' }} />
        </Col>
      </Row>
    </Card>
  );
};

export default Top1000;

interface Top100Props {
  data?: {
    total: number
    liquidado: number
    pagado: number
  }
  loading: boolean
}