import React, { useEffect, useState, useRef, useContext } from "react";
import { MessageOutlined, MessageTwoTone, SearchOutlined } from '@ant-design/icons';
import { Card, Input, Button, Table, Form, Tag, Select, Progress, Rate, Tooltip, Modal, message, Row, Col } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { connect } from "react-redux";
import { State, States } from 'sigt';
import { fetchWallet, fetchWallets, getExcelWallet, updateCollection } from '../../redux/actions/charges'
import handlingMessage from '../../utils/handlingMessage';
import { useWindowDimensions } from '../../utils/hooks';
import Highlighter from 'react-highlight-words';
import { ColumnsType } from "antd/lib/table/Table";
import excelImage from '../../assets/images/excel.png';

const EditableContext = React.createContext<any>(null);

const EditableRow: React.FC<any> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};


const EditableCell: React.FC<any> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  type,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>();
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const cases = ()=> {
    switch (dataIndex) {
      case 'estatusTelefonico':
        return (<Select ref={inputRef} open onChange={save} onBlur={save} >
                  <Select.Option value='contactado' style={{backgroundColor:'#00c875', color:'#fff', textAlign:'center'}}>Contactado</Select.Option>
                  <Select.Option value='desconectado' style={{backgroundColor:'#e2445c', color:'#fff', textAlign:'center'}}>Desconectado</Select.Option>
                  <Select.Option value='equivocado' style={{backgroundColor:'#e29a44', color:'#fff', textAlign:'center'}}>Equivocado</Select.Option>
                </Select>)
      default:
        return (<Select ref={inputRef} open onChange={save} onBlur={save} >
          <Select.Option value='true' style={{backgroundColor:'#00c875', color:'#fff', textAlign:'center'}}>SI</Select.Option>
          <Select.Option value='false' style={{backgroundColor:'#e2445c', color:'#fff', textAlign:'center'}}>NO</Select.Option>
        </Select>)
  }
}
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex]  });
  };

  const save = async e => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      if((['contactado', 'fiscalizar'].includes(dataIndex) && typeof values[dataIndex] === 'string')) values[dataIndex] =  values[dataIndex] === 'true' ? true : false;
      record[dataIndex] !== values[dataIndex] && handleSave({ ...record, ...values });
    } catch (errInfo) {
      message.error({duration:2.5, content:'Error al guardar el valor'});
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {cases()}
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ padding: '0 10px' }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const TableCharge: React.FC<PropsTable> = ({ thm, isAR, walletId = '0', loadingW, fetchWallet, fetchWallets, auth, chrg, updateCollection, editable = true }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColum] = useState('');
  const searchInput = useRef<any>(null);
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [loadingE, setLoadingE] = useState(false);
  const [idWallet, setIdWallet] = useState<number>(+walletId);
  const [id, setId] = useState<number | null>(null);
  const [_isAR, setIsAR] = useState<boolean | undefined>(isAR);
  const [formComment] = Form.useForm();
  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const getStatus = {
    contactado:{color:'green', text:'Contactado'},
    desconectado:{color:'red', text:'Desconectado'},
    equivocado:{color:'warning', text:'Equivocado'},
  };

  const openModal = (record) => {
    setId(record.idCobranza)
    formComment.setFieldsValue({ observaciones: record.observaciones });
  };
  const filterPhone = [
    { value: 'Sin definir', text: <Tag style={{width:100, textAlign:'center'}} color='default'>Sin definir</Tag> },
    { value: 'contactado', text: <Tag style={{width:100, textAlign:'center'}} color='green'>Contactado</Tag> },
    { value: 'desconectado', text: <Tag style={{width:100, textAlign:'center'}} color='red'>Desconectado</Tag> },
    { value: 'equivocado', text: <Tag style={{width:100, textAlign:'center'}} color='warning'>Equivocado</Tag> },
  ];

  const filterStars = [
    { value: '1', text: <Rate style={{width:'100%'}} value={1} disabled={true} /> },
    { value: '2', text: <Rate style={{width:'100%'}} value={2} disabled={true} /> },
    { value: '3', text: <Rate style={{width:'100%'}} value={3} disabled={true} /> },
    { value: '4', text: <Rate style={{width:'100%'}} value={4} disabled={true} /> },
    { value: '5', text: <Rate style={{width:'100%'}} value={5} disabled={true} /> },
  ];

  const filterPayments = [
    { value: 0, text: <Tag style={{width:100, textAlign:'center'}} color='red'>No declarado</Tag> },
    { value: 1, text: <Tag style={{width:100, textAlign:'center'}} color='warning'>Vigente</Tag> },
    { value: 2, text: <Tag style={{width:100, textAlign:'center'}} color='green'>Pagado</Tag> },
  ];

  const filterBoolean = [
    { value: true, text: <Tag style={{width:80, textAlign:'center'}} color='green'>SI</Tag> },
    { value: false, text: <Tag style={{width:80, textAlign:'center'}} color='red'>NO</Tag> }
  ];

  const getPayment = {
    0: {color: 'red', text:'No declarado'},
    1: {color: 'warning', text:'Vigente'},
    2: {color: 'green', text:'Pagado'},
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const columns: ColumnsType<any> | any = [
    {
      title: "Razon Social",
      dataIndex: "razonSocial",
      key: "razonSocial",
      fixed: 'left',
      width:300,
      ellipsis: true,
      ...getColumnSearchProps("razonSocial"),
      render: (text, _) => <Tooltip title={text} >{text}</Tooltip>,
    },
    {
      title: "RIM",
      dataIndex: "rim",
      key: "rim",
      width:100,
      ...getColumnSearchProps("rim")
    },
    {
      title: "RIF",
      dataIndex: "rif",
      key: "rif",
      width:100,
      ...getColumnSearchProps("rif")
    },
    {
      title: "Teléfono",
      dataIndex: "telefono",
      key: "telefono",
      width:100,
      ...getColumnSearchProps("telefono")
    },
    {
      title: "Contactado",
      dataIndex: "contactado",
      key: "contactado",
      width:120,
      filters: filterBoolean,
      onFilter: (value, record) => record.contactado === value,
      editable:editable,  
      render: (text, record) => {
        const contactado = record.contactado
        return<span style={{width:'100%', textAlign:'center'}}>
          <Tag style={{width:'100%', cursor:'pointer'}} color={contactado ? 'green' : 'red'}>{contactado ? 'SI' : 'NO'}</Tag>
          </span>
    }
    },
    {
      title: "Estatus Telefonico",
      dataIndex: "estatusTelefonico",
      key: "estatusTelefonico",
      filters: filterPhone,
      onFilter: (value, record) => record.estatusTelefonico.toLowerCase().indexOf((value as string).toLowerCase()) === 0,
      width:150,
      editable:editable,
      render: (text, record) => {
        const estatusTelefonico = getStatus[record.estatusTelefonico] ? getStatus[record.estatusTelefonico] : {color:'default', text:'Sin definir'}
        return<span style={{width:'100%', textAlign:'center'}}>
          <Tag style={{width:'100%', cursor:'pointer'}} color={estatusTelefonico.color}>{estatusTelefonico.text}</Tag>
          </span>
    }
    },
    {
      title: "Observación",
      dataIndex: "observaciones",
      key: "observaciones",
      align:  "center",
      width:150,
      render: (text, record) => text ? <MessageTwoTone onClick={()=> openModal(record)} /> : <MessageOutlined onClick={()=> editable && openModal(record)} />

    },
    {
      title: "Convenio",
      dataIndex: "poseeConvenio",
      width:120,
      key: "poseeConvenio",
      filters: filterBoolean,
      onFilter: (value, record) => record.poseeConvenio === value,
      render: (text, record) => {
        const poseeConvenio = record.poseeConvenio
        return<span style={{width:'100%', textAlign:'center'}}>
          <Tag style={{width:'100%', cursor:'pointer'}} color={poseeConvenio ? 'green' : 'red'}>{poseeConvenio ? 'SI' : 'NO'}</Tag>
          </span>
    }      
    },
    {
      title: "Fiscalizar",
      dataIndex: "fiscalizar",
      key: "fiscalizar",
      width:120,
      editable:editable,
      filters: filterBoolean,
      onFilter: (value, record) => record.fiscalizar === value,
      render: (text, record) => {
        const fiscalizar = record.fiscalizar
        return<span style={{width:'100%', textAlign:'center'}}>
          <Tag style={{width:'100%', cursor:'pointer'}} color={fiscalizar ? 'green' : 'red'}>{fiscalizar ? 'SI' : 'NO'}</Tag>
          </span>
    }      
    },
    {
      title: "Pago AE",
      dataIndex: "pagoAE",
      key: "pagoAE",
      width:120,
      filters:filterPayments,
      onFilter: (value, record) => record.pagoAE === value,         
      render: (text, record) => {
        const pagoAE = getPayment[record.pagoAE] ? getPayment[record.pagoAE] : {color:'default', text:'Sin definir'}
        return<span style={{width:'100%', textAlign:'center'}}>
          <Tooltip title={`${formatCurrency(record.montoAE)} Bs` || ''}>
            <Tag style={{width:'100%', cursor:'pointer'}} color={pagoAE.color}>{pagoAE.text}</Tag>
          </Tooltip>
          </span>
    }
    },
    {
      title: "Pago SM",
      dataIndex: "pagoSM",
      width:120,
      key: "pagoSM",
      filters:filterPayments,
      onFilter: (value, record) => record.pagoSM === value,       
      render: (text, record) => {
        const pagoSM = getPayment[record.pagoSM] ? getPayment[record.pagoSM] : {color:'default', text:'Sin definir'}
        return<span style={{width:'100%', textAlign:'center'}}>
          <Tooltip title={`${formatCurrency(record.montoSM)} Bs` || ''}>
            <Tag style={{width:'100%', cursor:'pointer'}} color={pagoSM.color}>{pagoSM.text}</Tag>
          </Tooltip>
          </span>
    }
    },
    {
      title: "Pago IU",
      dataIndex: "pagoIU",
      width:120,
      key: "pagoIU",
      filters:filterPayments,
      onFilter: (value, record) => record.pagoIU === value,        
      render: (text, record) => {
        const pagoIU = getPayment[record.pagoIU] ? getPayment[record.pagoIU] : {color:'default', text:'Sin definir'}
        return<span style={{width:'100%', textAlign:'center'}}>
          <Tooltip title={`${formatCurrency(record.montoIU)} Bs` || ''}>
            <Tag style={{width:'100%', cursor:'pointer'}} color={pagoIU.color}>{pagoIU.text}</Tag>
          </Tooltip>
          </span>
    }
    },
    {
      title: "Pago MUL",
      dataIndex: "pagoMUL",
      width:120,
      key: "pagoMUL",
      filters:filterPayments,
      onFilter: (value, record) => record.pagoMUL === value,      
      render: (text, record) => {
        const pagoMUL = getPayment[record.pagoMUL] ? getPayment[record.pagoMUL] : {color:'default', text:'Sin definir'}
        return<span style={{width:'100%', textAlign:'center'}}>
          <Tooltip title={`${formatCurrency(record.montoMUL)} Bs` || ''}>
            <Tag style={{width:'100%', cursor:'pointer'}} color={pagoMUL.color}>{pagoMUL.text}</Tag>
          </Tooltip>
          </span>
    }
    },
     {
      title: "Pago RE",
      dataIndex: "pago_re",
      width: _isAR ? 120 : 0,
      key: "pago_ret",
      filters:filterPayments,
      onFilter: (value, record) => record.pago_ret === value,
      render: (text, record) => {
        const pagoRE = getPayment[record.pago_ret] ? getPayment[record.pago_ret] : {color:'default', text:'Sin definir'}
        return<span style={{width:'100%', textAlign:'center'}}>
          <Tooltip title={record.monto_ret ? `${formatCurrency(record.monto_ret)} Bs` : ''}>
            <Tag style={{width:'100%', cursor:'pointer'}} color={pagoRE.color}>{pagoRE.text}</Tag>
          </Tooltip>
          </span>
    }
    }, 
    {
      title: "Pago PUB",
      dataIndex: "pagoPP",
      width:120,
      key: "pagoPP",
      filters:filterPayments,
      onFilter: (value, record) => record.pagoPP === value,
      render: (text, record) => {
        const pagoPP = getPayment[record.pagoPP] ? getPayment[record.pagoPP] : {color:'default', text:'Sin definir'}
        return<span style={{width:'100%', textAlign:'center'}}>
          <Tooltip title={`${formatCurrency(record.montoPP)} Bs` || ''}>
            <Tag style={{width:'100%', cursor:'pointer'}} color={pagoPP.color}>{pagoPP.text}</Tag>
          </Tooltip>
          </span>
    }
    },               
    {
      title: "Progreso",
      dataIndex: "progreso",
      key: "progreso",
      align:'center',
      width:150,
      render: (text, record) => {
        return<span style={{width:'100%', textAlign:'center'}}>
          <Progress style={{width:'90%'}} percent={+(record.progreso * 100).toFixed(2)} />
          </span>
    }
    },
    {
      title: "Calificación",
      dataIndex: "rating",
      align: 'center',
      width:150,
      filters:filterStars,
      onFilter: (value, record) => record.rating === value,
      key: "rating",
      render: (text, record) => {
        return<span style={{width:'100%', textAlign:'center'}}>
          <Rate style={{width:'100%'}} value={+record.rating} disabled={true} />
          </span>
    }
    },
  ];

  useEffect(() => {
    aux();
    // eslint-disable-next-line
  }, [auth.token, auth.user, editable]);

  const aux = async() => {
    if(auth.user) {
      if(editable){
        const wallets : any = await fetchWallets(auth.token || '');
        const wallet = wallets.cobranzas.find((w) =>  w.idUsuario === auth.user?.id);
        if(wallet?.idCartera){
          setIdWallet(wallet?.idCartera || 0);
          setIsAR(wallet?.esAr);
          await fetchWallet(auth.token, wallet?.idCartera || 0);
        }
      }
    }
    setLoading(false);
  }

  const handleSave = (row) => {
    handlingMessage({
      action: () => updateCollection(auth.token || '', row.idCobranza || 0, row),
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      }) 
  };

  const exportExcel = () => {
    setLoadingE(true);
    handlingMessage({
      action: () => getExcelWallet(auth.token || '', idWallet),
      key: 'submit',
      loadingMessage: 'Exportando a Excel...',
      cb: (data) => { 
        if(data.status === 200){
            const win = window.open(data.data, '_blank');
            win?.focus();
          }
          setLoadingE(false)
      }
    });      
  };

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: handleSave,
      })
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    }
  };

  const save = async() => {
    const values = await formComment.validateFields();
    setLoading(true);
    handlingMessage({
      action: () => updateCollection(auth.token || '', id || 0, values),
      key: 'submit',
			loadingMessage: 'Realizando operacion...',
			cb: (res) => {
        setLoading(false);
			}
		});
    setId(null);
  }

  const btns = [<Button key='back' onClick={() => setId(null)}>Atras</Button>,<Button key='submit' loading={loading} type='primary' onClick={() => save()}>Guardar</Button>];

  return (
    <Card style={{ height: '100%' }} title="Cobranzas" 
      headStyle={{ backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: "#fff" }}>
        <Row gutter={[8,8]} justify='end'>
          <Col><Button loading={loadingE} icon={<img src={excelImage} alt="excelIcon" style={{ width: 16, marginRight: 6 }} />} onClick={exportExcel}>Exportar a excel</Button></Col>
        </Row>
        <Form form={form} component={false}>
          <Table
          components={components}
          scroll={{x:1500, y: 500}}
          size='small'
          loading={loadingW || loading}
          pagination={{pageSize: 20}}
          columns={mergedColumns}
          dataSource={chrg.collections.map((c) => ({...c, estatusTelefonico: c.estatusTelefonico ? c.estatusTelefonico : 'Sin Definir'}))}
          rowKey={e => e.idCobranza}
          bordered
          />
        </Form>
        <Modal visible={id ? true : false} footer={btns} onCancel={() => setId(null)}>
          <Form form={formComment} layout='vertical'>
            <div style={{paddingTop:'30px'}}>
                <Form.Item name='observaciones' label='Observación' rules={[{ required: true, message: 'Debe ingresar la Observación' }]}>
                  <Input.TextArea readOnly={!editable} style={{resize:'none', height:120}} maxLength={250}></Input.TextArea>
                </Form.Item>
            </div>
          </Form>
        </Modal>
    </Card>
  );
};

const mapStateToProps = ({ auth, chrg, thm }: State) => ({ auth, chrg, thm });

export default connect(mapStateToProps, { fetchWallet, updateCollection, fetchWallets })(TableCharge);

interface PropsTable {
  fetchWallet: (token, id) => Promise<Response>;
  updateCollection: (token: string, id:number, body) => Promise<Response>;
  fetchWallets: (token) => Promise<Response>;
  editable?: boolean;
  auth: States.Auth;
  isAR: boolean | undefined;
  chrg: States.Charges
  thm: States.ThemeColors;
  walletId?: string;
  loadingW?: boolean;
}




