import { States } from "sigt";
import { CoinsType, SET_PESO, SET_PETRO, SET_EURO, SET_DOLAR } from "../actions/actionsTypes";

const initialState: States.Coins = {
  petro: 0,
  peso: 0,
  dolar: 0,
  euro: 0
};

export default (state = initialState, action: CoinsType<typeof SET_PETRO | typeof SET_PESO | typeof SET_DOLAR | typeof SET_EURO>): States.Coins => {
  switch(action.type) {
    case SET_PETRO: return { ...state, petro: action.payload }
    case SET_PESO: return { ...state, peso: action.payload }
    case SET_EURO: return {...state, euro: action.payload}
    case SET_DOLAR: return {...state, dolar: action.payload}
    default: return state;
  };
};