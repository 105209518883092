import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const fetchActivities = async (token) => {
  try {
    const response = await axios.get(`${server}/activities`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export const updateActivities = async (data, token) => {
  try {
    const response = await axios.put(`${server}/activities`, {alicuotas: data}, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}
