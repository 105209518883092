import { States } from "sigt";
import { InstActionsTypes, SET_INSTITUTIONS, AUTH_USER_LOGOUT } from "../actions/actionsTypes";

const intitialState: States.Institutions = {
  institutions: null
};

export default (state = intitialState, action: InstActionsTypes): States.Institutions => {
  switch(action.type) {
    case SET_INSTITUTIONS: return { ...state, institutions: action.payload };
    case AUTH_USER_LOGOUT: return intitialState;
    default: return state;
  }
};