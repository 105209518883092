import { SET_BANKS, BankTypes, BankStatementTypes, SET_BANK_STATEMENTS } from '../actions/actionsTypes';
import { States } from 'sigt';

const initialState: States.Banks = {
  banks: [],
  statements: []
}

export default (state: States.Banks = initialState, action: BankTypes | BankStatementTypes) => {
  switch(action.type) {
    case SET_BANKS: 
      return { ...state, banks: action.payload }
    case SET_BANK_STATEMENTS:
      return { ...state, statements: action.payload}
    default: return state;
  }
}