import axios, { AxiosResponse } from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk'; 
import { BankStatementTypes, BankTypes, SET_BANKS, SET_BANK_STATEMENTS } from './actionsTypes';
import { Banco, BanksWithStatements } from 'sigt';
const server = process.env.REACT_APP_SERVER_URL;

export const setBanks = (banks: Banco[]): BankTypes => ({ type: SET_BANKS, payload: banks });

export const fetchBanks = (token: string): ThunkAction<Promise<void>, {}, {}, BankTypes> => 
async (dispatch: ThunkDispatch<{}, {}, BankTypes>): Promise<void> => {
  try {
    const response: AxiosResponse<FetchBanksResponse> = await axios.get(`${server}/banks`, { headers: { Authorization: `Bearer ${token}` } });
    dispatch(setBanks(response.data.banks));
  } catch(e) {
    throw e;
  }
}

export const setBanksWithStatements = (statements: BanksWithStatements[]): BankStatementTypes => ({ type: SET_BANK_STATEMENTS, payload: statements });

export const fetchBankStatements = (token: string): ThunkAction<Promise<void>, {}, {}, BankStatementTypes> => 
async (dispatch: ThunkDispatch<{}, {}, BankStatementTypes>): Promise<void> => {
  try {
    const response: AxiosResponse<FetchBanksWithStatementsResponse> = await axios.get(`${server}/banks/accountState`, { headers: { Authorization: `Bearer ${token}` } });
    dispatch(setBanksWithStatements(response.data.accountState));
  } catch(e) {
    throw e;
  }
}
interface FetchBanksResponse {
  banks: Banco[]
}

interface FetchBanksWithStatementsResponse {
  accountState: BanksWithStatements[]
}