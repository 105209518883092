import React, { useState } from 'react';
import { Card, Tabs } from 'antd';
import { State, States } from 'sigt';
import { connect } from 'react-redux';
import { useWindowDimensions } from '../../../utils/hooks';
import BenefitTribTaxes from './BenefitTaxPayer';
import Exonerations from './Exonerations/index';
import Discount from './Discount';

const { TabPane } = Tabs;

const BenefitTrib : React.FC<BenefitTribProps> = ({auth, thm}) => {
  const [tab,setTab] = useState('1')
  const { width } = useWindowDimensions();

  return (
    <Card style={{ height: '100%' }} title='Beneficios fiscales' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Tabs activeKey={tab} onChange={(key) => setTab(key)}>
        <TabPane style={{padding: 8}} tab='Remisión, convenios y descuentos' key="1">
          <BenefitTribTaxes/>
        </TabPane>
        {(auth.user?.institucion?.cargo?.id  === 24 || auth.user?.institucion?.cargo?.id === 35 || auth.user?.institucion?.cargo?.id === 90) &&
          <TabPane tab="Exoneraciones" key="2">
            <Exonerations/>
          </TabPane>}
        {(auth.user?.institucion?.cargo?.id  === 24 || auth.user?.institucion?.cargo?.id === 35 || auth.user?.institucion?.cargo?.id === 90) &&
          <TabPane tab="Descuentos por aforo" key="3">
            <Discount/>
          </TabPane>}
      </Tabs> 
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ auth: state.auth, thm: state.thm });

export default connect(mapStateToProps)(BenefitTrib);

interface BenefitTribProps {
  auth: States.Auth
  thm: States.ThemeColors
}