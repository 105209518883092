import React, { useState } from 'react';
import { States, State, Estate } from 'sigt';
import { Card, Form, Row, Col, Input, Select, Button, message, Collapse, Divider, Typography, Descriptions, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { useWindowDimensions } from '../../utils/hooks';
import { SearchOutlined, SaveFilled } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import { cloneDeep } from 'lodash';
const server = process.env.REACT_APP_SERVER_URL;

const TaxpayerServices: React.FC<TaxpayerServicesProps> = ({ thm, auth, parish }) => {
  const [tipoDocumento, setTipoDocumento] = useState('J');
  const [searching, setSearching] = useState(false);
  const [saving, setSaving] = useState(false);
  const [estates, setEstates] = useState<Estate[]>([]);
  const [activeKey, setActiveKey] = useState<string>('0');
  
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();

  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  const search = async () => {
    const values = await form.validateFields();
    const { rim, doc } = values;
    setSearching(true);
    try {
      const response = await axios.get(`${server}/services?tipoDocumento=${tipoDocumento}&documento=${doc}&referencia=${rim}`, { headers: { Authorization: `Bearer ${auth?.token}` } });
      const inmuebles = response.data.inmuebles;
      if(inmuebles.length === 0 || !inmuebles) message.error('El contribuyente no tiene inmuebles asociados.');
      else setEstates(response.data.inmuebles);
    } catch(e) {
      message.error(e.response?.data?.error || e.response?.data?.message || 'Error al buscar contribuyente');
    } finally {
      setSearching(false);
    }
  };

  const changeStatus = (id: number, checked: boolean) => {
    const _estates = cloneDeep(estates);
    const eIndex = _estates.findIndex(e => e.id === id);
    const _estate = _estates[eIndex];
    _estate.poseeGas = checked;
    _estates[eIndex] = _estate;
    setEstates(_estates);
  };
  
  const save = async () => {
    const inmuebles = estates.map(e => ({
      id: e.id,
      estado: e.poseeGas
    }));
    setSaving(true);
    try {
      await axios.put(`${server}/services/gas`, { inmuebles }, { headers: { Authorization: `Bearer ${auth?.token}` } });
      message.success('Cambios guardados de manera exitosa.');
    } catch(e) {
      message.error(e.response?.data?.error || e.response?.data?.message || 'Error al guardar cambios');
    } finally {
      setSaving(false);
    }
  };  

  const { Panel } = Collapse;

  return (
    <Card style={{ height: '100%' }} title='Servicios Municipales por Contribuyente' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
    headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 1200 ? '0 10px' : '0 20px', color: 'white'}}>
      {estates.length === 0 &&
      <Form form={form} layout='vertical' scrollToFirstError onFinish={save}>
        <Row gutter={24}>
          <Col xs={24} xl={7}>
            <Form.Item label='Documento de Identidad' name='doc' rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}>
              <Input placeholder='Documento de Identidad' addonBefore={tipoAddon} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={7}>
            <Form.Item label='R.I.M.' name='rim' rules={[{ required: true, message: 'Debe ingresar el registro municipal' }]}>
              <Input placeholder='R.I.M.' />
            </Form.Item>
          </Col>
          <Col xl={6} xs={12}>
            <Button onClick={() => search()} style={{ marginTop: width < 1200 ? 0 : 38, marginBottom: width < 1200 ? 20 : 0 }} loading={searching} icon={<SearchOutlined />} type='primary'>Buscar</Button>
          </Col>
        </Row>
      </Form>}
      {estates.length > 0 &&
      <>
        <Divider orientation='left' style={{ marginLeft: -10 }}>
          <Typography.Title level={4}>Inmuebles del Contribuyente</Typography.Title>
        </Divider>
        <Collapse accordion activeKey={activeKey} onChange={(e) => setActiveKey(e as string)}>
          {estates.map((e, k) => (
            <Panel header={`Código Catastral: ${e.codCat}`} key={k}>
              <Descriptions bordered column={6}>
                <Descriptions.Item span={3} label='Tipo de Inmueble'>{e.tipoInmueble}</Descriptions.Item>
                <Descriptions.Item span={3} label='Parroquia'>{parish.find(p => p.id === e.parroquia)?.nombre || 'N/A'}</Descriptions.Item>
                <Descriptions.Item span={6} label='Dirección'>{e.direccion}</Descriptions.Item>
                <Descriptions.Item span={3} label='Metros de Construcción'>{e.metrosConstruccion} m²</Descriptions.Item>
                <Descriptions.Item span={3} label='Metros de Terreno'>{e.metrosTerreno} m²</Descriptions.Item>
                <Descriptions.Item span={3} label='Fecha de Actualización'>{moment(e.fechaActualizacion).format('DD/MM/YYYY')}</Descriptions.Item>
                <Descriptions.Item span={3} label='Fecha de Creación'>{moment(e.fechaCreacion).format('DD/MM/YYYY')}</Descriptions.Item>
                <Descriptions.Item span={3} label='Tarifa de Aseo'>Bs. {formatCurrency(parseFloat(e.tarifaAseo))}</Descriptions.Item>
                <Descriptions.Item span={3} label='Tarifa de Gas'>Bs. {formatCurrency(parseFloat(e.tarifaGas))}</Descriptions.Item>
              </Descriptions>
              <Row style={{ marginTop: 15 }}>
                <Col>
                  <Checkbox onChange={(c) => changeStatus(e.id, c.target.checked)} checked={e.poseeGas}>El inmueble posee instalación de gas</Checkbox>
                </Col>
              </Row>
            </Panel>
          ))}
        </Collapse>
        <Button style={{ marginTop: 15 }} loading={saving} onClick={save} type='primary' icon={<SaveFilled />}>Guardar Cambios</Button>
      </>}
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth, parish: state.prsh.parish });

export default connect(mapStateToProps)(TaxpayerServices);

interface TaxpayerServicesProps {
  thm: States.ThemeColors
  auth: States.Auth
  parish: States.Parish['parish']
};