import React from "react";
import { Card, Result } from "antd";

const Document: React.FC = () => {
  return (
    <Card>
      <Result
        status="success"
        title="Documento Válido"
      />
    </Card>
  );
};

export default Document;
