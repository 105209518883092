import React from 'react';
import { Table, Popconfirm, Tooltip, Button, Typography} from 'antd';
import { BillItems } from 'sigt';
import { ColumnProps } from 'antd/lib/table';
import { DeleteOutlined } from '@ant-design/icons';

const {Text} = Typography;

const BillTable: React.FC<BillTableProps> = ({ data, handleDelete, setTotal }) => {

  const columns: ColumnProps<BillItems>[] = [
    {
      title: 'Ordenanza',
      dataIndex: 'ordenanza',
    },
    {
      title: 'Factor',
      dataIndex: 'factor',
      render: (factor) => factor ? factor : 'N/A'
    },
    {
      title: 'Valor',
      dataIndex: 'factorValue',
      render: (factorValue) => factorValue ? factorValue : 'N/A'
    },
    {
      title: 'Tarifa',
      dataIndex: 'petro'
    },
    {
      title: 'Costo Ordenanza',
      dataIndex: 'costoOrdenanza',
      render:(text)=> `${text} Bs`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    {
      title: 'Valor calculado',
      dataIndex: 'valorCalc',
      render:(text)=> `${text} Bs`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  ];
  if (handleDelete) columns.push({
    title: '',
    dataIndex: 'id',
    render:(id) => (
      <Popconfirm title="Estas seguro？" onConfirm={() => handleDelete(id)} okText="Si" cancelText="No">
        <Tooltip placement="bottom" title="Eliminar Item">
          <Button type="link" icon={<DeleteOutlined />} />
        </Tooltip>
      </Popconfirm>
    )
  });

  const handleSummary = items => {
    let auxtotalUtmm = 0;
    let auxtotalBs = 0;

    items.forEach(({ utmm, valorCalc }) => {
      auxtotalUtmm += utmm;
      auxtotalBs += valorCalc;
    });

   
    //FIXME: mardito riak que se queja porque actualizo desde aqui aaaaaaa
    if(setTotal) setTotal({totalPetro:auxtotalUtmm, totalBs:auxtotalBs })

    return (
      <tr>
        <th colSpan={5}><Text strong>Total</Text></th>
        <td>
          <Text strong type='danger'>{`${auxtotalBs} Bs`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
        </td>
      </tr>
    );
  };

  return (
    <Table<BillItems>
      rowKey={({ id }) => id}
      columns={columns}
      dataSource={data}
      pagination={false}
      bordered
      summary={handleSummary}
    />
  );
};

export default BillTable;

interface BillTableProps {
  data: BillItems[];
  handleDelete?: Function
  setTotal?: Function
}
