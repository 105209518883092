import React from 'react';
import { Select } from 'antd';
import { States, State } from 'sigt';
import { connect } from 'react-redux';

const ParishSelect: React.FC<ParishSelectProps> = ({ tipoTramite, cargo, parish, value, onChange, disabled = false }) => {
  return (
    <Select placeholder='Parroquia' value={value} onChange={onChange} disabled={disabled}>
      {parish.map((p) => (
        <Select.Option value={p.nombre} key={p.id}>
          {p.nombre}
        </Select.Option>
      ))}
    </Select>
  );
};

const mapStateToProps = (state: State) => ({ parish: state.prsh.parish });

export default connect(mapStateToProps)(ParishSelect);

interface ParishSelectProps {
  tipoTramite?: number;
  cargo?: number;
  parish: States.Parish['parish'];
  value?: any;
  onChange?: (s: any) => any;
  disabled?: boolean;
}
