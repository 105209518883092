import React, { useEffect, useState } from 'react';
import { Tabs, Card, Button, Row, Col, Select, Form, Spin, Input, Descriptions, message } from 'antd';
import { useWindowDimensions } from '../../../utils/hooks';
import { connect } from 'react-redux';
import { State, States } from 'sigt';
import moment from 'moment';
import MothsActivites from './MonthsActivites';
import _ from 'lodash';
import handlingMessage from '../../../utils/handlingMessage';
import { newRepair, getRepairs } from '../../../services/repairs';
import { Utils } from '../../../utils/validators';

const Fixes: React.FC<FixesProps> = ({thm, auth, ae}) => {
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const { width } = useWindowDimensions();
  const [years, setYears] = useState<number[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [year, setYear] = useState<number | null>(0);
  const [panes, setPanes] = useState<any[]>([]);
  const [active, setActive] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [load, setLoad] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const searchRepairs = async() => {
    setDisabled(true);
    const values = await form.validateFields();
    const data = {doc: values.documento, ref: values.rim, pref: values.tipoDocumento}
    handlingMessage({
      action: () => getRepairs(data, auth.token),
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: (data) => {
        if(data.status === 200){
          setYears(data.reparos.REP);
          setUsers(data.reparos.usuarios);
        }
        setDisabled(false);
      }
   });
  }
  
  const remove = (targetKey) => {
    if(panes.length > 1){
      let newActiveKey = active;
      let lastIndex
      panes.forEach((pane, i) => {
        if (`${pane.anio}` === targetKey) {
          lastIndex = i - 1;
        }
      })
      const newPanes = panes.filter(pane => `${pane.anio}` !== targetKey);
      if (newPanes.length && (newActiveKey === targetKey)) {
        newActiveKey = newPanes[lastIndex].anio;
      } else {
        newActiveKey = newPanes[0].anio;
      }
      setPanes(newPanes);
      if(newActiveKey !== active)setActive(`${newActiveKey}`);
    }
  };
  
  useEffect(() => {
    // initYear();
    form.setFieldsValue({tipoDocumento:'J'});
    // eslint-disable-next-line
  }, []);


  const handleSubmit = async() => {
    if(total > 0 ){
      setLoad(true);
      const values = await form.validateFields();
      const data = { rim: values.rim, documento: values.documento, tipoDocumento: values.tipoDocumento, reparo:{}, usuario:values.usuario, total: total};
      delete values.tipoDocumento;
      delete values.rim;
      delete values.documento;
      delete values.usuario;
      delete values.year;
      for(let index in values){
        for(let i in values[index]){
          const mes = values[index].length > 4 ? moment(`${parseInt(i)+1}`,'M').format('MMMM') : moment(`${(parseInt(i)+1) * 3}`,'M').format('MMMM');
          values[index][i] = values[index][i] ? (_.map(values[index][i], (ele) => ({...ele, mes, descripcion: ae.activities.find(e => e.id === ele.aforo)?.descripcion}))).filter(e => e.monto) : values[index][i];
        }
        for(let i in values){
          for(let j in values[i]){
            values[i][j] = values[i][j]?.length > 0 ?  values[i][j] : undefined;
          }
      }
        values[index] = values[index].filter(e => e !== undefined);
      }
      data.reparo = values;
      handlingMessage({
        action: () => newRepair(data, auth.token),
        key: 'submit',
        loadingMessage: 'Realizando operacion...',
        cb:(data) => {
        if(data.status === 201){
          form.resetFields();
          setTotal(0);
          setPanes([]);
          setUsers([]);
          setYears([]);
          setDisabled(false);
          if(data.solicitud.recibo){
            const win = window.open(data.solicitud.recibo, '_blank');
            win?.focus();
            }
          }
          setLoad(false);
        },
      });
  }else{
    message.warning({content:'El Total debe ser mayor a 0', duration:2})
  }
  }
  const docTypeSelect = (
    <Form.Item noStyle name='tipoDocumento' rules={[{ required: true, message: 'Debe ingresar el tipo de documento del contribuyente' }]}>
      <Select>
        <Select.Option value='J'>J</Select.Option>
        <Select.Option value='G'>G</Select.Option>
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='P'>P</Select.Option>
        
      </Select>
    </Form.Item>
  );
  const addPane = () => {
    const items:any[] = [];
    if(year){
      if(year <= 2018){
        while(items.length < 4){
          items.push({title:`Trimestre ${items.length + 1}`, mes: 3 * (items.length +1)});
          }
        } else {
          if(year === moment().year()){
            while(items.length < moment().month()){
              const mes = `${moment(items.length + 1, 'M').format('MMMM').toUpperCase()}`;
              items.push({mes, title: mes});
            }
          }else{
            while(items.length < 12){
              const mes = `${moment(items.length + 1, 'M').format('MMMM').toUpperCase()}`;
              items.push({mes, title: mes});
            }
          }
        }
        const pane = {anio:year, meses:items};
        setPanes([...panes, pane]);
        setYear(null);
        setActive(`${year}`);
    }
  }
  const onEdit = (targetKey, action) => {
    remove(targetKey);  
  };
  const handleChange = (year) => {
    change(year);
  }

  const change = (val) => {
    setYear(year !== val ? val : year);
  }
  return(
    <Card style={{ height: '100%' }} title='Reparos' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
     headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}}>
       <Form form={form} layout='vertical' onFinish={handleSubmit}>
        <Row gutter={16}>
          <Col xs={24} xl={10}>
            <Form.Item label='Documento de Identidad' name='documento' rules={[{ required: true, message: 'Debe ingresar el documento de identidad del contribuyente' }]}>
              <Input placeholder='Documento de Identidad' addonBefore={docTypeSelect} />
            </Form.Item>
          </Col>
          <Col xs={20} xl={10}>
            <Form.Item name='rim' label='R.I.M' normalize={Utils.normalize.toUpper} rules={[{ required: true, message: 'Debe ingresar un R.I.M' }]}>
              <Input placeholder='Referencia Municipal' />
            </Form.Item>
          </Col>
          <Col xs={4} xl={4} style={{alignSelf:'center', paddingTop:'15px'}}>
              <Button type='primary' disabled={disabled} onClick={()=> searchRepairs()}>Buscar</Button>
          </Col>         
        </Row>
        {years.length > 0  && (<Row gutter={[16,16]}>
          <Col xs={20} xl={10}>
            <Form.Item name='usuario' label='Usuario S.U.T del contribuyente' rules={[{ required: true, message: 'Debe ingresar un usuario' }]}>
              <Select placeholder='Seleccione un usuario'>{users.map((ele, index)=><Select.Option key={index} value={ele.id}>{ele.correo}</Select.Option>)}</Select>
            </Form.Item>
          </Col>
          <Col xs={20} xl={10}>
            <Form.Item name='year' label='Año'>
              <Select placeholder='Año' value={year ? year : undefined} onChange={(e)=> handleChange(e)}>{years.filter(e => !panes.map(e => e.anio).includes(e)).map((ele, index)=><Select.Option key={index} value={ele}>{ele}</Select.Option>)}</Select>
            </Form.Item>
          </Col>
          <Col xs={4} xl={4} style={{alignSelf:'center', paddingTop:'22px'}}>
            <Button onClick={addPane}>Añadir</Button>
          </Col>
        </Row>)}
          <Tabs
            style={{marginTop:'10px'}}
            hideAdd
            onChange={(e) => setActive(e)}
            activeKey={active}
            type="editable-card"
            onEdit={onEdit}
            >
            {panes?.map(pane => (
              <TabPane tab={pane.anio} key={pane.anio}>
                <Spin spinning={loading}>
                  <MothsActivites setLoading={()=>setLoading(false)} setTotal={setTotal} form={form} ele={pane.meses} date={pane.anio} />
                </Spin>
              </TabPane>
            ))}
          </Tabs>
          {panes.length > 0 && (
          <Row align='middle' justify='end'>
            <Col xl={12} xs={24} md={24}>
              <Descriptions bordered column={1} >
                <Descriptions.Item label='SubTotal' span={1}>{ new Intl.NumberFormat('de-DE', {minimumFractionDigits:2, maximumFractionDigits: 2}).format(total)}</Descriptions.Item>
                <Descriptions.Item label='Multa (30%)' span={1}>{ new Intl.NumberFormat('de-DE', {minimumFractionDigits:2, maximumFractionDigits: 2}).format(total*0.3)}</Descriptions.Item>
                <Descriptions.Item label='Total Bs' span={1}>{ new Intl.NumberFormat('de-DE', {minimumFractionDigits:2, maximumFractionDigits: 2}).format(total*1.3)}</Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>)}
          <Row align='middle' justify='end' style={{marginTop:'20px'}}>
            {panes.length > 0 && 
            <Col xs={9} xl={6} style={{textAlignLast:'end'}}>
              <Button type='primary' htmlType="submit" loading={load} >Procesar</Button>
            </Col>}   
          </Row>
        </Form>
      </Card>
  )
}
interface FixesProps {
  auth: States.Auth
  ae: States.AES
  thm: States.ThemeColors
}

const mapStateToProps = ({ thm, auth, ae }: State) => ({ thm, auth, ae })

export default connect(mapStateToProps)(Fixes);