import React, { useState, useRef, useEffect } from 'react';
import { State, States, SM } from 'sigt';
import { connect } from 'react-redux';
import { Table, Input, Button, Col, Typography, DatePicker, Divider } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Form, { FormInstance } from 'antd/lib/form';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment, { Moment } from 'moment';

const MunicipalServicesChange: React.FC<MunicipalServicesChangeProps> = ({ smActivities, form, thm, data, preloaded }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColum] = useState('');
  const [selected, setSelected] = useState<SM | null>(null);
  // const [disabled, setDisable] = useState<number[]>([])
  const searchInput = useRef<any>(null);

  useEffect(() => {
    if (preloaded) {
      if (preloaded.id_tarifa_aseo) {
        preloaded.id = preloaded.id_tarifa_aseo
      }
      setSelected(preloaded)
    }
  }, [preloaded]);

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Código',
      dataIndex: 'codigo',
    },
    {
      title: 'Nombre del Aforo',
      dataIndex: 'descripcion',
      ...getColumnSearchProps("descripcion")
    }
  ];

  // useEffect(()=>{
  //   const aux: any[] = []
  //   selected?.forEach((ele)=> {
  //     if (typeof _.find(preloaded, (e) => e.codigo === ele.codigo) !== 'object' ){
  //       aux.push(ele.id)
  //     }
  //   })
  //   setDisable(aux)
  //   // eslint-disable-next-line
  // }, [selected]);

  useEffect(() => {
    console.log("selected: ", selected)
  }, [selected])

  return (
    <>
      <Table columns={columns} dataSource={smActivities?.map((i, k) => {
        i.key = i.id;
        return i;
      }) || []}
        rowSelection={{
          selectedRowKeys: [selected?.id || 0],
          onSelect: (record) => record.id === selected?.id,
          type: 'radio',
          hideDefaultSelections: true,
          onChange: (r, v) => {
            console.log("r: ", r)
            console.log("v: ", v)
            form.setFieldsValue({ servicioMunicipal: v[0] });
            setSelected(v[0]);
          }
        }} />

      {selected &&
        <React.Fragment key={selected?.codigo}>
          <Divider style={{ marginLeft: -5 }} orientation='left'>
            <Typography.Text strong>{selected?.descripcion}&nbsp;</Typography.Text>
          </Divider>
          <Col xs={24} xl={6} style={{ marginLeft: 10 }}>
            <Form.Item name='desdeServicioMunicipal' label='Desde'
              rules={[{ required: true, message: 'Debe ingresar desde cuando se declara para dicha actividad económica' }]}>
              <DatePicker disabled={false} style={{ width: '100%' }} format='DD-MM-YYYY' placeholder='Desde' />
            </Form.Item>
          </Col>
        </React.Fragment>
      }
    </>
  );
};

const mapStateToProps = (state: State) => ({ smActivities: state.ae.smActivities, thm: state.thm });

export default connect(mapStateToProps)(MunicipalServicesChange);

interface MunicipalServicesChangeProps {
  smActivities: SM[]
  form: FormInstance
  thm: States.ThemeColors
  data: any
  preloaded?: IPreloaded
}

interface IPreloaded extends SM {
  desde: string | Moment
}