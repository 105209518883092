import React from 'react';
import { Card, Typography } from 'antd';
import { Chart, Line, Axis } from 'bizcharts';
import moment from 'moment';

const NoiceLineChart: React.FC<NoiceLineChartProps> = ({ data = fakeData, extra, loading }) => {
  const _data = [...data];
  const biggestValue = _data.sort((a, b) => (a.valor > b.valor ? -1 : b.valor > a.valor ? 1 : 0))[0];
  // const latestValue = _data.sort((a, b) => moment(a.fecha).isBefore(b.fecha) ? 1 : moment(b.fecha).isBefore(a.fecha) ? -1 : 0)[0];
  // const firstValue = _data.sort((a, b) => moment(a.fecha).isBefore(b.fecha) ? -1 : moment(b.fecha).isBefore(a.fecha) ? 1 : 0)[0];

  return (
    <Card bodyStyle={{ paddingTop: 10 }} loading={loading}>
      <Typography.Text strong style={{ display: 'inline-block', marginBottom: 15 }}>
        Total recaudado por mes
      </Typography.Text>{' '}
      <br />
      <Chart
        height={400}
        data={data.map((d) => ({ Monto: d.valor, Fecha: d.fecha }))}
        autoFit
        scale={{ Monto: { nice: true, min: 0, max: biggestValue?.valor || 0 + biggestValue?.valor || 0 / 3 } }}
      >
        <Axis name='Fecha' />
        <Axis name='Monto' label={{ formatter: (val) => `Bs. ${val}` }} />
        <Line position='Fecha*Monto' shape='smooth' />
        {/* {extra?.map((e, k) => 
          <React.Fragment key={k}>
            <Annotation.Region 
              start={[moment(e.fechaInicio, 'DD/MM').isBefore(moment(firstValue.fecha, 'DD/MM')) ? firstValue.fecha : e.fechaInicio, 0]}
              end={[moment().isBefore(moment(e.fechaFin, 'DD/MM')) || !e.fechaFin ? latestValue.fecha : e.fechaFin, biggestValue.valor]}
            />
            <Annotation.DataMarker 
              autoAdjust={false}
              position={[moment(e.fechaInicio, 'DD/MM').isBefore(moment(firstValue.fecha, 'DD/MM')) ? firstValue.fecha : e.fechaInicio, (1 + 0.05 * k) * biggestValue.valor]}
              text={{ content: e.descripcion }} 
              line={{ length: (5 * k) }} point={null}
            />
          </React.Fragment>  
        )} */}
      </Chart>
    </Card>
  );
};

export default NoiceLineChart;

interface NoiceLineChartProps {
  data?: {
    fecha: string;
    valor: number;
  }[];
  extra?: {
    fechaInicio: string;
    fechaFin: string;
    color: string;
    descripcion: string;
  }[];
  loading: boolean;
}

const randomNumber = (min: number, max: number) => Math.random() * (max - min) + min;

const fakeData = new Array(31).fill(true).map((_, i) => ({
  fecha: moment('08/01/2020').add(i, 'd').format('DD/MM'),
  valor: +(randomNumber(20000000000, 40000000000) / 1000000).toFixed(2),
}));
