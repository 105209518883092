import axios, { AxiosResponse } from "axios";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { HolidaysTypes, CREATE_HOLIDAY, SET_HOLIDAYS, DELETE_HOLIDAY } from "./actionsTypes";
import { Holiday } from "sigt";
const server = process.env.REACT_APP_SERVER_URL;


export const setHoliday = (holiday: Holiday): HolidaysTypes => ({ type: CREATE_HOLIDAY, payload: holiday });

export const setHolidays = (holidays: Holiday[]): HolidaysTypes => ({ type: SET_HOLIDAYS, payload: holidays });

export const createHoliday = (formValues, token: string): ThunkAction<Promise<void>, {}, {}, HolidaysTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, HolidaysTypes>): Promise<void> => {
    try {
        const value = {data:[{dia:formValues.fecha, descripcion: formValues.descripcion}]}
        const response: AxiosResponse = await axios.post(`${server}/holiday`, value, { headers: { Authorization: `Bearer ${token}` } } );
        dispatch(setHoliday(response.data?.holidays[0]));
        return response.data;
    } catch (error) {
      throw error;
    }
};

export const fetchHolidays = (token: string): ThunkAction<Promise<void>, {}, {}, HolidaysTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, HolidaysTypes>): Promise<void> => {
    try {
      const response: AxiosResponse = await axios.get(`${server}/holiday`, { headers: { Authorization: `Bearer ${token}` } });
      dispatch(setHolidays(response.data.holidays));
      return response.data;
    } catch (error) {
      throw error;
    }
};


export const deleteHoliday = (id, token: string): ThunkAction<Promise<void>, {}, {}, HolidaysTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, HolidaysTypes>): Promise<void> => {
    try {
        const response: AxiosResponse = await axios.delete(`${server}/holiday/${id}`, { headers: { Authorization: `Bearer ${token}` } } );
        dispatch({ type: DELETE_HOLIDAY, payload: id });
        return response.data;
    } catch (error) {
      throw error;
    }
};


