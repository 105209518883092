import React, { useState } from 'react';
import { Row, Col, Typography, Button } from 'antd';
import { ChartCard, MiniArea, MiniProgress, Pie, Bar } from 'ant-design-pro/lib/Charts';
import NumberInfo from 'ant-design-pro/lib/NumberInfo';
import 'moment/locale/es';
import '../../assets/css/components/Statistics.css';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { States, State } from 'sigt';
import { connect } from 'react-redux';

const Statistics: React.FC<StatisticsProps> = ({ stats, auth }) => {
  const [selected, setSelected] = useState('daily');
  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  const SEDEMAT = {
    totalCount: 'Total de solicitudes recibidas',
    monthCount: 'Solicitudes recibidas este mes',
    gainsCount: 'Solicitudes recibidas en el mes',
    lineCount: 'Total de liquidaciones vigentes',
    lineGraph: 'Liquidaciones por día',
    progressCount: 'Total de solicitudes pagadas',
    progress: 'Porcentaje de solicitudes pagadas',
    progressGains: 'Solicitudes pagadas en el mes',
    pieTitle: 'Total de recaudación por ramo en el día',
    insidePie: 'Liquidaciones',
    pieFormat: () => stats?.lineChart?.count,
    legendFormat: (value) => <span>Bs. {formatCurrency(value)}</span>,
    barTitle: 'Liquidaciones por tiempo'
  };

  const FINES = {
    totalCount: 'Total de multas asignadas',
    monthCount: 'Multas asignadas este mes',
    gainsCount: 'Multas asignadas en el mes',
    lineCount: 'Total de multas en proceso',
    lineGraph: 'Multas asignadas por día',
    progressCount: 'Total de multas pagadas',
    progress: 'Porcentaje de multas pagadas',
    progressGains: 'Multas pagadas en el mes',
    pieTitle: 'Multas según su estado',
    insidePie: 'Multas',
    pieFormat: () => stats?.totalGraph?.count,
    legendFormat: (value) => <span>{value}</span>,
    barTitle: 'Multas por tiempo'
  };

  const DEFAULT = {
    totalCount: 'Total de trámites recibidos',
    monthCount: 'Trámites recibidos este mes',
    gainsCount: 'Trámites recibidos en el mes',
    lineCount: 'Total de trámites por atender',
    lineGraph: 'Trámites recibidos por día',
    progressCount: 'Total de trámites completados',
    progress: 'Porcentaje de trámites completados',
    progressGains: 'Trámites completados en el mes',
    pieTitle: 'Trámites según su estado',
    insidePie: 'Trámites',
    pieFormat: () => stats?.totalGraph?.count,
    legendFormat: (value) => <span>{value}</span>,
    barTitle: 'Trámites por tiempo'
  };

  const getTitle = (prop: string, sum?: number[]) => {
    if(auth.user?.institucion?.nombreCorto === 'HACEINDA') return SEDEMAT[prop];
    if(auth.user?.institucion?.nombreCorto === 'IMA' || auth.user?.institucion?.nombreCorto === 'PMM') return FINES[prop];
    return DEFAULT[prop];
  };

  return (
    <Row gutter={12}>
      <Col xs={24} xl={8} style={{ marginTop: 0 }}>
        <ChartCard title={getTitle('totalCount')} total={stats?.totalGraph?.count || 'N/A'} contentHeight={100}>
          <NumberInfo
            subTitle={<span>{getTitle('monthCount')}</span>}
            total={stats?.totalGraph?.monthCount || 'N/A'}
            status='up'
            style={{ marginBottom: 10 }}
          />
          <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
            {getTitle('gainsCount')}: &nbsp;<span style={{ fontWeight: 'bolder' }}>{isNaN(stats?.totalGraph?.gains as unknown as number) ? '0' : stats?.totalGraph?.gains}%</span>&nbsp;
            {stats?.totalGraph?.gains && stats.totalGraph.gains > 0 ? <ArrowUpOutlined style={{ color: 'green' }} /> : <ArrowDownOutlined style={{ color: 'red' }} />}
          </span>
        </ChartCard>
      </Col>
      <Col xs={24} xl={8} style={{ marginTop: 0 }}>
        <ChartCard title={getTitle('lineCount')} total={stats?.lineChart?.count || 'N/A'} contentHeight={100}>
          <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{getTitle('lineGraph')}</span> 
          <MiniArea line data={stats?.lineChart?.graph || []} height={70} />
        </ChartCard>
      </Col>
      <Col xs={24} xl={8} style={{ marginTop: 0 }}>
        <ChartCard title={getTitle('progressCount')} total={stats?.progressChart?.count || 'N/A'} contentHeight={60}
        footer={
          <div style={{ height: 30 }}>
            <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
              {getTitle('progress')}: &nbsp;<span style={{ fontWeight: 'bolder' }}>{isNaN(stats?.progressChart?.gains as unknown as number) ? '0' : stats?.progressChart?.gains}%</span>&nbsp;
              {stats?.progressChart?.gains && stats.progressChart.gains > 0 ? <ArrowUpOutlined style={{ color: 'green' }} /> : <ArrowDownOutlined style={{ color: 'red' }} />}
            </span>
          </div>
        }> 
          <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{getTitle('progressGains')}:&nbsp;
            <span style={{ fontWeight: 'bolder' }}>
              {isNaN(stats?.progressChart?.percentage as unknown as number) ? '0' : stats?.progressChart?.percentage}%
            </span>
          </span>
          <MiniProgress percent={stats?.progressChart?.percentage || 0} strokeWidth={20} target={90} targetLabel='Objetivo' />
        </ChartCard>
      </Col>
      <Col xs={24} xl={auth.user?.institucion?.id === 9 ? 24 : 12} style={{ marginTop: 40 }}>
        <ChartCard title={getTitle('pieTitle')} contentHeight={220}>
          <Pie data={stats?.pieChart?.graph.slice(0, 7) || []} hasLegend valueFormat={getTitle('legendFormat')} height={200} padding={[0, 0, 0, 0]}
            total={() => <span>{stats?.totalGraph?.count ? getTitle('pieFormat')() : 'N/A'}</span>} subTitle={getTitle('insidePie')} tooltip />
          {auth.user?.institucion?.nombreCorto === 'HACIENDA' &&
          <Typography.Text strong>
            Total en el día: Bs. {formatCurrency(stats?.pieChart?.graph.slice(0, 7).map(g => g.y).reduce(((c, v) => c + v), 0) || 0)}
          </Typography.Text>}
        </ChartCard>
      </Col>
      <Col xs={24} xl={auth.user?.institucion?.id === 9 ? 24 : 12} style={{ marginTop: 40 }}>
        <ChartCard title={getTitle('barTitle')} contentHeight={220} footer={
          <div>
            <Typography.Text>Filtrar por:</Typography.Text>
            <Button style={{ margin: 7, marginLeft: 14 }} onClick={() => setSelected('daily')}>Días</Button>
            <Button style={{ margin: 7 }} onClick={() => setSelected('monthly')}>Meses</Button>
            <Button style={{ margin: 7 }} onClick={() => setSelected('yearly')}>Años</Button>
          </div>   
        }>
          <Bar data={stats?.barChart?.[selected] || []} height={200} title='' />
        </ChartCard>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: State) => ({ stats: state.st.stats, auth: state.auth });

export default connect(mapStateToProps)(Statistics);

interface StatisticsProps {
  stats: States.Stats['stats']
  auth: States.Auth
}