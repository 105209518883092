import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const getStatements = async (type: string, params: SearchParams, token: string | null) => {
  const urlParams = new URLSearchParams(params);
  const response = await axios.get(`${server}/alterations/ae/${type}?${urlParams}`, { headers: { Authorization: `Bearer ${token}` } });
  return response.data.liquidaciones;
};

export const declareSettlements = async (type: string, liquidaciones: any, token: string | null) => {
  await axios.put(`${server}/alterations/ae/${type}`, { liquidaciones }, { headers: { Authorization: `Bearer ${token}` } });
  return liquidaciones[0];
};

interface SearchParams extends Record<string, string> { 
  tipoDocumento: string
  documento: string
  referencia: string
}