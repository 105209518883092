import React from 'react';
import { Form, Row, Col, Input, Divider, Button, Typography, InputNumber, Select } from 'antd';
import { State, States } from 'sigt';
import { connect } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';

const Vehicles: React.FC<VehiclesProps> = ({ brands }) => {
  const firstListItem = (fields, add) => {
    if(fields.length === 0) {
      setTimeout(() => add(), 50);
    }
  };
  return (<Form.List name='vehiculos'>
    {(fields, { add, remove }) => (
      <div>
        {firstListItem(fields, add)}
        {fields.map((field, index) => (
          <React.Fragment key={field.key}>
            <Divider style={{ margin: 5, marginLeft: -5 }} orientation='left'>
              <Typography.Text strong style={{ marginLeft: 5 }}>
                Vehiculo {index + 1}
              </Typography.Text>
            </Divider>
            <Form.Item required key={field.key} style={{ width: '100%', margin: 0 }}>
              <Row gutter={24} style={{ padding: '0 20px' }}>
                <Col xs={24} xl={5}>
                  <Form.Item label='Marca' name={[field.name, 'marca']} rules={[{ required: true, message: 'Debe ingresar la marca del vehiculo!' }]}>
                    <Select>
                      {brands.map((b)=>(<Select.Option value={b.id}>{b.nombre}</Select.Option>))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} xl={5}>
                  <Form.Item label='Placa' name={[field.name, 'placa']} rules={[{ required: true, message: 'Debe ingresar la placa del vehiculo!' }]}>
                    <Input placeholder='Placa' />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={5}>
                  <Form.Item label='Modelo' name={[field.name, 'modelo']} rules={[{ required: true, message: 'Debe ingresar el modelo del vehiculo!' }]}>
                    <Input placeholder='Modelo' />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={5}>
                  <Form.Item label='Clase' name={[field.name, 'clase']} rules={[{ required: true, message: 'Debe ingresar la clase del vehiculo!' }]}>
                    <Input placeholder='Clase' />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={5}>
                  <Form.Item label='Tipo' name={[field.name, 'tipo']} rules={[{ required: true, message: 'Debe ingresar el tipo del vehiculo!' }]}>
                    <Input placeholder='Tipo' />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={5}>
                  <Form.Item label='Color' name={[field.name, 'color']} rules={[{ required: true, message: 'Debe ingresar el color del vehiculo!' }]}>
                    <Input placeholder='Color' />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={5}>
                  <Form.Item label='Uso' name={[field.name, 'uso']} rules={[{ required: true, message: 'Debe ingresar uso del vehiculo!' }]}>
                    <Input placeholder='Uso' />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={5}>
                  <Form.Item  label='Año' name={[field.name, 'ano']} rules={[{ required: true, message: 'Debe ingresar el año del vehiculo!' }]}>
                    <InputNumber style={{width: '100%'}} min={1900} max={moment().year()+1} />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={8}>
                  <Form.Item label='Serial de Carroceria' name={[field.name, 'serial']} rules={[{ required: true, message: 'Debe ingresar el serial del vehiculo!' }]}>
                    <Input placeholder='Serial de Carroceria' />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={7}>
                  <Form.Item label='Serial del Motor' name={[field.name, 'serialMotor']} rules={[{ required: true, message: 'Debe ingresar el serial del motor del vehiculo!' }]}>
                    <Input placeholder='Serial del Motor ' />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </React.Fragment>
        ))}
        <Row gutter={[16, 16]} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {fields.length > 1 && 
          <Col>
            <Button type='danger' onClick={() => remove(fields.length - 1)}>Eliminar Vehiculos</Button>
          </Col>}
          <Col>
            <Button type='dashed' onClick={() => add()}>Agregar Vehiculos</Button>
          </Col>
        </Row>
      </div>
    )}
  </Form.List>
  );
};

const mapStateToProps = (state: State) => ({ brands: state.vhc.brands });

export default connect(mapStateToProps)(Vehicles);

interface VehiclesProps {
  form: FormInstance
  brands: States.Vehicles['brands']
}