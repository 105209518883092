import React, { useEffect, useState, Fragment } from 'react';
import { Card, Form, Input, Button, Row, Col, Select, Modal, InputNumber } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { createOrdinance, editOrdinance } from '../../redux/actions/ordinance';
import { State, States, TramitesDisponibles } from 'sigt';
import { useLocation } from 'react-router';
import handlingMessage from '../../utils/handlingMessage';
import { useWindowDimensions } from '../../utils/hooks';
import { getOrdinancesVariables } from '../../services/ordinances';
import Petro from '../Icons/Petro';
import { Utils } from '../../utils/validators';

const OrdinanceForm: React.FC<OrdinanceFormProps> = ({ thm, auth, createOrdinance, editOrdinance, modal, ordc, inst }) => {
  const { Option } = Select;
  const history = useHistory();
  const location = useLocation();
  const params : any = useParams();
  const [form] = Form.useForm();
  const [type, setType] = useState<number>()
  const { width } = useWindowDimensions();
  const [procedures, setProcedures] = useState<TramitesDisponibles[]| undefined>([])
  const [variables, setVariables] = useState<any[]| undefined>([])
  //moods
  const editOrdenanza = location.pathname.includes('/dashboard/ordenanzas/editar');
  const createOrdenanza = location.pathname.includes('/dashboard/ordenanzas/crear');
  const dashboard = location.pathname.includes('/dashboard');
  const title = editOrdenanza ? 'Editar Ordenanza' : 'Crear Ordenanza';

  useEffect(() => {
    if (editOrdenanza && params?.id && (ordc.ordinances.length > 0)) {
      const ordinance = ordc.ordinances?.find(ele => ele.id === parseInt(params?.id));
      const tipo = ordinance?.utilizaCodcat ? 2 : ordinance?.utilizaVariable ? 3 : 1 ;
      setType(tipo)
      form.setFieldsValue(ordinance ? { ...ordinance , tipoOrdenanza:tipo } : {});
    } else if (editOrdenanza) {
      history.push('/dashboard/ordenanzas');
    }
    getVariables();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (auth.user?.tipoUsuario === 2) {
      const data = inst.institutions?.find(ele => ele.id === auth.user?.institucion?.id)?.tramitesDisponibles;
      setProcedures(data)
    }
    //eslint-disable-next-line
  }, [procedures]);

  const getVariables = async () => {
    if (auth.user?.tipoUsuario === 2) {
      const { variables } = await getOrdinancesVariables();
      setVariables(variables);
    }
  }

  useEffect(() => {
    form.setFieldsValue({price: ''})
    // eslint-disable-next-line
  }, [type])

  useEffect(() => {
    if (auth.token && !dashboard) {
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  }, [auth.token, location.pathname]);

  const renderWrapper = () => {
    if (modal) 
      return <Modal title={title} footer={null} width={600} visible centered style={{ maxHeight: "700px" }} onCancel={() => history.goBack()}>
        {content}
      </Modal>

    return <Card title={title || undefined} style={{ width: !dashboard ? 550 : undefined }} bodyStyle={{ padding: dashboard ? '30px 50px' : '24px' }} headStyle={dashboard ? { backgroundColor: thm.primaryColor, color: '#fff' } : {}}>
      {content}
    </Card>
  }

  const handleSubmit = async () => {
    const values = await form.validateFields();
    if (values.tipoOrdenanza === 2) values.utilizaCodcat = true;
    if (values.tipoOrdenanza === 3) values.utilizaVariable = true;
    handlingMessage({
      action: () =>
          editOrdenanza
          ? editOrdinance(values, auth.token, params.id)
          : createOrdinance(values, auth.token, auth.user?.tipoUsuario),
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: data => (data.status === 200) || (data.status === 201) ? history.push('/dashboard/ordenanzas') : null
    });
  };
  const aux = type ? (
    <Row gutter={16}>
      { type === 3 ? (
      <Col span={width > 700 ? 12 : 24}>
        <Form.Item name="idVariable">
          <Select placeholder='variable' disabled={editOrdenanza}>
            {variables?.map((variable) => {
              return (<Option key={variable.id} value={variable.id}>{variable.nombrePlural}</Option>)})}
          </Select>
        </Form.Item>
      </Col>) : null}
      <Col span={(type === 3 && width) > 700  ? 12 : 24}>
          <Form.Item rules={[{ required: true, message: 'Por favor ingresar precio!' }]} normalize={Utils.normalize.isNumber} name="precioPetro">
            <Row align='middle' style={{ flexWrap: 'nowrap' }}>
              <Col><Petro style={{ marginRight: 8 }} /></Col>
              <Col flex={1}><InputNumber style={{ width: '100%' }} /></Col>
            </Row>
          </Form.Item>
      </Col>
    </Row>) : null;

  const content = (
    <Form className="ordenance-form" onFinish={handleSubmit} form={form}>
      <Row gutter={16}>
        <Col span={width > 700 ? 12 : 24}>
          <Form.Item rules={[{ required: true, message: 'Por favor ingresar Nombre!' }]} name="nombreOrdenanza">
            <Input placeholder="Nombre de Ordenanza" disabled={editOrdenanza} />
          </Form.Item>
        </Col>
        <Col span={width > 700 ? 12 : 24}>
          <Form.Item rules={[{ required: true, message: 'Por favor ingresar tipo!' }]} name='tipoOrdenanza'>
            <Select placeholder="Tipo de Ordenanza" onChange={(value:number) => setType(value)} disabled={editOrdenanza}>
              <Option value={1}>Precio fijo</Option>
              <Option value={2}>Codigo catastral</Option>
              <Option value={3}>Variable</Option>
            </Select>
          </Form.Item>
        </Col>        
      </Row>
      <Row gutter={16}>
          <Col span={24}>
            <Form.Item rules={[{ required: true, message: 'Por favor ingresar Tramite!' }]} name='idTipoTramite' >
              <Select placeholder='Tramite' disabled={editOrdenanza}>
                {procedures?.map(procedure => {
                  return (<Option key={procedure.id} value={procedure.id}>{procedure.titulo}</Option>)
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      {aux}
      <Row justify="center" style={{ textAlign: 'center' }} align="middle" gutter={16}>
        <Col span={dashboard && !modal ? 6 : 12}>
          <Button style={{ width: '100%' }} type="primary" htmlType="submit">
            {!createOrdenanza ? `Guardar Datos` : 'Crear Ordenanza'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
  
  return <Fragment>{renderWrapper()}</Fragment>
};

const mapStateToProps = ({ thm, auth, ordc, inst }: State) => ({ thm, auth, ordc, inst });

export default connect(mapStateToProps, { createOrdinance, editOrdinance })(OrdinanceForm);

interface OrdinanceFormProps {
  createOrdinance: Function;
  editOrdinance: Function;
  auth: States.Auth;
  ordc: States.Ordenances;
  inst: States.Institutions;
  thm: States.ThemeColors;
  modal?: boolean;
}
