import React, { useState, useEffect, useRef } from 'react';
import { State, States } from 'sigt';
import { connect } from 'react-redux';
import axios, { AxiosResponse } from 'axios';
import { Card, Form, Row, Col, Button, Input, Divider, Typography, Tooltip, message } from 'antd';
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { useHistory, useLocation, useParams } from 'react-router';
import { useWindowDimensions } from '../utils/hooks';
import { Utils } from '../utils/validators';
import ParishSelect from './Forms/components/ParishSelect';
import { setProcedure, changeProcedureData } from '../redux/actions/procedures';
import pdfImage from '../assets/images/pdf.png';
import fileImage from '../assets/images/file.png';
const server = process.env.REACT_APP_SERVER_URL;

const UploadProperty: React.FC<TaxpayerRegisterProps> = ({ thm, procedures, auth, setProcedure, changeProcedureData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const view = location.pathname.includes('inmuebles/documentos/ver');
  const [fileList , setFileList] = useState<any[]>([]);
  const fileRef = useRef<any>(null);
  const params: any = useParams();
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    if(view) {
      const data = procedures.find(e => e.id === +params.id);
      if(data){
        form.setFieldsValue({ contribuyente: data?.datos.usuario?.contribuyente});
        setFileList(data?.recaudos ? data?.recaudos : [])
      }else{history.push('/dashboard/bandejas')}
    } else {
      const datos = {...auth.user?.contribuyente, email: auth.user?.nombreUsuario, telefonoMovil: auth.user?.telefono}
      form.setFieldsValue({ contribuyente: datos });
    }
    // eslint-disable-next-line
  }, []);

  const handlePreview = async(file) => {
    if(!file.url){
      const win = window.open(file, '_blank');
      win?.focus();
    }
  }

  const handleSubmit = async() => {
    const datos = await form.validateFields();
    setLoading(true);
    message.loading({ content: 'Realizando operacion...', key:'load', duration: 0 });
    if(!view){
      const fd = new FormData();
      if(fileList) Object.keys(fileList).forEach(e => fd.append('recaudos', fileList[e].file))
      const tramite = {
        datos,
        tipoTramite: 38,
      };
      const resp = await axios.post(`${server}/procedures/init`, { tramite }, { headers: { Authorization: `Bearer ${auth.token}` }});
      if(resp.status === 201) {
        setProcedure(resp.data.tramite);
        const response: AxiosResponse<TakingUploadResponse> = await axios.post(`${server}/uploads/takings/${resp.data.tramite.codigoTramite}`, fd, { headers: { Authorization: `Bearer ${auth.token}` }});
        if(response.status === 200) {
          message.destroy();
          message.success('Tramite creado exitosamente');
          form.resetFields();
          setFileList([]);
          history.push('/dashboard/bandejas');
        }
      }
    } else {
      const tramite = {
        datos,
        idTramite: +params?.id,
        aprobado: true,
      };
      const resp = await axios.put(`${server}/procedures/update`, { tramite }, { headers: { Authorization: `Bearer ${auth.token}` }});
      if(resp.status === 200) {
          message.destroy();
          message.success('Tramite actualizado exitosamente');
          changeProcedureData(resp.data.tramite.id, resp.data.tramite);
          form.resetFields();
          setFileList([]);
          history.push('/dashboard/bandejas');
    }
  }
  setLoading(false);
}

  const handleUpload = async(target) => {
    const url = await getBase64(target.files[0]);
    const data = { file:target.files[0], url}
    setFileList([...fileList, data])
  }

  const getBase64 = (file) =>  {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      if(file){
        const type = file.type.split('/')[1] === 'pdf' ? file.type.split('/')[1] : '';
        switch (type) {
          case 'pdf':
            reader.readAsDataURL(file);
            reader.onload = () => resolve(pdfImage);
            reader.onerror = error => reject(error);           
            break;          
          default:
            reader.readAsDataURL(file);
            reader.onload = () => resolve(fileImage);
            reader.onerror = error => reject(error);
            break;
        }
      }
    });
  }

  return (
    <Card style={{ height: '100%' }} title='Consignación de documentos del Inmueble' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}}>
      <Form form={form} layout='vertical'  style={{ padding: width < 992 ? '0 10px' : '0px 20px' }} onFinish={handleSubmit}>
        <React.Fragment>
          <Row gutter={24}>
            <Divider orientation='left' style={{ marginLeft: -20 }}>
              <Typography.Title level={4} style={{ marginLeft: 5 }}>
                Datos del Contribuyente
              </Typography.Title>
            </Divider>
            <Col xs={24} xl={8}>
              <Form.Item label='Nombre Completo' name={['contribuyente', 'razonSocial']} rules={[{ required: true, message: 'Debe ingresar el nombre completo' }]}>
                <Input readOnly={view} placeholder='Nombre Completo' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={5}>
              <Form.Item label='Correo electrónico' name={['contribuyente', 'email']} rules={[{ required: true, message: 'Debe ingresar el correo electrónico',  }, { validator: Utils.validators.email }]}>
                <Input readOnly={view} placeholder='Correo electrónico' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={5}>
              <Form.Item label={
                <span>
                  Teléfono&nbsp;
                  <Tooltip title='El numero teléfonico que ingrese será verificado mediante un mensaje de texto con un código de 6 dígitos'>
                    <QuestionCircleOutlined style={{ marginLeft: 2.5 }} />
                  </Tooltip>
                </span>
              } name={['contribuyente', 'telefonoMovil']} rules={[{ required: true, message: 'Debe ingresar el numero de teléfono móvil',  }, { validator: Utils.validators.phoneNumber }]} normalize={Utils.normalize.isNumber}>
                <Input readOnly={view} placeholder='Teléfono Móvil' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={5}>
              <Form.Item label='Teléfono Habitación' name={['contribuyente', 'telefonoHabitacion']} normalize={Utils.normalize.isNumber}>
                <Input readOnly={view} placeholder='Teléfono Habitación' />
              </Form.Item>
            </Col>
            <Divider orientation='left' style={{ marginLeft: -20 }}>
              <Typography.Title level={4} style={{ marginLeft: 5 }}>
                Datos de la Dirección
              </Typography.Title>
            </Divider>
            <Col xs={24} xl={10}>
              <Form.Item label='Dirección' name={['contribuyente', 'direccion']} rules={[{ required: true, message: 'Debe ingresar la dirección' }]}>
                <Input readOnly={view} placeholder='Dirección' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={7}>
              <Form.Item label='Parroquia' name={['contribuyente', 'parroquia']} rules={[{ required: true, message: 'Debe ingresar la Parroquia' }]}>
                <ParishSelect disabled={view} />
              </Form.Item>
            </Col>
            <Col xs={24} xl={7}>
              <Form.Item label='Sector' name={['contribuyente', 'sector']} rules={[{ required: true, message: 'Debe ingresar el sector' }]}>
                <Input readOnly={view} placeholder='Sector' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item label='Punto de Referencia' name={['contribuyente', 'puntoReferencia']} rules={[{ required: true, message: 'Debe ingresar el punto de referencia' }]}>
                <Input readOnly={view} placeholder='Punto de Referencia' />
              </Form.Item>
            </Col>
          </Row>
            <Divider orientation='left'><Typography.Title level={4}>Archivos Adjuntos</Typography.Title></Divider>
            {!view && 
              (<Row style={{marginLeft: 16}}>
                <input accept='image/*' onChange={({target}) => handleUpload(target)} ref={fileRef} type='file' style={{display:'none'}}/>
                <Button icon={<UploadOutlined />} onClick={()=> fileRef.current.click()}>Subir Archivo</Button>
              </Row>)}
            <Row style={{marginLeft: 8, marginTop:10}} gutter={[16, 16]}>
              {fileList.map((e, index) =>
              <Col xl={4} lg={8} md={8} sm={8} xs={12} key={index} >
                <Card
                  bodyStyle={{borderTop:'1px solid #00000026'}}
                  hoverable
                  onClick={()=> handlePreview(e)}
                  style={{ width: '100%'}}
                  cover={<img src={e?.url? e?.url : e.includes('pdf') ? pdfImage : fileImage} alt='failed' />}
                >{e?.file?.name ? e?.file?.name : e.includes('pdf') ? `Pdf ${index}` : `Imagen ${index}` }</Card>
              </Col>)}
            </Row>            
            <Row justify="start" style={{ textAlign: 'center' }} align="middle" gutter={16}>
              <Col>
                <Button onClick={() => history.goBack()}>Atras</Button>
              </Col>
              <Col>
                <Button type='primary' loading={loading} htmlType="submit">Procesar</Button>
              </Col>
            </Row>
        </React.Fragment>
      </Form>
    </Card>

  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, procedures: state.prcd.procedures, auth: state.auth });

export default connect(mapStateToProps, { setProcedure, changeProcedureData })(UploadProperty);

interface TaxpayerRegisterProps {
  thm: States.ThemeColors
  procedures: States.Procedures['procedures']
  auth: States.Auth
  setProcedure: Function
  changeProcedureData:(id:number, data:any) => void
};

interface TakingUploadResponse {
  recaudos: string[]
}
