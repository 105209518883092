import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const getBothOrdinances = async (CodCat:string, typeId:number) => {
  try {
    const responseType = await axios.get(`${server}/ordinance/${typeId}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    const responseCodCatType = await axios.get(`${server}/ordinance/${CodCat}/${typeId}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    return { 
      ordenanzas: [...responseCodCatType.data.ordenanzas,...responseType.data.ordenanzas],
      metrosConstruccion: responseCodCatType.data?.metrosConstruccion,
      status: responseCodCatType.data?.status
    }
  } catch (error) {
    throw error;
  }
};

export const getOrdinancesByType = async (typeId:number) => {
  try {
    const response = await axios.get(`${server}/ordinance/${typeId}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getOrdinancesCodCatByType = async (CodCatId:number,typeId:number) => {
  try {
    const response = await axios.get(`${server}/ordinance/${CodCatId}/${typeId}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getOrdinancesVariables = async () => {
  try {
    const response = await axios.get(`${server}/ordinance/variables`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};