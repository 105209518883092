import { States } from 'sigt';
import { ParishTypes, SET_PARISHES } from '../actions/actionsTypes';

const initialState: States.Parish = {
  parish: []
};

export default (state = initialState, action: ParishTypes): States.Parish => {
  switch(action.type) {
    case SET_PARISHES: return { parish: action.payload }
    default: return state;
  };
};