import { States } from "sigt";
import { AEType, SET_AES, SET_SM } from "../actions/actionsTypes";

const initialState: States.AES = {
  activities: [],
  smActivities: []
};

export default (state: States.AES = initialState, action: AEType) => {
  switch(action.type) {
    case SET_AES: 
      return {  ...state, activities: action.payload };

    case SET_SM:
      return { ...state, 
        smActivities: action.payload.map((row) => ({ ...row, id: row.id_tarifa_aseo}))
      }
    default: return state;
  }
};