import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const registerProperty = async (data, token) => {
  try {
    const response = await axios.post(`${server}/estates/sedemat`, data,{ headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getProperty = async (code, token) => {
  try {
    const response = await axios.get(`${server}/estates/sedemat/?codCat=${code}`,{ headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const editProperty = async (data, token) => {
  try {
    const response = await axios.patch(`${server}/estates/sedemat/estate`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const generateCodCat = async (data, token) => {
  try {
    const response = axios.post(`${server}/estates/generateCodCat`, data, {
      headers: {Authorization: `Bearer ${token}`},
    });
    return response;
  } catch(error) {
    throw error
  }
}

export const getDataIU = async (token) => {
  try {
    const response = await axios.get(`${server}/estates/`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data.data;
  } catch (error) {
    throw error;
  }
}