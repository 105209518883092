import { States } from "sigt";
import { ChargesTypes, EDIT_COLLECTION, SET_COLLECTIONS, SET_INSPECTION, SET_INSPECTIONS, SET_WALLETS, SET_WALLET_USER, UPDATE_INSPECTION } from "../actions/actionsTypes";

const intitialState: States.Charges = {
  wallets: [],
  collections: [],
  inspections: []
};

export default (state = intitialState, action: ChargesTypes): States.Charges => {
  switch(action.type) {
    case SET_WALLETS: return { ...state, wallets: action.payload };
    case SET_COLLECTIONS: return { ...state, collections: action.payload };
    case EDIT_COLLECTION: 
      const cIndex = state.collections.findIndex((o) => o.idCobranza === action.payload.idCobranza);
      state.collections[cIndex] = Object.assign({}, {...state.collections[cIndex], ...action.payload});
      return {...state, ...state.collections };
    case SET_WALLET_USER:
      const _wallets = [...state.wallets];
      const wIndex = _wallets.findIndex((o) => o.idCartera === action.payload.idCartera);
      _wallets[wIndex] = Object.assign({}, action.payload);
      return {...state, wallets: _wallets };
    case SET_INSPECTIONS : return {...state, inspections: action.payload };
    case SET_INSPECTION: return {...state, inspections: [action.payload, ...state.inspections] };
    case UPDATE_INSPECTION:
      const _inspections = [...state.inspections];
      const iIndex = _inspections.findIndex((o) => o.idFiscalizacion === action.payload.idFiscalizacion);
      _inspections[iIndex] = Object.assign({..._inspections[iIndex]}, action.payload);
      return {...state, inspections: _inspections };
    default: return state;
  }
};