import axios, { AxiosResponse } from "axios";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ChargesTypes, SET_WALLETS, SET_COLLECTIONS, SET_WALLET_USER, EDIT_COLLECTION, SET_INSPECTIONS, SET_INSPECTION, UPDATE_INSPECTION } from "./actionsTypes";
import { Wallets, Collections, Inspection } from "sigt";
const server = process.env.REACT_APP_SERVER_URL;

export const setWallets = (wallets: Wallets[]): ChargesTypes => ({ type: SET_WALLETS, payload: wallets });
export const setWalletUser = (data: Wallets): ChargesTypes => ({ type: SET_WALLET_USER, payload: data });
export const setCollections = (collections: Collections[]): ChargesTypes => ({ type: SET_COLLECTIONS, payload: collections });

export const fetchWallets = (token: string): ThunkAction<Promise<Response>, {}, {}, ChargesTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, ChargesTypes>): Promise<Response> => {
    try {
      const response: AxiosResponse<any> = await axios.get(`${server}/wallet`, { headers: { Authorization: `Bearer ${token}` } });
      dispatch(setWallets(response.data.data.cobranzas));
      return response.data.data;
    } catch (error) {
      throw error;
    }
};

export const fetchWallet = (token: string, id: number): ThunkAction<Promise<Response>, {}, {}, ChargesTypes> => async (
  dispatch: ThunkDispatch<{}, {}, ChargesTypes>
): Promise<Response> => {
    try {
      const response: AxiosResponse<any> = await axios.get(`${server}/wallet/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      dispatch(setCollections(response.data.data.cobranzas));
      return response.data;
    } catch (error) {
      throw error;
    }
};

export const updateWalletUser = (token: string, user: Wallets): ThunkAction<Promise<Response>, {}, {}, ChargesTypes> => 
async (dispatch: ThunkDispatch<{}, {}, ChargesTypes>): Promise<Response> => {
  try {
    const response: AxiosResponse<any> = await axios.patch(`${server}/wallet/${user.idCartera}`, { idUser: user.idUsuario }, { headers: { Authorization: `Bearer ${token}` } });
    dispatch(setWalletUser({...response.data.data.cobranzas[0], nombreCompleto: user.nombreCompleto, esAr: user.esAr}));
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const editCollection = (data: Collections): ChargesTypes => ({ type: EDIT_COLLECTION, payload: data });

export const updateCollection = (token: string, id:number, body): ThunkAction<Promise<Response>, {}, {}, ChargesTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, ChargesTypes>): Promise<Response> => {
    try {
      const response: AxiosResponse<any> = await axios.patch(`${server}/wallet/charging/${id}`, body, { headers: { Authorization: `Bearer ${token}` } });
      dispatch(editCollection({...response.data.data.cobranza, idCobranza:id}));
      return response.data.data;
    } catch (error) {
      throw error;
    }
};

export const getExcelWallet = async (token: string, id: number) => {
  try {
    const response: AxiosResponse<any> = await axios.get(`${server}/wallet/report/${id}`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const setInspections = (inspections: Inspection[]): ChargesTypes => ({ type: SET_INSPECTIONS, payload: inspections });
export const setInspection = (inspection: Inspection): ChargesTypes => ({ type: SET_INSPECTION, payload: inspection });
export const editInspection = (inspection: Inspection): ChargesTypes => ({ type: UPDATE_INSPECTION, payload: inspection });

export const fetchInspections = (token: string): ThunkAction<Promise<Response>, {}, {}, ChargesTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, ChargesTypes>): Promise<Response> => {
    try {
      const response: AxiosResponse<any> = await axios.get(`${server}/fiscalization/`, { headers: { Authorization: `Bearer ${token}` } });
      dispatch(setInspections(response.data.data.fiscalizaciones));
      return response.data.data;
    } catch (error) {
      throw error;
    }
};

export const createInspection = (token: string, taxPayer: { typeDoc: string, doc: number, rim: string}): ThunkAction<Promise<Response>, {}, {}, ChargesTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, ChargesTypes>): Promise<Response> => {
    try {
      const response: AxiosResponse<any> = await axios.post(`${server}/fiscalization/`, taxPayer, { headers: { Authorization: `Bearer ${token}` } });
      dispatch(setInspection(response.data.data.fiscalizacion));
      return response.data.data;
    } catch (error) {
      throw error;
    }
};

export const updateInspection = (token: string, inspection: Inspection): ThunkAction<Promise<Response>, {}, {}, ChargesTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, ChargesTypes>): Promise<Response> => {
    try {
      const response: AxiosResponse<any> = await axios.patch(`${server}/fiscalization/${inspection.idFiscalizacion}`, inspection, { headers: { Authorization: `Bearer ${token}` } });
      dispatch(editInspection({...response.data.data.fiscalizacion, idFiscalizacion: inspection.idFiscalizacion}));
      return response.data.data;
    } catch (error) {
      throw error;
    }
};