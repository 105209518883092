import { States } from "sigt";
import { HolidaysTypes, SET_HOLIDAYS, CREATE_HOLIDAY, DELETE_HOLIDAY } from "../actions/actionsTypes";

const intitialState: States.Holidays = {
  holidays: [],
};

export default (state = intitialState, action: HolidaysTypes):States.Holidays => {
  switch(action.type) {
    case SET_HOLIDAYS: return { ...state, holidays: action.payload };
    case CREATE_HOLIDAY: return {...state, holidays: [...state.holidays, action.payload] }
    case DELETE_HOLIDAY:
      const hlds = state.holidays ? state.holidays : [];
      return {
        ...state,
        holidays: hlds.filter(e => e.id !== action.payload)
      };
    default: return state;
  }
};