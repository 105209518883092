import axios, { AxiosResponse } from "axios";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { OrdinancesTypes, SET_ORDINANCES, DELETE_ORDINANCE, CREATE_ORDINANCE, EDIT_ORDINANCE } from "./actionsTypes";
import { Response, Ordenanza } from "sigt";
const server = process.env.REACT_APP_SERVER_URL;


export const setOrdinance = (ordinance: Ordenanza): OrdinancesTypes => ({ type: CREATE_ORDINANCE, payload: ordinance });

export const updateOrdinance = (ordinance: Ordenanza): OrdinancesTypes => ({type: EDIT_ORDINANCE, payload: ordinance }); 

export const createOrdinance = (formValues, token: string, id): ThunkAction<Promise<Response>, {}, {}, OrdinancesTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, OrdinancesTypes>): Promise<Response> => {
    try {
        const response: AxiosResponse = await axios.post(`${server}/ordinance`, formValues, { headers: { Authorization: `Bearer ${token}` } } );
        dispatch(setOrdinance(response.data?.ordenanza));
        return response.data;
    } catch (error) {
      throw error;
    }
};

export const editOrdinance = (formValues, token: string, id): ThunkAction<Promise<Response>, {}, {}, OrdinancesTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, OrdinancesTypes>): Promise<Response> => {
    try {
        const response: AxiosResponse = await axios.patch(`${server}/ordinance/${id}`, formValues, { headers: { Authorization: `Bearer ${token}` } } );
        dispatch(updateOrdinance(response.data?.ordenanza));
        return response.data;
    } catch (error) {
      throw error;
    }
};

export const setOrdinances = (ordinances: any[]): OrdinancesTypes => ({ type: SET_ORDINANCES, payload: ordinances });

export const fetchOrdinance = (token: string): ThunkAction<Promise<Response>, {}, {}, OrdinancesTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, OrdinancesTypes>): Promise<Response> => {
    try {
      const response: AxiosResponse<FetchOrdinancesResponse> = await axios.get(`${server}/ordinance`, { headers: { Authorization: `Bearer ${token}` } });
      dispatch(setOrdinances(response.data.ordenanzas));
      return response.data;
    } catch (error) {
      throw error;
    }
};


export const deleteOrdinance = (id, token: string): ThunkAction<Promise<Response>, {}, {}, OrdinancesTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, OrdinancesTypes>): Promise<Response> => {
    try {
        const response: AxiosResponse = await axios.delete(`${server}/ordinance/${id}`, { headers: { Authorization: `Bearer ${token}` } } );
        dispatch({ type: DELETE_ORDINANCE, payload: id });
        return response.data;
    } catch (error) {
      throw error;
    }
};

interface FetchOrdinancesResponse extends Response {
  ordenanzas: Ordenanza[];
}
