import React, { useState } from 'react';
import { Layout, Row, Col, Button } from 'antd';
import Alcaldia from '../assets/images/logo-alcaldia-white.png';
import { ReactComponent as AlcaldiaLogo } from '../assets/images/svg/5.svg';
import '../assets/css/layouts/ExternalLayout.css';
import { useWindowDimensions } from '../utils/hooks';
import { useLocation } from 'react-router-dom';
import img from '../assets/images/suite-logo-white.png';
import { ReactComponent as LogoPolicia } from '../assets/images/svg/logoPolicia.svg';

const { Content, Header } = Layout;

const ExternalLayout: React.FC = ({ children }) => {
  const { width } = useWindowDimensions();
  const location = useLocation();
  // const [visibleCard, setVisibleCard] = useState(false);

  return (
    <Layout id='main' style={{ height: '100vh' }}>
      <Header id='header' style={{ padding: `0 60px`, zIndex: 2, height: 100 }}>
        <Row gutter={60} align='stretch' justify={width < 480 ? 'center' : 'end'} style={{ flexWrap: 'nowrap' }}>
          {/* {location.pathname.includes('ingresar') && !visibleCard && (
            // <Row justify={width > 480 ? 'start' : 'center'} style={{ padding: 80, zIndex: 9999 }}>
            <Col span={12} style={{ zIndex: 999 }}>
              <Button
                type='primary'
                size='large'
                style={{
                  padding: 25,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 40,
                  zIndex: 99,
                }}
                onClick={() => setVisibleCard(!visibleCard)}
              >
                <span style={{ fontSize: 'xx-large', fontWeight: 'bold' }}>Iniciar Sesión</span>
              </Button>
            </Col>
            // </Row>
          )} */}
          {width < 480 ? null : (
            <Col span={12}>
              <div style={{ display: 'flex', justifyContent: 'space-around', margin: 20 }}>
                <div style={{ height: '90px', width: '90px' }}>
                  <LogoPolicia />
                </div>
                <div style={{ height: '320px', width: '320px' }}>
                  <AlcaldiaLogo />
                </div>
              </div>
            </Col>
          )}
        </Row>
      </Header>

      <Content style={{ display: 'flex', zIndex: 2, alignItems: 'center', backgroundColor: 'transparent' }}>
        {location.pathname.includes('ingresar') ? (
          <Row id='login-content' align='middle' justify={width > 480 ? 'start' : 'center'} gutter={8}>
            <Col style={{ marginLeft: width > 480 ? 50 : 0 }}>{children}</Col>
          </Row>
        ) : (
          <Row id='main-content'>{children}</Row>
        )}
        <div
          style={{ backgroundColor: 'black' }}
          onClick={() => {
            window.open('https://www.reportes.wakusoftware.com/sut.html', '_blank');
          }}
          className='burp-suite-logo'
        >
          <div
            style={{
              width: 68.2 * 3,
              height: 38.4 * 3,
              position: 'fixed',
              right: '4vw',
              bottom: 0,
            }}
          >
            <img src={img} alt='asd' width={'100%'} height={'100%'} style={{ cursor: 'pointer' }} />
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default ExternalLayout;
