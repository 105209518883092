import React, { useEffect, useState, useRef } from "react";
import { DeleteOutlined, EditOutlined, UserAddOutlined, SearchOutlined } from '@ant-design/icons';
import { Table, Divider, Tooltip, Button, Popconfirm, Card, Row, Input } from "antd";
import { connect } from "react-redux";
import { State, States, Response } from 'sigt';
import { useHistory } from "react-router-dom";
import { fetchOrdinance, deleteOrdinance } from '../../redux/actions/ordinance'
import { useWindowDimensions } from "../../utils/hooks";
import Highlighter from "react-highlight-words"
import handlingMessage from '../../utils/handlingMessage';
import { ColumnsType } from "antd/lib/table";
import Petro from "../Icons/Petro";

const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

const TableOrdinance: React.FC<PropsTable> = ({ thm, fetchOrdinance, auth, ordc, deleteOrdinance, coin }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColum] = useState('');
  const searchInput = useRef<any>(null);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const { width } = useWindowDimensions();
  const editOrd = (id) => {
    history.push(`/dashboard/ordenanzas/editar/${id}`);
  }; 
  const data: DataArray[] = [];

  const deleteOrd = (id) => {
    handlingMessage({
      action: () => (deleteOrdinance(id, auth.token)),
      key: "delete",
      loadingMessage: "Realizando operacion...",
      cb: (data) => {}
    });
  }

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined onClick={() => {}} style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };


  const columns: ColumnsType<any> = [
    {
      title: "Descripcion",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      ellipsis:true,
      width: '45%'
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
      ...getColumnSearchProps("price"),
      render: (price) => <Tooltip title={`${formatCurrency(+(Number(+price * coin.petro).toFixed(2)))} Bs.` } ><span style={{cursor: 'pointer'}} >{price}<Petro style={{ marginLeft: 8 }}/></span></Tooltip>
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
      ...getColumnSearchProps("type")
    },
    {
      title: "Acciones",
      key: "action",
      render: ({key}) => (
        <span>
          <Tooltip placement="bottom" title="Editar ordenanza">
            <Button type="link" icon={<EditOutlined />} onClick={() => editOrd(key)} />
          </Tooltip>
          <Divider type="vertical" />
          <Popconfirm title="Estas seguro？"
          onConfirm={() => deleteOrd(key)}
          okText="Si"
          cancelText="No">
            <Tooltip placement="bottom" title="Eliminar ordenanza">
              <Button type="link" icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
        </span>
      )
    }
  ] 
  



  useEffect(() => {
    aux();
    // eslint-disable-next-line
  }, [fetchOrdinance, auth.token]);

  const aux = async() => {
    if(auth.token && auth.user?.tipoUsuario) {
      await fetchOrdinance(auth.token, auth.user?.tipoUsuario);
    }
    setLoading(false);
  }
  
  ordc.ordinances?.map(ordinance => data.push({    
    key: ordinance.id,
    name: ordinance.nombreOrdenanza,
    price: ordinance.precioPetro,
    type: ordinance.utilizaCodcat ? 'Codigo catastral': ordinance.utilizaVariable ? ordinance.nombreVariable : 'Precio fijo'
  }))

    // useEffect(() => {
    //   setLoading(data.length > 0 ? false : true)
    // }, [data]);

  return (
    <Card style={{ height: '100%' }} title='Ver Ordenanzas' 
      headStyle={{ backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: "#fff" }} bodyStyle={{ overflowY: 'auto' }}>
      <Row gutter={[0,20]}><Button type ="primary" onClick={() => history.push('/dashboard/ordenanzas/crear')}icon={<UserAddOutlined />}>Crear Ordenanza</Button></Row>
      <Table
        style={{ height: '100%' }}
        size='middle'
        loading={loading}
        pagination={{ pageSize: 7 }}
        columns={columns}
        dataSource={data}
        scroll={{ x: 550 }}
      />
    </Card>
  );
};
const mapStateToProps = ({ auth, ordc, thm, coin }: State) => ({ auth, ordc, thm, coin });

export default connect( mapStateToProps, { fetchOrdinance, deleteOrdinance })(TableOrdinance);

interface PropsTable {
  fetchOrdinance: (token, id) => Promise<Response>;
  deleteOrdinance: (id, token) => Promise<Response>;
  auth: States.Auth;
  ordc: any;
  thm: States.ThemeColors
  coin: States.Coins
}

interface DataArray {
  key: number;
  price: string;
  name: string;
  type: string;
}
