import React, { useRef, useState, useEffect } from 'react';
import { Card, Row, Button, Table, Tooltip, Popconfirm, Input, DatePicker, Col } from 'antd';
import { States, State } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import { connect } from 'react-redux';
import { CalendarOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Highlighter from "react-highlight-words"
import { ColumnsType } from 'antd/lib/table';
import { deleteHoliday, fetchHolidays } from '../../redux/actions/holidays'
import moment, { Moment } from 'moment';
import handlingMessage from '../../utils/handlingMessage';

const TableBankCalendar: React.FC<TableBankCalendarProps> = ({thm, hlds, auth, deleteHoliday, fetchHolidays}) => {
  const { width } = useWindowDimensions();
  const history = useHistory();
  const data: any[] = []
  const searchInput = useRef<any>(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColum] = useState('');
  const [loading, setLoading] = useState(true);
  const [keys, setKeys] = useState<Moment[]>([]);
  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };
  const getColumnFilterDate = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      setKeys(selectedKeys);
      return(
      <Row style={{width:250, padding: 8}} gutter={[8,8]}>
        <Col span={24}>
          <DatePicker.RangePicker style={{width:'100%'}}  placeholder={['Fecha inicio ','Fecha fin']} size='small' 
            onChange={(values)=>{setSelectedKeys(values) }} value={selectedKeys} 
          />
        </Col>
        <Col span={12}>
          <Button style={{width:'100%'}} type='primary' icon={<SearchOutlined />} size='small' onClick={() => { confirm(); }}>
            Buscar
          </Button>
        </Col>
        <Col span={12} >
          <Button style={{width:'100%'}} onClick={() => clearFilters()} size='small'>
            Restablecer
          </Button>
        </Col>
      </Row> 
    )},
    filterIcon: filtered => (
      <CalendarOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) => {
      return moment(record[dataIndex]).utc().format('DD-MM-YYYY') === keys[0]?.utc().format('DD-MM-YYYY') 
        || moment(record[dataIndex]).utc().isBetween(keys[0]?.utc(), keys[1]?.utc(), undefined, '[]')
    },  
  });
  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined onClick={() => {}} style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const deleteHld = (id:number) => {
    handlingMessage({
      action: () => (deleteHoliday(id, auth.token ? auth.token :'')),
      key: "delete",
      loadingMessage: "Realizando operacion...",
      cb: (data) => {}
    });
  }

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };
  const columns: ColumnsType<any> = [
    {
      title: "Descripcion",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      ellipsis:true,
    },
    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "fecha",
      ...getColumnFilterDate("fecha"),
      render: (text) => moment.utc(text).format('DD/MM/YYYY')
    },
    {
      title: "Acciones",
      key: "action",
      render: ({key}) => (
        <span>
          <Popconfirm title="Estas seguro？"
          onConfirm={() => deleteHld(key)}
          okText="Si"
          cancelText="No">
            <Tooltip placement="bottom" title="Eliminar Fecha">
              <Button type="link" icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
        </span>
      )
    }
  ]
  useEffect(() => {
      aux();
    // eslint-disable-next-line
  }, [fetchHolidays, auth.token]);

  hlds.holidays?.map(holiday => data.push({    
    key: holiday.id,
    name: holiday.descripcion,
    fecha: holiday.dia
    }));

    const aux = async() => {
      if(auth.token && auth.user?.tipoUsuario) {
        await fetchHolidays(auth.token);
      }
      setLoading(false);
    }

    useEffect(() => {
      setLoading(data.length > 0 ? false : true)
    }, [data]);

  return (
    <Card
      style={{ width: '100%' }}
      bodyStyle={{ overflowY: 'auto' }}
      headStyle={{ backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: "#fff" }}
      title="Calendario Bancario" >
      <Row gutter={[0,20]}><Button type ="primary" onClick={() => history.push('/dashboard/fechaBancaria/crear')}icon={<CalendarOutlined />}>Nueva Fecha Bancaria</Button></Row>
      <Table
        style={{ height: '100%' }}
        size='middle'
        pagination={{ pageSize: 7 }}
        columns={columns}
        loading={loading}
        dataSource={data}
        scroll={{ x: 550 }}
        rowKey={e => e.id}
      />      
    </Card>
    );
};
const mapStateToProps = ({ thm, hlds, auth }: State) => ({ thm, hlds, auth });

export default connect(mapStateToProps, { deleteHoliday, fetchHolidays })(TableBankCalendar);

interface TableBankCalendarProps {
  thm: States.ThemeColors;
  hlds: States.Holidays;
  auth: States.Auth;
  fetchHolidays: (token: string) => Promise<void>;
  deleteHoliday: (id:number, token:string) => Promise<void>;
}
