import React, { useEffect, useState } from 'react';
import { States, State } from 'sigt';
import { connect, useSelector } from 'react-redux';
import { Card, Typography, Form, Divider, Select, Table, Row, Col, DatePicker, Button, Input, message, Descriptions } from 'antd';
import { useWindowDimensions } from '../utils/hooks';
import moment from 'moment';
import { useParams, useHistory } from 'react-router';
import { CreditCardOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { changeFineCertificate, changeFineStatus } from '../redux/actions/procedures';
import Axios from 'axios';
import VoucherUpload from './Forms/components/VoucherUpload';
import MultipleTransactions from './Forms/components/MultipleTransactions';
import MultipleWireTransfers from './Forms/components/MultipleWireTransfers';
import TooltipCoin from './TooltipCoin';
import { roundCalc } from './Taxes/PaySpecificTax';
import { cloneDeep } from 'lodash';
import { getFineForPayment } from '../services/fines';
const server = process.env.REACT_APP_SERVER_URL;

const PayFine: React.FC<PayFineProps> = ({ banks, thm, fines, inst, auth, changeFineStatus, changeFineCertificate }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const { width } = useWindowDimensions();
  const { idMulta } = useParams<any>();
  const [form] = Form.useForm();
  const history = useHistory();
  const [comprobantes, setComprobantes] = useState<any>([]);
  const [selectedFine, setSelectedFine] = useState<any>(null);

  const petro = useSelector((state: State) => state.coin.petro);
  const pesoBS = useSelector((state: State) => state.coin.peso);
  const dolar = useSelector((state: State) => state.coin.dolar);
  const euro = useSelector((state: State) => state.coin.euro);

  // const selectedFine = fines.find((f) => f.id === parseInt(idMulta || '0'));

  useEffect(() => {
    getFineForPayment(auth.token, idMulta)
      .then((res) => setSelectedFine(res))
      .catch((err) => {
        message.error(err?.message ?? 'Error al obtener datos de la multa para proceder al pago');
        console.log(err);
      });

    return () => {
      setSelectedFine(null);
    };
  }, []);

  const selectedInst = inst?.filter((i) => [9].includes(i.id));
  const cuentasBancarias =
    selectedInst?.reduce((prev, curr) => {
      if (curr?.cuentasBancarias) {
        return prev.concat(curr?.cuentasBancarias);
      } else {
        return prev;
      }
    }, [] as any) ?? [];

  const disabledDate = (current) => current > moment().endOf('day');

  const handleBankChange = (banco) => {
    form.setFieldsValue({
      banco,
    });
  };

  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(Number(number.toFixed(2)));

  const isProntoPago = () => {
    const fechaCreacion = moment(selectedFine?.fechaCreacion).locale('es');
    return moment().locale('es').diff(fechaCreacion, 'days') <= 30;
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();
    setLoading(true);
    Object.keys(values).forEach((k) => {
      if (moment.isMoment(values[k])) {
        values[k] = values[k].toISOString();
      }
    });
    const pagos = Array.isArray(form.getFieldValue('pagos')) ? cloneDeep(form.getFieldValue('pagos')) || [] : [];
    pagos
      .filter((p) => p)
      .forEach((p) => {
        Object.keys(p).forEach((k) => {
          if (moment.isMoment(p[k])) {
            p[k] = p[k].toISOString();
          }
        });
      });
    pagos.forEach((pago) => (pago.costo = pago.metodoPago === 'EFECTIVO PESO' ? pago.costo * pesoBS : pago.costo));
    pagos.forEach((pago) => (pago.costo = pago.metodoPago === 'EFECTIVO DOLAR' ? pago.costo * dolar : pago.costo));
    pagos.forEach((pago) => (pago.costo = pago.metodoPago === 'EFECTIVO EURO' ? pago.costo * euro : pago.costo));

    pagos.forEach((pago) => {
      if (pago?.metodoPago?.includes('EFECTIVO')) {
        delete pago.banco;
        delete pago.destino;
        delete pago.referencia;
      }
    });

    pagos.forEach((pago) => (pago.costo = +pago.costo.toFixed(2)));

    console.log(pagos);

    // const pago = {
    //   fecha: values.fecha,
    //   banco: values?.bancoOrigen || values.banco,
    //   destino: values?.bancoDestino || values.banco,
    //   referencia: values.referencia,
    //   costo: Number(selectedFine?.montoPetro ?? 0).toFixed(2),
    //   metodoPago: values.metodoPago,
    // };
    const tramite = {
      estado: 'ingresardatos',
      pago: pagos,
      idTramite: selectedFine?.id,
      tipoTramite: selectedFine?.tipoTramite,
      costo: isProntoPago()
        ? (Number(selectedFine?.montoPetro ?? 0) * petro * (1 - 0.3)).toFixed(2)
        : (Number(selectedFine?.montoPetro ?? 0) * petro).toFixed(2),
      montoPetro: isProntoPago() ? selectedFine?.montoPetro * (1 - 0.3) ?? 0 : selectedFine?.montoPetro ?? 0,
    };
    try {
      const response = await Axios.put(
        `${server}/fines/update`,
        { tramite },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );

      if (response.status === 200) {
        const fd = new FormData();
        if (comprobantes) {
          comprobantes.forEach((e) => fd.append('comprobantesMultas', e.file));
          const responseFile = await Axios.post(`${server}/uploads/comprobantesMultas/${idMulta}`, fd, {
            headers: { Authorization: `Bearer ${auth.token}` },
          });
          if (responseFile.status !== 200) message.error('Error al subir los comprobantes');
        }

        changeFineStatus(response.data.multa.id, response.data.multa.estado);
        if (response.data.multa.certificado) {
          changeFineCertificate(response.data.multa.id, response.data.multa.certificado);
        }
        message.success('Pago registrado de manera exitosa.');

        if (response.data.multa.certificado) {
          if (Array.isArray(response.data.multa.certificado)) {
            response.data.multa.certificado.forEach((cert) => {
              window.open(cert, '_blank');
            });
          } else {
            const win = window.open(response.data.multa.certificado, '_blank');
            win?.focus();
          }
        }

        setLoading(false);

        auth.user?.tipoUsuario === 4
          ? history.push('/dashboard/bandejas?tipo=multas')
          : history.push('/dashboard/multas/pendientes');
      }
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al procesar el pago.');
      setLoading(false);
    }
  };

  const addVoucher = (e) => {
    setComprobantes([...comprobantes, e]);
  };

  const removeVoucher = (e) => {
    let newComprobantes = comprobantes.filter((f) => f.file.name !== e);
    setComprobantes(newComprobantes);
  };

  const columns: any = [
    {
      title: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
      fixed: 'left',
    },
    {
      title: 'Numero de Cuenta',
      dataIndex: 'numeroCuenta',
      key: 'numeroCuenta',
    },
    {
      title: 'Titular',
      dataIndex: 'nombreTitular',
      key: 'nombreTitular',
    },
    {
      title: 'Documento de Identidad',
      dataIndex: 'documento',
      key: 'documento',
    },
  ];

  const externalPayment = (
    <Col xs={24} xl={8}>
      <Form.Item label='Banco' name='banco' rules={[{ required: true, message: 'Debe indicar el banco' }]}>
        {banks.length > 0 ? (
          <Select placeholder='Banco' onChange={handleBankChange}>
            {banks
              ?.filter((b) => cuentasBancarias?.map((c) => c.banco).includes(b.id))
              .map((b, i) => (
                <Select.Option key={`b-${b.id}`} value={b.id}>
                  {b.nombre}
                </Select.Option>
              ))}
          </Select>
        ) : (
          <div></div>
        )}
      </Form.Item>
    </Col>
  );

  const officialPayment = (
    <>
      <Col xl={8} xs={24}>
        <Form.Item
          label='Método de Pago'
          name={'metodoPago'}
          rules={[{ required: true, message: 'Debe indicar el método de pago' }]}
        >
          <Select placeholder='Método de Pago'>
            <Select.Option value='TRANSFERENCIA'>Transferencia</Select.Option>
            <Select.Option value='PUNTO DE VENTA'>Punto de Venta</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} xl={8}>
        <Form.Item
          label='Banco Origen'
          name='bancoOrigen'
          rules={[{ required: true, message: 'Debe indicar el banco de origen' }]}
        >
          {banks.length > 0 ? (
            <Select placeholder='Banco' onChange={handleBankChange}>
              {banks.map((b, i) => (
                <Select.Option key={`b-${b.id}`} value={b.id}>
                  {b.nombre}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <div></div>
          )}
        </Form.Item>
      </Col>
      <Col xs={24} xl={8}>
        <Form.Item
          label='Banco Destino'
          name='bancoDestino'
          rules={[{ required: true, message: 'Debe indicar el banco de destino' }]}
        >
          {banks.length > 0 ? (
            <Select placeholder='Banco' onChange={handleBankChange}>
              {banks
                ?.filter((b) => cuentasBancarias?.map((c) => c.banco).includes(b.id))
                .map((b, i) => (
                  <Select.Option key={`b-${b.id}`} value={b.id}>
                    {b.nombre}
                  </Select.Option>
                ))}
            </Select>
          ) : (
            <div></div>
          )}
        </Form.Item>
      </Col>
    </>
  );

  const descriptionsFine = (
    <Row style={{ padding: width < 992 ? '0 10px' : '0px 20px', marginBlock: '20px' }}>
      <Descriptions bordered>
        <Descriptions.Item label='Documento de Identidad'>
          {selectedFine?.nacionalidad ?? ''}-{selectedFine?.cedula ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label='Fecha'>
          {selectedFine?.fechaCreacion ? moment(selectedFine?.fechaCreacion).utc().format('DD/MM/YYYY') : 'Sin Fecha'}
        </Descriptions.Item>
        <Descriptions.Item label='Monto Bs'>{formatCurrency(Number(selectedFine?.montoPetro ?? 0) * petro)}</Descriptions.Item>
      </Descriptions>
    </Row>
  );

  const calcTotalIngresado = () =>
    data.pagos?.reduce((acc, p) => {
      if (!p) return 0;
      if (p.metodoPago === 'EFECTIVO PESO') {
        const amount = +p.costo * +pesoBS;
        return acc + amount;
      }
      if (p.metodoPago === 'EFECTIVO DOLAR') {
        const amount = +p.costo * +dolar;
        return acc + amount;
      }
      if (p.metodoPago === 'EFECTIVO EURO') {
        const amount = +p.costo * +euro;
        return acc + amount;
      }
      return acc + +p.costo;
    }, 0) || 0;

  const calcDiff = () =>
    Math.abs(
      +calcTotalIngresado() -
        (isProntoPago()
          ? +(Number(selectedFine?.montoPetro ?? 0) * petro * (1 - 0.3)).toFixed(2)
          : +(Number(selectedFine?.montoPetro ?? 0) * petro).toFixed(2))
    );

  const calcDiffSign = () =>
    +calcTotalIngresado() >=
    (isProntoPago()
      ? +(Number(selectedFine?.montoPetro ?? 0) * petro * (1 - 0.3)).toFixed(2)
      : +(Number(selectedFine?.montoPetro ?? 0) * petro).toFixed(2));

  return (
    <Card
      style={{ height: '100%' }}
      title='Pagar Multa'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Text strong>Codigo de Multa: {selectedFine?.codigoMulta}</Typography.Text>
        <Typography.Text strong>Fecha: {moment(selectedFine?.fechaCreacion).format('DD-MM-YYYY')}</Typography.Text>
      </div>
      <Row style={{ padding: width < 992 ? '0 10px' : '0px 20px' }}>
        <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
          <Typography.Title level={4}>Boleta de la Multa</Typography.Title>
        </Divider>
        <Button type='primary' icon={<CloudDownloadOutlined />} target='_blank' href={`${server}/fines/ticket/${idMulta}`}>
          Ver Boleta
        </Button>
      </Row>
      {selectedFine?.estado !== 'ingresardatos' && descriptionsFine}
      {selectedFine?.estado === 'ingresardatos' && (
        <Form
          form={form}
          layout='vertical'
          style={{ padding: width < 992 ? '0 10px' : '0px 20px' }}
          onFinish={handleSubmit}
          initialValues={{ metodoPago: 'TRANSFERENCIA' }}
          onValuesChange={(c, v) => {
            form.setFieldsValue(v);
            setData(v);
          }}
        >
          <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
            <Typography.Title level={4}>Datos de Pago</Typography.Title>
          </Divider>
          {isProntoPago() ? (
            <Typography.Text strong type='danger'>
              Por motivo de pronto pago se aplicara un descuento del 30% a la multa. La multa tiene un costo de Bs.{' '}
              {formatCurrency(Number(selectedFine?.montoPetro ?? 0) * petro * (1 - 0.3))} (equivalente a{' '}
              {formatCurrency(selectedFine?.montoPetro * (1 - 0.3))} MMV). El monto de la transferencia debe coincidir exactamente
              con el costo de la multa y debe ser realizada desde el mismo banco.
            </Typography.Text>
          ) : (
            <Typography.Text strong type='danger'>
              La multa tiene un costo de Bs. {+Number(selectedFine?.montoPetro ?? 0) * petro} (equivalente a{' '}
              {selectedFine?.montoPetro} MMV). El monto de la transferencia debe coincidir exactamente con el costo de la multa y
              debe ser realizada desde el mismo banco.
            </Typography.Text>
          )}
          <ul>
            {selectedFine?.datos?.funcionario?.infraccion?.map((f) => (
              <li>
                <Typography.Text strong type='danger' key={f?.numeral?.descripcion}>
                  {f?.numeral?.descripcion ?? ''}
                </Typography.Text>
              </li>
            ))}
          </ul>
          {/* <Table
            bordered
            style={{ margin: '20px 0px' }}
            pagination={false}
            columns={columns}
            dataSource={cuentasBancarias?.map((c) => ({
              ...c,
              documento: `${c.rif ? 'RIF: ' : 'Cedula: '}${c.rif || c.cedula}`,
              banco: banks?.find((b) => b.id === c.banco)?.nombre,
              key: c.banco + c.institutions,
            }))}
          /> */}
          {width >= 992 && <Col span={16} />}
          {auth.user?.tipoUsuario !== 4 ? (
            <MultipleTransactions selectedInstitution={selectedInst?.find((inst) => inst.id === 9)} form={form} data={data} />
          ) : (
            <MultipleWireTransfers
              showVoucher={true}
              addVoucher={(e) => addVoucher(e)}
              removeVoucher={(e) => removeVoucher(e)}
              selectedInstitution={selectedInst?.find((inst) => inst.id === 9)}
              form={form}
            />
          )}
          <Row>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Total Ingresado</Typography.Title>
            </Divider>
            <Col xl={24} xs={24}>
              <Descriptions bordered>
                <Descriptions.Item
                  label='Total Ingresado'
                  style={{
                    fontSize: '1.2rem',
                  }}
                >
                  <TooltipCoin priceBs={calcTotalIngresado()} dolarBs={dolar} pesoBs={pesoBS} euroBs={euro}>
                    Bs. {console.log('data: ', data) + '' === 'undefined' && calcTotalIngresado().toFixed(2)}
                  </TooltipCoin>
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Monto Restante</Typography.Title>
            </Divider>
            <Col xl={24} xs={24}>
              <Descriptions bordered>
                <Descriptions.Item
                  label={calcDiffSign() ? 'Total a Favor' : 'Total Restante'}
                  style={{
                    color: calcDiffSign() ? '#86E92B' : 'red',
                    fontSize: '1.2rem',
                  }}
                >
                  <TooltipCoin priceBs={calcDiff()} dolarBs={dolar} pesoBs={pesoBS} euroBs={euro}>
                    Bs. {calcDiff().toFixed(2)}
                  </TooltipCoin>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          {/* <Row gutter={24}> */}
          {/* {auth.user?.tipoUsuario !== 4 ? officialPayment : externalPayment} */}
          {/* <Col xs={24} xl={8}>
              <Form.Item
                label='Numero de Referencia'
                name='referencia'
                rules={[{ required: true, message: 'Por favor ingrese el numero de referencia.' }]}
              >
                <Input placeholder='Referencia' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item
                label='Fecha de Pago'
                name='fecha'
                rules={[{ required: true, message: 'Por favor ingrese la fecha de pago.' }]}
              >
                <DatePicker style={{ width: '100%' }} format={'DD-MM-YYYY'} disabledDate={disabledDate} />
              </Form.Item>
            </Col> */}
          {/* {auth.user?.tipoUsuario === 4 && (
              <Col xs={24} xl={24}>
                <VoucherUpload
                  showVoucher={true}
                  onUpload={(e) => {
                    addVoucher(e);
                  }}
                  onRemove={(e) => {
                    removeVoucher(e);
                  }}
                />
              </Col>
            )} */}
          {/* </Row> */}
        </Form>
      )}
      <Row
        gutter={12}
        style={{ marginTop: 20, padding: width < 992 ? '0 10px' : '0px 20px', display: 'flex', justifyContent: 'space-between' }}
      >
        <Col>
          <Button onClick={() => history.goBack()}>Atrás</Button>
        </Col>
        <Col>
          {selectedFine?.estado === 'ingresardatos' && (
            <Button
              type='primary'
              loading={loading}
              style={{ marginTop: 15 }}
              onClick={form.submit}
              icon={<CreditCardOutlined />}
            >
              {auth.user?.tipoUsuario === 4 ? 'Pagar' : 'Procesar Pago'}
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({
  banks: state.bk.banks,
  thm: state.thm,
  fines: state.prcd.fines,
  inst: state.inst.institutions,
  auth: state.auth,
});

export default connect(mapStateToProps, { changeFineStatus, changeFineCertificate })(PayFine);

interface PayFineProps {
  banks: States.Banks['banks'];
  thm: States.ThemeColors;
  fines: States.Procedures['fines'];
  inst: States.Institutions['institutions'];
  auth: States.Auth;
  changeFineStatus: (id: number, status: string) => void;
  changeFineCertificate: (id: number, certificate: string) => void;
}
