import { States } from 'sigt';
import { ThemeColorType, SET_PRIMARY_COLOR } from '../actions/actionsTypes';

const intitialState: States.ThemeColors = {
  primaryColor: '#0aa679',
};

export default (state = intitialState, action: ThemeColorType): States.ThemeColors => {
  switch (action.type) {
    case SET_PRIMARY_COLOR:
      return { primaryColor: action.payload };
    default:
      return state;
  }
};
