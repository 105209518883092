import { SET_TAXES, TaxesTypes, UPDATE_CERT, UPDATE_RECIBO, SET_TAX, CHANGE_TAX_DATA, AUTH_USER_LOGOUT } from "../actions/actionsTypes";
import { States } from "sigt";

const initialState: States.Taxes = {
  taxes: []
};

export default (state = initialState, action: TaxesTypes):States.Taxes => {
  switch(action.type) {
    case SET_TAXES: return { taxes: action.payload };
    case SET_TAX: return {  ...state, taxes: [action.payload, ...state.taxes]};
    case UPDATE_CERT: {
    const { id, certificado } = action.payload;
    const taxes = [...state.taxes];
    const tIndex = taxes.findIndex(p => p.id === id);
    const _tax = Object.assign({}, taxes[tIndex]);
    _tax.certificado = certificado;
    taxes[tIndex] = _tax;
    return {
      ...state,
      taxes
    }
    }
    case UPDATE_RECIBO: {
      const { id, recibo } = action.payload;
      const taxes = [...state.taxes];
      const tIndex = taxes.findIndex(p => p.id === id);
      const _tax = Object.assign({}, taxes[tIndex]);
      _tax.recibo = recibo;
      taxes[tIndex] = _tax;
      return {
        ...state,
        taxes
      }
      }
      case CHANGE_TAX_DATA: {
        const { id, data } = action.payload;
        const taxes = [...state.taxes];
        const tIndex = taxes.findIndex(p => p.id === id);
        taxes[tIndex] = Object.assign({}, data);;
        return {
          ...state,
          taxes
        }
      };
    case AUTH_USER_LOGOUT:  
      return initialState;
    default: return state;
  }
}