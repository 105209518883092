import React from 'react';
import { Row, Col, Descriptions, Typography, Table, Button, message } from 'antd';
import { DatosEnlace, Sucursal, Parroquia, State } from 'sigt';
import { ColumnsType } from 'antd/lib/table';
import { connect } from 'react-redux';

const RegistersSelect: React.FC<RegistersSelectProps> = ({ linkingData, selectedRegisters, setCurrent, setSelectedRegisters, parish }) => {
  const withKeys = linkingData?.sucursales
    .filter(s => s.datosSucursal?.registroMunicipal && s.datosSucursal?.registroMunicipal.trim() !== '')
    .map((i, k) => {
      i.key = k;
      return i;
    }) || [];


  const selectRims = () => {
    if(!linkingData) message.error('No posee datos para el enlace de la cuenta de SEDEMAT')
    else if(linkingData?.sucursales.filter(s => s.datosSucursal?.registroMunicipal && s.datosSucursal?.registroMunicipal.trim() !== '') 
      && linkingData?.sucursales.filter(s => s.datosSucursal?.registroMunicipal && s.datosSucursal?.registroMunicipal.trim() !== '').length > 0 
      && selectedRegisters.length <= 0) {
        message.error('Debe seleccionar por lo menos un registro municipal para actualizarle los datos.');
      }
    else setCurrent(c => c + 1);
  };

  const columns: ColumnsType<Sucursal> = [
    {
      title: 'R.I.M.',
      dataIndex: ['datosSucursal', 'registroMunicipal'],
    },
    {
      title: 'Razón Social',
      dataIndex: ['datosSucursal', 'razonSocial'],
    },
    {
      title: 'Representante',
      dataIndex: ['datosSucursal', 'nombreRepresentante'],
    }
  ];

  return (
    <Row gutter={24}>
      <Col span={24} style={{ marginTop: 20 }}>
        <Descriptions title='Datos del Contribuyente' bordered column={6}>
          <Descriptions.Item label='Razón Social' span={3}>{linkingData?.datosContribuyente.razonSocial || 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Documento de Identidad' span={3}>{linkingData?.datosContribuyente.documento ? 
            `${linkingData?.datosContribuyente.tipoDocumento}-${linkingData?.datosContribuyente.documento}` : 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label='Denominación Comercial' span={2}>{linkingData?.datosContribuyente.denomComercial || 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Siglas' span={2}>{linkingData?.datosContribuyente.siglas || 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Tipo de Contribuyente' span={2}>{linkingData?.datosContribuyente.tipoContribuyente || 'N/A'}</Descriptions.Item>
        </Descriptions>
      </Col>
      <Col span={24} style={{ marginTop: 15 }}>
        <Descriptions title='Ubicación' bordered column={6}>
          <Descriptions.Item label='Dirección' span={3}>{linkingData?.datosContribuyente.direccion || 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Parroquia' span={3}>{parish.find(p => p.id === linkingData?.datosContribuyente.parroquia)?.nombre || 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Sector' span={3}>{linkingData?.datosContribuyente.sector || 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Punto de Referencia' span={3}>{linkingData?.datosContribuyente.puntoReferencia || 'N/A'}</Descriptions.Item>
        </Descriptions>
      </Col>
      {linkingData?.sucursales.filter(s => s.datosSucursal?.registroMunicipal && s.datosSucursal?.registroMunicipal.trim() !== '') 
        && linkingData?.sucursales.filter(s => s.datosSucursal?.registroMunicipal && s.datosSucursal?.registroMunicipal.trim() !== '').length > 0 &&
      <React.Fragment>
        <Typography.Text strong style={{ marginLeft: 12, marginTop: 20, fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' }}>
          Registros de Información Municipal
        </Typography.Text>
        {/* <Typography.Text style={{ fontSize: 14, marginLeft: 16, marginRight: 16, marginTop: 5 }}>
          Por favor, seleccione los registros de información municipal a los cuales desea actualizarle los datos.&nbsp;
          Tenga en cuenta que para poder declarar y liquidar para dichos registros, éstos deben tener sus datos actualizados.
        </Typography.Text> */}
        <Typography.Text style={{ fontSize: 14, marginLeft: 16, marginRight: 16, marginTop: 5 }}>
          A continuación se muestran los registros municipales que van a ser importados al S.U.T.&nbsp;
        </Typography.Text>
        <Col span={24}>
          <Table columns={columns} dataSource={withKeys} bordered rowSelection={{ type: 'checkbox', onChange: () => {}, selectedRowKeys: withKeys.map(m => m.key) }} style={{ marginTop: 15  }} pagination={false} />
        </Col>
      </React.Fragment>}
      <Col span={24} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end' }}>
        <Button type='primary' onClick={() => selectRims()}>Siguiente</Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: State) => ({ parish: state.prsh.parish });

export default connect(mapStateToProps)(RegistersSelect);

interface RegistersSelectProps {
  linkingData: DatosEnlace | null
  selectedRegisters: Sucursal[]
  setCurrent: (s: any) => void
  setSelectedRegisters: (s: any) => void
  parish: Parroquia[]
}