import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Fine, State, States } from 'sigt';
import { Select, Tag, Form, Card, Col, Input, Row, Table, Button, message } from 'antd';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { useWindowDimensions } from '../utils/hooks';
import { CreditCardFilled, EyeFilled } from '@ant-design/icons/lib/icons';
import Axios from 'axios';
import { useHistory } from 'react-router';
const server = process.env.REACT_APP_SERVER_URL;

const PendingPayFine: React.FC<PendingPayFine> = ({ thm, auth }) => {
  const [fines, setFines] = useState<Fine[]>([]);
  const [tipoDocumento, setTipoDocumento] = useState('V');
  const [searching, setSearching] = useState(false);
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const history = useHistory();

  const PETRO = useSelector((state: State) => state.coin.petro);

  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  const canPay: number[] = [109];

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  const getState = (estado) => {
    if (estado === 'ingresardatos') return { name: 'En espera de pago', color: 'cyan' };
    else if (estado === 'validando') return { name: 'Validando pago', color: 'warning' };
    else return { name: 'Solvente', color: 'green' };
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Documento de Identidad',
      render: (_, r) => (
        <span>
          {r.nacionalidad}-{r.cedula}
        </span>
      ),
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (fecha) => (fecha ? <span>{moment(fecha).utc().format('DD/MM/YYYY')}</span> : 'Sin Fecha'),
    },
    {
      title: 'Monto (Bs.)',
      dataIndex: 'monto_petro',
      render: (montoPetro, record) => (
        <span>{`Bs. ${
          record.estado !== 'ingresardatos'
            ? formatCurrency(Number(record.monto))
            : formatCurrency(Number(montoPetro ?? 0) * PETRO)
        }`}</span>
      ),
    },
    {
      title: 'Monto MMV',
      dataIndex: 'monto_petro',
      render: (monto, record) => <span>{`${formatCurrency(+monto)}`}</span>,
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      render: (_, record) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={getState(record.estado).color}>
          {getState(record.estado).name}
        </Tag>
      ),
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      render: (id, record) => (
        <>
          {record.estado !== 'validando' && (
            <Button
              icon={
                (auth.user?.tipoUsuario === 4 || canPay.includes(auth.user?.institucion?.cargo?.id || 0)) &&
                record.estado === 'ingresardatos' ? (
                  <CreditCardFilled />
                ) : (
                  <EyeFilled />
                )
              }
              type='primary'
              onClick={() => history.push(`/dashboard/pagarMulta/${id}`)}
            >
              {(auth.user?.tipoUsuario === 4 || canPay.includes(auth.user?.institucion?.cargo?.id || 0)) &&
              record.estado === 'ingresardatos'
                ? 'Pagar'
                : 'Ver'}
            </Button>
          )}
        </>
      ),
    },
  ];

  const search = async () => {
    const values = await form.validateFields();
    try {
      const _tipoDocumento = /^\d+$/.test(values?.documento ?? 'null') ? tipoDocumento : 'V';
      setSearching(true);
      const response = await Axios.get(
        `${server}/fines/instances/usuario/payment?documento=${values.documento}&tipoDocumento=${_tipoDocumento}`,
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      console.log(response.data.fines);
      setFines(response.data.fines);
    } catch (e) {
      message.error(e?.response?.data?.message || 'Error al buscar contribuyente');
    } finally {
      setSearching(false);
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Multas Policía'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Form layout='vertical' form={form}>
        <Row gutter={24}>
          <Col xs={24} xl={7}>
            <Form.Item
              label='Documento de Identidad / Placa del Vehículo'
              name='documento'
              rules={[{ required: true, message: 'Debe ingresar el documento de identidad o placa del vehículo' }]}
            >
              <Input placeholder='Documento de Identidad / Placa del Vehículo' addonBefore={tipoAddon} />
            </Form.Item>
          </Col>
          <Col xl={3} xs={12}>
            <Button
              onClick={() => search()}
              style={{ marginTop: width < 1200 ? 0 : 38, width: '100%' }}
              loading={searching}
              icon={<SearchOutlined />}
              type='primary'
            >
              Buscar
            </Button>
          </Col>
          <Col span={24}>
            <Table
              style={{ marginTop: width < 1200 ? 38 : 0 }}
              columns={columns}
              dataSource={fines}
              rowKey='id'
              bordered
              loading={searching}
            />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({
  thm: state.thm,
  auth: state.auth,
});

export default connect(mapStateToProps)(PendingPayFine);

interface PendingPayFine {
  thm: States.ThemeColors;
  auth: States.Auth;
}
