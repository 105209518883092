import { States } from "sigt";
import { StatsType, SET_STATS, AUTH_USER_LOGOUT } from "../actions/actionsTypes";

const initialState: States.Stats = {
  stats: null
};

export default (state = initialState, action: StatsType): States.Stats => {
  switch(action.type) {
    case SET_STATS: return { stats: action.payload };
    case AUTH_USER_LOGOUT: return initialState;
    default: return state;
  }
}