import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const fetchDiscountTaxPayer = async ({document,docType,rim}) => {
  try {
    const response = await axios.get(`${server}/discounts/contributor/${docType}/${document}/${rim}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const createDiscountTaxPayer = async ({doc,typeDoc,from,branches,ref}) => {
  try {
    const response = await axios.post(`${server}/discounts/contributor`,{doc,typeDoc,ref,from,branches},{ headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchDiscountAE = async () => {
  try {
    const response = await axios.get(`${server}/discounts/activities`,{ headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const createDiscountAE = async ({from,activities}) => {
  try {
    const response = await axios.post(`${server}/discounts/activity`,{from,activities},{ headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateDiscountEndDate = async ({to,id}) => {
  try {
    const response = await axios.patch(`${server}/discounts/${id}`,{to},{ headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}
