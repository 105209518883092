import React from "react";
import { Result, Card } from "antd";

const ResultError: React.FC = () => {
  return (
    <Card>
      <Result
        status="500"
        title="Codigo de validación expirado o invalido"
        subTitle="Contacta con el administrador del sitio si crees que es un error"
      />
    </Card>
  );
};

export default ResultError;
