import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Select, Form, Input, Button, Divider, Typography, Checkbox } from 'antd';
import { State, States } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import { useLocation, useHistory } from 'react-router';
import { Utils } from '../../utils/validators';
import { setSupport, changeSupportData } from '../../redux/actions/procedures';
import ParishSelect from './components/ParishSelect';
import moment from 'moment';
import handlingMessage from '../../utils/handlingMessage';
import { generateCertificade } from '../../services/receipt';

const firstListItem = (fields, add) => {
  if (fields.length === 0) {
    add();
  }
};

const CertificadeForm: React.FC<SupportFormProps> = ({ thm, auth, prsh }) => {
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [renovacion, setRenov] = useState<boolean>(false);
  const [taxpayer, setTaxpayer] = useState<any | null>(null);
  const [contributors, setContributors] = useState<any[]>([]);
  const [data, setData] = useState<any | null>(null);
  const history = useHistory();
  const location = useLocation();
  const SM = location.pathname.includes('/dashboard/certificado/SM/generar');
  const IU = location.pathname.includes('/dashboard/certificado/IU/generar');
  const EL = location.pathname.includes('/dashboard/certificado/EL/generar');
  const SOLA = location.pathname.includes('/dashboard/certificado/SOLA/generar');
  const title = SM
    ? 'Solvencia de Servicios Municipales'
    : IU
    ? 'Solvencia de Inmueble Urbano'
    : SOLA
    ? 'Solvencia A'
    : 'Certificado de Licores';

  const getType = {
    LC: { horario: 'LUN. A DOM. DE ONCE AM A TRES AM (11:00 AM A 03:00 AM)', clasificacion: 'EXPENDIO DE CONSUMO' },
    LE: {
      horario:
        'LUN. A SAB. DE NUEVE AM A DIEZ PM (09:00 AM A 10:00 PM), Y DOMINGO DE NUEVE DE LA MAÑANA A CUATRO DE LA TARDE (9:00 AM A 4:00 PM)',
      clasificacion: 'EXPENDIO DE LICORES',
    },
    LD: { horario: 'LUN. A SAB. DE 06:00 A.M A 08:00PM (06:00 A.M A 08:00PM)', clasificacion: 'DISTRIBUCIÓN DE LICORES' },
  };

  useEffect(() => {
    if (!(history.location?.state as any)?.contribuyente && !EL) history.goBack();
    else setTaxpayer((history.location?.state as any)?.contribuyente);
    setContributors((history.location.state as any)?.contribuyentes);
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    const values = await form.validateFields();
    setLoading(true);
    const fechaLetra = `${moment().endOf('M').format('DD')} de ${moment().format('MMMM')} del ${moment().year()}.`;
    const mes = moment().format('MM');
    const anio = moment().format('YYYY');
    const horario = values.licencia && !renovacion ? getType[values.licencia]?.horario : getType[values.tipo]?.horario;
    const clasificacion =
      values.licencia && !renovacion ? getType[values.licencia]?.clasificacion : getType[values.tipo]?.clasificacion;
    const data = EL
      ? { funcionario: { ...values, fechaLetra, mes, anio, horario, clasificacion } }
      : { ...values, fechaLetra, mes, anio };
    const type = SM ? 'SM' : IU ? 'IU' : SOLA ? 'SOLA' : 'LIC';
    handlingMessage({
      action: () => generateCertificade(data, renovacion, type, auth.token),
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: (data) => {
        if (data.status === 200) {
          const win = window.open(data.url, '_blank');
          win?.focus();
          // form.resetFields();
          form.setFieldsValue({ tipoDocumento: 'J' });
        }
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    form.setFieldsValue({ tipoDocumento: 'J' });
    form.setFieldsValue({ tipoDocumentoRepresentante: 'V' });
    //eslint-disable-next-line
  }, []);

  const docTypeSelect = (
    <Form.Item noStyle name='tipoDocumento'>
      <Select>
        <Select.Option value='J'>J</Select.Option>
        <Select.Option value='G'>G</Select.Option>
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='P'>P</Select.Option>
      </Select>
    </Form.Item>
  );

  const docTypeSelectForList = (name) => {
    return (
      <Form.Item noStyle name={[name, 'tipoDocumento']}>
        <Select>
          <Select.Option value='J'>J</Select.Option>
          <Select.Option value='G'>G</Select.Option>
          <Select.Option value='V'>V</Select.Option>
          <Select.Option value='E'>E</Select.Option>
          <Select.Option value='P'>P</Select.Option>
        </Select>
      </Form.Item>
    );
  };

  const LIC = (
    <>
      <Divider orientation='left'>
        <Typography.Title level={4}>Datos del Solicitante</Typography.Title>
      </Divider>
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        <Col xs={24} xl={12}>
          <Form.Item
            label='RIM'
            name='referenciaMunicipal'
            rules={[{ required: true, message: 'Debe ingresar una Referencia Municipal' }]}
          >
            <Input placeholder='2900000000' />
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item
            label='RIF'
            name='documento'
            rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}
          >
            <Input placeholder='Documento de Identidad' addonBefore={docTypeSelect} />
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item
            label='Representante Legal'
            name='representanteLegal'
            rules={[{ required: true, message: 'Debe ingresar un representante legal' }]}
          >
            <Input placeholder='Representante Legal' />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation='left'>
        <Typography.Title level={4}>Datos del Establecimiento</Typography.Title>
      </Divider>
      <Row style={{ paddingLeft: 16, marginBottom: 16 }} gutter={16}>
        <Col xs={24}>
          <Checkbox value={renovacion} onChange={({ target }) => setRenov(target.checked)}>
            Renovación de licencia
          </Checkbox>
        </Col>
      </Row>
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        {renovacion ? (
          <>
            <Col xs={24} xl={8}>
              <Form.Item
                label='Codigo de Licencia'
                name='licencia'
                rules={[{ required: true, message: 'Debe ingresar el Tipo De Licencia' }]}
              >
                <Input placeholder='Codigo de Licencia' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item
                label='Tipo De Licencia'
                name='tipo'
                rules={[{ required: true, message: 'Debe ingresar el Tipo De Licencia' }]}
              >
                <Select placeholder='Tipo De Licencia'>
                  <Select.Option value='LC'>Licencia de consumo (LC)</Select.Option>
                  <Select.Option value='LE'>Licencia de expendio (LE)</Select.Option>
                  <Select.Option value='LD'>Licencia de distribución (LD)</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </>
        ) : (
          <Col xs={24} xl={8}>
            <Form.Item
              label='Tipo De Licencia'
              name='licencia'
              rules={[{ required: true, message: 'Debe ingresar el Tipo De Licencia' }]}
            >
              <Select placeholder='Tipo De Licencia'>
                <Select.Option value='LC'>Licencia de consumo (LC)</Select.Option>
                <Select.Option value='LE'>Licencia de expendio (LE)</Select.Option>
                <Select.Option value='LD'>Licencia de distribución (LD)</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col xs={24} xl={8}>
          <Form.Item
            label='Denominación Comercial'
            name='denomComercial'
            rules={[{ required: true, message: 'Debe ingresar una denominacion comercial' }]}
          >
            <Input placeholder='Denominación Comercial' />
          </Form.Item>
        </Col>
        <Col xs={24} xl={8}>
          <Form.Item
            label='Razón Social'
            name='razonSocial'
            rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}
          >
            <Input placeholder='Razón Social' />
          </Form.Item>
        </Col>
        <Col xs={24} xl={16}>
          <Form.Item label='Domicilio' name='domicilio' rules={[{ required: true, message: 'Por favor ingresar su domicilio!' }]}>
            <Input placeholder='Domicilio' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginLeft: 10, marginTop: 16 }}>
        <Col>
          <Button type='primary' loading={loading} htmlType='submit' style={{ width: '100%' }}>
            Procesar
          </Button>
        </Col>
        <Col>
          <Button onClick={() => history.goBack()}>Atras</Button>
        </Col>
      </Row>
    </>
  );

  const setDataInfo = (id) => {
    const data = taxpayer?.sucursales.find((el) => el.id === id);
    const datos = {
      contribuyente: data.denomComercial,
      cedulaORif: taxpayer.documento,
      tipoDocumento: taxpayer.tipoDocumento,
      rim: data.referenciaMunicipal,
      parroquia: prsh.parish.find((ele) => ele.id === data.parroquia)?.nombre,
      direccion: data.direccion,
    };
    setData(datos);
  };

  useEffect(() => {
    form.setFieldsValue(data);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (taxpayer) {
      const datos = {
        contribuyente: taxpayer.denomComercial,
        cedulaORif: taxpayer.documento,
        tipoDocumento: taxpayer.tipoDocumento,
        parroquia: prsh.parish.find((ele) => ele.id === taxpayer.parroquia)?.nombre,
        direccion: taxpayer.direccion,
      };
      form.setFieldsValue(datos);
    }
    // eslint-disable-next-line
  }, [taxpayer]);

  const content = (
    <>
      {taxpayer?.sucursales && (
        <>
          <Divider orientation='left'>
            <Typography.Title level={4}>Contribuyente</Typography.Title>
          </Divider>
          <Row style={{ paddingLeft: 16 }} gutter={16}>
            <Col xl={12} xs={24}>
              <Select placeholder='Contribuyente' onChange={(id) => setDataInfo(id)}>
                {taxpayer?.sucursales?.map((ele) => (
                  <Select.Option value={ele.id}>{`${ele.referenciaMunicipal} - ${ele.denomComercial}`}</Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </>
      )}
      <Divider orientation='left'>
        <Typography.Title level={4}>Datos del Certificado</Typography.Title>
      </Divider>
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        <Col xl={8} md={12} xs={24}>
          <Form.Item
            label='Contribuyente'
            rules={[{ required: true, message: 'Por favor ingresar la contribuyente!' }]}
            name='contribuyente'
          >
            <Input placeholder='Contribuyente' />
          </Form.Item>
        </Col>
        {!SOLA && (
          <Col xl={8} md={12} xs={24}>
            <Form.Item
              label='Documento'
              rules={[{ required: true, message: 'Por favor ingresar la documento!' }]}
              normalize={Utils.normalize.isDoc}
              name='cedulaORif'
            >
              <Input addonBefore={docTypeSelect} placeholder='Documento' />
            </Form.Item>
          </Col>
        )}
        <Col xl={8} md={24} xs={24}>
          <Form.Item label='R.I.M' normalize={Utils.normalize.toUpper} name='rim'>
            <Input placeholder='RIM' />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        {IU && (
          <Col xl={8} md={24} xs={24}>
            <Form.Item
              rules={[{ required: true, message: 'Por favor ingresar la dirección!' }]}
              name='valorFiscal'
              label='Valor Fiscal'
            >
              <Input type='number' placeholder='Valor Fiscal' />
            </Form.Item>
          </Col>
        )}
        <Col xl={8} md={24} xs={24}>
          <Form.Item rules={[{ required: true, message: 'Por favor ingresar parroquia!' }]} name='parroquia' label='Parroquia'>
            <ParishSelect />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        <Col flex={1}>
          <Form.Item label='Dirección' name='direccion'>
            <Input.TextArea maxLength={250} style={{ resize: 'none' }} placeholder='Dirección' />
          </Form.Item>
        </Col>
      </Row>
      {SOLA && (
        <>
          <Divider orientation='left'>
            <Typography.Title level={4}>Datos del Inmueble</Typography.Title>
          </Divider>
          <Form.List name='cedulaList'>
            {(fields, { add, remove }) => (
              <>
                <Row gutter={24} style={{ paddingLeft: '16px' }}>
                  {firstListItem(fields, add)}
                  {fields.map((field, index) => (
                    <Col xs={8}>
                      <Form.Item
                        key={index}
                        label='Documento'
                        rules={[{ required: true, message: 'Por favor ingresar la documento!' }]}
                        normalize={Utils.normalize.isDoc}
                        name={[field.name, 'cedulaORif']}
                      >
                        <Input addonBefore={docTypeSelectForList(field.name)} placeholder='Documento' />
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
                <Row gutter={[16, 16]} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {fields.length > 1 && (
                    <Col xs={6}>
                      <Button type='danger' onClick={() => remove(fields.length - 1)}>
                        Eliminar Propietario
                      </Button>
                    </Col>
                  )}
                  <Col xs={6}>
                    <Button type='dashed' onClick={() => add()}>
                      Agregar Propietario
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>

          <Form.List name='codCatList'>
            {(fields, { add, remove }) => (
              <>
                <Row gutter={24} style={{ paddingLeft: '16px' }}>
                  {firstListItem(fields, add)}
                  {fields.map((field, index) => (
                    <Col xs={10} key={index}>
                      <Form.Item label='Código Catastral' name={[field.name, 'codCat']}>
                        <Input maxLength={40} style={{ resize: 'none' }} placeholder='Código' />
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
                <Row gutter={[16, 16]} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {fields.length > 1 && (
                    <Col xs={6}>
                      <Button type='danger' onClick={() => remove(fields.length - 1)}>
                        Eliminar Cédula Catastral
                      </Button>
                    </Col>
                  )}
                  <Col xs={6}>
                    <Button type='dashed' onClick={() => add()}>
                      Agregar Cédula Catastral
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
          <Row style={{ paddingLeft: 16 }} gutter={24}>
            <Col flex={1} xs={8}>
              <Form.Item label='Monto de la Operación' name='montoOperacion'>
                <Input type={'number'} placeholder='Bs' />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ paddingLeft: 16 }} gutter={16}>
            <Col flex={1}>
              <Form.Item label='Dirección del Inmueble' name='direccionInmueble'>
                <Input.TextArea maxLength={250} style={{ resize: 'none' }} placeholder='Dirección' />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ paddingLeft: 16 }} gutter={16}>
            <Col flex={1} xl={8} xs={16}>
              <Form.Item label='Tipo del Inmueble' name='tipoInmueble'>
                <Input maxLength={40} style={{ resize: 'none' }} placeholder='Tipo' />
              </Form.Item>
            </Col>
            <Col flex={1} xl={8} xs={8}>
              <Form.Item label='Último Avalúo' name='avaluo'>
                <Input type={'number'} maxLength={40} style={{ resize: 'none' }} placeholder='Bolívares' />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <Row gutter={16} style={{ marginLeft: 10, marginTop: 16 }}>
        <Col>
          <Button type='primary' loading={loading} htmlType='submit' style={{ width: '100%' }}>
            Procesar
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() =>
              EL
                ? history.goBack()
                : history.push(SM ? '/dashboard/certificado/SM' : '/dashboard/certificado/IU', { contribuyentes: contributors })
            }
          >
            Atras
          </Button>
        </Col>
      </Row>
    </>
  );

  return (
    <Card
      style={{ height: '100%' }}
      title={title}
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Form form={form} onFinish={handleSubmit} layout='vertical'>
        {EL ? LIC : content}
      </Form>
    </Card>
  );
};

interface SupportFormProps {
  auth: States.Auth;
  thm: States.ThemeColors;
  prsh: States.Parish;
}

const mapStateToProps = ({ thm, auth, prsh }: State) => ({ thm, auth, prsh });

export default connect(mapStateToProps, { setSupport, changeSupportData })(CertificadeForm);
