import axios, { AxiosResponse } from 'axios';
import { Brand, Vehicle, VehicleType } from 'sigt';
import { VehiclesType, SET_BRANDS, SET_TYPES, SET_VEHICLES, SET_VEHICLE, DELETE_VEHICLE, UPDATE_VEHICLE } from './actionsTypes';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
const server = process.env.REACT_APP_SERVER_URL;

export const setBrands = (brands: Brand[]): VehiclesType => ({ type: SET_BRANDS, payload: brands });
export const setTypes = (types: VehicleType[]): VehiclesType => ({ type: SET_TYPES, payload: types });
export const setVehicles = (vehicles: Vehicle[]): VehiclesType => ({ type: SET_VEHICLES, payload: vehicles });
export const setVehicle = (vehicle: Vehicle): VehiclesType => ({ type: SET_VEHICLE, payload: vehicle });
export const removeVehicle = (id: number): VehiclesType => ({ type: DELETE_VEHICLE, payload: id});
export const actualizeVehicle = (vehicle: Vehicle): VehiclesType => ({ type: UPDATE_VEHICLE, payload: vehicle});


export const fetchBrands = (): ThunkAction<Promise<void>, {}, {}, VehiclesType> =>
  async (dispatch: ThunkDispatch<{}, {}, VehiclesType>): Promise<void> => {
    try {
      const response: AxiosResponse = await axios.get(`${server}/vehicles/brands`,{ headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      dispatch(setBrands(response.data.marcas));
    } catch(e) {
      throw e;
    }
  };

export const getVehicleTypes = (): ThunkAction<Promise<void>, {}, {}, VehiclesType> =>
  async (dispatch: ThunkDispatch<{}, {}, VehiclesType>): Promise<void> => {
    try {
      const response: AxiosResponse = await axios.get(`${server}/vehicles/types`,{ headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      dispatch(setTypes(response.data.tipoVehiculo));
    } catch(e) {
      throw e;
    }
  };


export const fetchVehicles = (): ThunkAction<Promise<void>, {}, {}, VehiclesType> =>
  async (dispatch: ThunkDispatch<{}, {}, VehiclesType>): Promise<void> => {
    try {
      const response: AxiosResponse = await axios.get(`${server}/vehicles`,{ headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      dispatch(setVehicles(response.data.vehiculos));
    } catch(e) {
      throw e;
    }
  };
  
export const registerVehicleInternal = 
(vehicle, id: number | undefined, isRim: boolean | undefined): ThunkAction<Promise<any>, {}, {}, VehiclesType> =>
async (dispatch: ThunkDispatch<{}, {}, VehiclesType>): Promise<any> => {
  try {
    const url = isRim ? 'internal/rim' : 'internal'
    const response: AxiosResponse = await axios.post(
      `${server}/vehicles/${url}`,
      { vehiculo: vehicle, id },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );
    dispatch(setVehicle(response.data.vehiculo));
    return response.data;
  } catch (e) {
    throw e;
  }
};


  
export const registerVehicle = (vehicle): ThunkAction<Promise<any>, {}, {}, VehiclesType> =>
  async (dispatch: ThunkDispatch<{}, {}, VehiclesType>): Promise<any> => {
    try {
      const response: AxiosResponse = await axios.post(`${server}/vehicles`, { vehiculo: vehicle }, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      dispatch(setVehicle(response.data.vehiculo));
      return response.data;
    } catch(e) {
      throw e;
    }
  };


export const deleteVehicle = (id: number): ThunkAction<Promise<any>, {}, {}, VehiclesType> =>
  async (dispatch: ThunkDispatch<{}, {}, VehiclesType>): Promise<any> => {
    try {
      const response: AxiosResponse = await axios.post(`${server}/vehicles/unlink/`,{idVehiculo:id}, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      dispatch(removeVehicle(id));
      return response.data;
    } catch(e) {
      throw e;
    }
  };

export const linkVehicle = (data): ThunkAction<Promise<any>, {}, {}, VehiclesType> =>
  async (dispatch: ThunkDispatch<{}, {}, VehiclesType>): Promise<any> => {
    try {
      const response: AxiosResponse = await axios.post(`${server}/vehicles/link/`, data , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      dispatch(setVehicle(response.data.vehicle));
      return response.data;
    } catch(e) {
      throw e;
    }
  };

export const updateVehicle = (id: number, vehicle): ThunkAction<Promise<any>, {}, {}, VehiclesType> =>
  async (dispatch: ThunkDispatch<{}, {}, VehiclesType>): Promise<any> => {
  try {
    const response: AxiosResponse = await axios.put(`${server}/vehicles/${id}`,{vehiculo: vehicle}, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    dispatch(actualizeVehicle(response.data.vehiculo));
    return response.data;
  } catch(e) {
    throw e;
  }
};