import axios, { AxiosResponse } from "axios";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { CostTypes, SET_COST, SET_DESTINATION_COSTS, EDIT_DESTINATION_COST, CREATE_DESTINATION_COST } from "./actionsTypes";
import { Destination } from "sigt";
const server = process.env.REACT_APP_SERVER_URL;


export const editCost = (formValues, token: string, id): ThunkAction<Promise<Response>, {}, {}, CostTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, CostTypes>): Promise<Response> => {
    try {
        const data = {costo: formValues.cost}
        const response: AxiosResponse = await axios.patch(`${server}/procedures/${id}`, data, { headers: { Authorization: `Bearer ${token}` } } );
        return response.data;
      } catch (error) { 
        throw error;
      }
};

export const setCost = (costs: any[]): CostTypes => ({ type: SET_COST, payload: costs });

export const fetchCost = (token: string, id): ThunkAction<Promise<Response>, {}, {}, CostTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, CostTypes>): Promise<Response> => {
    try {
      const response: AxiosResponse<FetchCostResponse> = await axios.get(`${server}/procedures/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      // dispatch(setCost(response?.data?.options ? response.data.options : undefined));
      dispatch(setCost(response.data.options[0].tramitesDisponibles));
      return response.data;
    } catch (error) {
      throw error;
    }
};

export const setDestinationCosts = (costs: Destination[]): CostTypes => ({ type: SET_DESTINATION_COSTS, payload: costs });

export const fetchDestinationCosts = (token: string): ThunkAction<Promise<void>, {}, {}, CostTypes> =>
  async (dispatch: ThunkDispatch<{}, {}, CostTypes>): Promise<void> => {
    try {
      const response: AxiosResponse<FetchDestinationCostsResponse> = await axios.get(`${server}/destination`, { headers: { Authorization: `Bearer ${token}` } });
      dispatch(setDestinationCosts(response.data.destinos));
    } catch(e) {
      throw e;
    }
  }

export const updateAllDestinationCosts = (value: {tasa: number}): ThunkAction<Promise<Response>, {}, {}, CostTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, CostTypes>): Promise<Response> => {
    try {
        const response: AxiosResponse = await axios.patch(`${server}/destination/`, value, 
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } } );
        dispatch(setDestinationCosts(response.data?.destino));
        return response.data;
    } catch (error) {
      throw error;
    }
};

export const editDestinationCost = (destination: Destination): CostTypes => ({type: EDIT_DESTINATION_COST, payload: destination});

export const updateDestinationCost = (formValues: Destination): ThunkAction<Promise<Response>, {}, {}, CostTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, CostTypes>): Promise<Response> => {
    try {
        const response: AxiosResponse = await axios.patch(`${server}/destination/${formValues.id}`, formValues, 
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } } );
        dispatch(editDestinationCost(response.data?.destino));
        return response.data;
    } catch (error) {
      throw error;
    }
};

export const setDestinationCost = (destination: Destination): CostTypes => ({type: CREATE_DESTINATION_COST, payload: destination});

export const createDestinationCost = (formValues: Destination): ThunkAction<Promise<Response>, {}, {}, CostTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, CostTypes>): Promise<Response> => {
    try {
        const response: AxiosResponse = await axios.post(`${server}/destination`, formValues, 
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } } );
        dispatch(setDestinationCost(response.data?.destino));
        return response.data;
    } catch (error) {
      throw error;
    }
};

//FIXME: Arreglar la interfaz
interface FetchCostResponse extends Response {
  options: any;
}

interface FetchDestinationCostsResponse {
  destinos: Destination[]
}