import { AUTH_USER_LOGIN, AUTH_USER_LOGOUT, AuthActionTypes, SET_USER, EDIT_USER, SET_TAXPAYER_INFO, AUTH_USER_BLOCK} from '../actions/actionsTypes';
import { States } from 'sigt';

const initialState: States.Auth = {
  user: null,
  token: null,
  block: null
};

export default (state: States.Auth = initialState, action: AuthActionTypes) => {
  switch(action.type) {
    case SET_USER: 
      return { ...state, user: action.payload.user, token: action.payload.token || state.token }
    case AUTH_USER_LOGIN:
      return { ...state, ...action.payload }
    case AUTH_USER_LOGOUT:
      return { ...state, token: null }
    case AUTH_USER_BLOCK:
      return { ...state, block: action.payload }      
    case EDIT_USER:
      return { ...state, user: {...state.user,...action.payload }}
    case SET_TAXPAYER_INFO: 
      return { ...state, user: { ...state.user, contribuyente: action.payload } };
    default: return state;
  }
};