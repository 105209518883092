import React from 'react';
import { Card, Typography, Row, Col, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const TDI: React.FC<TDIPRops> = ({ data, loading }) => {
  const formatNumber = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  return (
    <Card bodyStyle={{ paddingTop: 10 }} loading={loading}>
      <Typography.Text strong style={{ display: 'inline-block', marginBottom: 8 }}>Tasa de Default Intermensual (TDI)</Typography.Text> <br />
      <Row style={{ margin: 5 }}>
        <Col span={7}>
          <Typography.Text style={{ fontSize: 16, fontWeight: 'lighter', textAlign: 'center', width: '100%', color: 'rgba(0, 0, 0, 0.6)' }}>
            TDI
            <Tooltip title='Tasa de Default Intermensual: Representa el coeficiente entre los contribuyentes que pagaron el mes anterior pero no el mes actual.
              Los valores menores a 1 representan un decrecimiento en el numero de contribuyentes que cumplen dicha condición y viceversa.'>
              <QuestionCircleOutlined style={{ margin: '0 5px' }} />
            </Tooltip>
          </Typography.Text> <br />
          <Typography.Text strong style={{ fontSize: 18, textAlign: 'center', width: '100%', color: 'black' }}>
            {formatNumber(data?.coeficiente || 0)}
          </Typography.Text> 
        </Col>
        <Col span={17}>
          <Typography.Text style={{ fontSize: 16, fontWeight: 'lighter', textAlign: 'center', width: '100%', color: 'rgba(0, 0, 0, 0.6)' }}>
            Numero de Contribuyentes
            <Tooltip title='Numero de contribuyentes que pagaron el mes anterior pero no el mes actual.'>
              <QuestionCircleOutlined style={{ margin: '0 5px' }} />
            </Tooltip>
          </Typography.Text> <br />
          <Typography.Text strong style={{ fontSize: 18, textAlign: 'center', width: '100%', color: 'black' }}>
            {formatNumber(data?.valor || 0)}
          </Typography.Text> 
        </Col>
      </Row>
    </Card>
  );
};

export default TDI;

interface TDIPRops {
  data?: {
    anio: number
    mes: string
    coeficiente: number
    valor: number
  }
  loading: boolean
}