import React, { useState, useEffect } from 'react';
import { Card, Typography, Col, Row, DatePicker, Spin } from 'antd';
import { DonutChart } from 'bizcharts';
import '../../../assets/css/components/PieChart.css';
import { cloneDeep } from 'lodash';
import axios from 'axios';
import moment, { Moment } from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { State } from 'sigt';
const server = process.env.REACT_APP_SERVER_URL;

const PieChart: React.FC<PieChartProps> = ({ loading, setLoading, finished }) => {
  const [data, setData] = useState(fakeData);
  const [fetching, setFetching] = useState(false);
  const [startDate, setStartDate] = useState<string>(moment().utcOffset('-4').format('MM-DD-YYYY'));
  const [endDate, setEndDate] = useState<string>(moment().utcOffset('-4').format('MM-DD-YYYY'));

  const thm = useSelector((state: State) => state.thm);

  useEffect(() => {
    fetchPieStats();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loading) fetchInterval();
    // eslint-disable-next-line
  }, [startDate, endDate]);

  const fetchPieStats = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${server}/stats/sedemat/branch/bs?fechaInicio=${startDate}&fechaFin=${endDate}`);
      setData(response.data.estadisticas.totalBsPorRamo.map((t) => ({ ...t, valor: +(t.valor / 1).toFixed(2) })));
    } catch (e) {
      // TODO: XD
    } finally {
      if (finished) setLoading(false);
    }
  };

  const fetchInterval = async () => {
    setFetching(true);
    try {
      const response = await axios.get(`${server}/stats/sedemat/branch/bs?fechaInicio=${startDate}&fechaFin=${endDate}`);
      setData(response.data.estadisticas.totalBsPorRamo.map((t) => ({ ...t, valor: +(t.valor / 1).toFixed(2) })));
    } catch (e) {
      // TODO: XD
    } finally {
      setFetching(false);
    }
  };

  const formatNumber = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  const getName = (name: string) => {
    switch (name) {
      case 'AE':
        return 'Actividades Económicas';
      case 'SM':
        return 'Servicios Municipales';
      case 'PP':
        return 'Publicidad y Propaganda';
      case 'IU':
        return 'Propiedad Inmobiliaria';
      case 'OTROS':
        return 'Otros Ramos';
      default:
        return 'Ramo Desconocido';
    }
  };

  const changeDates = (e: Moment[]) => {
    if (e[0]) setStartDate(e[0].utcOffset('-4').format('MM-DD-YYYY'));
    if (e[1]) setEndDate(e[1].utcOffset('-4').format('MM-DD-YYYY'));
  };

  const copy = cloneDeep(data);
  const _data =
    copy.filter((d) => d.ramo === 'AE').length > 1
      ? copy.reduce((c, p) => {
          const index = c.findIndex((d) => d.ramo === p.ramo);
          if (index !== -1) c[index].valor += p.valor;
          else c.push(p);
          return c;
        }, [] as typeof copy)
      : copy;

  return (
    <Card bodyStyle={{ paddingTop: 10 }} loading={loading}>
      <Typography.Text strong style={{ display: 'inline-block', marginBottom: 15 }}>
        Recaudado por ramo por día
      </Typography.Text>{' '}
      <br />
      {!fetching ? (
        <DonutChart
          statistic={{ totalLabel: 'Total Bs.', visible: true }}
          data={_data}
          title={{ visible: false, text: '' }}
          forceFit
          description={{ visible: false, text: '' }}
          radius={1}
          height={350}
          padding='auto'
          angleField='valor'
          colorField='ramo'
          pieStyle={{ stroke: 'white', lineWidth: 5 }}
          meta={{ valor: { formatter: (value) => `Bs. ${formatNumber(value)}`, alias: 'Monto' }, ramo: { alias: 'Ramo' } }}
          tooltip={{ visible: true, formatter: (value, name) => ({ name: getName(name), value }) }}
          label={{ type: 'outer', visible: true }}
          legend={{ visible: true }}
        />
      ) : (
        <Row>
          <Col span={24} style={{ height: 350, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            <Spin
              tip='Cargando...'
              indicator={<LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '15px 0px' }} />}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col span={21} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
          <Typography.Text strong>Intervalo: </Typography.Text>
          <DatePicker.RangePicker
            value={[moment(startDate), moment(endDate)]}
            onChange={(v) => changeDates(v as any)}
            format='DD/MM/YYYY'
            style={{ marginLeft: 10 }}
            disabled={loading}
            disabledDate={(current) => current > moment() || current < moment('08/01/2020')}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default PieChart;

interface PieChartProps {
  loading: boolean;
  setLoading: (b: boolean) => void;
  finished: boolean;
}

const fakeData = [
  { ramo: 'AE', valor: +(4323987653 / 1000000).toFixed(2) },
  { ramo: 'IU', valor: +(1046827534 / 1000000).toFixed(2) },
  { ramo: 'SM', valor: +(872635827 / 1000000).toFixed(2) },
  { ramo: 'PP', valor: +(601827549 / 1000000).toFixed(2) },
];
