import React, { useState, useRef, useEffect } from 'react';
import { Input, Button, Table, Tag, Card, Tooltip, Row, Col, DatePicker } from 'antd';
import { connect } from 'react-redux';
import {  SearchOutlined, CreditCardOutlined, CalendarOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnsType } from 'antd/lib/table';
import { useWindowDimensions } from '../../utils/hooks';
import '@ant-design/compatible/assets/index.css';
import { State, States } from 'sigt';
import moment, { Moment } from 'moment';
import '../../assets/css/components/TableProcedures.css'
import { useHistory } from 'react-router';

const TableProcedure: React.FC<TableProcedureProps> = ({ thm, auth, prcd }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const inputRef = useRef<any>(null);
  const { width } = useWindowDimensions();
  const history = useHistory();
  const [keys, setKeys] = useState<Moment[]>([]);
  const [data, setData] = useState<any[]>([]);
  
  useEffect(() => {
    let _data: any[] = [];
    _data = prcd.procedures.filter(e => e.estado === 'pagocajero');
    setData(_data);
    // eslint-disable-next-line
  }, [prcd.procedures]);

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={inputRef}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) => 
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        if (inputRef.current) {
          inputRef.current?.select();
        }
      }
    },
    render: text =>  searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
    ),
  });

  const getColumnFilterDate = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      setKeys(selectedKeys);
    return(
      <Row style={{width:250, padding: 8}} gutter={[8,8]}>
        <Col span={24}>
          <DatePicker.RangePicker style={{width:'100%'}} format='DD/MM/YYYY' placeholder={['Fecha inicio ','Fecha fin']} size='small' 
            onChange={(values)=>{ setSelectedKeys(values) }} value={selectedKeys} 
          />
        </Col>
        <Col span={12}>
          <Button style={{width:'100%'}} type='primary' icon={<SearchOutlined />} size='small' onClick={() => { confirm(); }}>
            Buscar
          </Button>
        </Col>
        <Col span={12} >
          <Button style={{width:'100%'}} onClick={() => clearFilters()} size='small'>
            Restablecer
          </Button>
        </Col>
      </Row> 
    )},
    filterIcon: filtered => (
      <CalendarOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) => {
      return moment(record[dataIndex]).utc().format('DD-MM-YYYY') === keys[0]?.utc().format('DD-MM-YYYY') 
        || moment(record[dataIndex]).utc().isBetween(keys[0]?.utc(), keys[1]?.utc(), undefined, '[]')
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const columns: ColumnsType<any> = [
    //if inst === alcaldia. (esto para casos sociales)
    {
      title: 'Codigo',
      dataIndex: 'codigoTramite',
      align: 'center',
      ...getColumnSearchProps('codigoTramite'),
    },
    {
      title: 'Trámite',
      dataIndex: 'nombreTramiteCorto',
      align: 'center',
      ...getColumnSearchProps('nombreTramiteCorto'),
      render: (text, record) => <Tooltip title={record.nombreTramiteLargo}>{text}</Tooltip>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaCreacion',
      align: 'center',
      ...getColumnFilterDate('fechaCreacion'),
      render: text => moment(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      align: 'center',
      render: value => (<Tag style={{ width: '100%', textAlign: 'center' }} color='cyan'>En espera de pago</Tag>)
    },
    {
      title: '',
      dataIndex: 'acciones',
      fixed: 'right',
      align: 'center',
      width: 50,
      render: (text, record) => <Tooltip title='Pagar'>
      <Button shape='round' type='primary' className='ant-btn-procedure' icon={<CreditCardOutlined />}
      size='small' onClick={() => history.push(`/dashboard/pagarTramite/${record.id}`)}>
        {width > 440 ? 'Pagar' : null}
      </Button>
    </Tooltip>
    }
  ];

  return (
    <Card style={{ height: '100%' }} title='Tramites CPU por pagar' headStyle={{ backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: '#fff' }} bodyStyle={{ overflowY: 'auto', height:'90%' }}>
      <Table pagination={{ pageSize: 10 }} size='middle' columns={columns} dataSource={data} rowKey={e => e.id} />
    </Card>
  );
};

const mapStateToProps = (state: State) => ({thm: state.thm, auth: state.auth, prcd: state.prcd});

export default connect(mapStateToProps)(TableProcedure);

interface TableProcedureProps {
  updateProcedure: (data: any, token: string) => Promise<void>
  thm: States.ThemeColors
  auth: States.Auth
  prcd: States.Procedures
}