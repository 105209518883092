import React from 'react';
import { Select } from 'antd';

const TaxpayerTypeSelect: React.FC<TaxpayerTypeSelectProps> = ({ onChange, value, placeholder, disabled = false }) => {
  return (
    <Select value={value} onChange={onChange} placeholder={placeholder} disabled={disabled}>
      <Select.Option value='NATURAL'>Natural</Select.Option>
      <Select.Option value='JURIDICO'>Jurídico</Select.Option>
    </Select>
  );
};

export default TaxpayerTypeSelect;

interface TaxpayerTypeSelectProps {
  onChange?: (e: any) => any
  value?: any
  placeholder?: string
  disabled?: boolean
}