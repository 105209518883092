import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
  Select,
  Form,
  Input,
  InputNumber,
  Button,
  Divider,
  Typography,
  Upload,
  message,
  Modal,
  Spin,
  DatePicker,
  Descriptions,
} from 'antd';
import { State, States } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import MutiplePropertyValues from '../Forms/components/MutiplePropertyValues';
import moment from 'moment';
import handlingMessage from '../../utils/handlingMessage';
import { registerProperty, getProperty, editProperty, getDataIU } from '../../services/estates';
import fileImage from '../../assets/images/file.png';
import { useLocation } from 'react-router';
import { Utils } from '../../utils/validators';
import { UploadFile } from 'antd/lib/upload/interface';
import { AxiosResponse } from 'axios';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { cementerio, ejido, mercado, quiosco } from './../../utils/valuesIU';
import Petro from '../Icons/Petro';
import TextArea from 'antd/lib/input/TextArea';

const server = process.env.REACT_APP_SERVER_URL;
const { Dragger } = Upload;

const RegisterProperty: React.FC<RegisterPropertyProps> = ({ thm, auth, prsh, coin }) => {
  const { width } = useWindowDimensions();
  const tipoInmueble = [{ value: 'RESIDENCIAL' }, { value: 'COMERCIAL' }, { value: 'INDUSTRIAL' }];
  const clasificacionIU = [
    { value: 'EJIDO' },
    { value: 'MERCADO' },
    { value: 'QUIOSCO' },
    { value: 'CEMENTERIO' },
    { value: 'NO APLICA' },
  ];
  const [form] = Form.useForm();
  const [years, setYears] = useState<number[]>([]);
  const [property, setProperty] = useState<any>(null);
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);
  const [selectClasificacionIU, setSelectClasificacionIU] = useState('');
  const [dataIU, setDataIU] = useState<any>();
  const [selectAmbito, setSelectAmbito] = useState<any>();
  const [selectSector, setSelectSector] = useState<any>();
  const [selectManzana, setSelectManzana] = useState<any>();
  const [selectTipoTierraUrbana, setSelectTipoTierraUrbana] = useState<any>();
  const [selectClaseEdificacion, setSelectClaseEdificacion] = useState<any>();
  const [selectTipoConstruccion, setSelectTipoConstruccion] = useState<any>();
  const [selectValorConstruccion, setSelectValorConstruccion] = useState<any>();
  const [selectSubsector, setSelectSubsector] = useState<any>();
  const [tipoDocumento, setTipoDocumento] = useState('V');
  const [avaluos, setAvaluos] = useState<{
    valueTerreno: any;
    valueCosntruccion: any;
    mtsTerreno: any;
    mtsConstruccion: any;
    terreno: number;
    construccion: number;
  }>({
    mtsTerreno: 0,
    mtsConstruccion: 0,
    terreno: 0,
    construccion: 0,
    valueTerreno: 0,
    valueCosntruccion: 0,
  });

  const edit = location.pathname.includes('/dashboard/inmueblesUrbanos/editar');
  const title = edit ? 'Editar Inmueble' : 'Registrar Inmueble';

  const tipoAddon = (
    <Select value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value ='G'>G</Select.Option>
      <Select.Option value ='P'>P</Select.Option>
    </Select>
  );

  const handleSubmit = async () => {
    const valuesWithNoUserId = await form.validateFields();
    const {
      areaServicios,
      entidad,
      codCat_parroquia,
      ambito,
      codCat_sector: sector,
      manzana,
      tipoTierraUrbana,
      tipoConstruccion,
      codCat_subsector: subSector,
      parcela,
      SubParcela,
      nivel,
      unidad,
      clasificacion,
      contrato,
    } = valuesWithNoUserId;

    let _entidad = dataIU.entities.find((f) => f.id_entidad === entidad); // entidad federal 00 y municipio 00
    let _parroquia = dataIU.parishes.find((f) => f.id_parroquia === codCat_parroquia); // 00
    let _ambito = dataIU.ambits.find((f) => f.id_ambito === ambito); // U|R00
    let _sector = dataIU.sectors.find((f) => f.id_sector === sector); // 00-00
    let _manzana = dataIU.blocks.find((f) => f.id_manzana === manzana); // 00

    let _subSector = dataIU.subsectors.find((f) => f.id_subsector === subSector) ?? '000'; // 000
    let _parcela = parcela ?? '000'; // 000
    let _subParcela = SubParcela ?? '000'; // 000
    let _nivel = nivel ?? '000'; // 000
    let _unidad = unidad ?? '000'; // 000

    let _tipoTierraUrbana = dataIU.urbanLandTypes.find((f) => f.id_tipo_tierra_urbana === tipoTierraUrbana);
    let _tipoConstruccion = dataIU.constructionTypes.find((f) => f.id_tipo_construccion === tipoConstruccion);

    let codCat = ['MERCADO', 'QUIOSCO', 'CEMENTERIO'].includes(clasificacion)
      ? contrato
      : `${_entidad.codigo_entidad_federal}${_entidad.codigo_municipio}${_parroquia.cod_parroquia}${_ambito.cod_ambito}${_sector.cod_sector}${_manzana.cod_manzana}${_parcela}${_subParcela}${_nivel}${_unidad}`.replace(
          /-/g,
          ''
        );
    console.log('codCat', codCat);

    delete valuesWithNoUserId.entidad;
    delete valuesWithNoUserId.codCat_parroquia;
    delete valuesWithNoUserId.ambito;
    delete valuesWithNoUserId.codCat_sector;
    delete valuesWithNoUserId.tipoEdificacion;
    delete valuesWithNoUserId.claseEdificacion;
    delete valuesWithNoUserId.tipoVivienda;
    delete valuesWithNoUserId.codCatSearch;
    delete valuesWithNoUserId.codCat_subsector;
    delete valuesWithNoUserId.parcela;
    delete valuesWithNoUserId.SubParcela;
    delete valuesWithNoUserId.nivel;
    delete valuesWithNoUserId.unida;
    delete valuesWithNoUserId.contrato;

    valuesWithNoUserId.areaServicioIndicador = cementerio?.area_servicio?.find((a) => a.value === areaServicios)?.indicador ?? 0;

    const values = {
      ...valuesWithNoUserId,
      tipoDocumento: tipoDocumento,
      codCat,
      tipoConstruccion: _tipoConstruccion,
      tipoTierraUrbana: _tipoTierraUrbana,
      userId: auth.user ? auth.user.id : null,
    };
    const fd = new FormData();
    setLoading(true);
    fd.append('inmueble', file);
    const modal = Modal.info({
      title: 'Cargando...',
      content: (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Spin indicator={<LoadingOutlined color={thm.primaryColor} style={{ fontSize: 30 }} />} />
          <br />
          <br />
          <Typography.Text>Subiendo documento de soporte...</Typography.Text>
        </div>
      ),
      centered: true,
      maskClosable: false,
      okButtonProps: { disabled: true },
    });
    try {
      if (edit) valuesWithNoUserId.id = property?.id;
      if (file && !file?.url) {
        const response: AxiosResponse = await axios.post(
          `${server}/uploads/inmueble/${valuesWithNoUserId.codCat}?nuevoInmueble=${!edit}`,
          fd,
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        modal.update({
          content: (
            <div style={{ textAlign: 'center', marginTop: 20 }}>
              <Spin indicator={<LoadingOutlined color={thm.primaryColor} style={{ fontSize: 30 }} />} />
              <br />
              <br />
              <Typography.Text>Subiendo datos del inmueble...</Typography.Text>
            </div>
          ),
        });
        valuesWithNoUserId.dirDoc = response.data.archivos[0];
      }

      handlingMessage({
        action: () =>
          edit
            ? editProperty(
                {
                  ...valuesWithNoUserId,
                  tipoDocumento: tipoDocumento,
                  tipoConstruccion: _tipoConstruccion,
                  tipoTierraUrbana: _tipoTierraUrbana,
                  codCat: property?.codigoCatastral,
                  newCodCat: codCat,
                  userId: auth.user ? auth.user.id : null,
                },
                auth.token
              )
            : registerProperty(values, auth.token),
        key: 'submit',
        loadingMessage: 'Realizando operacion...',
        cb: (data) => {
          modal.destroy();
          if (data.status === 200) {
            Modal.info({
              title: 'Datos del Inmueble',
              content: (
                <div>
                  <p>Código De Identificación Del Inmueble: {data?.inmueble?.codigoCatastral}</p>
                  <p>Clasificación: {data?.inmueble?.clasificacion}</p>
                  <p>Dirección: {data?.inmueble?.direccion}</p>
                </div>
              ),
              centered: true,
              maskClosable: false,
            });

            reset();
          }
          setLoading(false);
        },
      });
    } catch (e) {
      modal.destroy();
      message.error('Error en el servidor');
    }
  };

  const reset = () => {
    form.resetFields();
    initYear();
    setLoading(false);
    setProperty(null);
  };

  const initYear = () => {
    const yearsAux: number[] = [];
    const today = moment().year();
    let init: number = parseInt(moment(today, 'YYYY').subtract(4, 'years').format('YYYY'));
    while (init <= today) {
      yearsAux.push(init);
      init++;
    }
    setYears(yearsAux);
  };

  useEffect(() => {
    initYear();
    setLoading(true);
    getDataIU(auth.token)
      .then((res) => setDataIU(res))
      .catch((error) => message.error('Error al momento de obtener los datos'));
    // eslint-disable-next-line
  }, []);
  const searchProperty = async () => {
    setDisabled(true);
    const values = await form.validateFields();
    handlingMessage({
      action: () => getProperty(values.codCatSearch, auth.token),
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: (data) => {
        if (data.status === 200) {
          const inmueble = { ...data.inmueble };
          inmueble.avaluos = inmueble.avaluos.map((a) => ({ ...a, avaluo: +Number(a.avaluo).toFixed(2) }));
          setProperty(inmueble);
        }
        setDisabled(false);
      },
    });
  };
  useEffect(() => {
    return () => {
      setProperty(null);
      setLoading(false);
      setYears([]);
      setDisabled(false);
      setFile(null);
    };
  }, []);

  useEffect(() => {
    const datos = {
      ...property,
      codCat: property?.codigoCatastral,
      fechaVencimiento: property?.fechaVencimiento ? moment(property?.fechaVencimiento).utcOffset(4) : moment(),
    };

    if (edit && property) {
      const {
        clasificacion,
        codigoCatastral,
        claseTerreno,
        manzana,
        ambito,
        tipoConstruccion,
        tipoTierraUrbana,
        valorConstruccion,
        avaluos: avaluosIU,
      } = property;

      delete datos.claseTerreno;
      delete datos.manzana;
      delete datos.tipoConstruccion;
      delete datos.tipoTierraUrbana;
      delete datos.valorConstruccion;
      delete datos.idTipoConstruccion;
      delete datos.idTipoTierraUrbana;

      datos.parcela = codigoCatastral.slice(-12, -9);
      datos.SubParcela = codigoCatastral.slice(-9, -6);
      datos.nivel = codigoCatastral.slice(-6, -3);
      datos.unidad = codigoCatastral.slice(-3);

      datos.contrato = ['MERCADO', 'QUIOSCO', 'CEMENTERIO'].includes(clasificacion) && codigoCatastral ? codigoCatastral : null;

      setAvaluos({
        ...avaluos,
        valueTerreno: avaluosIU[0]?.avaluoTerreno ?? 0,
        valueCosntruccion: avaluosIU[0]?.avaluoConstruccion ?? 0,
      });

      let _parroquia = codigoCatastral ? +codigoCatastral.slice(4, 6) : null;
      let _tipoEdificacion = property.hasOwnProperty('tipoConstruccion')
        ? dataIU.buildingClassifications.find((f) => f.id_clase_edificacion === tipoConstruccion.id_clase_edificacion)
            .id_tipo_edificacion
        : null;
      let _codCat_subsector = property.hasOwnProperty('manzana')
        ? dataIU.subsectors.find((f) => f.id_subsector === manzana.id_subsector)
        : null;
      let _codCat_sector =
        property.hasOwnProperty('manzana') && _codCat_subsector
          ? dataIU.sectors.find((f) => f.id_sector === _codCat_subsector.id_sector).id_sector
          : null;

      datos.entidad = _parroquia ? dataIU.parishes.find((f) => f.id_parroquia === _parroquia)?.id_entidad : null;
      datos.codCat_parroquia = _parroquia;
      datos.codCat_sector = _codCat_sector;
      datos.codCat_subsector = property.hasOwnProperty('manzana') ? _codCat_subsector?.id_subsector : null;
      datos.ambito = property.hasOwnProperty('ambito') ? ambito.id_ambito : null;
      datos.manzana = property.hasOwnProperty('manzana') ? manzana.id_manzana : null;
      datos.tipoTierraUrbana = property.hasOwnProperty('tipoTierraUrbana') ? tipoTierraUrbana?.id_tipo_tierra_urbana : null;

      datos.tipoConstruccion = property.hasOwnProperty('tipoConstruccion') ? tipoConstruccion?.id_tipo_construccion : null;
      datos.claseEdificacion = property.hasOwnProperty('tipoConstruccion') ? tipoConstruccion?.id_clase_edificacion : null;
      datos.tipoEdificacion = _tipoEdificacion;

      datos.claseTerreno = property.hasOwnProperty('claseTerreno') ? claseTerreno?.id_clase_terreno : null;
      datos.valorConstruccion = property.hasOwnProperty('valorConstruccion') ? valorConstruccion?.id_valor_construccion : null;
      datos.tipoVivienda = property.hasOwnProperty('valorConstruccion') ? valorConstruccion?.id_tipo_vivienda : null;

      let _ambito =
        property.hasOwnProperty('ambito') && _parroquia
          ? dataIU.ambits.filter((f) => f.id_parroquia === ambito.id_parroquia)
          : null;
      setSelectAmbito(property.hasOwnProperty('ambito') ? _ambito : null);

      let _sector =
        property.hasOwnProperty('ambito') && _parroquia ? dataIU.sectors.filter((f) => f.id_parroquia === _parroquia) : null;
      setSelectSector(_sector);

      let _subsector = property.hasOwnProperty('manzana')
        ? dataIU.subsectors.filter((f) => f.id_sector === _codCat_sector)
        : null;
      setSelectSubsector(_subsector);
      console.log(_subsector);

      let _manzana = property.hasOwnProperty('manzana')
        ? dataIU.blocks.filter((f) => f.id_subsector === manzana.id_subsector)
        : null;
      setSelectManzana(_manzana);

      let _tipoTierraUrbana = property.hasOwnProperty('tipoTierraUrbana')
        ? dataIU.urbanLandTypes.filter((f) => f.id_tipo_tierra_urbana === tipoTierraUrbana.id_tipo_tierra_urbana)
        : null;
      setSelectTipoTierraUrbana(_tipoTierraUrbana);

      let _tipoConstruccion = property.hasOwnProperty('tipoConstruccion')
        ? dataIU.constructionTypes.filter((f) => f.id_clase_edificacion === tipoConstruccion.id_clase_edificacion)
        : null;
      setSelectTipoConstruccion(_tipoConstruccion);

      let _claseEdificacion = dataIU.buildingClassifications.filter((f) => f.id_tipo_edificacion === _tipoEdificacion);
      setSelectClaseEdificacion(_claseEdificacion);

      let _valorConstruccion = property.hasOwnProperty('valorConstruccion')
        ? dataIU.constructionValues.filter((f) => f.id_tipo_vivienda === valorConstruccion.id_tipo_vivienda)
        : null;
      setSelectValorConstruccion(_valorConstruccion);
    }

    if (property?.dirDoc) {
      const urlSplit: string[] = property.dirDoc?.split('/');
      const name = urlSplit[urlSplit.length - 1];
      setFile({ uid: 1, url: property.dirDoc, status: 'done', name });
    }
    form.setFieldsValue({ ...datos });
    if (edit && property?.clasificacion) setSelectClasificacionIU(property?.clasificacion);
    // eslint-disable-next-line
  }, [property]);

  const formClasificaionIU = (key) => {
    switch (key) {
      case 'EJIDO':
        return (
          <>
            <Divider orientation='left'>
              <Typography.Title level={4}>Datos de inmueble {key?.toLowerCase() ?? key}</Typography.Title>
            </Divider>
            <Row style={{ paddingLeft: 16, width: '100%' }} gutter={16}>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Clase de Ejido'
                  name='clase'
                  rules={[{ required: true, message: 'Debe ingresar la clase de ejido' }]}
                >
                  <Select placeholder='Clase de ejido'>
                    {ejido.clase.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Tenencia'
                  name='tenencia'
                  rules={[{ required: true, message: 'Debe ingresar la tenencia del ejido' }]}
                >
                  <Select placeholder='Tenencia de ejido'>
                    {ejido.tenencia.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Uso de ejido'
                  name='uso'
                  rules={[{ required: true, message: 'Debe ingresar el uso del ejido' }]}
                >
                  <Select placeholder='Uso de ejido'>
                    {ejido.uso.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Nro de contrato'
                  name='contrato'
                  rules={[{ required: true, message: 'Debe ingresar el número del contrato' }]}
                >
                  <Input placeholder='Nro de contrato' />
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Fecha de vencimiento'
                  name='fechaVencimiento'
                  rules={[{ required: true, message: 'Debe ingresar la fecha de vencimiento' }]}
                >
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
          </>
        );
      case 'MERCADO':
        return (
          <>
            <Divider orientation='left'>
              <Typography.Title level={4}>Datos de inmueble {key?.toLowerCase() ?? key}</Typography.Title>
            </Divider>
            <Row style={{ paddingLeft: 16, width: '100%' }} gutter={16}>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Tipo de local'
                  name='tipoLocal'
                  rules={[{ required: true, message: 'Debe ingresar el tipo de local' }]}
                >
                  <Select placeholder='Tipo de local'>
                    {mercado.local.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Tipo de Mercado'
                  name='tipoMercado'
                  rules={[{ required: true, message: 'Debe ingresar el tipo de mercado' }]}
                >
                  <Select placeholder='Tipo de Mercado'>
                    <Select.Option key={1} value={'MERCADO LA ERMITA'}>
                      MERCADO LA ERMITA
                    </Select.Option>
                    <Select.Option key={2} value={'MERCADO LA GUAYANA'}>
                      MERCADO LA GUAYANA
                    </Select.Option>
                    <Select.Option key={3} value={'MERCADO LA VILLA'}>
                      MERCADO LA VILLA
                    </Select.Option>
                    <Select.Option key={4} value={'MERCADO SANTA TERESA'}>
                      MERCADO SANTA TERESA
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Actividad Comercial'
                  name='tipoAE'
                  rules={[{ required: true, message: 'Debe ingresar la actividad comercial' }]}
                >
                  <Select placeholder='Actividad Comercial'>
                    {mercado.tipo_ae.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Nro de contrato'
                  name='contrato'
                  rules={[
                    { required: true, message: 'Debe ingresar el número del contrato' },
                    { validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' },
                  ]}
                >
                  <Input placeholder='Nro de contrato' />
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Canon de Arrendamiento'
                  name='canonArrendamientoMercado'
                  rules={[{ required: true, message: 'Debe ingresar el canon de arrendamiento' }]}
                >
                  <InputNumber style={{ width: '100%' }} placeholder='Canon de Arrendamiento'></InputNumber>
                </Form.Item>
              </Col>
            </Row>
          </>
        );
      case 'QUIOSCO':
        return (
          <>
            <Divider orientation='left'>
              <Typography.Title level={4}>Datos de inmueble {key?.toLowerCase() ?? key}</Typography.Title>
            </Divider>
            <Row style={{ paddingLeft: 16, width: '100%' }} gutter={16}>
              <Col xl={8} xs={24}>
                <Form.Item label='Tipo' name='tipoQuiosco' rules={[{ required: true, message: 'Debe ingresar el tipo' }]}>
                  <Select placeholder='Tipo'>
                    {quiosco.tipo.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item label='Objeto' name='objetoQuiosco' rules={[{ required: true, message: 'Debe ingresar el objeto' }]}>
                  <Select placeholder='objeto'>
                    {quiosco.objeto.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item label='Zona' name='zonaQuiosco' rules={[{ required: true, message: 'Debe ingresar la zona' }]}>
                  <Select placeholder='Zona'>
                    {quiosco.zona.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Nro de contrato'
                  name='contrato'
                  rules={[
                    { required: true, message: 'Debe ingresar el número del contrato' },
                    { validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' },
                  ]}
                >
                  <Input placeholder='Nro de contrato' />
                </Form.Item>
              </Col>
            </Row>
          </>
        );
      case 'CEMENTERIO':
        return (
          <>
            <Divider orientation='left'>
              <Typography.Title level={4}>Datos de inmueble {key?.toLowerCase() ?? key}</Typography.Title>
            </Divider>
            <Row style={{ paddingLeft: 16, width: '100%' }} gutter={16}>
              <Col xl={10} xs={24}>
                <Form.Item
                  label='Area de servicio'
                  name='areaServicios'
                  rules={[{ required: true, message: 'Debe ingresar el area de servicio' }]}
                >
                  <Select placeholder='Area de Servicio'>
                    {cementerio.area_servicio.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={6} xs={24}>
                <Form.Item label='Sector' name='sector' rules={[{ required: true, message: 'Debe ingresar el sector' }]}>
                  <Select placeholder='Sector'>
                    {cementerio.sector.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Tenencia cementerio'
                  name='tenencia'
                  rules={[{ required: true, message: 'Debe ingresar la tenencia del cementerio' }]}
                >
                  <Select placeholder='Tenencia cementerio'>
                    {cementerio.tenencia.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Nro de contrato'
                  name='contrato'
                  rules={[
                    { required: true, message: 'Debe ingresar el número del contrato' },
                    { validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' },
                  ]}
                >
                  <Input placeholder='Nro de contrato' />
                </Form.Item>
              </Col>
            </Row>
          </>
        );
      default:
        break;
    }
  };

  const handleClasificacionIU = (value) => {
    setSelectClasificacionIU(value);
  };

  const content = (
    <>
      <Col xl={8} xs={24}>
        <Form.Item
          label='Clasificación del Inmueble'
          name='clasificacion'
          rules={[{ required: true, message: 'Debe ingresar la clasificación del inmueble' }]}
        >
          <Select placeholder='Clasificación del Inmueble' onChange={(e) => handleClasificacionIU(e)}>
            {clasificacionIU.map((option, index) => (
              <Select.Option key={index} value={option.value}>
                {option.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      {!['MERCADO', 'QUIOSCO', 'CEMENTERIO'].includes(selectClasificacionIU) && (
        <>
          {' '}
          <Divider orientation='left'>
            <Typography.Title level={4}>Código Catastral</Typography.Title>
          </Divider>
          <Row style={{ paddingLeft: 16 }} gutter={16}>
            <Col xl={8} xs={24}>
              <Form.Item label='Entidad' name='entidad' rules={[{ required: true, message: 'Debe ingresar la entidad' }]}>
                <Select placeholder='Entidad'>
                  {dataIU?.entities &&
                    dataIU?.entities?.map((option) => (
                      <Select.Option key={`${option.municipio} ${option.entidad_federal}`} value={option.id_entidad}>
                        {`${option.entidad_federal} ${option.municipio}`}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item
                label='Parroquia'
                name='codCat_parroquia'
                rules={[{ required: true, message: 'Debe ingresar la parroquia' }]}
              >
                <Select
                  placeholder='Parroquia'
                  onChange={(e) => {
                    let ambits = dataIU?.ambits.filter((f) => f.id_parroquia === e);
                    let sectors = dataIU?.sectors.filter((f) => f.id_parroquia === e);
                    form.setFieldsValue({
                      ...form,
                      codCat_sector: null,
                      ambito: null,
                      codCat_subsector: null,
                      manzana: null,
                      tipoTierraUrbana: null,
                    });
                    setSelectAmbito(ambits);
                    setSelectSector(sectors);
                    setSelectSubsector(null);
                    setSelectManzana(null);
                    setSelectTipoTierraUrbana(null);
                  }}
                >
                  {dataIU?.parishes &&
                    dataIU?.parishes?.map((option) => (
                      <Select.Option key={'codCat-' + option.nombre} value={option.id_parroquia}>
                        {option.nombre}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item label='Ambito' name='ambito' rules={[{ required: true, message: 'Debe ingresar el ambito' }]}>
                <Select placeholder='Ambito'>
                  {selectAmbito &&
                    selectAmbito?.map((option) => (
                      <Select.Option key={`${option.nombre} ${option.cod_ambito}`} value={option.id_ambito}>
                        {`${option.nombre} COD. ${option.cod_ambito}`}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item label='Sector' name='codCat_sector' rules={[{ required: true, message: 'Debe ingresar el sector' }]}>
                <Select
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option!.children.props.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder='Sector'
                  onChange={(e) => {
                    let subsectors = dataIU?.subsectors.filter((f) => f.id_sector === e);
                    form.setFieldsValue({ ...form, codCat_subsector: null, manzana: null, tipoTierraUrbana: null });
                    console.log(subsectors, e);
                    let blocks = dataIU?.blocks.filter((f) => subsectors.find((sub) => sub.id_subsector === f.id_subsector));
                    setSelectSubsector(subsectors);
                    setSelectManzana(blocks);
                    setSelectTipoTierraUrbana(null);
                  }}
                >
                  {selectSector &&
                    selectSector.map((option) => (
                      <Select.Option key={option.cod_sector} value={option.id_sector}>
                        <div
                          style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}
                        >{`${option.nombre} COD. ${option.cod_sector}`}</div>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col xl={8} xs={24}>
              <Form.Item
                label='Subsector'
                name='codCat_subsector'
                rules={[{ required: true, message: 'Debe ingresar el Subsector' }]}
              >
                <Select
                  placeholder='Subsector'
                  onChange={(e) => {
                    let blocks = dataIU?.blocks.filter((f) => f.id_subsector === e);
                    form.setFieldsValue({ ...form, manzana: null, tipoTierraUrbana: null });
                    setSelectManzana(blocks);
                    setSelectTipoTierraUrbana(null);
                  }}
                >
                  {selectSubsector &&
                    selectSubsector.map((option) => (
                      <Select.Option key={`${option.nombre} COD. ${option.cod_subsector}`} value={option.id_subsector}>
                        <div
                          style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}
                        >{`${option.nombre} COD. ${option.cod_subsector}`}</div>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col> */}
            <Col xl={8} xs={24}>
              <Form.Item label='Manzana' name='manzana' rules={[{ required: true, message: 'Debe ingresar la manzana' }]}>
                <Select
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option!.children.props.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder='Manzana'
                  onChange={(e) => {
                    let blocks = dataIU?.blocks.find((f) => f.id_manzana === e);
                    let urbanLandTypes = dataIU?.urbanLandTypes.filter(
                      (f) => f.id_tipo_tierra_urbana === blocks.id_tipo_tierra_urbana
                    );
                    form.setFieldsValue({ ...form, tipoTierraUrbana: null });
                    setSelectTipoTierraUrbana(urbanLandTypes);
                  }}
                >
                  {selectManzana &&
                    selectManzana.map((option) => (
                      <Select.Option key={option.cod_manzana} value={option.id_manzana}>
                        <div
                          style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}
                        >{`${option.nombre} COD. ${option.cod_manzana}`}</div>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item
                label='Tipo Tierra Urbana'
                name='tipoTierraUrbana'
                rules={[{ required: true, message: 'Debe ingresar un tipo de tierra urbana' }]}
              >
                <Select placeholder='Tipo Tierra Urbana'>
                  {selectTipoTierraUrbana &&
                    selectTipoTierraUrbana.map((option) => (
                      <Select.Option key={option.nombre} value={option.id_tipo_tierra_urbana}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.nombre}</div>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item
                label='Parcela'
                name='parcela'
                rules={[
                  { required: true, message: 'Debe ingresar una parcela' },
                  { validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' },
                ]}
              >
                <Input maxLength={3} placeholder='Parcela' />
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item
                label='SubParcela'
                name='SubParcela'
                rules={[
                  { required: true, message: 'Debe ingresar una SubParcela' },
                  { validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' },
                ]}
              >
                <Input maxLength={3} placeholder='SubParcela' />
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item
                label='Nivel'
                name='nivel'
                rules={[
                  { required: true, message: 'Debe ingresar un nivel' },
                  { validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' },
                ]}
              >
                <Input maxLength={3} placeholder='nivel' />
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item
                label='Unidad'
                name='unidad'
                rules={[
                  { required: true, message: 'Debe ingresar una unidad' },
                  { validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' },
                ]}
              >
                <Input maxLength={3} placeholder='Unidad' />
              </Form.Item>
            </Col>
          </Row>{' '}
        </>
      )}
      <Divider orientation='left'>
        <Typography.Title level={4}>Datos del inmueble</Typography.Title>
      </Divider>
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        {/* <Col xl={8} xs={24}>
          <Form.Item
            label='Código de identificación del Inmueble'
            name='codCat'
            normalize={Utils.normalize.toUpper}
            rules={[{ required: true, message: 'Debe ingresar el Código de identificación del Inmueble' }]}
          >
            <Input placeholder='Código de identificación del Inmueble' />
          </Form.Item>
        </Col> */}
        { (selectClasificacionIU !== 'CEMENTERIO') &&
        <Col xl={8} xs={24}>
          <Form.Item
            label='Metros de Construcción'
            name='metrosConstruccion'
            rules={[{ required: true, message: 'Debe ingresar la cantidad de metros' }]}
          >
            <InputNumber placeholder='Metros de Construcción' style={{ width: '100%' }} />
          </Form.Item>
        </Col>}
        <Col xl={8} xs={24}>
          <Form.Item
            label='Metros de Terreno'
            name='metrosTerreno'
            rules={[{ required: true, message: 'Debe ingresar la cantidad de metros' }]}
          >
            <InputNumber placeholder='Metros de Terreno' style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item label='Parroquia' name='idParroquia' rules={[{ required: true, message: 'Debe ingresar la parroquia' }]}>
            <Select placeholder='Parroquia'>
              {prsh.parish.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ paddingLeft: 16 }} gutter={16}>
      { (selectClasificacionIU !== 'CEMENTERIO') &&
        <Col xl={8} xs={24}>
          <Form.Item
            label='Tipo de Inmueble'
            name='tipoInmueble'
            rules={[{ required: true, message: 'Debe ingresar el tipo de inmueble' }]}
          >
            <Select placeholder='Tipo de Inmueble'>
              {tipoInmueble.map((option, index) => (
                <Select.Option key={index} value={option.value}>
                  {option.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col> }
        <Col xl={8} xs={24}>
          <Form.Item label='Dirección' name='direccion' rules={[{ required: true, message: 'Debe ingresar la direccion' }]}>
            <Input maxLength={250} placeholder='Dirección' />
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item
            label='Ocupado por'
            name='doc'
            rules={[{ required: false }]}
          >
            <Input addonBefore={tipoAddon} placeholder='Documento de Identidad' />
          </Form.Item>
        </Col>
        {formClasificaionIU(selectClasificacionIU)}
      </Row>
      <Divider orientation='left'>
        <Typography.Title level={4}>Impuesto De Construcción</Typography.Title>
      </Divider>
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        { (selectClasificacionIU !== 'EJIDO' && selectClasificacionIU !== 'QUIOSCO' && selectClasificacionIU !== 'CEMENTERIO') &&
        <Col xl={8} xs={24}>
          <Form.Item
            label='Tipo Edificación'
            name='tipoEdificacion'
            rules={[{ required: true, message: 'Debe ingresar un tipo de edificación' }]}
          >
            <Select
              placeholder='Tipo Edificación'
              onChange={(e) => {
                let buildingClassifications = dataIU?.buildingClassifications.filter((f) => f.id_tipo_edificacion === e);
                form.setFieldsValue({ ...form, claseEdificacion: null, tipoConstruccion: null });
                setSelectClaseEdificacion(buildingClassifications);
                setSelectTipoConstruccion(null);
              }}
            >
              {dataIU?.buildingTypes &&
                dataIU?.buildingTypes?.map((option) => (
                  <Select.Option key={option.nombre} value={option.id_tipo_edificacion}>
                    <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.nombre}</div>
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col> }
        { (selectClasificacionIU !== 'EJIDO' && selectClasificacionIU !== 'QUIOSCO' && selectClasificacionIU !== 'CEMENTERIO') &&
        <Col xl={8} xs={24}>
          <Form.Item
            label='Clase Edificación'
            name='claseEdificacion'
            rules={[{ required: true, message: 'Debe ingresar una clase de edificación' }]}
          >
            <Select
              placeholder='Clase Edificación'
              onChange={(e) => {
                let constructionTypes = dataIU?.constructionTypes.filter((f) => f.id_clase_edificacion === e);
                form.setFieldsValue({ ...form, tipoConstruccion: null });
                setSelectTipoConstruccion(constructionTypes);
              }}
            >
              {selectClaseEdificacion &&
                selectClaseEdificacion.map((option) => (
                  <Select.Option key={option.id_clase_edificacion} value={option.id_clase_edificacion}>
                    <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.nombre}</div>
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col> }
        { (selectClasificacionIU !== 'EJIDO' && selectClasificacionIU !== 'QUIOSCO' && selectClasificacionIU !== 'CEMENTERIO') &&
        <Col xl={8} xs={24}>
          <Form.Item
            label='Tipo Construcción'
            name='tipoConstruccion'
            rules={[{ required: true, message: 'Debe ingresar un tipo de construcción' }]}
          >
            <Select placeholder='Tipo Construcción'>
              {selectTipoConstruccion &&
                selectTipoConstruccion.map((option) => (
                  <Select.Option key={option.id} value={option.id_tipo_construccion}>
                    <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.nombre}</div>
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col> }
      </Row>
      { (selectClasificacionIU !== 'QUIOSCO' && selectClasificacionIU !== 'CEMENTERIO') &&
      <Divider orientation='left'>
        <Typography.Title level={4}>Impuesto De Terreno</Typography.Title>
      </Divider>}
      <Row style={{ paddingLeft: 16 }} gutter={16}>
      { (selectClasificacionIU !== 'QUIOSCO' && selectClasificacionIU !== 'CEMENTERIO') &&
        <Col xl={8} xs={24}>
          <Form.Item
            label='Clase Terreno'
            name='claseTerreno'
            rules={[{ required: true, message: 'Debe ingresar una clase de terreno' }]}
          >
            <Select placeholder='Clase Terreno'>
              {dataIU?.landClasses &&
                dataIU?.landClasses?.map((option) => (
                  <Select.Option key={option.nombre} value={option.id_clase_terreno}>
                    <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.nombre}</div>
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col> }
        { (selectClasificacionIU !== 'EJIDO' && selectClasificacionIU !== 'QUIOSCO' && selectClasificacionIU !== 'CEMENTERIO') &&
        <Col xl={8} xs={24}>
          <Form.Item
            label='Tipo Viviendas'
            name='tipoVivienda'
            rules={[{ required: true, message: 'Debe ingresar un tipo de vivienda' }]}
          >
            <Select
              placeholder='Tipo Viviendas'
              onChange={(e) => {
                let constructionValues = dataIU?.constructionValues.filter((f) => f.id_tipo_vivienda === e);
                form.setFieldsValue({ ...form, valorConstruccion: null });
                setSelectValorConstruccion(constructionValues);
              }}
            >
              {dataIU?.dwellingTypes &&
                dataIU?.dwellingTypes?.map((option) => (
                  <Select.Option key={option.id_tipo_vivienda} value={option.id_tipo_vivienda}>
                    <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.nombre}</div>
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col> }
        { (selectClasificacionIU !== 'EJIDO' && selectClasificacionIU !== 'QUIOSCO' && selectClasificacionIU !== 'CEMENTERIO') &&
        <Col xl={8} xs={24}>
          <Form.Item
            label='Valor Construcción'
            name='valorConstruccion'
            rules={[{ required: true, message: 'Debe ingresar un valor de construcción' }]}
          >
            <Select placeholder='Valor Construcción'>
              {selectValorConstruccion &&
                selectValorConstruccion.map((option) => (
                  <Select.Option key={option.nombre} value={option.id_valor_construccion}>
                    <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.nombre}</div>
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>}
      </Row>

      {edit && (
        <>
          <Divider orientation='left'>
            <Typography.Title level={4}>Avalúos del inmueble</Typography.Title>
          </Divider>
          <Descriptions
            style={{ marginTop: 15 }}
            column={width > 992 ? 2 : 1}
            layout={width > 768 ? 'horizontal' : 'vertical'}
            bordered
          >
            <Descriptions.Item label='Avalúo de Terreno en Petros'>
              {edit && avaluos?.valueTerreno ? Number(avaluos?.valueTerreno ?? 0).toFixed(8) : 0} <Petro />
            </Descriptions.Item>
            <Descriptions.Item label='Avalúo de Construcción en Petros'>
              {edit && avaluos?.valueCosntruccion ? Number(avaluos?.valueCosntruccion ?? 0).toFixed(8) : 0} <Petro />
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
      <Divider orientation='left'>
        <Typography.Title level={4}>Relativo</Typography.Title>
      </Divider>
      <Row style={{ paddingLeft: 16 }}>
        <Col xl={24} xs={24}>
          <Form.Item name='relativo'>
            <TextArea rows={5} />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation='left'>
        <Typography.Title level={4}>Documento soporte del inmueble</Typography.Title>
      </Divider>
      <Row style={{ paddingLeft: 16 }} gutter={[16, 24]}>
        <Col xl={8} xs={24}>
          <Dragger
            style={{ width: '100%', padding: 16 }}
            accept='.pdf, image/*'
            name='doc'
            fileList={file ? [file] : []}
            beforeUpload={(file: UploadFile) => {
              setFile(file);
              return false;
            }}
            onRemove={() => setFile(undefined)}
          >
            <p className='ant-upload-drag-icon'>
              <img src={fileImage} alt='pdfIcon' style={{ width: 100 }} />
            </p>
            <p className='ant-upload-text'>Haga clic o arrastre el archivo a esta área para subir el archivo</p>
          </Dragger>
        </Col>
      </Row>
      {/* <Divider orientation='left'>
        <Typography.Title level={4}>Avalúos del inmueble</Typography.Title>
      </Divider> */}
      {/* <MutiplePropertyValues yearsArray={years} form={form} loading={loading} /> */}
      <Row style={{ marginLeft: 16 }}>
        <Button type='primary' onClick={handleSubmit}>
          {edit ? 'Guardar' : 'Registrar'}
        </Button>
      </Row>
    </>
  );
  const search = (
    <Row style={{ paddingLeft: 16 }} gutter={16}>
      <Col span={12}>
        <Form.Item
          label='Código de identificación del Inmueble'
          name='codCatSearch'
          normalize={Utils.normalize.toUpper}
          rules={[{ required: true, message: 'Debe ingresar el Código de identificación del Inmueble' }]}
        >
          <Input placeholder='Código de identificación del Inmueble' />
        </Form.Item>
      </Col>
      <Col span={12} style={{ alignSelf: 'center', paddingTop: '15px' }}>
        <Button type='primary' disabled={disabled} onClick={() => searchProperty()}>
          Buscar
        </Button>
      </Col>
    </Row>
  );

  return (
    <Card
      style={{ height: '100%' }}
      title={title}
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Form form={form} layout='vertical'>
        {edit && search}
        {property && edit && content}
        {!property && !edit && content}
      </Form>
    </Card>
  );
};

interface RegisterPropertyProps {
  auth: States.Auth;
  thm: States.ThemeColors;
  prsh: States.Parish;
  coin: States.Coins;
}

export interface Entity {
  id_entidad: number;
  entidad_federal: string;
  municipio: string;
  codigo_entidad_federal: number;
  codigo_municipio: number;
}

export interface Parish {
  id_parroquia: number;
  nombre: string;
  cod_parroquia: string;
  id_entidad: number;
}

export interface Ambit {
  id_ambito: number;
  nombre: string;
  cod_ambito: string;
  id_parroquia: number;
}

export interface Sector {
  id_sector: number;
  nombre: string;
  cod_sector: string;
  id_parroquia: number;
}

export interface Block {
  id_manzana: number;
  nombre: string;
  cod_manzana: string;
  id_sector: number;
  id_tipo_tierra_urbana: number;
}

export interface UrbanLandType {
  id_tipo_tierra_urbana: number;
  monto: string;
  id_valor_tierra_urbana: number;
  id_clasificacion_tierra_urbana: number;
  nombre: string;
}

export interface BuildingType {
  id_tipo_edificacion: number;
  nombre: string;
}

export interface BuildingClassification {
  id_clase_edificacion: number;
  nombre: string;
  id_tipo_edificacion: number;
}

export interface ConstructionType {
  id_tipo_construccion: number;
  nombre: string;
  id_clase_edificacion: number;
  monto: string;
}

export interface DwellingType {
  id_tipo_vivienda: number;
  nombre: string;
}

export interface ConstructionValue {
  id_valor_construccion: number;
  nombre: string;
  id_tipo_vivienda: number;
  indicador: string;
  minimo: string;
}

export interface LandClass {
  id_clase_terreno: number;
  nombre: string;
  indicador: string;
  periodo: number;
}

export interface RootObject {
  entities: Entity[];
  parishes: Parish[];
  ambits: Ambit[];
  sectors: Sector[];
  blocks: Block[];
  urbanLandTypes: UrbanLandType[];
  buildingTypes: BuildingType[];
  buildingClassifications: BuildingClassification[];
  constructionTypes: ConstructionType[];
  dwellingTypes: DwellingType[];
  constructionValues: ConstructionValue[];
  landClasses: LandClass[];
}

const mapStateToProps = ({ thm, auth, prsh, coin }: State) => ({ thm, auth, prsh, coin });

export default connect(mapStateToProps)(RegisterProperty);
