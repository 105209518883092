import { States } from 'sigt';
import { BranchesType, SET_BRANCHES, SET_BRANCHES_CATEGORY } from '../actions/actionsTypes';

const initialState: States.Branches = {
  branches: [],
  branchesCategory: [],
};

export default (state: States.Branches = initialState, action: BranchesType) => {
  switch (action.type) {
    case SET_BRANCHES:
      return { ...state, branches: action.payload };
    case SET_BRANCHES_CATEGORY:
      return { ...state, branchesCategory: action.payload };
    default:
      return state;
  }
};
