import { States } from "sigt";
import { AgreementsType, SET_AGREEMENTS, PAY_AGREEMENT, AUTH_USER_LOGOUT } from "../actions/actionsTypes";
import _ from 'lodash';

const initialState: States.Agreements = {
  agreements: []
};

export default (state = initialState, action: AgreementsType) => {
  switch(action.type) {
    case SET_AGREEMENTS: return { ...state, agreements: action.payload };
    case PAY_AGREEMENT: 
      const agreements = _.cloneDeep(state.agreements)
      const cIndex = agreements.findIndex(a => a.id === action.payload.idConvenio);
      const porciones = agreements[cIndex].porciones;
      const fIndex = porciones.findIndex(p=> p.id === action.payload.id);
      porciones[fIndex] = Object.assign({}, action.payload);
      agreements[cIndex] = {...agreements[cIndex], porciones};
      return { ...state, agreements };
    case AUTH_USER_LOGOUT: 
      return initialState;
    default: return state;
  }
}